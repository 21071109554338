let headerAnimdata = {
  nm: "newScene",
  ddd: 0,
  h: 720,
  w: 1152,
  meta: { g: "@lottiefiles/toolkit-js 0.25.8" },
  layers: [
    {
      ty: 4,
      nm: "numbers Outlines",
      sr: 1,
      st: 0,
      op: 120,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [576, 360, 0] },
        s: { a: 0, k: [100, 100, 100] },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [576, 360, 0] },
        r: { a: 0, k: 0 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 1",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.191, -0.287],
                    [0.335, 0],
                    [0.191, 0.288],
                    [0, 0.601],
                    [-0.191, 0.288],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                  ],
                  o: [
                    [-0.191, 0.288],
                    [-0.329, 0],
                    [-0.191, -0.287],
                    [0, -0.601],
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.288],
                    [0, 0.601],
                  ],
                  v: [
                    [0.789, 1.333],
                    [0.001, 1.764],
                    [-0.779, 1.333],
                    [-1.065, 0],
                    [-0.779, -1.333],
                    [0.001, -1.764],
                    [0.789, -1.333],
                    [1.075, 0],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.356, 0.224],
                    [0.455, 0],
                    [0.355, -0.225],
                    [0.202, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                    [-0.356, -0.224],
                    [-0.455, 0],
                    [-0.355, 0.225],
                    [-0.202, 0.426],
                    [0, 0.591],
                    [0.201, 0.426],
                  ],
                  o: [
                    [-0.355, -0.225],
                    [-0.455, 0],
                    [-0.356, 0.224],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.202, 0.426],
                    [0.355, 0.225],
                    [0.455, 0],
                    [0.356, -0.224],
                    [0.201, -0.426],
                    [0, -0.591],
                    [-0.202, -0.426],
                  ],
                  v: [
                    [1.216, -2.501],
                    [0.001, -2.839],
                    [-1.214, -2.501],
                    [-2.05, -1.525],
                    [-2.352, 0],
                    [-2.05, 1.525],
                    [-1.214, 2.501],
                    [0.001, 2.839],
                    [1.216, 2.501],
                    [2.052, 1.525],
                    [2.353, 0],
                    [2.052, -1.525],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [836.032, 220.937], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 2",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1, -1.5],
                    [0, -1.5],
                    [0, 2.5],
                    [1, 2.5],
                    [1, -2.5],
                    [-1, -2.5],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [836, 211.5], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 3",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [1, 3],
                    [1, -3],
                    [-1, -3],
                    [-1, -2],
                    [0, -2],
                    [0, 3],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [836, 202], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 4",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.287],
                    [0, 0.601],
                    [-0.191, 0.287],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                  ],
                  o: [
                    [-0.191, 0.287],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.287],
                    [0, 0.601],
                  ],
                  v: [
                    [0.789, 1.333],
                    [0.001, 1.765],
                    [-0.779, 1.333],
                    [-1.065, 0.001],
                    [-0.779, -1.332],
                    [0.001, -1.764],
                    [0.789, -1.332],
                    [1.075, 0.001],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.356, 0.225],
                    [0.455, 0],
                    [0.355, -0.224],
                    [0.202, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                    [-0.356, -0.224],
                    [-0.455, 0],
                    [-0.355, 0.225],
                    [-0.202, 0.426],
                    [0, 0.591],
                    [0.201, 0.426],
                  ],
                  o: [
                    [-0.355, -0.224],
                    [-0.455, 0],
                    [-0.356, 0.225],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.202, 0.426],
                    [0.355, 0.225],
                    [0.455, 0],
                    [0.356, -0.224],
                    [0.201, -0.426],
                    [0, -0.591],
                    [-0.202, -0.426],
                  ],
                  v: [
                    [1.216, -2.501],
                    [0.001, -2.839],
                    [-1.214, -2.501],
                    [-2.05, -1.524],
                    [-2.352, 0.001],
                    [-2.05, 1.526],
                    [-1.214, 2.502],
                    [0.001, 2.839],
                    [1.216, 2.502],
                    [2.052, 1.526],
                    [2.353, 0.001],
                    [2.052, -1.524],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [836.032, 192.707], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 5",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [1, 2.5],
                    [1, -2.5],
                    [-1, -2.5],
                    [-1, -1.5],
                    [0, -1.5],
                    [0, 2.5],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [836, 183.5], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 6",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.191, -0.287],
                    [0.335, 0],
                    [0.191, 0.288],
                    [0, 0.601],
                    [-0.191, 0.287],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                  ],
                  o: [
                    [-0.191, 0.288],
                    [-0.329, 0],
                    [-0.191, -0.287],
                    [0, -0.601],
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.287],
                    [0, 0.601],
                  ],
                  v: [
                    [0.789, 1.333],
                    [0.001, 1.764],
                    [-0.779, 1.333],
                    [-1.065, 0],
                    [-0.779, -1.333],
                    [0.001, -1.764],
                    [0.789, -1.333],
                    [1.075, 0],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.356, 0.224],
                    [0.455, 0],
                    [0.355, -0.225],
                    [0.202, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                    [-0.356, -0.224],
                    [-0.455, 0],
                    [-0.355, 0.225],
                    [-0.202, 0.426],
                    [0, 0.591],
                    [0.201, 0.426],
                  ],
                  o: [
                    [-0.355, -0.225],
                    [-0.455, 0],
                    [-0.356, 0.224],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.202, 0.426],
                    [0.355, 0.225],
                    [0.455, 0],
                    [0.356, -0.224],
                    [0.201, -0.426],
                    [0, -0.591],
                    [-0.202, -0.426],
                  ],
                  v: [
                    [1.216, -2.501],
                    [0.001, -2.839],
                    [-1.214, -2.501],
                    [-2.05, -1.525],
                    [-2.352, 0],
                    [-2.05, 1.525],
                    [-1.214, 2.501],
                    [0.001, 2.839],
                    [1.216, 2.501],
                    [2.052, 1.525],
                    [2.353, 0],
                    [2.052, -1.525],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [836.032, 173.888], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 7",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [1, 2.5],
                    [1, -2.5],
                    [-1, -2.5],
                    [-1, -1.5],
                    [0, -1.5],
                    [0, 2.5],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [836, 164.5], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 8",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.191, -0.287],
                    [0.335, 0],
                    [0.191, 0.288],
                    [0, 0.601],
                    [-0.191, 0.288],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                  ],
                  o: [
                    [-0.191, 0.288],
                    [-0.329, 0],
                    [-0.191, -0.287],
                    [0, -0.601],
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.288],
                    [0, 0.601],
                  ],
                  v: [
                    [0.789, 1.333],
                    [0.001, 1.764],
                    [-0.779, 1.333],
                    [-1.065, 0],
                    [-0.779, -1.333],
                    [0.001, -1.764],
                    [0.789, -1.333],
                    [1.075, 0],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.356, 0.224],
                    [0.455, 0],
                    [0.355, -0.225],
                    [0.202, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                    [-0.356, -0.224],
                    [-0.455, 0],
                    [-0.355, 0.225],
                    [-0.202, 0.426],
                    [0, 0.591],
                    [0.201, 0.426],
                  ],
                  o: [
                    [-0.355, -0.225],
                    [-0.455, 0],
                    [-0.356, 0.224],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.202, 0.426],
                    [0.355, 0.225],
                    [0.455, 0],
                    [0.356, -0.224],
                    [0.201, -0.426],
                    [0, -0.591],
                    [-0.202, -0.426],
                  ],
                  v: [
                    [1.216, -2.501],
                    [0.001, -2.839],
                    [-1.214, -2.501],
                    [-2.05, -1.525],
                    [-2.352, 0],
                    [-2.05, 1.525],
                    [-1.214, 2.501],
                    [0.001, 2.839],
                    [1.216, 2.501],
                    [2.052, 1.525],
                    [2.353, 0],
                    [2.052, -1.525],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [836.032, 155.068], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 9",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [1, 2.5],
                    [1, -2.5],
                    [-1, -2.5],
                    [-1, -1.5],
                    [0, -1.5],
                    [0, 2.5],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [836, 145.5], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 10",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.191, 0.288],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                    [0.191, -0.287],
                    [0.335, 0],
                    [0.191, 0.288],
                    [0, 0.601],
                  ],
                  o: [
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.288],
                    [0, 0.601],
                    [-0.191, 0.288],
                    [-0.329, 0],
                    [-0.191, -0.287],
                    [0, -0.601],
                  ],
                  v: [
                    [-0.781, -1.333],
                    [-0.001, -1.764],
                    [0.787, -1.333],
                    [1.073, 0],
                    [0.787, 1.333],
                    [-0.001, 1.764],
                    [-0.781, 1.333],
                    [-1.067, 0],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.356, -0.224],
                    [-0.455, 0],
                    [-0.356, 0.226],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.201, 0.426],
                    [0.355, 0.225],
                    [0.455, 0],
                    [0.355, -0.225],
                    [0.201, -0.426],
                    [0, -0.591],
                    [-0.202, -0.426],
                  ],
                  o: [
                    [0.355, 0.226],
                    [0.455, 0],
                    [0.355, -0.224],
                    [0.201, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                    [-0.356, -0.225],
                    [-0.455, 0],
                    [-0.356, 0.225],
                    [-0.202, 0.426],
                    [0, 0.591],
                    [0.201, 0.426],
                  ],
                  v: [
                    [-1.216, 2.501],
                    [-0.001, 2.839],
                    [1.215, 2.501],
                    [2.05, 1.525],
                    [2.352, 0],
                    [2.05, -1.525],
                    [1.215, -2.501],
                    [-0.001, -2.839],
                    [-1.216, -2.501],
                    [-2.051, -1.525],
                    [-2.353, 0],
                    [-2.051, 1.525],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [836.033, 136.248], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 11",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.191, -0.287],
                    [0.335, 0],
                    [0.191, 0.288],
                    [0, 0.601],
                    [-0.191, 0.288],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                  ],
                  o: [
                    [-0.191, 0.288],
                    [-0.329, 0],
                    [-0.191, -0.287],
                    [0, -0.601],
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.288],
                    [0, 0.601],
                  ],
                  v: [
                    [0.789, 1.333],
                    [0.001, 1.764],
                    [-0.779, 1.333],
                    [-1.065, 0],
                    [-0.779, -1.333],
                    [0.001, -1.764],
                    [0.789, -1.333],
                    [1.075, 0],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.356, 0.224],
                    [0.455, 0],
                    [0.356, -0.225],
                    [0.202, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                    [-0.355, -0.224],
                    [-0.455, 0],
                    [-0.355, 0.225],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.202, 0.426],
                  ],
                  o: [
                    [-0.355, -0.225],
                    [-0.455, 0],
                    [-0.355, 0.224],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.202, 0.426],
                    [0.356, 0.225],
                    [0.455, 0],
                    [0.356, -0.224],
                    [0.202, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                  ],
                  v: [
                    [1.216, -2.501],
                    [0.001, -2.839],
                    [-1.215, -2.501],
                    [-2.05, -1.525],
                    [-2.352, 0],
                    [-2.05, 1.525],
                    [-1.215, 2.501],
                    [0.001, 2.839],
                    [1.216, 2.501],
                    [2.051, 1.525],
                    [2.353, 0],
                    [2.051, -1.525],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [824.052, 220.937], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 12",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.287],
                    [0, 0.601],
                    [-0.191, 0.287],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                  ],
                  o: [
                    [-0.191, 0.287],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.287],
                    [0, 0.601],
                  ],
                  v: [
                    [0.789, 1.333],
                    [0.001, 1.765],
                    [-0.779, 1.333],
                    [-1.065, 0.001],
                    [-0.779, -1.332],
                    [0.001, -1.764],
                    [0.789, -1.332],
                    [1.075, 0.001],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.356, 0.225],
                    [0.455, 0],
                    [0.356, -0.224],
                    [0.202, -0.427],
                    [0, -0.591],
                    [-0.201, -0.426],
                    [-0.355, -0.225],
                    [-0.455, 0],
                    [-0.355, 0.225],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.202, 0.426],
                  ],
                  o: [
                    [-0.355, -0.224],
                    [-0.455, 0],
                    [-0.355, 0.225],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.202, 0.426],
                    [0.356, 0.225],
                    [0.455, 0],
                    [0.356, -0.225],
                    [0.202, -0.426],
                    [0, -0.591],
                    [-0.201, -0.427],
                  ],
                  v: [
                    [1.216, -2.501],
                    [0.001, -2.839],
                    [-1.215, -2.501],
                    [-2.05, -1.524],
                    [-2.352, 0.001],
                    [-2.05, 1.526],
                    [-1.215, 2.502],
                    [0.001, 2.839],
                    [1.216, 2.502],
                    [2.051, 1.526],
                    [2.353, 0.001],
                    [2.051, -1.524],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [824.052, 211.527], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 13",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1, -2],
                    [0, -2],
                    [0, 3],
                    [1, 3],
                    [1, -3],
                    [-1, -3],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [824, 202], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 14",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [1, 2.5],
                    [1, -2.5],
                    [-1, -2.5],
                    [-1, -1.5],
                    [0, -1.5],
                    [0, 2.5],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [824, 192.5], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 15",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.191, -0.287],
                    [0.335, 0],
                    [0.191, 0.287],
                    [0, 0.601],
                    [-0.191, 0.287],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                  ],
                  o: [
                    [-0.191, 0.287],
                    [-0.329, 0],
                    [-0.191, -0.287],
                    [0, -0.601],
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.287],
                    [0, 0.601],
                  ],
                  v: [
                    [0.789, 1.333],
                    [0.001, 1.764],
                    [-0.779, 1.333],
                    [-1.065, 0],
                    [-0.779, -1.333],
                    [0.001, -1.764],
                    [0.789, -1.333],
                    [1.075, 0],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.356, 0.225],
                    [0.455, 0],
                    [0.356, -0.224],
                    [0.202, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                    [-0.355, -0.224],
                    [-0.455, 0],
                    [-0.355, 0.225],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.202, 0.426],
                  ],
                  o: [
                    [-0.355, -0.224],
                    [-0.455, 0],
                    [-0.355, 0.225],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.202, 0.426],
                    [0.356, 0.225],
                    [0.455, 0],
                    [0.356, -0.224],
                    [0.202, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                  ],
                  v: [
                    [1.216, -2.502],
                    [0.001, -2.839],
                    [-1.215, -2.502],
                    [-2.05, -1.525],
                    [-2.352, 0],
                    [-2.05, 1.525],
                    [-1.215, 2.501],
                    [0.001, 2.839],
                    [1.216, 2.501],
                    [2.051, 1.525],
                    [2.353, 0],
                    [2.051, -1.525],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [824.052, 183.298], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 16",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [1, 3],
                    [1, -3],
                    [-1, -3],
                    [-1, -2],
                    [0, -2],
                    [0, 3],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [824, 174], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 17",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.191, -0.287],
                    [0.335, 0],
                    [0.191, 0.288],
                    [0, 0.601],
                    [-0.191, 0.287],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                  ],
                  o: [
                    [-0.191, 0.288],
                    [-0.329, 0],
                    [-0.191, -0.287],
                    [0, -0.601],
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.287],
                    [0, 0.601],
                  ],
                  v: [
                    [0.789, 1.333],
                    [0.001, 1.764],
                    [-0.779, 1.333],
                    [-1.065, 0],
                    [-0.779, -1.333],
                    [0.001, -1.764],
                    [0.789, -1.333],
                    [1.075, 0],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.356, 0.225],
                    [0.455, 0],
                    [0.356, -0.224],
                    [0.202, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                    [-0.355, -0.224],
                    [-0.455, 0],
                    [-0.355, 0.225],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.202, 0.426],
                  ],
                  o: [
                    [-0.355, -0.224],
                    [-0.455, 0],
                    [-0.355, 0.225],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.202, 0.426],
                    [0.356, 0.225],
                    [0.455, 0],
                    [0.356, -0.224],
                    [0.202, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                  ],
                  v: [
                    [1.216, -2.502],
                    [0.001, -2.839],
                    [-1.215, -2.502],
                    [-2.05, -1.525],
                    [-2.352, 0],
                    [-2.05, 1.525],
                    [-1.215, 2.501],
                    [0.001, 2.839],
                    [1.216, 2.501],
                    [2.051, 1.525],
                    [2.353, 0],
                    [2.051, -1.525],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [824.052, 164.478], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 18",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.191, -0.287],
                    [0.335, 0],
                    [0.191, 0.288],
                    [0, 0.601],
                    [-0.191, 0.288],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                  ],
                  o: [
                    [-0.191, 0.288],
                    [-0.329, 0],
                    [-0.191, -0.287],
                    [0, -0.601],
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.288],
                    [0, 0.601],
                  ],
                  v: [
                    [0.789, 1.333],
                    [0.001, 1.764],
                    [-0.779, 1.333],
                    [-1.065, 0],
                    [-0.779, -1.333],
                    [0.001, -1.764],
                    [0.789, -1.333],
                    [1.075, 0],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.356, 0.224],
                    [0.455, 0],
                    [0.356, -0.225],
                    [0.202, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                    [-0.355, -0.224],
                    [-0.455, 0],
                    [-0.355, 0.225],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.202, 0.426],
                  ],
                  o: [
                    [-0.355, -0.225],
                    [-0.455, 0],
                    [-0.355, 0.224],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.202, 0.426],
                    [0.356, 0.225],
                    [0.455, 0],
                    [0.356, -0.224],
                    [0.202, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                  ],
                  v: [
                    [1.216, -2.501],
                    [0.001, -2.839],
                    [-1.215, -2.501],
                    [-2.05, -1.525],
                    [-2.352, 0],
                    [-2.05, 1.525],
                    [-1.215, 2.501],
                    [0.001, 2.839],
                    [1.216, 2.501],
                    [2.051, 1.525],
                    [2.353, 0],
                    [2.051, -1.525],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [824.052, 155.068], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 19",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [0, 2.5],
                    [1, 2.5],
                    [1, -2.5],
                    [-1, -2.5],
                    [-1, -1.5],
                    [0, -1.5],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [824, 145.5], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 20",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [0, 2.5],
                    [1, 2.5],
                    [1, -2.5],
                    [-1, -2.5],
                    [-1, -1.5],
                    [0, -1.5],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [824, 136.5], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 21",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.191, -0.287],
                    [0.335, 0],
                    [0.191, 0.288],
                    [0, 0.601],
                    [-0.191, 0.288],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                  ],
                  o: [
                    [-0.191, 0.288],
                    [-0.329, 0],
                    [-0.191, -0.287],
                    [0, -0.601],
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.288],
                    [0, 0.601],
                  ],
                  v: [
                    [0.789, 1.333],
                    [0.001, 1.764],
                    [-0.779, 1.333],
                    [-1.065, 0],
                    [-0.779, -1.333],
                    [0.001, -1.764],
                    [0.789, -1.333],
                    [1.075, 0],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.355, 0.224],
                    [0.455, 0],
                    [0.355, -0.225],
                    [0.202, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                    [-0.356, -0.224],
                    [-0.455, 0],
                    [-0.356, 0.225],
                    [-0.202, 0.426],
                    [0, 0.591],
                    [0.201, 0.426],
                  ],
                  o: [
                    [-0.356, -0.225],
                    [-0.455, 0],
                    [-0.356, 0.224],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.202, 0.426],
                    [0.355, 0.225],
                    [0.455, 0],
                    [0.355, -0.224],
                    [0.201, -0.426],
                    [0, -0.591],
                    [-0.202, -0.426],
                  ],
                  v: [
                    [1.217, -2.501],
                    [0.001, -2.839],
                    [-1.214, -2.501],
                    [-2.05, -1.525],
                    [-2.352, 0],
                    [-2.05, 1.525],
                    [-1.214, 2.501],
                    [0.001, 2.839],
                    [1.217, 2.501],
                    [2.052, 1.525],
                    [2.353, 0],
                    [2.052, -1.525],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [811.636, 220.937], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 22",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [1.5, 2.5],
                    [1.5, -2.5],
                    [-1.5, -2.5],
                    [-1.5, -1.5],
                    [-0.5, -1.5],
                    [-0.5, 2.5],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [811.5, 211.5], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 23",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.287],
                    [0, 0.601],
                    [-0.191, 0.287],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                  ],
                  o: [
                    [-0.191, 0.287],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.287],
                    [0, 0.601],
                  ],
                  v: [
                    [0.789, 1.333],
                    [0.001, 1.764],
                    [-0.779, 1.333],
                    [-1.065, 0],
                    [-0.779, -1.333],
                    [0.001, -1.764],
                    [0.789, -1.333],
                    [1.075, 0],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.355, 0.225],
                    [0.455, 0],
                    [0.355, -0.224],
                    [0.202, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                    [-0.356, -0.224],
                    [-0.455, 0],
                    [-0.356, 0.225],
                    [-0.202, 0.426],
                    [0, 0.591],
                    [0.201, 0.426],
                  ],
                  o: [
                    [-0.356, -0.224],
                    [-0.455, 0],
                    [-0.356, 0.225],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.202, 0.426],
                    [0.355, 0.225],
                    [0.455, 0],
                    [0.355, -0.224],
                    [0.201, -0.426],
                    [0, -0.591],
                    [-0.202, -0.426],
                  ],
                  v: [
                    [1.217, -2.502],
                    [0.001, -2.839],
                    [-1.214, -2.502],
                    [-2.05, -1.525],
                    [-2.352, 0],
                    [-2.05, 1.525],
                    [-1.214, 2.501],
                    [0.001, 2.839],
                    [1.217, 2.501],
                    [2.052, 1.525],
                    [2.353, 0],
                    [2.052, -1.525],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [811.636, 202.117], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 24",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [1.5, 2.5],
                    [1.5, -2.5],
                    [-1.5, -2.5],
                    [-1.5, -1.5],
                    [-0.5, -1.5],
                    [-0.5, 2.5],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [811.5, 192.5], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 25",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.191, -0.287],
                    [0.335, 0],
                    [0.191, 0.287],
                    [0, 0.601],
                    [-0.191, 0.287],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                  ],
                  o: [
                    [-0.191, 0.287],
                    [-0.329, 0],
                    [-0.191, -0.287],
                    [0, -0.601],
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.287],
                    [0, 0.601],
                  ],
                  v: [
                    [0.789, 1.333],
                    [0.001, 1.764],
                    [-0.779, 1.333],
                    [-1.065, 0],
                    [-0.779, -1.333],
                    [0.001, -1.764],
                    [0.789, -1.333],
                    [1.075, 0],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.355, 0.225],
                    [0.455, 0],
                    [0.355, -0.224],
                    [0.202, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                    [-0.356, -0.224],
                    [-0.455, 0],
                    [-0.356, 0.225],
                    [-0.202, 0.426],
                    [0, 0.591],
                    [0.201, 0.426],
                  ],
                  o: [
                    [-0.356, -0.224],
                    [-0.455, 0],
                    [-0.356, 0.225],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.202, 0.426],
                    [0.355, 0.225],
                    [0.455, 0],
                    [0.355, -0.224],
                    [0.201, -0.426],
                    [0, -0.591],
                    [-0.202, -0.426],
                  ],
                  v: [
                    [1.217, -2.502],
                    [0.001, -2.839],
                    [-1.214, -2.502],
                    [-2.05, -1.525],
                    [-2.352, 0],
                    [-2.05, 1.525],
                    [-1.214, 2.501],
                    [0.001, 2.839],
                    [1.217, 2.501],
                    [2.052, 1.525],
                    [2.353, 0],
                    [2.052, -1.525],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [811.636, 183.298], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 26",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.191, -0.287],
                    [0.335, 0],
                    [0.191, 0.288],
                    [0, 0.601],
                    [-0.191, 0.287],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                  ],
                  o: [
                    [-0.191, 0.288],
                    [-0.329, 0],
                    [-0.191, -0.287],
                    [0, -0.601],
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.287],
                    [0, 0.601],
                  ],
                  v: [
                    [0.789, 1.333],
                    [0.001, 1.764],
                    [-0.779, 1.333],
                    [-1.065, 0],
                    [-0.779, -1.333],
                    [0.001, -1.764],
                    [0.789, -1.333],
                    [1.075, 0],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.355, 0.224],
                    [0.455, 0],
                    [0.355, -0.225],
                    [0.202, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                    [-0.356, -0.224],
                    [-0.455, 0],
                    [-0.356, 0.225],
                    [-0.202, 0.426],
                    [0, 0.591],
                    [0.201, 0.426],
                  ],
                  o: [
                    [-0.356, -0.225],
                    [-0.455, 0],
                    [-0.356, 0.224],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.202, 0.426],
                    [0.355, 0.225],
                    [0.455, 0],
                    [0.355, -0.224],
                    [0.201, -0.426],
                    [0, -0.591],
                    [-0.202, -0.426],
                  ],
                  v: [
                    [1.217, -2.501],
                    [0.001, -2.839],
                    [-1.214, -2.501],
                    [-2.05, -1.525],
                    [-2.352, 0],
                    [-2.05, 1.525],
                    [-1.214, 2.501],
                    [0.001, 2.839],
                    [1.217, 2.501],
                    [2.052, 1.525],
                    [2.353, 0],
                    [2.052, -1.525],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [811.636, 173.888], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 27",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.191, -0.287],
                    [0.335, 0],
                    [0.191, 0.288],
                    [0, 0.601],
                    [-0.191, 0.287],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                  ],
                  o: [
                    [-0.191, 0.288],
                    [-0.329, 0],
                    [-0.191, -0.287],
                    [0, -0.601],
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.287],
                    [0, 0.601],
                  ],
                  v: [
                    [0.789, 1.333],
                    [0.001, 1.764],
                    [-0.779, 1.333],
                    [-1.065, 0],
                    [-0.779, -1.333],
                    [0.001, -1.764],
                    [0.789, -1.333],
                    [1.075, 0],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.355, 0.225],
                    [0.455, 0],
                    [0.355, -0.224],
                    [0.202, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                    [-0.356, -0.224],
                    [-0.455, 0],
                    [-0.356, 0.225],
                    [-0.202, 0.426],
                    [0, 0.591],
                    [0.201, 0.426],
                  ],
                  o: [
                    [-0.356, -0.224],
                    [-0.455, 0],
                    [-0.356, 0.225],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.202, 0.426],
                    [0.355, 0.225],
                    [0.455, 0],
                    [0.355, -0.224],
                    [0.201, -0.426],
                    [0, -0.591],
                    [-0.202, -0.426],
                  ],
                  v: [
                    [1.217, -2.502],
                    [0.001, -2.839],
                    [-1.214, -2.502],
                    [-2.05, -1.525],
                    [-2.352, 0],
                    [-2.05, 1.525],
                    [-1.214, 2.501],
                    [0.001, 2.839],
                    [1.217, 2.501],
                    [2.052, 1.525],
                    [2.353, 0],
                    [2.052, -1.525],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [811.636, 164.478], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 28",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1.5, -2],
                    [-0.5, -2],
                    [-0.5, 3],
                    [1.5, 3],
                    [1.5, -3],
                    [-1.5, -3],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [811.5, 155], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 29",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [1.5, 2.5],
                    [1.5, -2.5],
                    [-1.5, -2.5],
                    [-1.5, -1.5],
                    [-0.5, -1.5],
                    [-0.5, 2.5],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [811.5, 145.5], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 30",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.287],
                    [0, 0.601],
                    [-0.191, 0.287],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                  ],
                  o: [
                    [-0.191, 0.287],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.287],
                    [0, 0.601],
                  ],
                  v: [
                    [0.789, 1.333],
                    [0.001, 1.764],
                    [-0.779, 1.333],
                    [-1.065, 0],
                    [-0.779, -1.333],
                    [0.001, -1.764],
                    [0.789, -1.333],
                    [1.075, 0],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.355, 0.225],
                    [0.455, 0],
                    [0.355, -0.224],
                    [0.202, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                    [-0.356, -0.224],
                    [-0.455, 0],
                    [-0.356, 0.225],
                    [-0.202, 0.426],
                    [0, 0.591],
                    [0.201, 0.426],
                  ],
                  o: [
                    [-0.356, -0.224],
                    [-0.455, 0],
                    [-0.356, 0.225],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.202, 0.426],
                    [0.355, 0.225],
                    [0.455, 0],
                    [0.355, -0.224],
                    [0.201, -0.426],
                    [0, -0.591],
                    [-0.202, -0.426],
                  ],
                  v: [
                    [1.217, -2.502],
                    [0.001, -2.839],
                    [-1.214, -2.502],
                    [-2.05, -1.525],
                    [-2.352, 0],
                    [-2.05, 1.525],
                    [-1.214, 2.501],
                    [0.001, 2.839],
                    [1.217, 2.501],
                    [2.052, 1.525],
                    [2.353, 0],
                    [2.052, -1.525],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [811.636, 136.248], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 31",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.191, -0.287],
                    [0.335, 0],
                    [0.191, 0.288],
                    [0, 0.601],
                    [-0.191, 0.288],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                  ],
                  o: [
                    [-0.191, 0.288],
                    [-0.329, 0],
                    [-0.191, -0.287],
                    [0, -0.601],
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.288],
                    [0, 0.601],
                  ],
                  v: [
                    [0.789, 1.333],
                    [0.001, 1.764],
                    [-0.779, 1.333],
                    [-1.065, 0],
                    [-0.779, -1.333],
                    [0.001, -1.764],
                    [0.789, -1.333],
                    [1.075, 0],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.356, 0.224],
                    [0.455, 0],
                    [0.355, -0.225],
                    [0.202, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                    [-0.356, -0.224],
                    [-0.455, 0],
                    [-0.355, 0.225],
                    [-0.202, 0.426],
                    [0, 0.591],
                    [0.201, 0.426],
                  ],
                  o: [
                    [-0.355, -0.225],
                    [-0.455, 0],
                    [-0.356, 0.224],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.202, 0.426],
                    [0.355, 0.225],
                    [0.455, 0],
                    [0.356, -0.224],
                    [0.201, -0.426],
                    [0, -0.591],
                    [-0.202, -0.426],
                  ],
                  v: [
                    [1.216, -2.501],
                    [0.001, -2.839],
                    [-1.214, -2.501],
                    [-2.05, -1.525],
                    [-2.352, 0],
                    [-2.05, 1.525],
                    [-1.214, 2.501],
                    [0.001, 2.839],
                    [1.216, 2.501],
                    [2.052, 1.525],
                    [2.353, 0],
                    [2.052, -1.525],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [764.032, 189.937], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 32",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1, -1.5],
                    [0, -1.5],
                    [0, 2.5],
                    [1, 2.5],
                    [1, -2.5],
                    [-1, -2.5],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [764, 180.5], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 33",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [1, 3],
                    [1, -3],
                    [-1, -3],
                    [-1, -2],
                    [0, -2],
                    [0, 3],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [764, 171], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 34",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.287],
                    [0, 0.601],
                    [-0.191, 0.287],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                  ],
                  o: [
                    [-0.191, 0.287],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.287],
                    [0, 0.601],
                  ],
                  v: [
                    [0.789, 1.333],
                    [0.001, 1.765],
                    [-0.779, 1.333],
                    [-1.065, 0.001],
                    [-0.779, -1.332],
                    [0.001, -1.764],
                    [0.789, -1.332],
                    [1.075, 0.001],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.356, 0.225],
                    [0.455, 0],
                    [0.355, -0.224],
                    [0.202, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                    [-0.356, -0.224],
                    [-0.455, 0],
                    [-0.355, 0.225],
                    [-0.202, 0.426],
                    [0, 0.591],
                    [0.201, 0.426],
                  ],
                  o: [
                    [-0.355, -0.224],
                    [-0.455, 0],
                    [-0.356, 0.225],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.202, 0.426],
                    [0.355, 0.225],
                    [0.455, 0],
                    [0.356, -0.224],
                    [0.201, -0.426],
                    [0, -0.591],
                    [-0.202, -0.426],
                  ],
                  v: [
                    [1.216, -2.501],
                    [0.001, -2.839],
                    [-1.214, -2.501],
                    [-2.05, -1.524],
                    [-2.352, 0.001],
                    [-2.05, 1.526],
                    [-1.214, 2.502],
                    [0.001, 2.839],
                    [1.216, 2.502],
                    [2.052, 1.526],
                    [2.353, 0.001],
                    [2.052, -1.524],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [764.032, 161.707], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 35",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [1, 2.5],
                    [1, -2.5],
                    [-1, -2.5],
                    [-1, -1.5],
                    [0, -1.5],
                    [0, 2.5],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [764, 152.5], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 36",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.191, -0.287],
                    [0.335, 0],
                    [0.191, 0.288],
                    [0, 0.601],
                    [-0.191, 0.287],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                  ],
                  o: [
                    [-0.191, 0.288],
                    [-0.329, 0],
                    [-0.191, -0.287],
                    [0, -0.601],
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.287],
                    [0, 0.601],
                  ],
                  v: [
                    [0.789, 1.333],
                    [0.001, 1.764],
                    [-0.779, 1.333],
                    [-1.065, 0],
                    [-0.779, -1.333],
                    [0.001, -1.764],
                    [0.789, -1.333],
                    [1.075, 0],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.356, 0.224],
                    [0.455, 0],
                    [0.355, -0.225],
                    [0.202, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                    [-0.356, -0.224],
                    [-0.455, 0],
                    [-0.355, 0.225],
                    [-0.202, 0.426],
                    [0, 0.591],
                    [0.201, 0.426],
                  ],
                  o: [
                    [-0.355, -0.225],
                    [-0.455, 0],
                    [-0.356, 0.224],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.202, 0.426],
                    [0.355, 0.225],
                    [0.455, 0],
                    [0.356, -0.224],
                    [0.201, -0.426],
                    [0, -0.591],
                    [-0.202, -0.426],
                  ],
                  v: [
                    [1.216, -2.501],
                    [0.001, -2.839],
                    [-1.214, -2.501],
                    [-2.05, -1.525],
                    [-2.352, 0],
                    [-2.05, 1.525],
                    [-1.214, 2.501],
                    [0.001, 2.839],
                    [1.216, 2.501],
                    [2.052, 1.525],
                    [2.353, 0],
                    [2.052, -1.525],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [764.032, 142.888], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 37",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [1, 2.5],
                    [1, -2.5],
                    [-1, -2.5],
                    [-1, -1.5],
                    [0, -1.5],
                    [0, 2.5],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [764, 133.5], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 38",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.191, -0.287],
                    [0.335, 0],
                    [0.191, 0.288],
                    [0, 0.601],
                    [-0.191, 0.288],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                  ],
                  o: [
                    [-0.191, 0.288],
                    [-0.329, 0],
                    [-0.191, -0.287],
                    [0, -0.601],
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.288],
                    [0, 0.601],
                  ],
                  v: [
                    [0.789, 1.333],
                    [0.001, 1.764],
                    [-0.779, 1.333],
                    [-1.065, 0],
                    [-0.779, -1.333],
                    [0.001, -1.764],
                    [0.789, -1.333],
                    [1.075, 0],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.356, 0.224],
                    [0.455, 0],
                    [0.355, -0.225],
                    [0.202, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                    [-0.356, -0.224],
                    [-0.455, 0],
                    [-0.355, 0.225],
                    [-0.202, 0.426],
                    [0, 0.591],
                    [0.201, 0.426],
                  ],
                  o: [
                    [-0.355, -0.225],
                    [-0.455, 0],
                    [-0.356, 0.224],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.202, 0.426],
                    [0.355, 0.225],
                    [0.455, 0],
                    [0.356, -0.224],
                    [0.201, -0.426],
                    [0, -0.591],
                    [-0.202, -0.426],
                  ],
                  v: [
                    [1.216, -2.501],
                    [0.001, -2.839],
                    [-1.214, -2.501],
                    [-2.05, -1.525],
                    [-2.352, 0],
                    [-2.05, 1.525],
                    [-1.214, 2.501],
                    [0.001, 2.839],
                    [1.216, 2.501],
                    [2.052, 1.525],
                    [2.353, 0],
                    [2.052, -1.525],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [764.032, 124.068], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 39",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [1, 2.5],
                    [1, -2.5],
                    [-1, -2.5],
                    [-1, -1.5],
                    [0, -1.5],
                    [0, 2.5],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [764, 114.5], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 40",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.191, 0.288],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                    [0.191, -0.287],
                    [0.335, 0],
                    [0.191, 0.288],
                    [0, 0.601],
                  ],
                  o: [
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.288],
                    [0, 0.601],
                    [-0.191, 0.288],
                    [-0.329, 0],
                    [-0.191, -0.287],
                    [0, -0.601],
                  ],
                  v: [
                    [-0.781, -1.333],
                    [-0.001, -1.764],
                    [0.787, -1.333],
                    [1.073, 0],
                    [0.787, 1.333],
                    [-0.001, 1.764],
                    [-0.781, 1.333],
                    [-1.067, 0],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.356, -0.224],
                    [-0.455, 0],
                    [-0.356, 0.226],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.201, 0.426],
                    [0.355, 0.225],
                    [0.455, 0],
                    [0.355, -0.225],
                    [0.201, -0.426],
                    [0, -0.591],
                    [-0.202, -0.426],
                  ],
                  o: [
                    [0.355, 0.226],
                    [0.455, 0],
                    [0.355, -0.224],
                    [0.201, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                    [-0.356, -0.225],
                    [-0.455, 0],
                    [-0.356, 0.225],
                    [-0.202, 0.426],
                    [0, 0.591],
                    [0.201, 0.426],
                  ],
                  v: [
                    [-1.216, 2.501],
                    [-0.001, 2.839],
                    [1.215, 2.501],
                    [2.05, 1.525],
                    [2.352, 0],
                    [2.05, -1.525],
                    [1.215, -2.501],
                    [-0.001, -2.839],
                    [-1.216, -2.501],
                    [-2.051, -1.525],
                    [-2.353, 0],
                    [-2.051, 1.525],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [764.033, 105.248], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 41",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.191, -0.287],
                    [0.335, 0],
                    [0.191, 0.288],
                    [0, 0.601],
                    [-0.191, 0.288],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                  ],
                  o: [
                    [-0.191, 0.288],
                    [-0.329, 0],
                    [-0.191, -0.287],
                    [0, -0.601],
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.288],
                    [0, 0.601],
                  ],
                  v: [
                    [0.789, 1.333],
                    [0.001, 1.764],
                    [-0.779, 1.333],
                    [-1.065, 0],
                    [-0.779, -1.333],
                    [0.001, -1.764],
                    [0.789, -1.333],
                    [1.075, 0],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.356, 0.224],
                    [0.455, 0],
                    [0.356, -0.225],
                    [0.202, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                    [-0.355, -0.224],
                    [-0.455, 0],
                    [-0.355, 0.225],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.202, 0.426],
                  ],
                  o: [
                    [-0.355, -0.225],
                    [-0.455, 0],
                    [-0.355, 0.224],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.202, 0.426],
                    [0.356, 0.225],
                    [0.455, 0],
                    [0.356, -0.224],
                    [0.202, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                  ],
                  v: [
                    [1.216, -2.501],
                    [0.001, -2.839],
                    [-1.215, -2.501],
                    [-2.05, -1.525],
                    [-2.352, 0],
                    [-2.05, 1.525],
                    [-1.215, 2.501],
                    [0.001, 2.839],
                    [1.216, 2.501],
                    [2.051, 1.525],
                    [2.353, 0],
                    [2.051, -1.525],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [752.052, 189.937], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 42",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.287],
                    [0, 0.601],
                    [-0.191, 0.287],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                  ],
                  o: [
                    [-0.191, 0.287],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.287],
                    [0, 0.601],
                  ],
                  v: [
                    [0.789, 1.333],
                    [0.001, 1.765],
                    [-0.779, 1.333],
                    [-1.065, 0.001],
                    [-0.779, -1.332],
                    [0.001, -1.764],
                    [0.789, -1.332],
                    [1.075, 0.001],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.356, 0.225],
                    [0.455, 0],
                    [0.356, -0.224],
                    [0.202, -0.427],
                    [0, -0.591],
                    [-0.201, -0.426],
                    [-0.355, -0.225],
                    [-0.455, 0],
                    [-0.355, 0.225],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.202, 0.426],
                  ],
                  o: [
                    [-0.355, -0.224],
                    [-0.455, 0],
                    [-0.355, 0.225],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.202, 0.426],
                    [0.356, 0.225],
                    [0.455, 0],
                    [0.356, -0.225],
                    [0.202, -0.426],
                    [0, -0.591],
                    [-0.201, -0.427],
                  ],
                  v: [
                    [1.216, -2.501],
                    [0.001, -2.839],
                    [-1.215, -2.501],
                    [-2.05, -1.524],
                    [-2.352, 0.001],
                    [-2.05, 1.526],
                    [-1.215, 2.502],
                    [0.001, 2.839],
                    [1.216, 2.502],
                    [2.051, 1.526],
                    [2.353, 0.001],
                    [2.051, -1.524],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [752.052, 180.527], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 43",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1, -2],
                    [0, -2],
                    [0, 3],
                    [1, 3],
                    [1, -3],
                    [-1, -3],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [752, 171], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 44",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [1, 2.5],
                    [1, -2.5],
                    [-1, -2.5],
                    [-1, -1.5],
                    [0, -1.5],
                    [0, 2.5],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [752, 161.5], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 45",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.191, -0.287],
                    [0.335, 0],
                    [0.191, 0.287],
                    [0, 0.601],
                    [-0.191, 0.287],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                  ],
                  o: [
                    [-0.191, 0.287],
                    [-0.329, 0],
                    [-0.191, -0.287],
                    [0, -0.601],
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.287],
                    [0, 0.601],
                  ],
                  v: [
                    [0.789, 1.333],
                    [0.001, 1.764],
                    [-0.779, 1.333],
                    [-1.065, 0],
                    [-0.779, -1.333],
                    [0.001, -1.764],
                    [0.789, -1.333],
                    [1.075, 0],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.356, 0.225],
                    [0.455, 0],
                    [0.356, -0.224],
                    [0.202, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                    [-0.355, -0.224],
                    [-0.455, 0],
                    [-0.355, 0.225],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.202, 0.426],
                  ],
                  o: [
                    [-0.355, -0.224],
                    [-0.455, 0],
                    [-0.355, 0.225],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.202, 0.426],
                    [0.356, 0.225],
                    [0.455, 0],
                    [0.356, -0.224],
                    [0.202, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                  ],
                  v: [
                    [1.216, -2.502],
                    [0.001, -2.839],
                    [-1.215, -2.502],
                    [-2.05, -1.525],
                    [-2.352, 0],
                    [-2.05, 1.525],
                    [-1.215, 2.501],
                    [0.001, 2.839],
                    [1.216, 2.501],
                    [2.051, 1.525],
                    [2.353, 0],
                    [2.051, -1.525],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [752.052, 152.298], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 46",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [1, 3],
                    [1, -3],
                    [-1, -3],
                    [-1, -2],
                    [0, -2],
                    [0, 3],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [752, 143], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 47",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.191, -0.287],
                    [0.335, 0],
                    [0.191, 0.288],
                    [0, 0.601],
                    [-0.191, 0.287],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                  ],
                  o: [
                    [-0.191, 0.288],
                    [-0.329, 0],
                    [-0.191, -0.287],
                    [0, -0.601],
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.287],
                    [0, 0.601],
                  ],
                  v: [
                    [0.789, 1.333],
                    [0.001, 1.764],
                    [-0.779, 1.333],
                    [-1.065, 0],
                    [-0.779, -1.333],
                    [0.001, -1.764],
                    [0.789, -1.333],
                    [1.075, 0],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.356, 0.225],
                    [0.455, 0],
                    [0.356, -0.224],
                    [0.202, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                    [-0.355, -0.224],
                    [-0.455, 0],
                    [-0.355, 0.225],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.202, 0.426],
                  ],
                  o: [
                    [-0.355, -0.224],
                    [-0.455, 0],
                    [-0.355, 0.225],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.202, 0.426],
                    [0.356, 0.225],
                    [0.455, 0],
                    [0.356, -0.224],
                    [0.202, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                  ],
                  v: [
                    [1.216, -2.502],
                    [0.001, -2.839],
                    [-1.215, -2.502],
                    [-2.05, -1.525],
                    [-2.352, 0],
                    [-2.05, 1.525],
                    [-1.215, 2.501],
                    [0.001, 2.839],
                    [1.216, 2.501],
                    [2.051, 1.525],
                    [2.353, 0],
                    [2.051, -1.525],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [752.052, 133.478], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 48",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.191, -0.287],
                    [0.335, 0],
                    [0.191, 0.288],
                    [0, 0.601],
                    [-0.191, 0.288],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                  ],
                  o: [
                    [-0.191, 0.288],
                    [-0.329, 0],
                    [-0.191, -0.287],
                    [0, -0.601],
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.288],
                    [0, 0.601],
                  ],
                  v: [
                    [0.789, 1.333],
                    [0.001, 1.764],
                    [-0.779, 1.333],
                    [-1.065, 0],
                    [-0.779, -1.333],
                    [0.001, -1.764],
                    [0.789, -1.333],
                    [1.075, 0],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.356, 0.224],
                    [0.455, 0],
                    [0.356, -0.225],
                    [0.202, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                    [-0.355, -0.224],
                    [-0.455, 0],
                    [-0.355, 0.225],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.202, 0.426],
                  ],
                  o: [
                    [-0.355, -0.225],
                    [-0.455, 0],
                    [-0.355, 0.224],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.202, 0.426],
                    [0.356, 0.225],
                    [0.455, 0],
                    [0.356, -0.224],
                    [0.202, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                  ],
                  v: [
                    [1.216, -2.501],
                    [0.001, -2.839],
                    [-1.215, -2.501],
                    [-2.05, -1.525],
                    [-2.352, 0],
                    [-2.05, 1.525],
                    [-1.215, 2.501],
                    [0.001, 2.839],
                    [1.216, 2.501],
                    [2.051, 1.525],
                    [2.353, 0],
                    [2.051, -1.525],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [752.052, 124.068], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 49",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [0, 2.5],
                    [1, 2.5],
                    [1, -2.5],
                    [-1, -2.5],
                    [-1, -1.5],
                    [0, -1.5],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [752, 114.5], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 50",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [0, 2.5],
                    [1, 2.5],
                    [1, -2.5],
                    [-1, -2.5],
                    [-1, -1.5],
                    [0, -1.5],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [752, 105.5], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 51",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.191, -0.287],
                    [0.335, 0],
                    [0.191, 0.288],
                    [0, 0.601],
                    [-0.191, 0.288],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                  ],
                  o: [
                    [-0.191, 0.288],
                    [-0.329, 0],
                    [-0.191, -0.287],
                    [0, -0.601],
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.288],
                    [0, 0.601],
                  ],
                  v: [
                    [0.789, 1.333],
                    [0.001, 1.764],
                    [-0.779, 1.333],
                    [-1.065, 0],
                    [-0.779, -1.333],
                    [0.001, -1.764],
                    [0.789, -1.333],
                    [1.075, 0],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.355, 0.224],
                    [0.455, 0],
                    [0.355, -0.225],
                    [0.202, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                    [-0.356, -0.224],
                    [-0.455, 0],
                    [-0.356, 0.225],
                    [-0.202, 0.426],
                    [0, 0.591],
                    [0.201, 0.426],
                  ],
                  o: [
                    [-0.356, -0.225],
                    [-0.455, 0],
                    [-0.356, 0.224],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.202, 0.426],
                    [0.355, 0.225],
                    [0.455, 0],
                    [0.355, -0.224],
                    [0.201, -0.426],
                    [0, -0.591],
                    [-0.202, -0.426],
                  ],
                  v: [
                    [1.217, -2.501],
                    [0.001, -2.839],
                    [-1.214, -2.501],
                    [-2.05, -1.525],
                    [-2.352, 0],
                    [-2.05, 1.525],
                    [-1.214, 2.501],
                    [0.001, 2.839],
                    [1.217, 2.501],
                    [2.052, 1.525],
                    [2.353, 0],
                    [2.052, -1.525],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [739.636, 189.937], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 52",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [1.5, 2.5],
                    [1.5, -2.5],
                    [-1.5, -2.5],
                    [-1.5, -1.5],
                    [-0.5, -1.5],
                    [-0.5, 2.5],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [739.5, 180.5], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 53",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.287],
                    [0, 0.601],
                    [-0.191, 0.287],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                  ],
                  o: [
                    [-0.191, 0.287],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.287],
                    [0, 0.601],
                  ],
                  v: [
                    [0.789, 1.333],
                    [0.001, 1.764],
                    [-0.779, 1.333],
                    [-1.065, 0],
                    [-0.779, -1.333],
                    [0.001, -1.764],
                    [0.789, -1.333],
                    [1.075, 0],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.355, 0.225],
                    [0.455, 0],
                    [0.355, -0.224],
                    [0.202, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                    [-0.356, -0.224],
                    [-0.455, 0],
                    [-0.356, 0.225],
                    [-0.202, 0.426],
                    [0, 0.591],
                    [0.201, 0.426],
                  ],
                  o: [
                    [-0.356, -0.224],
                    [-0.455, 0],
                    [-0.356, 0.225],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.202, 0.426],
                    [0.355, 0.225],
                    [0.455, 0],
                    [0.355, -0.224],
                    [0.201, -0.426],
                    [0, -0.591],
                    [-0.202, -0.426],
                  ],
                  v: [
                    [1.217, -2.502],
                    [0.001, -2.839],
                    [-1.214, -2.502],
                    [-2.05, -1.525],
                    [-2.352, 0],
                    [-2.05, 1.525],
                    [-1.214, 2.501],
                    [0.001, 2.839],
                    [1.217, 2.501],
                    [2.052, 1.525],
                    [2.353, 0],
                    [2.052, -1.525],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [739.636, 171.117], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 54",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [1.5, 2.5],
                    [1.5, -2.5],
                    [-1.5, -2.5],
                    [-1.5, -1.5],
                    [-0.5, -1.5],
                    [-0.5, 2.5],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [739.5, 161.5], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 55",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.191, -0.287],
                    [0.335, 0],
                    [0.191, 0.287],
                    [0, 0.601],
                    [-0.191, 0.287],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                  ],
                  o: [
                    [-0.191, 0.287],
                    [-0.329, 0],
                    [-0.191, -0.287],
                    [0, -0.601],
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.287],
                    [0, 0.601],
                  ],
                  v: [
                    [0.789, 1.333],
                    [0.001, 1.764],
                    [-0.779, 1.333],
                    [-1.065, 0],
                    [-0.779, -1.333],
                    [0.001, -1.764],
                    [0.789, -1.333],
                    [1.075, 0],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.355, 0.225],
                    [0.455, 0],
                    [0.355, -0.224],
                    [0.202, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                    [-0.356, -0.224],
                    [-0.455, 0],
                    [-0.356, 0.225],
                    [-0.202, 0.426],
                    [0, 0.591],
                    [0.201, 0.426],
                  ],
                  o: [
                    [-0.356, -0.224],
                    [-0.455, 0],
                    [-0.356, 0.225],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.202, 0.426],
                    [0.355, 0.225],
                    [0.455, 0],
                    [0.355, -0.224],
                    [0.201, -0.426],
                    [0, -0.591],
                    [-0.202, -0.426],
                  ],
                  v: [
                    [1.217, -2.502],
                    [0.001, -2.839],
                    [-1.214, -2.502],
                    [-2.05, -1.525],
                    [-2.352, 0],
                    [-2.05, 1.525],
                    [-1.214, 2.501],
                    [0.001, 2.839],
                    [1.217, 2.501],
                    [2.052, 1.525],
                    [2.353, 0],
                    [2.052, -1.525],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [739.636, 152.298], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 56",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.191, -0.287],
                    [0.335, 0],
                    [0.191, 0.288],
                    [0, 0.601],
                    [-0.191, 0.287],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                  ],
                  o: [
                    [-0.191, 0.288],
                    [-0.329, 0],
                    [-0.191, -0.287],
                    [0, -0.601],
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.287],
                    [0, 0.601],
                  ],
                  v: [
                    [0.789, 1.333],
                    [0.001, 1.764],
                    [-0.779, 1.333],
                    [-1.065, 0],
                    [-0.779, -1.333],
                    [0.001, -1.764],
                    [0.789, -1.333],
                    [1.075, 0],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.355, 0.224],
                    [0.455, 0],
                    [0.355, -0.225],
                    [0.202, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                    [-0.356, -0.224],
                    [-0.455, 0],
                    [-0.356, 0.225],
                    [-0.202, 0.426],
                    [0, 0.591],
                    [0.201, 0.426],
                  ],
                  o: [
                    [-0.356, -0.225],
                    [-0.455, 0],
                    [-0.356, 0.224],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.202, 0.426],
                    [0.355, 0.225],
                    [0.455, 0],
                    [0.355, -0.224],
                    [0.201, -0.426],
                    [0, -0.591],
                    [-0.202, -0.426],
                  ],
                  v: [
                    [1.217, -2.501],
                    [0.001, -2.839],
                    [-1.214, -2.501],
                    [-2.05, -1.525],
                    [-2.352, 0],
                    [-2.05, 1.525],
                    [-1.214, 2.501],
                    [0.001, 2.839],
                    [1.217, 2.501],
                    [2.052, 1.525],
                    [2.353, 0],
                    [2.052, -1.525],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [739.636, 142.888], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 57",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.191, -0.287],
                    [0.335, 0],
                    [0.191, 0.288],
                    [0, 0.601],
                    [-0.191, 0.287],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                  ],
                  o: [
                    [-0.191, 0.288],
                    [-0.329, 0],
                    [-0.191, -0.287],
                    [0, -0.601],
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.287],
                    [0, 0.601],
                  ],
                  v: [
                    [0.789, 1.333],
                    [0.001, 1.764],
                    [-0.779, 1.333],
                    [-1.065, 0],
                    [-0.779, -1.333],
                    [0.001, -1.764],
                    [0.789, -1.333],
                    [1.075, 0],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.355, 0.225],
                    [0.455, 0],
                    [0.355, -0.224],
                    [0.202, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                    [-0.356, -0.224],
                    [-0.455, 0],
                    [-0.356, 0.225],
                    [-0.202, 0.426],
                    [0, 0.591],
                    [0.201, 0.426],
                  ],
                  o: [
                    [-0.356, -0.224],
                    [-0.455, 0],
                    [-0.356, 0.225],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.202, 0.426],
                    [0.355, 0.225],
                    [0.455, 0],
                    [0.355, -0.224],
                    [0.201, -0.426],
                    [0, -0.591],
                    [-0.202, -0.426],
                  ],
                  v: [
                    [1.217, -2.502],
                    [0.001, -2.839],
                    [-1.214, -2.502],
                    [-2.05, -1.525],
                    [-2.352, 0],
                    [-2.05, 1.525],
                    [-1.214, 2.501],
                    [0.001, 2.839],
                    [1.217, 2.501],
                    [2.052, 1.525],
                    [2.353, 0],
                    [2.052, -1.525],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [739.636, 133.478], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 58",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1.5, -2],
                    [-0.5, -2],
                    [-0.5, 3],
                    [1.5, 3],
                    [1.5, -3],
                    [-1.5, -3],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [739.5, 124], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 59",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [1.5, 2.5],
                    [1.5, -2.5],
                    [-1.5, -2.5],
                    [-1.5, -1.5],
                    [-0.5, -1.5],
                    [-0.5, 2.5],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [739.5, 114.5], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 60",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.287],
                    [0, 0.601],
                    [-0.191, 0.287],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                  ],
                  o: [
                    [-0.191, 0.287],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.287],
                    [0, 0.601],
                  ],
                  v: [
                    [0.789, 1.333],
                    [0.001, 1.764],
                    [-0.779, 1.333],
                    [-1.065, 0],
                    [-0.779, -1.333],
                    [0.001, -1.764],
                    [0.789, -1.333],
                    [1.075, 0],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.355, 0.225],
                    [0.455, 0],
                    [0.355, -0.224],
                    [0.202, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                    [-0.356, -0.224],
                    [-0.455, 0],
                    [-0.356, 0.225],
                    [-0.202, 0.426],
                    [0, 0.591],
                    [0.201, 0.426],
                  ],
                  o: [
                    [-0.356, -0.224],
                    [-0.455, 0],
                    [-0.356, 0.225],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.202, 0.426],
                    [0.355, 0.225],
                    [0.455, 0],
                    [0.355, -0.224],
                    [0.201, -0.426],
                    [0, -0.591],
                    [-0.202, -0.426],
                  ],
                  v: [
                    [1.217, -2.502],
                    [0.001, -2.839],
                    [-1.214, -2.502],
                    [-2.05, -1.525],
                    [-2.352, 0],
                    [-2.05, 1.525],
                    [-1.214, 2.501],
                    [0.001, 2.839],
                    [1.217, 2.501],
                    [2.052, 1.525],
                    [2.353, 0],
                    [2.052, -1.525],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [739.636, 105.248], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 61",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.191, -0.287],
                    [0.335, 0],
                    [0.191, 0.288],
                    [0, 0.601],
                    [-0.191, 0.288],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                  ],
                  o: [
                    [-0.191, 0.288],
                    [-0.329, 0],
                    [-0.191, -0.287],
                    [0, -0.601],
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.288],
                    [0, 0.601],
                  ],
                  v: [
                    [0.789, 1.333],
                    [0.001, 1.764],
                    [-0.779, 1.333],
                    [-1.065, 0],
                    [-0.779, -1.333],
                    [0.001, -1.764],
                    [0.789, -1.333],
                    [1.075, 0],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.356, 0.224],
                    [0.455, 0],
                    [0.355, -0.225],
                    [0.201, -0.426],
                    [0, -0.591],
                    [-0.202, -0.426],
                    [-0.356, -0.224],
                    [-0.455, 0],
                    [-0.355, 0.225],
                    [-0.202, 0.426],
                    [0, 0.591],
                    [0.201, 0.426],
                  ],
                  o: [
                    [-0.355, -0.225],
                    [-0.455, 0],
                    [-0.356, 0.224],
                    [-0.202, 0.426],
                    [0, 0.591],
                    [0.201, 0.426],
                    [0.355, 0.225],
                    [0.455, 0],
                    [0.356, -0.224],
                    [0.201, -0.426],
                    [0, -0.591],
                    [-0.202, -0.426],
                  ],
                  v: [
                    [1.216, -2.501],
                    [0.001, -2.839],
                    [-1.214, -2.501],
                    [-2.049, -1.525],
                    [-2.352, 0],
                    [-2.049, 1.525],
                    [-1.214, 2.501],
                    [0.001, 2.839],
                    [1.216, 2.501],
                    [2.052, 1.525],
                    [2.353, 0],
                    [2.052, -1.525],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [690.032, 150.937], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 62",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1, -1.5],
                    [0, -1.5],
                    [0, 2.5],
                    [1, 2.5],
                    [1, -2.5],
                    [-1, -2.5],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [690, 141.5], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 63",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [1, 3],
                    [1, -3],
                    [-1, -3],
                    [-1, -2],
                    [0, -2],
                    [0, 3],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [690, 132], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 64",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.287],
                    [0, 0.601],
                    [-0.191, 0.287],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                  ],
                  o: [
                    [-0.191, 0.287],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.287],
                    [0, 0.601],
                  ],
                  v: [
                    [0.789, 1.333],
                    [0.001, 1.765],
                    [-0.779, 1.333],
                    [-1.065, 0.001],
                    [-0.779, -1.332],
                    [0.001, -1.764],
                    [0.789, -1.332],
                    [1.075, 0.001],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.356, 0.225],
                    [0.455, 0],
                    [0.355, -0.224],
                    [0.201, -0.426],
                    [0, -0.591],
                    [-0.202, -0.426],
                    [-0.356, -0.224],
                    [-0.455, 0],
                    [-0.355, 0.225],
                    [-0.202, 0.426],
                    [0, 0.591],
                    [0.201, 0.426],
                  ],
                  o: [
                    [-0.355, -0.224],
                    [-0.455, 0],
                    [-0.356, 0.225],
                    [-0.202, 0.426],
                    [0, 0.591],
                    [0.201, 0.426],
                    [0.355, 0.225],
                    [0.455, 0],
                    [0.356, -0.224],
                    [0.201, -0.426],
                    [0, -0.591],
                    [-0.202, -0.426],
                  ],
                  v: [
                    [1.216, -2.501],
                    [0.001, -2.839],
                    [-1.214, -2.501],
                    [-2.049, -1.524],
                    [-2.352, 0.001],
                    [-2.049, 1.526],
                    [-1.214, 2.502],
                    [0.001, 2.839],
                    [1.216, 2.502],
                    [2.052, 1.526],
                    [2.353, 0.001],
                    [2.052, -1.524],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [690.032, 122.707], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 65",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [1, 2.5],
                    [1, -2.5],
                    [-1, -2.5],
                    [-1, -1.5],
                    [0, -1.5],
                    [0, 2.5],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [690, 113.5], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 66",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.191, -0.287],
                    [0.335, 0],
                    [0.191, 0.288],
                    [0, 0.601],
                    [-0.191, 0.287],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                  ],
                  o: [
                    [-0.191, 0.288],
                    [-0.329, 0],
                    [-0.191, -0.287],
                    [0, -0.601],
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.287],
                    [0, 0.601],
                  ],
                  v: [
                    [0.789, 1.333],
                    [0.001, 1.764],
                    [-0.779, 1.333],
                    [-1.065, 0],
                    [-0.779, -1.333],
                    [0.001, -1.764],
                    [0.789, -1.333],
                    [1.075, 0],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.356, 0.224],
                    [0.455, 0],
                    [0.355, -0.225],
                    [0.201, -0.426],
                    [0, -0.591],
                    [-0.202, -0.426],
                    [-0.356, -0.224],
                    [-0.455, 0],
                    [-0.355, 0.225],
                    [-0.202, 0.426],
                    [0, 0.591],
                    [0.201, 0.426],
                  ],
                  o: [
                    [-0.355, -0.225],
                    [-0.455, 0],
                    [-0.356, 0.224],
                    [-0.202, 0.426],
                    [0, 0.591],
                    [0.201, 0.426],
                    [0.355, 0.225],
                    [0.455, 0],
                    [0.356, -0.224],
                    [0.201, -0.426],
                    [0, -0.591],
                    [-0.202, -0.426],
                  ],
                  v: [
                    [1.216, -2.501],
                    [0.001, -2.839],
                    [-1.214, -2.501],
                    [-2.049, -1.525],
                    [-2.352, 0],
                    [-2.049, 1.525],
                    [-1.214, 2.501],
                    [0.001, 2.839],
                    [1.216, 2.501],
                    [2.052, 1.525],
                    [2.353, 0],
                    [2.052, -1.525],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [690.032, 103.888], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 67",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [1, 2.5],
                    [1, -2.5],
                    [-1, -2.5],
                    [-1, -1.5],
                    [0, -1.5],
                    [0, 2.5],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [690, 94.5], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 68",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.191, -0.287],
                    [0.335, 0],
                    [0.191, 0.288],
                    [0, 0.601],
                    [-0.191, 0.288],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                  ],
                  o: [
                    [-0.191, 0.288],
                    [-0.329, 0],
                    [-0.191, -0.287],
                    [0, -0.601],
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.288],
                    [0, 0.601],
                  ],
                  v: [
                    [0.789, 1.333],
                    [0.001, 1.764],
                    [-0.779, 1.333],
                    [-1.065, 0],
                    [-0.779, -1.333],
                    [0.001, -1.764],
                    [0.789, -1.333],
                    [1.075, 0],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.356, 0.224],
                    [0.455, 0],
                    [0.355, -0.225],
                    [0.201, -0.426],
                    [0, -0.591],
                    [-0.202, -0.426],
                    [-0.356, -0.224],
                    [-0.455, 0],
                    [-0.355, 0.225],
                    [-0.202, 0.426],
                    [0, 0.591],
                    [0.201, 0.426],
                  ],
                  o: [
                    [-0.355, -0.225],
                    [-0.455, 0],
                    [-0.356, 0.224],
                    [-0.202, 0.426],
                    [0, 0.591],
                    [0.201, 0.426],
                    [0.355, 0.225],
                    [0.455, 0],
                    [0.356, -0.224],
                    [0.201, -0.426],
                    [0, -0.591],
                    [-0.202, -0.426],
                  ],
                  v: [
                    [1.216, -2.501],
                    [0.001, -2.839],
                    [-1.214, -2.501],
                    [-2.049, -1.525],
                    [-2.352, 0],
                    [-2.049, 1.525],
                    [-1.214, 2.501],
                    [0.001, 2.839],
                    [1.216, 2.501],
                    [2.052, 1.525],
                    [2.353, 0],
                    [2.052, -1.525],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [690.032, 85.068], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 69",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [1, 2.5],
                    [1, -2.5],
                    [-1, -2.5],
                    [-1, -1.5],
                    [0, -1.5],
                    [0, 2.5],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [690, 75.5], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 70",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.191, 0.288],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                    [0.191, -0.287],
                    [0.335, 0],
                    [0.191, 0.288],
                    [0, 0.601],
                  ],
                  o: [
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.288],
                    [0, 0.601],
                    [-0.191, 0.288],
                    [-0.329, 0],
                    [-0.191, -0.287],
                    [0, -0.601],
                  ],
                  v: [
                    [-0.78, -1.333],
                    [0, -1.764],
                    [0.788, -1.333],
                    [1.073, 0],
                    [0.788, 1.333],
                    [0, 1.764],
                    [-0.78, 1.333],
                    [-1.066, 0],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.356, -0.224],
                    [-0.454, 0],
                    [-0.356, 0.226],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.201, 0.426],
                    [0.355, 0.225],
                    [0.455, 0],
                    [0.355, -0.225],
                    [0.201, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                  ],
                  o: [
                    [0.355, 0.226],
                    [0.455, 0],
                    [0.355, -0.224],
                    [0.201, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                    [-0.356, -0.225],
                    [-0.454, 0],
                    [-0.356, 0.225],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.201, 0.426],
                  ],
                  v: [
                    [-1.216, 2.501],
                    [0, 2.839],
                    [1.216, 2.501],
                    [2.051, 1.525],
                    [2.353, 0],
                    [2.051, -1.525],
                    [1.216, -2.501],
                    [0, -2.839],
                    [-1.216, -2.501],
                    [-2.05, -1.525],
                    [-2.352, 0],
                    [-2.05, 1.525],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [690.032, 66.248], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 71",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.191, -0.287],
                    [0.335, 0],
                    [0.191, 0.288],
                    [0, 0.601],
                    [-0.191, 0.288],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                  ],
                  o: [
                    [-0.191, 0.288],
                    [-0.329, 0],
                    [-0.191, -0.287],
                    [0, -0.601],
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.288],
                    [0, 0.601],
                  ],
                  v: [
                    [0.789, 1.333],
                    [0.001, 1.764],
                    [-0.779, 1.333],
                    [-1.065, 0],
                    [-0.779, -1.333],
                    [0.001, -1.764],
                    [0.789, -1.333],
                    [1.075, 0],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.356, 0.224],
                    [0.455, 0],
                    [0.356, -0.225],
                    [0.202, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                    [-0.355, -0.224],
                    [-0.455, 0],
                    [-0.355, 0.225],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.202, 0.426],
                  ],
                  o: [
                    [-0.355, -0.225],
                    [-0.455, 0],
                    [-0.355, 0.224],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.202, 0.426],
                    [0.356, 0.225],
                    [0.455, 0],
                    [0.356, -0.224],
                    [0.202, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                  ],
                  v: [
                    [1.216, -2.501],
                    [0.001, -2.839],
                    [-1.215, -2.501],
                    [-2.05, -1.525],
                    [-2.352, 0],
                    [-2.05, 1.525],
                    [-1.215, 2.501],
                    [0.001, 2.839],
                    [1.216, 2.501],
                    [2.051, 1.525],
                    [2.353, 0],
                    [2.051, -1.525],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [678.052, 150.937], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 72",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.287],
                    [0, 0.601],
                    [-0.191, 0.287],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                  ],
                  o: [
                    [-0.191, 0.287],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.287],
                    [0, 0.601],
                  ],
                  v: [
                    [0.789, 1.333],
                    [0.001, 1.765],
                    [-0.779, 1.333],
                    [-1.065, 0.001],
                    [-0.779, -1.332],
                    [0.001, -1.764],
                    [0.789, -1.332],
                    [1.075, 0.001],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.356, 0.225],
                    [0.455, 0],
                    [0.356, -0.224],
                    [0.202, -0.427],
                    [0, -0.591],
                    [-0.201, -0.426],
                    [-0.355, -0.225],
                    [-0.455, 0],
                    [-0.355, 0.225],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.202, 0.426],
                  ],
                  o: [
                    [-0.355, -0.224],
                    [-0.455, 0],
                    [-0.355, 0.225],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.202, 0.426],
                    [0.356, 0.225],
                    [0.455, 0],
                    [0.356, -0.225],
                    [0.202, -0.426],
                    [0, -0.591],
                    [-0.201, -0.427],
                  ],
                  v: [
                    [1.216, -2.501],
                    [0.001, -2.839],
                    [-1.215, -2.501],
                    [-2.05, -1.524],
                    [-2.352, 0.001],
                    [-2.05, 1.526],
                    [-1.215, 2.502],
                    [0.001, 2.839],
                    [1.216, 2.502],
                    [2.051, 1.526],
                    [2.353, 0.001],
                    [2.051, -1.524],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [678.052, 141.527], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 73",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1, -2],
                    [0, -2],
                    [0, 3],
                    [1, 3],
                    [1, -3],
                    [-1, -3],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [678, 132], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 74",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [1, 2.5],
                    [1, -2.5],
                    [-1, -2.5],
                    [-1, -1.5],
                    [0, -1.5],
                    [0, 2.5],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [678, 122.5], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 75",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.191, -0.287],
                    [0.335, 0],
                    [0.191, 0.287],
                    [0, 0.601],
                    [-0.191, 0.287],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                  ],
                  o: [
                    [-0.191, 0.287],
                    [-0.329, 0],
                    [-0.191, -0.287],
                    [0, -0.601],
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.287],
                    [0, 0.601],
                  ],
                  v: [
                    [0.789, 1.333],
                    [0.001, 1.764],
                    [-0.779, 1.333],
                    [-1.065, 0],
                    [-0.779, -1.333],
                    [0.001, -1.764],
                    [0.789, -1.333],
                    [1.075, 0],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.356, 0.225],
                    [0.455, 0],
                    [0.356, -0.224],
                    [0.202, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                    [-0.355, -0.224],
                    [-0.455, 0],
                    [-0.355, 0.225],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.202, 0.426],
                  ],
                  o: [
                    [-0.355, -0.224],
                    [-0.455, 0],
                    [-0.355, 0.225],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.202, 0.426],
                    [0.356, 0.225],
                    [0.455, 0],
                    [0.356, -0.224],
                    [0.202, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                  ],
                  v: [
                    [1.216, -2.502],
                    [0.001, -2.839],
                    [-1.215, -2.502],
                    [-2.05, -1.525],
                    [-2.352, 0],
                    [-2.05, 1.525],
                    [-1.215, 2.501],
                    [0.001, 2.839],
                    [1.216, 2.501],
                    [2.051, 1.525],
                    [2.353, 0],
                    [2.051, -1.525],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [678.052, 113.298], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 76",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [1, 3],
                    [1, -3],
                    [-1, -3],
                    [-1, -2],
                    [0, -2],
                    [0, 3],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [678, 104], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 77",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.191, -0.287],
                    [0.335, 0],
                    [0.191, 0.288],
                    [0, 0.601],
                    [-0.191, 0.287],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                  ],
                  o: [
                    [-0.191, 0.288],
                    [-0.329, 0],
                    [-0.191, -0.287],
                    [0, -0.601],
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.287],
                    [0, 0.601],
                  ],
                  v: [
                    [0.789, 1.333],
                    [0.001, 1.764],
                    [-0.779, 1.333],
                    [-1.065, 0],
                    [-0.779, -1.333],
                    [0.001, -1.764],
                    [0.789, -1.333],
                    [1.075, 0],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.356, 0.225],
                    [0.455, 0],
                    [0.356, -0.224],
                    [0.202, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                    [-0.355, -0.224],
                    [-0.455, 0],
                    [-0.355, 0.225],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.202, 0.426],
                  ],
                  o: [
                    [-0.355, -0.224],
                    [-0.455, 0],
                    [-0.355, 0.225],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.202, 0.426],
                    [0.356, 0.225],
                    [0.455, 0],
                    [0.356, -0.224],
                    [0.202, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                  ],
                  v: [
                    [1.216, -2.502],
                    [0.001, -2.839],
                    [-1.215, -2.502],
                    [-2.05, -1.525],
                    [-2.352, 0],
                    [-2.05, 1.525],
                    [-1.215, 2.501],
                    [0.001, 2.839],
                    [1.216, 2.501],
                    [2.051, 1.525],
                    [2.353, 0],
                    [2.051, -1.525],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [678.052, 94.478], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 78",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.191, -0.287],
                    [0.335, 0],
                    [0.191, 0.288],
                    [0, 0.601],
                    [-0.191, 0.288],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                  ],
                  o: [
                    [-0.191, 0.288],
                    [-0.329, 0],
                    [-0.191, -0.287],
                    [0, -0.601],
                    [0.191, -0.288],
                    [0.335, 0],
                    [0.191, 0.288],
                    [0, 0.601],
                  ],
                  v: [
                    [0.789, 1.333],
                    [0.001, 1.764],
                    [-0.779, 1.333],
                    [-1.065, 0],
                    [-0.779, -1.333],
                    [0.001, -1.764],
                    [0.789, -1.333],
                    [1.075, 0],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.356, 0.224],
                    [0.455, 0],
                    [0.356, -0.225],
                    [0.202, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                    [-0.355, -0.224],
                    [-0.455, 0],
                    [-0.355, 0.225],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.202, 0.426],
                  ],
                  o: [
                    [-0.355, -0.225],
                    [-0.455, 0],
                    [-0.355, 0.224],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.202, 0.426],
                    [0.356, 0.225],
                    [0.455, 0],
                    [0.356, -0.224],
                    [0.202, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                  ],
                  v: [
                    [1.216, -2.501],
                    [0.001, -2.839],
                    [-1.215, -2.501],
                    [-2.05, -1.525],
                    [-2.352, 0],
                    [-2.05, 1.525],
                    [-1.215, 2.501],
                    [0.001, 2.839],
                    [1.216, 2.501],
                    [2.051, 1.525],
                    [2.353, 0],
                    [2.051, -1.525],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [678.052, 85.068], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 79",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [0, 2.5],
                    [1, 2.5],
                    [1, -2.5],
                    [-1, -2.5],
                    [-1, -1.5],
                    [0, -1.5],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [678, 75.5], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 80",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [0, 2.5],
                    [1, 2.5],
                    [1, -2.5],
                    [-1, -2.5],
                    [-1, -1.5],
                    [0, -1.5],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [678, 66.5], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 81",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.191, -0.287],
                    [0.334, 0],
                    [0.191, 0.288],
                    [0, 0.601],
                    [-0.191, 0.288],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                  ],
                  o: [
                    [-0.191, 0.288],
                    [-0.329, 0],
                    [-0.191, -0.287],
                    [0, -0.601],
                    [0.191, -0.288],
                    [0.334, 0],
                    [0.191, 0.288],
                    [0, 0.601],
                  ],
                  v: [
                    [0.789, 1.333],
                    [0.001, 1.764],
                    [-0.779, 1.333],
                    [-1.065, 0],
                    [-0.779, -1.333],
                    [0.001, -1.764],
                    [0.789, -1.333],
                    [1.075, 0],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.356, 0.224],
                    [0.455, 0],
                    [0.355, -0.225],
                    [0.201, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                    [-0.356, -0.224],
                    [-0.455, 0],
                    [-0.355, 0.225],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.201, 0.426],
                  ],
                  o: [
                    [-0.355, -0.225],
                    [-0.455, 0],
                    [-0.356, 0.224],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.201, 0.426],
                    [0.355, 0.225],
                    [0.455, 0],
                    [0.356, -0.224],
                    [0.201, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                  ],
                  v: [
                    [1.216, -2.501],
                    [0.001, -2.839],
                    [-1.214, -2.501],
                    [-2.05, -1.525],
                    [-2.352, 0],
                    [-2.05, 1.525],
                    [-1.214, 2.501],
                    [0.001, 2.839],
                    [1.216, 2.501],
                    [2.051, 1.525],
                    [2.353, 0],
                    [2.051, -1.525],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [665.636, 150.937], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 82",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [1.5, 2.5],
                    [1.5, -2.5],
                    [-1.5, -2.5],
                    [-1.5, -1.5],
                    [-0.5, -1.5],
                    [-0.5, 2.5],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [665.5, 141.5], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 83",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.191, -0.288],
                    [0.334, 0],
                    [0.191, 0.287],
                    [0, 0.601],
                    [-0.191, 0.287],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                  ],
                  o: [
                    [-0.191, 0.287],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                    [0.191, -0.288],
                    [0.334, 0],
                    [0.191, 0.287],
                    [0, 0.601],
                  ],
                  v: [
                    [0.789, 1.333],
                    [0.001, 1.764],
                    [-0.779, 1.333],
                    [-1.065, 0],
                    [-0.779, -1.333],
                    [0.001, -1.764],
                    [0.789, -1.333],
                    [1.075, 0],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.356, 0.225],
                    [0.455, 0],
                    [0.355, -0.224],
                    [0.201, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                    [-0.356, -0.224],
                    [-0.455, 0],
                    [-0.355, 0.225],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.201, 0.426],
                  ],
                  o: [
                    [-0.355, -0.224],
                    [-0.455, 0],
                    [-0.356, 0.225],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.201, 0.426],
                    [0.355, 0.225],
                    [0.455, 0],
                    [0.356, -0.224],
                    [0.201, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                  ],
                  v: [
                    [1.216, -2.502],
                    [0.001, -2.839],
                    [-1.214, -2.502],
                    [-2.05, -1.525],
                    [-2.352, 0],
                    [-2.05, 1.525],
                    [-1.214, 2.501],
                    [0.001, 2.839],
                    [1.216, 2.501],
                    [2.051, 1.525],
                    [2.353, 0],
                    [2.051, -1.525],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [665.636, 132.117], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 84",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [1.5, 2.5],
                    [1.5, -2.5],
                    [-1.5, -2.5],
                    [-1.5, -1.5],
                    [-0.5, -1.5],
                    [-0.5, 2.5],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [665.5, 122.5], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 85",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.191, -0.287],
                    [0.334, 0],
                    [0.191, 0.287],
                    [0, 0.601],
                    [-0.191, 0.287],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                  ],
                  o: [
                    [-0.191, 0.287],
                    [-0.329, 0],
                    [-0.191, -0.287],
                    [0, -0.601],
                    [0.191, -0.288],
                    [0.334, 0],
                    [0.191, 0.287],
                    [0, 0.601],
                  ],
                  v: [
                    [0.789, 1.333],
                    [0.001, 1.764],
                    [-0.779, 1.333],
                    [-1.065, 0],
                    [-0.779, -1.333],
                    [0.001, -1.764],
                    [0.789, -1.333],
                    [1.075, 0],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.356, 0.225],
                    [0.455, 0],
                    [0.355, -0.224],
                    [0.201, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                    [-0.356, -0.224],
                    [-0.455, 0],
                    [-0.355, 0.225],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.201, 0.426],
                  ],
                  o: [
                    [-0.355, -0.224],
                    [-0.455, 0],
                    [-0.356, 0.225],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.201, 0.426],
                    [0.355, 0.225],
                    [0.455, 0],
                    [0.356, -0.224],
                    [0.201, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                  ],
                  v: [
                    [1.216, -2.502],
                    [0.001, -2.839],
                    [-1.214, -2.502],
                    [-2.05, -1.525],
                    [-2.352, 0],
                    [-2.05, 1.525],
                    [-1.214, 2.501],
                    [0.001, 2.839],
                    [1.216, 2.501],
                    [2.051, 1.525],
                    [2.353, 0],
                    [2.051, -1.525],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [665.636, 113.298], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 86",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.191, -0.287],
                    [0.334, 0],
                    [0.191, 0.288],
                    [0, 0.601],
                    [-0.191, 0.287],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                  ],
                  o: [
                    [-0.191, 0.288],
                    [-0.329, 0],
                    [-0.191, -0.287],
                    [0, -0.601],
                    [0.191, -0.288],
                    [0.334, 0],
                    [0.191, 0.287],
                    [0, 0.601],
                  ],
                  v: [
                    [0.789, 1.333],
                    [0.001, 1.764],
                    [-0.779, 1.333],
                    [-1.065, 0],
                    [-0.779, -1.333],
                    [0.001, -1.764],
                    [0.789, -1.333],
                    [1.075, 0],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.356, 0.224],
                    [0.455, 0],
                    [0.355, -0.225],
                    [0.201, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                    [-0.356, -0.224],
                    [-0.455, 0],
                    [-0.355, 0.225],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.201, 0.426],
                  ],
                  o: [
                    [-0.355, -0.225],
                    [-0.455, 0],
                    [-0.356, 0.224],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.201, 0.426],
                    [0.355, 0.225],
                    [0.455, 0],
                    [0.356, -0.224],
                    [0.201, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                  ],
                  v: [
                    [1.216, -2.501],
                    [0.001, -2.839],
                    [-1.214, -2.501],
                    [-2.05, -1.525],
                    [-2.352, 0],
                    [-2.05, 1.525],
                    [-1.214, 2.501],
                    [0.001, 2.839],
                    [1.216, 2.501],
                    [2.051, 1.525],
                    [2.353, 0],
                    [2.051, -1.525],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [665.636, 103.888], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 87",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.191, -0.287],
                    [0.334, 0],
                    [0.191, 0.288],
                    [0, 0.601],
                    [-0.191, 0.287],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                  ],
                  o: [
                    [-0.191, 0.288],
                    [-0.329, 0],
                    [-0.191, -0.287],
                    [0, -0.601],
                    [0.191, -0.288],
                    [0.334, 0],
                    [0.191, 0.287],
                    [0, 0.601],
                  ],
                  v: [
                    [0.789, 1.333],
                    [0.001, 1.764],
                    [-0.779, 1.333],
                    [-1.065, 0],
                    [-0.779, -1.333],
                    [0.001, -1.764],
                    [0.789, -1.333],
                    [1.075, 0],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.356, 0.225],
                    [0.455, 0],
                    [0.355, -0.224],
                    [0.201, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                    [-0.356, -0.224],
                    [-0.455, 0],
                    [-0.355, 0.225],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.201, 0.426],
                  ],
                  o: [
                    [-0.355, -0.224],
                    [-0.455, 0],
                    [-0.356, 0.225],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.201, 0.426],
                    [0.355, 0.225],
                    [0.455, 0],
                    [0.356, -0.224],
                    [0.201, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                  ],
                  v: [
                    [1.216, -2.502],
                    [0.001, -2.839],
                    [-1.214, -2.502],
                    [-2.05, -1.525],
                    [-2.352, 0],
                    [-2.05, 1.525],
                    [-1.214, 2.501],
                    [0.001, 2.839],
                    [1.216, 2.501],
                    [2.051, 1.525],
                    [2.353, 0],
                    [2.051, -1.525],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [665.636, 94.478], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 88",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1.5, -2],
                    [-0.5, -2],
                    [-0.5, 3],
                    [1.5, 3],
                    [1.5, -3],
                    [-1.5, -3],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [665.5, 85], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 89",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [1.5, 2.5],
                    [1.5, -2.5],
                    [-1.5, -2.5],
                    [-1.5, -1.5],
                    [-0.5, -1.5],
                    [-0.5, 2.5],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [665.5, 75.5], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 90",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.191, -0.288],
                    [0.334, 0],
                    [0.191, 0.287],
                    [0, 0.601],
                    [-0.191, 0.287],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                  ],
                  o: [
                    [-0.191, 0.287],
                    [-0.329, 0],
                    [-0.191, -0.288],
                    [0, -0.601],
                    [0.191, -0.288],
                    [0.334, 0],
                    [0.191, 0.287],
                    [0, 0.601],
                  ],
                  v: [
                    [0.789, 1.333],
                    [0.001, 1.764],
                    [-0.779, 1.333],
                    [-1.065, 0],
                    [-0.779, -1.333],
                    [0.001, -1.764],
                    [0.789, -1.333],
                    [1.075, 0],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.356, 0.225],
                    [0.455, 0],
                    [0.355, -0.224],
                    [0.201, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                    [-0.356, -0.224],
                    [-0.455, 0],
                    [-0.355, 0.225],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.201, 0.426],
                  ],
                  o: [
                    [-0.355, -0.224],
                    [-0.455, 0],
                    [-0.356, 0.225],
                    [-0.201, 0.426],
                    [0, 0.591],
                    [0.201, 0.426],
                    [0.355, 0.225],
                    [0.455, 0],
                    [0.356, -0.224],
                    [0.201, -0.426],
                    [0, -0.591],
                    [-0.201, -0.426],
                  ],
                  v: [
                    [1.216, -2.502],
                    [0.001, -2.839],
                    [-1.214, -2.502],
                    [-2.05, -1.525],
                    [-2.352, 0],
                    [-2.05, 1.525],
                    [-1.214, 2.501],
                    [0.001, 2.839],
                    [1.216, 2.501],
                    [2.051, 1.525],
                    [2.353, 0],
                    [2.051, -1.525],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [665.636, 66.248], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 1,
    },
    {
      ty: 4,
      nm: "bottom_right_text Outlines",
      sr: 1,
      st: 0,
      op: 120,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: true,
      ao: 0,
      ks: {
        a: { a: 0, k: [576, 360, 0] },
        s: { a: 0, k: [100, 100, 100] },
        sk: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 360, 0],
              t: 3,
              ti: [0, 0, 0],
              to: [0, -1.16666662693024, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 353, 0],
              t: 14,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 360, 0],
              t: 27,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 353, 0],
              t: 41,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 360, 0],
              t: 54,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 353, 0],
              t: 69,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 360, 0],
              t: 82,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 353, 0],
              t: 95,
              ti: [0, -1.16666662693024, 0],
              to: [0, 0, 0],
            },
            { s: [576, 360, 0], t: 108 },
          ],
        },
        r: { a: 0, k: 0 },
        sa: { a: 0, k: 0 },
        o: {
          a: 1,
          k: [
            { o: { x: 0.285, y: 0 }, i: { x: 0.621, y: 0.986 }, s: [0], t: 17 },
            {
              o: { x: 0.213, y: 0.213 },
              i: { x: 0.68, y: 0.68 },
              s: [100],
              t: 21,
            },
            { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [100], t: 94 },
            { s: [0], t: 97 },
          ],
        },
      },
      ef: [],
      masksProperties: [
        {
          nm: "Mask 1",
          inv: false,
          mode: "a",
          x: {
            a: 1,
            k: [
              { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [-8], t: 19 },
              {
                o: { x: 0.333, y: 0.333 },
                i: { x: 0.833, y: 0.833 },
                s: [100],
                t: 24,
              },
              { o: { x: 0.167, y: 0 }, i: { x: 0.833, y: 1 }, s: [100], t: 93 },
              { s: [-8], t: 97 },
            ],
          },
          o: { a: 0, k: 100 },
          pt: {
            a: 0,
            k: {
              c: true,
              i: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
              ],
              o: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
              ],
              v: [
                [816.375, 291.561],
                [743.125, 333.311],
                [744, 345.561],
                [823.5, 298.811],
              ],
            },
          },
        },
      ],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 1",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [1.737, -7.121],
                    [-2.947, 7.169],
                    [-1.742, 7.122],
                    [2.946, -7.169],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [825.636, 350.505], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 2",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-4.055, -5.978],
                    [-4.755, -4.272],
                    [2.163, -3.32],
                    [-4.56, 5.039],
                    [-3.893, 5.979],
                    [4.754, -4.765],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [835.213, 346.934], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 3",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [4.742, -5.161],
                    [4.031, -6.052],
                    [-4.742, 5.312],
                    [3.937, 6.052],
                    [4.601, 4.344],
                    [-2.148, 3.763],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [816.946, 352.886], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 4",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [9.656, -8.642],
                    [-9.673, 2.518],
                    [-9.656, 8.642],
                    [9.673, -2.518],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [834.517, 328.762], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 5",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [36.063, -23.888],
                    [-36.079, 17.763],
                    [-36.062, 23.888],
                    [36.08, -17.764],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [782.75, 358.65], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 6",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [36.755, -24.289],
                    [-36.771, 18.161],
                    [-36.754, 24.289],
                    [36.771, -18.161],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [783.413, 348.382], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 7",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [6.414, -6.772],
                    [-6.431, 0.644],
                    [-6.413, 6.772],
                    [6.431, -0.644],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [834.461, 309.025], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 8",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [6.411, -6.771],
                    [-6.429, 0.643],
                    [-6.412, 6.771],
                    [6.428, -0.642],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [818.377, 318.312], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 9",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [30.864, -20.888],
                    [-30.88, 14.76],
                    [-30.864, 20.888],
                    [30.88, -14.76],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [777.495, 341.915], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 10",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [44.74, -28.897],
                    [-44.756, 22.774],
                    [-44.739, 28.897],
                    [44.757, -22.774],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [791.34, 323.015], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 11",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [4.553, -5.695],
                    [-4.57, -0.428],
                    [-4.552, 5.695],
                    [4.57, 0.428],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [811.898, 301.262], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 12",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [28.715, -19.645],
                    [-28.732, 13.522],
                    [-28.714, 19.645],
                    [28.732, -13.522],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [775.287, 322.399], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 2,
    },
    {
      ty: 4,
      nm: "bottom_right_object Outlines",
      sr: 1,
      st: 0,
      op: 120,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [576, 360, 0] },
        s: { a: 0, k: [100, 100, 100] },
        sk: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 360, 0],
              t: 3,
              ti: [0, 0, 0],
              to: [0, -1.16666662693024, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 353, 0],
              t: 14,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 360, 0],
              t: 27,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 353, 0],
              t: 41,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 360, 0],
              t: 54,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 353, 0],
              t: 69,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 360, 0],
              t: 82,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 353, 0],
              t: 95,
              ti: [0, -1.16666662693024, 0],
              to: [0, 0, 0],
            },
            { s: [576, 360, 0], t: 108 },
          ],
        },
        r: { a: 0, k: 0 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 1",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.007, -1.325],
                    [1.229, -0.346],
                    [0.006, 1.325],
                    [-1.229, 0.346],
                  ],
                  o: [
                    [0.007, 1.325],
                    [-1.229, 0.346],
                    [-0.007, -1.324],
                    [1.229, -0.346],
                  ],
                  v: [
                    [2.225, -0.626],
                    [0.012, 2.399],
                    [-2.225, 0.627],
                    [-0.012, -2.398],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.1098, 0.2, 0.3216] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [797.422, 381.483], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 2",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [8, -5.155],
                    [0, 0],
                    [-8.222, 5.181],
                    [0.053, 1.718],
                  ],
                  o: [
                    [0, 0],
                    [9, -5.181],
                    [-0.053, -1.719],
                    [-8.169, 5.155],
                  ],
                  v: [
                    [-12.347, 5.116],
                    [-12.347, 10.35],
                    [12.347, -5.194],
                    [12.174, -10.349],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.7216, 0.7216, 0.7216] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [788.347, 398.497], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 3",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-0.007, -2.558],
                    [0, 0],
                    [2.227, -1.286],
                    [0, 0],
                    [0.007, 2.559],
                    [0, 0],
                    [-2.227, 1.286],
                  ],
                  o: [
                    [2.227, -1.286],
                    [0, 0],
                    [0.007, 2.559],
                    [0, 0],
                    [-2.227, 1.286],
                    [0, 0],
                    [-0.007, -2.559],
                    [0, 0],
                  ],
                  v: [
                    [50.902, -70.209],
                    [54.965, -67.894],
                    [55.17, 4.307],
                    [51.133, 11.299],
                    [-50.902, 70.209],
                    [-54.965, 67.893],
                    [-55.17, -4.307],
                    [-51.133, -11.299],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [795.636, 337.169], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 4",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-0.009, -3.367],
                    [0, 0],
                    [2.934, -1.694],
                    [0, 0],
                    [0.009, 3.367],
                    [0, 0],
                    [-2.93, 1.692],
                  ],
                  o: [
                    [2.934, -1.694],
                    [0, 0],
                    [0.01, 3.367],
                    [0, 0],
                    [-2.93, 1.692],
                    [0, 0],
                    [-0.01, -3.367],
                    [0, 0],
                  ],
                  v: [
                    [55.568, -79.543],
                    [60.917, -76.499],
                    [61.151, 6.022],
                    [55.836, 15.224],
                    [-55.572, 79.545],
                    [-60.918, 76.499],
                    [-61.152, -6.022],
                    [-55.841, -15.222],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [794.918, 339.606], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 5",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.01, 0.006],
                    [0, 0],
                    [-0.01, -0.006],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.01, 0.006],
                    [0, 0],
                    [-0.009, -0.006],
                  ],
                  v: [
                    [4.178, 2.428],
                    [-4.207, -2.445],
                    [-4.177, -2.428],
                    [4.207, 2.445],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.855, -0.493],
                    [0, 0],
                    [-0.971, -0.566],
                    [0, 0],
                    [0.524, 0],
                  ],
                  o: [
                    [0, 0],
                    [1.476, -0.852],
                    [0, 0],
                    [-0.409, -0.238],
                    [-0.718, 0],
                  ],
                  v: [
                    [-7.97, -2.063],
                    [0.416, 2.809],
                    [4.207, 2.446],
                    [-4.178, -2.428],
                    [-5.587, -2.79],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [850.07, 257.253], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 6",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.093, 0.039],
                    [0, 0],
                    [-0.087, -0.049],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.093, 0.039],
                    [0, 0],
                    [-0.087, -0.049],
                  ],
                  v: [
                    [4.057, 2.37],
                    [-4.328, -2.503],
                    [-4.058, -2.371],
                    [4.327, 2.503],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.855, -0.493],
                    [0, 0],
                    [-0.971, -0.566],
                    [0, 0],
                    [0.524, 0],
                  ],
                  o: [
                    [0, 0],
                    [1.476, -0.852],
                    [0, 0],
                    [-0.409, -0.238],
                    [-0.718, 0],
                  ],
                  v: [
                    [-7.821, -1.989],
                    [0.565, 2.883],
                    [4.356, 2.52],
                    [-4.029, -2.354],
                    [-5.438, -2.716],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [849.921, 257.179], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 7",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.097, 0.028],
                    [0, 0],
                    [-0.092, -0.038],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.097, 0.029],
                    [0, 0],
                    [-0.091, -0.038],
                  ],
                  v: [
                    [4.051, 2.386],
                    [-4.334, -2.487],
                    [-4.05, -2.387],
                    [4.334, 2.486],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.855, -0.493],
                    [0, 0],
                    [-0.971, -0.566],
                    [0, 0],
                    [0.524, 0],
                  ],
                  o: [
                    [0, 0],
                    [1.476, -0.852],
                    [0, 0],
                    [-0.409, -0.238],
                    [-0.718, 0],
                  ],
                  v: [
                    [-7.544, -1.873],
                    [0.842, 2.999],
                    [4.633, 2.636],
                    [-3.752, -2.238],
                    [-5.161, -2.6],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [849.644, 257.063], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 8",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.103, 0.018],
                    [0, 0],
                    [-0.097, -0.029],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.103, 0.018],
                    [0, 0],
                    [-0.098, -0.028],
                  ],
                  v: [
                    [4.042, 2.402],
                    [-4.343, -2.471],
                    [-4.042, -2.401],
                    [4.343, 2.471],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.855, -0.493],
                    [0, 0],
                    [-0.971, -0.566],
                    [0, 0],
                    [0.524, 0],
                  ],
                  o: [
                    [0, 0],
                    [1.476, -0.852],
                    [0, 0],
                    [-0.409, -0.238],
                    [-0.718, 0],
                  ],
                  v: [
                    [-7.251, -1.788],
                    [1.135, 3.084],
                    [4.926, 2.721],
                    [-3.459, -2.153],
                    [-4.868, -2.515],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [849.351, 256.978], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 9",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.11, 0.007],
                    [0, 0],
                    [-0.106, -0.018],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.111, 0.007],
                    [0, 0],
                    [-0.106, -0.018],
                  ],
                  v: [
                    [4.031, 2.417],
                    [-4.354, -2.456],
                    [-4.031, -2.418],
                    [4.354, 2.456],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.855, -0.493],
                    [0, 0],
                    [-0.971, -0.566],
                    [0, 0],
                    [0.524, 0],
                  ],
                  o: [
                    [0, 0],
                    [1.476, -0.852],
                    [0, 0],
                    [-0.409, -0.238],
                    [-0.718, 0],
                  ],
                  v: [
                    [-6.939, -1.735],
                    [1.447, 3.137],
                    [5.238, 2.774],
                    [-3.147, -2.1],
                    [-4.556, -2.462],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [849.039, 256.925], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 10",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.12, -0.005],
                    [0, 0],
                    [-0.115, -0.007],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.121, -0.005],
                    [0, 0],
                    [-0.116, -0.007],
                  ],
                  v: [
                    [4.016, 2.437],
                    [-4.369, -2.436],
                    [-4.015, -2.433],
                    [4.37, 2.44],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.855, -0.493],
                    [0, 0],
                    [-0.971, -0.566],
                    [0, 0],
                    [0.524, 0],
                  ],
                  o: [
                    [0, 0],
                    [1.476, -0.852],
                    [0, 0],
                    [-0.409, -0.238],
                    [-0.718, 0],
                  ],
                  v: [
                    [-6.6, -1.713],
                    [1.786, 3.159],
                    [5.577, 2.796],
                    [-2.808, -2.078],
                    [-4.217, -2.44],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [848.7, 256.903], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 11",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.135, -0.021],
                    [0, 0],
                    [-0.13, 0.006],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.135, -0.02],
                    [0, 0],
                    [-0.13, 0.006],
                  ],
                  v: [
                    [3.994, 2.457],
                    [-4.391, -2.416],
                    [-3.994, -2.456],
                    [4.391, 2.417],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.855, -0.493],
                    [0, 0],
                    [-0.971, -0.566],
                    [0, 0],
                    [0.524, 0],
                  ],
                  o: [
                    [0, 0],
                    [1.476, -0.852],
                    [0, 0],
                    [-0.409, -0.238],
                    [-0.718, 0],
                  ],
                  v: [
                    [-6.225, -1.733],
                    [2.161, 3.139],
                    [5.952, 2.776],
                    [-2.433, -2.098],
                    [-3.842, -2.46],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [848.325, 256.923], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 12",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.159, -0.043],
                    [0, 0],
                    [-0.153, 0.024],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.159, -0.043],
                    [0, 0],
                    [-0.153, 0.024],
                  ],
                  v: [
                    [3.959, 2.487],
                    [-4.426, -2.386],
                    [-3.958, -2.486],
                    [4.427, 2.387],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.855, -0.493],
                    [0, 0],
                    [-0.971, -0.566],
                    [0, 0],
                    [0.524, 0],
                  ],
                  o: [
                    [0, 0],
                    [1.476, -0.852],
                    [0, 0],
                    [-0.409, -0.238],
                    [-0.718, 0],
                  ],
                  v: [
                    [-5.791, -1.803],
                    [2.595, 3.069],
                    [6.386, 2.706],
                    [-1.999, -2.168],
                    [-3.408, -2.53],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [847.891, 256.993], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 13",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.213, -0.089],
                    [0, 0],
                    [-0.204, 0.055],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.213, -0.088],
                    [0, 0],
                    [-0.204, 0.056],
                  ],
                  v: [
                    [3.88, 2.544],
                    [-4.505, -2.329],
                    [-3.879, -2.544],
                    [4.505, 2.328],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.855, -0.493],
                    [0, 0],
                    [-0.971, -0.566],
                    [0, 0],
                    [0.524, 0],
                  ],
                  o: [
                    [0, 0],
                    [1.476, -0.852],
                    [0, 0],
                    [-0.409, -0.238],
                    [-0.718, 0],
                  ],
                  v: [
                    [-5.245, -1.961],
                    [3.142, 2.911],
                    [6.932, 2.548],
                    [-1.452, -2.326],
                    [-2.862, -2.688],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [847.344, 257.151], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 14",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.251, -0.145],
                    [0, 0],
                    [-0.241, 0.1],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.251, -0.144],
                    [0, 0],
                    [-0.242, 0.101],
                  ],
                  v: [
                    [3.823, 2.62],
                    [-4.562, -2.253],
                    [-3.823, -2.62],
                    [4.562, 2.252],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.855, -0.493],
                    [0, 0],
                    [-0.971, -0.566],
                    [0, 0],
                    [0.524, 0],
                  ],
                  o: [
                    [0, 0],
                    [1.476, -0.852],
                    [0, 0],
                    [-0.409, -0.238],
                    [-0.718, 0],
                  ],
                  v: [
                    [-4.562, -2.252],
                    [3.824, 2.62],
                    [7.615, 2.257],
                    [-0.77, -2.617],
                    [-2.179, -2.979],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [846.662, 257.442], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 15",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.971, -0.564],
                    [0, 0],
                    [1.477, -0.853],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.971, -0.565],
                    [0, 0],
                    [1.477, -0.852],
                  ],
                  v: [
                    [-2.296, -2.337],
                    [6.089, 2.536],
                    [2.297, 2.901],
                    [-6.089, -1.973],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [848.189, 257.162], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 16",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.011, 0.076],
                    [-0.027, 0.141],
                    [-0.022, 0.098],
                    [-0.024, 0.092],
                    [-0.036, 0.125],
                    [-0.024, 0.075],
                    [-0.051, 0.142],
                    [-0.022, 0.058],
                    [-0.084, 0.194],
                    [-0.012, 0.027],
                    [-0.083, 0.167],
                    [-0.029, 0.054],
                    [-0.089, 0.157],
                    [-0.019, 0.033],
                    [-0.115, 0.179],
                    [-0.035, 0.052],
                    [-0.09, 0.124],
                    [-0.051, 0.068],
                    [-0.081, 0.101],
                    [-0.071, 0.084],
                    [-0.069, 0.076],
                    [-0.12, 0.122],
                    [-0.06, 0.058],
                    [-0.255, 0.202],
                    [-0.047, 0.035],
                    [-0.28, 0.162],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.269, -0.204],
                    [0.046, -0.036],
                    [0.242, -0.234],
                    [0.02, -0.019],
                    [0.039, -0.04],
                    [0.115, -0.129],
                    [0.037, -0.042],
                    [0.031, -0.038],
                    [0.069, -0.086],
                    [0.043, -0.055],
                    [0.037, -0.048],
                    [0.05, -0.068],
                    [0.032, -0.046],
                    [0.054, -0.082],
                    [0.034, -0.052],
                    [0.013, -0.021],
                    [0.09, -0.153],
                    [0.006, -0.01],
                    [0.019, -0.033],
                    [0.058, -0.11],
                    [0.026, -0.051],
                    [0.027, -0.055],
                    [0.023, -0.048],
                    [0.054, -0.121],
                    [0.012, -0.026],
                    [0.003, -0.008],
                    [0.062, -0.161],
                    [0.01, -0.027],
                    [0.021, -0.058],
                    [0.028, -0.083],
                    [0.018, -0.06],
                    [0.022, -0.073],
                    [0.014, -0.048],
                    [0.02, -0.079],
                    [0.021, -0.091],
                    [0.007, -0.033],
                    [0.013, -0.065],
                    [0.02, -0.139],
                    [0.005, -0.04],
                    [0.004, -0.036],
                    [-0.001, -0.302],
                    [0, 0],
                    [-0.958, -0.557],
                    [0, 0],
                    [0.004, 1.673],
                    [0, 0],
                    [-0.036, 0.312],
                  ],
                  o: [
                    [0.02, -0.139],
                    [0.019, -0.098],
                    [0.021, -0.092],
                    [0.031, -0.126],
                    [0.022, -0.075],
                    [0.044, -0.142],
                    [0.021, -0.058],
                    [0.073, -0.196],
                    [0.012, -0.026],
                    [0.076, -0.169],
                    [0.028, -0.056],
                    [0.082, -0.16],
                    [0.018, -0.032],
                    [0.107, -0.184],
                    [0.035, -0.054],
                    [0.086, -0.128],
                    [0.049, -0.069],
                    [0.078, -0.104],
                    [0.068, -0.086],
                    [0.067, -0.079],
                    [0.116, -0.13],
                    [0.059, -0.06],
                    [0.241, -0.234],
                    [0.046, -0.036],
                    [0.269, -0.204],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.281, 0.162],
                    [-0.047, 0.035],
                    [-0.255, 0.202],
                    [-0.02, 0.02],
                    [-0.04, 0.039],
                    [-0.12, 0.122],
                    [-0.037, 0.041],
                    [-0.032, 0.037],
                    [-0.071, 0.083],
                    [-0.043, 0.054],
                    [-0.037, 0.048],
                    [-0.051, 0.067],
                    [-0.032, 0.046],
                    [-0.056, 0.08],
                    [-0.035, 0.052],
                    [-0.013, 0.021],
                    [-0.096, 0.15],
                    [-0.006, 0.01],
                    [-0.018, 0.032],
                    [-0.061, 0.108],
                    [-0.026, 0.049],
                    [-0.028, 0.054],
                    [-0.024, 0.047],
                    [-0.058, 0.121],
                    [-0.012, 0.027],
                    [-0.003, 0.008],
                    [-0.069, 0.159],
                    [-0.01, 0.027],
                    [-0.022, 0.058],
                    [-0.029, 0.083],
                    [-0.02, 0.059],
                    [-0.024, 0.074],
                    [-0.014, 0.048],
                    [-0.022, 0.079],
                    [-0.023, 0.091],
                    [-0.007, 0.033],
                    [-0.014, 0.066],
                    [-0.026, 0.14],
                    [-0.005, 0.04],
                    [-0.005, 0.036],
                    [-0.037, 0.312],
                    [0, 0],
                    [0.005, 1.672],
                    [0, 0],
                    [-0.958, -0.557],
                    [0, 0],
                    [-0.001, -0.303],
                    [0.009, -0.076],
                  ],
                  v: [
                    [-54.078, -4.921],
                    [-54.006, -5.341],
                    [-53.948, -5.635],
                    [-53.88, -5.91],
                    [-53.78, -6.286],
                    [-53.711, -6.511],
                    [-53.568, -6.937],
                    [-53.505, -7.111],
                    [-53.269, -7.697],
                    [-53.233, -7.776],
                    [-52.995, -8.28],
                    [-52.911, -8.445],
                    [-52.654, -8.921],
                    [-52.6, -9.018],
                    [-52.265, -9.563],
                    [-52.159, -9.721],
                    [-51.896, -10.099],
                    [-51.745, -10.304],
                    [-51.506, -10.61],
                    [-51.297, -10.865],
                    [-51.092, -11.097],
                    [-50.738, -11.475],
                    [-50.561, -11.652],
                    [-49.817, -12.307],
                    [-49.677, -12.412],
                    [-48.855, -12.968],
                    [62.553, -77.29],
                    [54.168, -82.162],
                    [-57.239, -17.841],
                    [-58.062, -17.284],
                    [-58.201, -17.18],
                    [-58.946, -16.525],
                    [-59.007, -16.471],
                    [-59.123, -16.347],
                    [-59.476, -15.971],
                    [-59.589, -15.851],
                    [-59.682, -15.737],
                    [-59.891, -15.483],
                    [-60.023, -15.323],
                    [-60.131, -15.176],
                    [-60.281, -14.973],
                    [-60.38, -14.838],
                    [-60.544, -14.593],
                    [-60.649, -14.437],
                    [-60.69, -14.375],
                    [-60.968, -13.921],
                    [-60.985, -13.89],
                    [-61.039, -13.793],
                    [-61.22, -13.468],
                    [-61.296, -13.317],
                    [-61.379, -13.154],
                    [-61.451, -13.012],
                    [-61.618, -12.648],
                    [-61.654, -12.57],
                    [-61.664, -12.546],
                    [-61.861, -12.066],
                    [-61.89, -11.984],
                    [-61.953, -11.81],
                    [-62.042, -11.561],
                    [-62.096, -11.382],
                    [-62.164, -11.161],
                    [-62.208, -11.018],
                    [-62.266, -10.781],
                    [-62.333, -10.508],
                    [-62.357, -10.408],
                    [-62.391, -10.213],
                    [-62.463, -9.794],
                    [-62.483, -9.673],
                    [-62.49, -9.566],
                    [-62.551, -8.641],
                    [-62.317, 73.88],
                    [-60.758, 77.289],
                    [-52.373, 82.162],
                    [-53.932, 78.752],
                    [-54.166, -3.768],
                    [-54.106, -4.693],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [787.933, 337.352], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 17",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [23.938, -35.519],
                    [-12.101, -35.519],
                    [-31.902, -12.414],
                    [7.13, 35.519],
                    [31.901, 20.784],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [768.662, 368.519], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 18",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-17.793, -26.614],
                    [-20.549, -23.32],
                    [20.549, 26.614],
                    [20.549, 21.379],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.651, 0.651, 0.651] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [755.45, 382.233], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 19",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [10.782, -33.042],
                    [-56.429, 7.879],
                    [-10.782, 33.042],
                    [56.429, -7.878],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.8588, 0.8588, 0.8588] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [791.054, 404.944], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 20",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [22.831, 10.03],
                    [22.816, 15.134],
                    [-22.831, -10.028],
                    [-22.817, -15.133],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5412, 0.5412, 0.5412] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [757.441, 427.956], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 21",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [33.613, -23.012],
                    [33.599, -17.908],
                    [-33.612, 23.012],
                    [-33.598, 17.908],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.7686, 0.7686, 0.7686] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [813.87, 420.077], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 22",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [37.225, 18.06],
                    [37.225, 24.47],
                    [36.835, 24.7],
                    [-37.225, -18.06],
                    [-37.205, -24.7],
                    [36.855, 18.06],
                    [37.205, 17.86],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.8118, 0.8118, 0.8118] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [753.775, 439.93], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 23",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [79.849, -3.507],
                    [-5.793, 46.263],
                    [-79.85, 3.506],
                    [5.792, -46.264],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [796.421, 411.729], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 24",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [79.86, -28.205],
                    [79.84, -21.565],
                    [-5.41, 27.975],
                    [-5.8, 28.205],
                    [-79.86, -14.555],
                    [-79.84, -21.195],
                    [-5.78, 21.565],
                    [-5.43, 21.365],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [796.41, 436.425], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 3,
    },
    {
      ty: 4,
      nm: "top_left_object Outlines",
      sr: 1,
      st: 0,
      op: 120,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [576, 360, 0] },
        s: { a: 0, k: [100, 100, 100] },
        sk: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 360, 0],
              t: 6,
              ti: [0, 0, 0],
              to: [0, -1.16666662693024, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 353, 0],
              t: 17,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 360, 0],
              t: 30,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 353, 0],
              t: 44,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 360, 0],
              t: 57,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 353, 0],
              t: 72,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.167, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 360, 0],
              t: 85,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.167, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 353, 0],
              t: 98,
              ti: [0, -1.16666662693024, 0],
              to: [0, 0, 0],
            },
            { s: [576, 360, 0], t: 111 },
          ],
        },
        r: { a: 0, k: 0 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 1",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [1.84, 0.866],
                    [0, 0],
                    [0.01, 0.006],
                    [0.093, 0.039],
                    [0.017, 0.007],
                    [0.03, 0.011],
                    [0.048, 0.014],
                    [0.05, 0.011],
                    [0.033, 0.007],
                    [2.647, -1.932],
                    [0, 0],
                    [0.269, -0.204],
                    [0.046, -0.036],
                    [0.241, -0.234],
                    [0.019, -0.02],
                    [0.039, -0.04],
                    [0.116, -0.129],
                    [0.036, -0.041],
                    [0.032, -0.038],
                    [0.068, -0.085],
                    [0.043, -0.056],
                    [0.036, -0.049],
                    [0.049, -0.068],
                    [0.032, -0.046],
                    [0.055, -0.083],
                    [0.033, -0.051],
                    [0.014, -0.022],
                    [0.089, -0.153],
                    [0.006, -0.01],
                    [0.017, -0.031],
                    [0.059, -0.11],
                    [0.027, -0.051],
                    [0.027, -0.053],
                    [0.023, -0.049],
                    [0.054, -0.122],
                    [0.011, -0.026],
                    [0.003, -0.008],
                    [0.062, -0.16],
                    [0.011, -0.027],
                    [0.02, -0.058],
                    [0.028, -0.083],
                    [0.019, -0.06],
                    [0.021, -0.073],
                    [0.013, -0.047],
                    [0.02, -0.08],
                    [0.02, -0.091],
                    [0.007, -0.034],
                    [0.012, -0.065],
                    [0.02, -0.14],
                    [0.006, -0.04],
                    [0.005, -0.037],
                    [-0.001, -0.303],
                    [0, 0],
                    [-0.958, -0.557],
                    [0, 0],
                    [0.004, 1.673],
                    [0, 0],
                    [-0.036, 0.313],
                    [-0.011, 0.076],
                    [-0.027, 0.14],
                    [-0.022, 0.098],
                    [-0.023, 0.092],
                    [-0.037, 0.126],
                    [-0.023, 0.075],
                    [-0.051, 0.142],
                    [-0.022, 0.057],
                    [-0.085, 0.194],
                    [-0.012, 0.028],
                    [-0.083, 0.166],
                    [-0.028, 0.054],
                    [-0.089, 0.157],
                    [-0.019, 0.032],
                    [-0.116, 0.18],
                    [-0.035, 0.052],
                    [-0.09, 0.125],
                    [-0.05, 0.067],
                    [-0.082, 0.101],
                    [-0.071, 0.084],
                    [-0.069, 0.077],
                    [-0.12, 0.122],
                    [-0.06, 0.058],
                    [-0.255, 0.201],
                    [-0.046, 0.035],
                    [-0.281, 0.162],
                    [0, 0],
                    [-0.218, 0.095],
                    [-0.069, 0.027],
                    [-0.123, 0.037],
                    [-0.078, 0.019],
                    [-0.072, 0.014],
                    [-0.093, 0.011],
                    [-0.052, 0.004],
                    [-0.114, -0.004],
                    [-0.024, -0.002],
                    [-0.128, -0.025],
                    [-0.031, -0.007],
                    [-0.095, -0.033],
                    [-0.03, -0.012],
                    [-0.112, -0.064],
                  ],
                  o: [
                    [-1.621, -1.013],
                    [0, 0],
                    [-0.01, -0.006],
                    [-0.087, -0.049],
                    [-0.017, -0.007],
                    [-0.03, -0.012],
                    [-0.047, -0.016],
                    [-0.049, -0.014],
                    [-0.033, -0.008],
                    [-1.228, -0.184],
                    [0, 0],
                    [-0.28, 0.161],
                    [-0.047, 0.035],
                    [-0.255, 0.202],
                    [-0.02, 0.019],
                    [-0.04, 0.039],
                    [-0.12, 0.122],
                    [-0.037, 0.041],
                    [-0.032, 0.037],
                    [-0.07, 0.083],
                    [-0.044, 0.055],
                    [-0.037, 0.048],
                    [-0.05, 0.066],
                    [-0.033, 0.045],
                    [-0.058, 0.082],
                    [-0.034, 0.051],
                    [-0.014, 0.022],
                    [-0.095, 0.149],
                    [-0.006, 0.01],
                    [-0.018, 0.031],
                    [-0.062, 0.109],
                    [-0.027, 0.051],
                    [-0.027, 0.053],
                    [-0.024, 0.048],
                    [-0.059, 0.12],
                    [-0.012, 0.026],
                    [-0.003, 0.008],
                    [-0.069, 0.159],
                    [-0.01, 0.028],
                    [-0.021, 0.057],
                    [-0.03, 0.083],
                    [-0.02, 0.06],
                    [-0.023, 0.073],
                    [-0.014, 0.048],
                    [-0.022, 0.08],
                    [-0.023, 0.09],
                    [-0.007, 0.033],
                    [-0.014, 0.065],
                    [-0.027, 0.14],
                    [-0.006, 0.04],
                    [-0.004, 0.036],
                    [-0.036, 0.312],
                    [0, 0],
                    [0.005, 1.673],
                    [0, 0],
                    [-0.958, -0.557],
                    [0, 0],
                    [0, -0.302],
                    [0.009, -0.075],
                    [0.02, -0.14],
                    [0.019, -0.098],
                    [0.02, -0.092],
                    [0.032, -0.126],
                    [0.022, -0.074],
                    [0.045, -0.143],
                    [0.021, -0.058],
                    [0.074, -0.196],
                    [0.011, -0.027],
                    [0.075, -0.168],
                    [0.027, -0.055],
                    [0.083, -0.161],
                    [0.018, -0.032],
                    [0.107, -0.185],
                    [0.034, -0.052],
                    [0.086, -0.129],
                    [0.049, -0.068],
                    [0.079, -0.104],
                    [0.068, -0.086],
                    [0.066, -0.078],
                    [0.116, -0.13],
                    [0.059, -0.06],
                    [0.241, -0.234],
                    [0.046, -0.036],
                    [0.269, -0.204],
                    [0, 0],
                    [0.225, -0.13],
                    [0.07, -0.03],
                    [0.126, -0.048],
                    [0.081, -0.024],
                    [0.074, -0.018],
                    [0.096, -0.018],
                    [0.054, -0.006],
                    [0.119, -0.008],
                    [0.023, 0.001],
                    [0.135, 0.007],
                    [0.033, 0.006],
                    [0.101, 0.023],
                    [0.03, 0.011],
                    [0.12, 0.048],
                    [0, 0],
                  ],
                  v: [
                    [63.85, -78.602],
                    [56.648, -82.786],
                    [56.595, -82.817],
                    [56.566, -82.834],
                    [56.296, -82.966],
                    [56.245, -82.984],
                    [56.154, -83.016],
                    [56.013, -83.066],
                    [55.863, -83.1],
                    [55.765, -83.123],
                    [50.514, -81.131],
                    [-59.668, -17.517],
                    [-60.49, -16.961],
                    [-60.63, -16.856],
                    [-61.374, -16.201],
                    [-61.435, -16.147],
                    [-61.551, -16.024],
                    [-61.905, -15.647],
                    [-62.017, -15.528],
                    [-62.111, -15.413],
                    [-62.318, -15.161],
                    [-62.451, -14.999],
                    [-62.56, -14.852],
                    [-62.708, -14.65],
                    [-62.808, -14.516],
                    [-62.975, -14.266],
                    [-63.076, -14.115],
                    [-63.119, -14.051],
                    [-63.396, -13.597],
                    [-63.413, -13.566],
                    [-63.465, -13.473],
                    [-63.648, -13.145],
                    [-63.726, -12.992],
                    [-63.807, -12.832],
                    [-63.879, -12.688],
                    [-64.046, -12.324],
                    [-64.082, -12.246],
                    [-64.092, -12.223],
                    [-64.289, -11.743],
                    [-64.319, -11.66],
                    [-64.38, -11.488],
                    [-64.47, -11.238],
                    [-64.525, -11.057],
                    [-64.592, -10.839],
                    [-64.636, -10.695],
                    [-64.694, -10.456],
                    [-64.761, -10.185],
                    [-64.785, -10.084],
                    [-64.819, -9.889],
                    [-64.891, -9.47],
                    [-64.912, -9.35],
                    [-64.919, -9.242],
                    [-64.979, -8.318],
                    [-64.743, 75.025],
                    [-63.184, 78.435],
                    [-54.799, 83.307],
                    [-56.358, 79.898],
                    [-56.595, -3.445],
                    [-56.534, -4.37],
                    [-56.506, -4.597],
                    [-56.434, -5.018],
                    [-56.376, -5.311],
                    [-56.309, -5.587],
                    [-56.208, -5.964],
                    [-56.14, -6.188],
                    [-55.996, -6.615],
                    [-55.934, -6.787],
                    [-55.697, -7.373],
                    [-55.66, -7.455],
                    [-55.423, -7.957],
                    [-55.34, -8.121],
                    [-55.082, -8.598],
                    [-55.028, -8.694],
                    [-54.692, -9.241],
                    [-54.588, -9.396],
                    [-54.324, -9.777],
                    [-54.175, -9.98],
                    [-53.934, -10.287],
                    [-53.725, -10.542],
                    [-53.521, -10.773],
                    [-53.167, -11.151],
                    [-52.989, -11.329],
                    [-52.245, -11.983],
                    [-52.106, -12.089],
                    [-51.283, -12.645],
                    [61.188, -77.58],
                    [61.853, -77.911],
                    [62.061, -77.994],
                    [62.435, -78.123],
                    [62.674, -78.189],
                    [62.892, -78.236],
                    [63.175, -78.279],
                    [63.335, -78.295],
                    [63.684, -78.301],
                    [63.756, -78.3],
                    [64.15, -78.25],
                    [64.246, -78.228],
                    [64.539, -78.143],
                    [64.63, -78.111],
                    [64.98, -77.945],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [397.714, 98.731], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 2",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-9.187, 5.304],
                    [-3.045, -3.161],
                    [-0.021, -7.451],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [4.593, -2.652],
                    [3.04, 3.164],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.042, -14.907],
                  ],
                  v: [
                    [-0.217, -38.304],
                    [11.723, -37.224],
                    [16.806, -20.985],
                    [16.926, 21.506],
                    [-16.762, 40.956],
                    [-16.883, -1.535],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.8, 0.8, 0.8] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [418.859, 140.341], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 3",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.005, 0.08],
                    [-0.12, 0.952],
                    [-0.018, 0.133],
                    [-0.108, 0.634],
                    [-0.001, 0.009],
                    [-0.134, 0.639],
                    [-0.027, 0.127],
                    [-0.284, 1.026],
                    [-0.01, 0.035],
                    [-0.287, 0.866],
                    [-0.012, 0.037],
                    [-0.269, 0.707],
                    [-0.041, 0.106],
                    [-0.257, 0.6],
                    [-0.057, 0.129],
                    [-0.232, 0.485],
                    [-0.038, 0.08],
                    [-0.263, 0.5],
                    [-0.069, 0.129],
                    [-0.207, 0.36],
                    [-0.075, 0.128],
                    [-0.277, 0.435],
                    [-0.02, 0.032],
                    [-0.266, 0.384],
                    [-0.086, 0.12],
                    [-0.241, 0.317],
                    [-0.073, 0.094],
                    [-0.341, 0.396],
                    [-0.065, 0.073],
                    [-0.438, 0.435],
                    [-0.071, 0.069],
                    [-0.231, 0.206],
                    [-0.107, 0.093],
                    [-0.208, 0.166],
                    [-0.113, 0.087],
                    [-0.219, 0.153],
                    [-0.101, 0.068],
                    [-0.323, 0.187],
                    [-0.401, 0.184],
                    [-0.084, 0.037],
                    [-0.211, 0.079],
                    [-0.273, 0.079],
                    [-0.239, 0.052],
                    [-0.157, 0.027],
                    [-0.336, 0.021],
                    [-0.043, 0.002],
                    [-0.364, -0.03],
                    [-0.101, -0.012],
                    [-0.259, -0.056],
                    [-0.109, -0.029],
                    [-0.27, -0.102],
                    [-0.07, -0.029],
                    [-0.335, -0.195],
                    [0, 0],
                    [0.033, 0.018],
                    [0.301, 0.126],
                    [0.018, 0.007],
                    [0.071, 0.027],
                    [0.206, 0.06],
                    [0.071, 0.018],
                    [0.111, 0.025],
                    [0.115, 0.02],
                    [0.149, 0.018],
                    [0.103, 0.008],
                    [0.05, 0.003],
                    [0.317, -0.014],
                    [0.007, 0],
                    [0.044, -0.003],
                    [0.291, -0.044],
                    [0.052, -0.009],
                    [0.158, -0.034],
                    [0.173, -0.046],
                    [0.069, -0.02],
                    [0.277, -0.104],
                    [0.141, -0.059],
                    [0.071, -0.031],
                    [0.084, -0.039],
                    [0.406, -0.235],
                    [0.318, -0.214],
                    [0.101, -0.071],
                    [0.216, -0.166],
                    [0.113, -0.09],
                    [0.205, -0.176],
                    [0.107, -0.096],
                    [0.228, -0.22],
                    [0.06, -0.059],
                    [0.011, -0.011],
                    [0.424, -0.482],
                    [0.038, -0.043],
                    [0.027, -0.032],
                    [0.33, -0.424],
                    [0.021, -0.027],
                    [0.051, -0.067],
                    [0.238, -0.334],
                    [0.048, -0.069],
                    [0.035, -0.051],
                    [0.258, -0.402],
                    [0.017, -0.027],
                    [0.003, -0.005],
                    [0.267, -0.454],
                    [0.028, -0.047],
                    [0.046, -0.08],
                    [0.201, -0.373],
                    [0.044, -0.084],
                    [0.024, -0.046],
                    [0.25, -0.516],
                    [0.01, -0.021],
                    [0.029, -0.061],
                    [0.218, -0.497],
                    [0.035, -0.08],
                    [0.022, -0.051],
                    [0.239, -0.615],
                    [0.016, -0.042],
                    [0.025, -0.066],
                    [0.242, -0.726],
                    [0.003, -0.011],
                    [0.008, -0.025],
                    [0.247, -0.886],
                    [0.003, -0.013],
                    [0.006, -0.023],
                    [0.226, -1.045],
                    [0.01, -0.048],
                    [0.016, -0.077],
                    [0.11, -0.643],
                    [0.001, -0.005],
                    [0.085, -0.639],
                    [0.01, -0.081],
                    [0.006, -0.05],
                    [0.065, -0.95],
                    [0.005, -0.079],
                    [-0.002, -1.009],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.064, 1.02],
                  ],
                  o: [
                    [0.065, -0.95],
                    [0.017, -0.133],
                    [0.085, -0.635],
                    [0.001, -0.009],
                    [0.11, -0.642],
                    [0.026, -0.127],
                    [0.226, -1.045],
                    [0.01, -0.035],
                    [0.246, -0.885],
                    [0.012, -0.037],
                    [0.243, -0.726],
                    [0.04, -0.107],
                    [0.239, -0.616],
                    [0.056, -0.13],
                    [0.219, -0.498],
                    [0.038, -0.08],
                    [0.25, -0.516],
                    [0.068, -0.13],
                    [0.2, -0.372],
                    [0.075, -0.13],
                    [0.267, -0.453],
                    [0.02, -0.032],
                    [0.258, -0.401],
                    [0.085, -0.122],
                    [0.235, -0.331],
                    [0.073, -0.095],
                    [0.331, -0.423],
                    [0.064, -0.074],
                    [0.424, -0.482],
                    [0.071, -0.07],
                    [0.227, -0.22],
                    [0.107, -0.096],
                    [0.205, -0.177],
                    [0.113, -0.09],
                    [0.217, -0.165],
                    [0.1, -0.071],
                    [0.319, -0.214],
                    [0.406, -0.234],
                    [0.084, -0.039],
                    [0.214, -0.093],
                    [0.277, -0.103],
                    [0.242, -0.071],
                    [0.158, -0.034],
                    [0.344, -0.058],
                    [0.043, -0.002],
                    [0.376, -0.018],
                    [0.103, 0.008],
                    [0.266, 0.031],
                    [0.111, 0.024],
                    [0.279, 0.073],
                    [0.07, 0.027],
                    [0.354, 0.146],
                    [0, 0],
                    [-0.033, -0.018],
                    [-0.288, -0.163],
                    [-0.018, -0.008],
                    [-0.07, -0.029],
                    [-0.201, -0.076],
                    [-0.07, -0.02],
                    [-0.109, -0.029],
                    [-0.114, -0.024],
                    [-0.147, -0.026],
                    [-0.102, -0.012],
                    [-0.05, -0.004],
                    [-0.309, -0.02],
                    [-0.007, 0],
                    [-0.043, 0.002],
                    [-0.285, 0.017],
                    [-0.052, 0.008],
                    [-0.157, 0.026],
                    [-0.172, 0.038],
                    [-0.068, 0.019],
                    [-0.273, 0.08],
                    [-0.14, 0.052],
                    [-0.071, 0.029],
                    [-0.084, 0.037],
                    [-0.401, 0.185],
                    [-0.323, 0.186],
                    [-0.102, 0.068],
                    [-0.219, 0.153],
                    [-0.114, 0.086],
                    [-0.207, 0.166],
                    [-0.107, 0.093],
                    [-0.231, 0.207],
                    [-0.06, 0.059],
                    [-0.011, 0.011],
                    [-0.438, 0.434],
                    [-0.038, 0.042],
                    [-0.027, 0.032],
                    [-0.342, 0.395],
                    [-0.021, 0.027],
                    [-0.052, 0.066],
                    [-0.244, 0.319],
                    [-0.048, 0.068],
                    [-0.035, 0.05],
                    [-0.267, 0.385],
                    [-0.017, 0.027],
                    [-0.003, 0.005],
                    [-0.278, 0.435],
                    [-0.028, 0.047],
                    [-0.046, 0.08],
                    [-0.208, 0.362],
                    [-0.045, 0.083],
                    [-0.024, 0.046],
                    [-0.263, 0.499],
                    [-0.01, 0.022],
                    [-0.029, 0.061],
                    [-0.23, 0.483],
                    [-0.035, 0.079],
                    [-0.022, 0.051],
                    [-0.257, 0.599],
                    [-0.016, 0.043],
                    [-0.025, 0.065],
                    [-0.269, 0.707],
                    [-0.003, 0.01],
                    [-0.009, 0.024],
                    [-0.287, 0.867],
                    [-0.003, 0.013],
                    [-0.007, 0.022],
                    [-0.284, 1.027],
                    [-0.01, 0.048],
                    [-0.016, 0.078],
                    [-0.133, 0.64],
                    [-0.001, 0.006],
                    [-0.109, 0.638],
                    [-0.011, 0.081],
                    [-0.006, 0.05],
                    [-0.121, 0.953],
                    [-0.005, 0.08],
                    [-0.065, 1.021],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.003, -1.009],
                    [0.006, -0.079],
                  ],
                  v: [
                    [-8.962, -2.866],
                    [-8.684, -5.719],
                    [-8.632, -6.117],
                    [-8.346, -8.021],
                    [-8.342, -8.048],
                    [-7.972, -9.969],
                    [-7.892, -10.349],
                    [-7.129, -13.46],
                    [-7.101, -13.565],
                    [-6.299, -16.193],
                    [-6.264, -16.304],
                    [-5.493, -18.453],
                    [-5.373, -18.773],
                    [-4.628, -20.598],
                    [-4.458, -20.986],
                    [-3.783, -22.461],
                    [-3.67, -22.703],
                    [-2.899, -24.226],
                    [-2.691, -24.612],
                    [-2.08, -25.71],
                    [-1.857, -26.098],
                    [-1.042, -27.432],
                    [-0.98, -27.526],
                    [-0.193, -28.702],
                    [0.063, -29.064],
                    [0.779, -30.035],
                    [0.997, -30.322],
                    [2.005, -31.551],
                    [2.2, -31.77],
                    [3.492, -33.15],
                    [3.707, -33.354],
                    [4.394, -33.996],
                    [4.715, -34.276],
                    [5.334, -34.79],
                    [5.673, -35.057],
                    [6.327, -35.533],
                    [6.628, -35.745],
                    [7.59, -36.349],
                    [8.8, -36.968],
                    [9.051, -37.083],
                    [9.686, -37.325],
                    [10.512, -37.608],
                    [11.234, -37.789],
                    [11.708, -37.89],
                    [12.728, -38.009],
                    [12.859, -38.021],
                    [13.968, -37.997],
                    [14.273, -37.961],
                    [15.061, -37.83],
                    [15.392, -37.754],
                    [16.214, -37.489],
                    [16.427, -37.414],
                    [17.463, -36.906],
                    [9.079, -41.78],
                    [8.981, -41.835],
                    [8.097, -42.267],
                    [8.042, -42.286],
                    [7.829, -42.362],
                    [7.221, -42.577],
                    [7.007, -42.626],
                    [6.677, -42.703],
                    [6.337, -42.781],
                    [5.889, -42.835],
                    [5.583, -42.87],
                    [5.435, -42.887],
                    [4.495, -42.896],
                    [4.474, -42.895],
                    [4.343, -42.882],
                    [3.481, -42.796],
                    [3.323, -42.762],
                    [2.85, -42.663],
                    [2.335, -42.553],
                    [2.127, -42.481],
                    [1.301, -42.197],
                    [0.882, -42.053],
                    [0.667, -41.956],
                    [0.415, -41.841],
                    [-0.795, -41.221],
                    [-1.756, -40.618],
                    [-2.059, -40.405],
                    [-2.711, -39.929],
                    [-3.051, -39.663],
                    [-3.669, -39.15],
                    [-3.991, -38.869],
                    [-4.678, -38.228],
                    [-4.86, -38.056],
                    [-4.893, -38.021],
                    [-6.184, -36.643],
                    [-6.299, -36.521],
                    [-6.38, -36.421],
                    [-7.388, -35.193],
                    [-7.452, -35.116],
                    [-7.603, -34.911],
                    [-8.325, -33.932],
                    [-8.472, -33.732],
                    [-8.576, -33.577],
                    [-9.365, -32.398],
                    [-9.418, -32.319],
                    [-9.427, -32.304],
                    [-10.242, -30.969],
                    [-10.327, -30.831],
                    [-10.463, -30.586],
                    [-11.076, -29.484],
                    [-11.213, -29.238],
                    [-11.284, -29.098],
                    [-12.054, -27.577],
                    [-12.086, -27.514],
                    [-12.17, -27.329],
                    [-12.842, -25.859],
                    [-12.95, -25.624],
                    [-13.013, -25.47],
                    [-13.757, -23.648],
                    [-13.808, -23.522],
                    [-13.879, -23.323],
                    [-14.649, -21.176],
                    [-14.66, -21.143],
                    [-14.683, -21.069],
                    [-15.486, -18.439],
                    [-15.497, -18.4],
                    [-15.514, -18.332],
                    [-16.277, -15.221],
                    [-16.312, -15.078],
                    [-16.357, -14.844],
                    [-16.727, -12.919],
                    [-16.73, -12.902],
                    [-17.017, -10.986],
                    [-17.054, -10.743],
                    [-17.068, -10.594],
                    [-17.347, -7.738],
                    [-17.364, -7.499],
                    [-17.461, -4.453],
                    [-17.34, 38.039],
                    [-8.956, 42.91],
                    [-9.076, 0.42],
                    [-8.98, -2.626],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.7412, 0.7412, 0.7412] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [411.052, 138.387], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 4",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-0.009, -3.367],
                    [0, 0],
                    [2.934, -1.694],
                    [0, 0],
                    [0.009, 3.367],
                    [0, 0],
                    [-2.93, 1.692],
                  ],
                  o: [
                    [2.934, -1.694],
                    [0, 0],
                    [0.009, 3.367],
                    [0, 0],
                    [-2.93, 1.692],
                    [0, 0],
                    [-0.009, -3.367],
                    [0, 0],
                  ],
                  v: [
                    [56.098, -80.262],
                    [61.448, -77.218],
                    [61.685, 6.125],
                    [56.369, 15.328],
                    [-56.102, 80.263],
                    [-61.448, 77.216],
                    [-61.685, -6.127],
                    [-56.373, -15.327],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [402.805, 101.412], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 5",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [44.516, -6.218],
                    [-8.502, 26.067],
                    [-44.516, 6.216],
                    [8.502, -26.067],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [414.657, 168.866], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 6",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [18.014, 7.372],
                    [18, 12.477],
                    [-18.014, -7.372],
                    [-18, -12.477],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.7098, 0.7098, 0.7098] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [388.141, 187.56], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 7",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [26.517, -18.694],
                    [26.503, -13.59],
                    [-26.517, 18.694],
                    [-26.501, 13.59],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.8, 0.8, 0.8] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [432.657, 181.343], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 8",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [79.854, -3.507],
                    [-5.793, 46.265],
                    [-79.853, 3.506],
                    [5.793, -46.265],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.949, 0.949, 0.949] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [398.822, 166.907], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 9",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [37.04, 18.062],
                    [37.021, 24.697],
                    [-37.04, -18.062],
                    [-37.021, -24.697],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.8118, 0.8118, 0.8118] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [355.989, 195.111], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 10",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [42.833, -28.204],
                    [42.814, -21.568],
                    [-42.832, 28.204],
                    [-42.814, 21.568],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [435.843, 191.604], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 4,
    },
    {
      ty: 4,
      nm: "bottom_right_object_shadow Outlines",
      sr: 1,
      st: 0,
      op: 120,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [576, 360, 0] },
        s: { a: 0, k: [100, 100, 100] },
        sk: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 360, 0],
              t: 3,
              ti: [0, 0, 0],
              to: [0, -1.16666662693024, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 353, 0],
              t: 14,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 360, 0],
              t: 27,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 353, 0],
              t: 41,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 360, 0],
              t: 54,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 353, 0],
              t: 69,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 360, 0],
              t: 82,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 353, 0],
              t: 95,
              ti: [0, -1.16666662693024, 0],
              to: [0, 0, 0],
            },
            { s: [576, 360, 0], t: 108 },
          ],
        },
        r: { a: 0, k: 0 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 1",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-83.281, 4.987],
                    [-6.158, 49.514],
                    [83.276, -2.458],
                    [83.281, -4.987],
                    [6.159, -49.514],
                    [-83.275, 2.458],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-6.153, 52.981],
                    [-86.286, 6.716],
                    [-86.27, 0.729],
                    [6.154, -52.981],
                    [86.286, -6.717],
                    [86.271, -0.729],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.0706, 0.6392, 0.7098] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [810.757, 478.892], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 2",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [6.156, -51.248],
                    [-84.773, 1.593],
                    [-84.784, 5.852],
                    [-6.156, 51.248],
                    [84.772, -1.594],
                    [84.784, -5.852],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [810.758, 478.892], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 5,
    },
    {
      ty: 4,
      nm: "symbol_01",
      sr: 1,
      st: 0,
      op: 120,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [330, 355, 0] },
        s: {
          a: 1,
          k: [
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [0, 0, 100],
              t: 4,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [100, 100, 100],
              t: 12,
            },
            {
              o: { x: 0.167, y: 0 },
              i: { x: 0.833, y: 1 },
              s: [100, 100, 100],
              t: 89,
            },
            { s: [0, 0, 100], t: 95 },
          ],
        },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [330, 355, 0] },
        r: { a: 0, k: 0 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 1",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.164, 0.01],
                    [-0.023, 0.002],
                    [-0.144, 0.006],
                    [-0.058, 0.002],
                    [-0.119, 0.002],
                    [-0.067, 0],
                    [0, 0],
                    [-0.294, -0.008],
                    [-0.041, -0.001],
                    [-0.267, -0.018],
                    [-0.021, -0.001],
                    [-0.163, -0.018],
                    [-0.083, -0.151],
                    [-0.023, -0.041],
                    [-0.06, -0.111],
                    [-0.025, -0.046],
                    [-0.051, -0.095],
                    [-0.024, -0.045],
                    [-0.027, -0.05],
                    [-0.067, -0.129],
                    [-0.032, -0.062],
                    [-0.07, -0.139],
                    [-0.04, -0.081],
                    [-0.013, -0.025],
                    [-0.091, -0.188],
                    [-0.012, -0.026],
                    [-0.11, -0.232],
                    [1.291, -1.01],
                    [0.201, -0.145],
                    [-0.021, -0.048],
                    [-0.025, -0.062],
                    [-0.02, -0.047],
                    [-0.04, -0.097],
                    [-0.021, -0.054],
                    [-0.053, -0.136],
                    [-0.017, -0.043],
                    [-0.08, -0.213],
                    [-0.02, -0.053],
                    [-0.063, -0.171],
                    [-0.023, -0.065],
                    [-0.075, -0.218],
                    [-0.11, -0.046],
                    [-0.035, -0.014],
                    [-0.101, -0.043],
                    [-0.055, -0.023],
                    [-0.106, -0.046],
                    [-0.071, -0.031],
                    [-0.104, -0.047],
                    [-0.169, -0.078],
                    [-0.085, -0.04],
                    [-0.289, -0.144],
                    [-0.178, -0.091],
                    [-0.097, -0.051],
                    [-0.301, -0.163],
                    [-0.053, -0.182],
                    [-0.011, -0.038],
                    [-0.038, -0.139],
                    [-0.066, -0.251],
                    [-0.016, -0.061],
                    [-0.049, -0.193],
                    [-0.02, -0.082],
                    [-0.008, -0.034],
                    [-0.033, -0.142],
                    [-0.005, -0.023],
                    [-0.025, -0.115],
                    [-0.002, -0.013],
                    [-0.018, -0.096],
                    [0, 0],
                    [0.026, 0.121],
                    [0.002, 0.007],
                    [0.003, 0.013],
                    [0.03, 0.132],
                    [0.007, 0.028],
                    [0.038, 0.156],
                    [0.008, 0.035],
                    [0.012, 0.047],
                    [0.01, 0.037],
                    [0.052, 0.201],
                    [0.017, 0.063],
                    [0.068, 0.247],
                    [0.005, 0.016],
                    [0.033, 0.116],
                    [0.011, 0.04],
                    [0.046, 0.151],
                    [0.004, 0.013],
                    [0.28, 0.145],
                    [0.065, 0.033],
                    [0.196, 0.097],
                    [0.002, 0.002],
                    [0.257, 0.121],
                    [0.083, 0.039],
                    [0.152, 0.069],
                    [0.031, 0.014],
                    [0.069, 0.03],
                    [0.068, 0.03],
                    [0.096, 0.042],
                    [0.053, 0.022],
                    [0.095, 0.04],
                    [0.024, 0.01],
                    [0.087, 0.036],
                    [0.082, 0.228],
                    [0.009, 0.025],
                    [0.015, 0.04],
                    [0.063, 0.168],
                    [0.02, 0.054],
                    [0.076, 0.196],
                    [0.002, 0.006],
                    [0.014, 0.036],
                    [0.049, 0.121],
                    [0.02, 0.051],
                    [0.034, 0.084],
                    [0.01, 0.022],
                    [0.009, 0.022],
                    [0.021, 0.047],
                    [0.008, 0.02],
                    [0.009, 0.02],
                    [-0.277, 0.295],
                    [0.097, 0.201],
                    [0.011, 0.022],
                    [0.081, 0.164],
                    [0.012, 0.025],
                    [0.016, 0.034],
                    [0.022, 0.044],
                    [0.065, 0.127],
                    [0.031, 0.06],
                    [0.066, 0.124],
                    [0.017, 0.032],
                    [0.009, 0.017],
                    [0.024, 0.045],
                    [0.052, 0.097],
                    [0.025, 0.046],
                    [0.064, 0.118],
                    [0.022, 0.041],
                    [0.093, 0.17],
                    [0.183, 0.013],
                    [0.006, 0],
                    [0.014, 0.001],
                    [0.293, 0.01],
                    [0.043, 0.001],
                    [0.296, 0],
                    [0.03, 0],
                    [0.056, -0.001],
                    [0.066, -0.001],
                    [0.115, -0.003],
                    [0.057, -0.002],
                    [0.137, -0.008],
                    [0.019, -0.001],
                    [0.127, -0.011],
                    [0.019, -0.001],
                    [0, 0],
                    [0.07, 0.113],
                    [0.048, 0.078],
                    [0.02, 0.034],
                    [0.057, 0.09],
                    [0.01, 0.016],
                    [0.07, 0.109],
                    [0.021, 0.033],
                    [0.058, 0.087],
                    [0.029, 0.043],
                    [0.074, 0.109],
                    [0.021, 0.03],
                    [0.004, 0.006],
                    [0.067, 0.098],
                    [0.046, 0.067],
                    [0.143, 0.201],
                    [-0.055, 0.153],
                    [-0.018, 0.051],
                    [-0.043, 0.125],
                    [-0.023, 0.068],
                    [-0.044, 0.134],
                    [-0.024, 0.074],
                    [-0.053, 0.178],
                    [-0.012, 0.044],
                    [-0.002, 0.006],
                    [-0.074, 0.283],
                    [-0.02, 0.078],
                    [-0.06, 0.254],
                    [-0.014, 0.058],
                    [-0.016, 0.072],
                    [0.091, 0.117],
                    [0.029, 0.037],
                    [0.04, 0.051],
                    [0.03, 0.038],
                    [0.042, 0.053],
                    [0.077, 0.096],
                    [0.044, 0.054],
                    [0.097, 0.119],
                    [0.028, 0.034],
                    [0.119, 0.142],
                    [0.007, 0.008],
                    [0.144, 0.17],
                    [0.03, 0.036],
                    [0.105, 0.12],
                    [0.014, 0.016],
                    [0.083, 0],
                    [0, 0],
                    [0.16, -0.093],
                    [0, 0],
                    [-0.173, 0.093],
                    [-0.107, -0.122],
                    [-0.113, -0.131],
                    [-0.031, -0.036],
                    [-0.148, -0.178],
                    [-0.125, -0.152],
                    [-0.026, -0.032],
                    [-0.095, -0.118],
                    [-0.043, -0.053],
                    [-0.071, -0.089],
                    [-0.042, -0.052],
                    [-0.065, -0.084],
                    [-0.026, -0.034],
                    [-0.073, -0.096],
                    [0.029, -0.126],
                    [0.06, -0.233],
                    [0.02, -0.075],
                    [0.073, -0.253],
                    [0.015, -0.048],
                    [0.052, -0.165],
                    [0.023, -0.07],
                    [0.042, -0.125],
                    [0.022, -0.064],
                    [0.041, -0.116],
                    [0.017, -0.049],
                    [0.05, -0.137],
                    [-0.122, -0.175],
                    [-0.044, -0.063],
                    [-0.065, -0.096],
                    [-0.02, -0.03],
                    [-0.066, -0.099],
                    [-0.027, -0.042],
                    [-0.053, -0.081],
                    [-0.02, -0.032],
                    [-0.063, -0.1],
                    [-0.013, -0.02],
                    [-0.052, -0.083],
                    [-0.018, -0.029],
                    [-0.07, -0.111],
                    [-0.001, -0.001],
                    [-0.051, -0.083],
                    [0, 0],
                  ],
                  o: [
                    [0.023, -0.001],
                    [0.134, -0.007],
                    [0.056, -0.002],
                    [0.115, -0.003],
                    [0.066, -0.001],
                    [0, 0],
                    [0.295, 0],
                    [0.042, 0.001],
                    [0.291, 0.01],
                    [0.022, 0.002],
                    [0.183, 0.013],
                    [0.094, 0.17],
                    [0.022, 0.041],
                    [0.064, 0.118],
                    [0.025, 0.046],
                    [0.053, 0.097],
                    [0.024, 0.045],
                    [0.026, 0.049],
                    [0.065, 0.124],
                    [0.031, 0.061],
                    [0.066, 0.128],
                    [0.038, 0.077],
                    [0.012, 0.025],
                    [0.08, 0.164],
                    [0.012, 0.025],
                    [0.097, 0.199],
                    [-0.242, 0.259],
                    [-0.184, 0.145],
                    [0.015, 0.033],
                    [0.021, 0.048],
                    [0.018, 0.041],
                    [0.034, 0.083],
                    [0.02, 0.051],
                    [0.048, 0.121],
                    [0.017, 0.042],
                    [0.076, 0.196],
                    [0.02, 0.053],
                    [0.063, 0.168],
                    [0.024, 0.065],
                    [0.082, 0.227],
                    [0.084, 0.035],
                    [0.033, 0.013],
                    [0.088, 0.037],
                    [0.052, 0.022],
                    [0.097, 0.042],
                    [0.068, 0.029],
                    [0.097, 0.043],
                    [0.153, 0.069],
                    [0.081, 0.038],
                    [0.259, 0.122],
                    [0.168, 0.083],
                    [0.095, 0.048],
                    [0.28, 0.144],
                    [0.05, 0.163],
                    [0.011, 0.038],
                    [0.038, 0.133],
                    [0.069, 0.246],
                    [0.016, 0.061],
                    [0.052, 0.201],
                    [0.021, 0.085],
                    [0.008, 0.034],
                    [0.039, 0.158],
                    [0.006, 0.023],
                    [0.031, 0.134],
                    [0.003, 0.013],
                    [0.029, 0.131],
                    [0, 0],
                    [-0.018, -0.091],
                    [-0.002, -0.007],
                    [-0.003, -0.013],
                    [-0.025, -0.113],
                    [-0.007, -0.027],
                    [-0.033, -0.141],
                    [-0.008, -0.035],
                    [-0.012, -0.046],
                    [-0.009, -0.037],
                    [-0.048, -0.194],
                    [-0.016, -0.063],
                    [-0.066, -0.252],
                    [-0.004, -0.016],
                    [-0.033, -0.12],
                    [-0.012, -0.041],
                    [-0.049, -0.166],
                    [-0.005, -0.014],
                    [-0.301, -0.163],
                    [-0.067, -0.034],
                    [-0.21, -0.108],
                    [-0.002, -0.002],
                    [-0.287, -0.142],
                    [-0.087, -0.041],
                    [-0.167, -0.077],
                    [-0.031, -0.014],
                    [-0.072, -0.032],
                    [-0.073, -0.032],
                    [-0.107, -0.046],
                    [-0.056, -0.024],
                    [-0.112, -0.047],
                    [-0.026, -0.01],
                    [-0.115, -0.048],
                    [-0.075, -0.219],
                    [-0.009, -0.024],
                    [-0.014, -0.04],
                    [-0.063, -0.172],
                    [-0.021, -0.054],
                    [-0.081, -0.213],
                    [-0.003, -0.006],
                    [-0.014, -0.037],
                    [-0.053, -0.137],
                    [-0.022, -0.055],
                    [-0.04, -0.099],
                    [-0.01, -0.023],
                    [-0.009, -0.024],
                    [-0.025, -0.06],
                    [-0.009, -0.022],
                    [-0.011, -0.024],
                    [1.613, -1.157],
                    [-0.112, -0.236],
                    [-0.011, -0.022],
                    [-0.091, -0.189],
                    [-0.013, -0.025],
                    [-0.017, -0.034],
                    [-0.023, -0.045],
                    [-0.069, -0.139],
                    [-0.032, -0.062],
                    [-0.067, -0.128],
                    [-0.017, -0.032],
                    [-0.009, -0.017],
                    [-0.024, -0.044],
                    [-0.051, -0.094],
                    [-0.025, -0.045],
                    [-0.06, -0.11],
                    [-0.022, -0.039],
                    [-0.082, -0.15],
                    [-0.163, -0.017],
                    [-0.006, 0],
                    [-0.014, -0.001],
                    [-0.268, -0.019],
                    [-0.042, -0.001],
                    [-0.294, -0.009],
                    [-0.029, 0],
                    [-0.056, 0],
                    [-0.067, 0],
                    [-0.119, 0.002],
                    [-0.059, 0.002],
                    [-0.148, 0.005],
                    [-0.019, 0.001],
                    [-0.142, 0.009],
                    [-0.019, 0.002],
                    [0, 0],
                    [-0.072, -0.116],
                    [-0.048, -0.077],
                    [-0.021, -0.033],
                    [-0.054, -0.086],
                    [-0.01, -0.016],
                    [-0.064, -0.101],
                    [-0.021, -0.032],
                    [-0.053, -0.082],
                    [-0.028, -0.042],
                    [-0.066, -0.099],
                    [-0.02, -0.03],
                    [-0.004, -0.007],
                    [-0.062, -0.09],
                    [-0.043, -0.063],
                    [-0.122, -0.175],
                    [0.051, -0.138],
                    [0.017, -0.05],
                    [0.041, -0.116],
                    [0.022, -0.065],
                    [0.042, -0.124],
                    [0.023, -0.071],
                    [0.051, -0.164],
                    [0.012, -0.043],
                    [0.002, -0.006],
                    [0.073, -0.252],
                    [0.02, -0.076],
                    [0.059, -0.232],
                    [0.013, -0.057],
                    [0.016, -0.071],
                    [-0.073, -0.095],
                    [-0.027, -0.035],
                    [-0.038, -0.049],
                    [-0.03, -0.037],
                    [-0.04, -0.051],
                    [-0.073, -0.092],
                    [-0.043, -0.053],
                    [-0.093, -0.116],
                    [-0.028, -0.034],
                    [-0.118, -0.144],
                    [-0.007, -0.009],
                    [-0.149, -0.179],
                    [-0.031, -0.036],
                    [-0.112, -0.131],
                    [-0.014, -0.016],
                    [-0.092, -0.105],
                    [0, 0],
                    [-0.173, 0],
                    [0, 0],
                    [0.16, -0.093],
                    [0.095, 0.105],
                    [0.105, 0.119],
                    [0.03, 0.035],
                    [0.144, 0.169],
                    [0.126, 0.151],
                    [0.026, 0.032],
                    [0.099, 0.121],
                    [0.044, 0.053],
                    [0.075, 0.093],
                    [0.043, 0.055],
                    [0.072, 0.092],
                    [0.028, 0.035],
                    [0.091, 0.117],
                    [-0.029, 0.132],
                    [-0.06, 0.254],
                    [-0.019, 0.077],
                    [-0.074, 0.283],
                    [-0.014, 0.049],
                    [-0.053, 0.179],
                    [-0.023, 0.073],
                    [-0.043, 0.134],
                    [-0.023, 0.067],
                    [-0.043, 0.125],
                    [-0.018, 0.05],
                    [-0.055, 0.153],
                    [0.143, 0.202],
                    [0.046, 0.067],
                    [0.071, 0.104],
                    [0.021, 0.03],
                    [0.074, 0.108],
                    [0.029, 0.044],
                    [0.058, 0.087],
                    [0.021, 0.033],
                    [0.07, 0.108],
                    [0.013, 0.021],
                    [0.054, 0.086],
                    [0.019, 0.029],
                    [0.07, 0.112],
                    [0, 0.002],
                    [0.05, 0.082],
                    [0, 0],
                    [0.142, -0.014],
                  ],
                  v: [
                    [-3.537, -2.511],
                    [-3.466, -2.515],
                    [-3.049, -2.534],
                    [-2.876, -2.539],
                    [-2.525, -2.486],
                    [-2.324, -2.428],
                    [-2.071, -2.428],
                    [-1.183, -2.477],
                    [-1.06, -2.503],
                    [-0.217, -2.477],
                    [-0.151, -2.48],
                    [0.37, -2.439],
                    [0.632, -1.964],
                    [0.698, -1.844],
                    [0.884, -1.503],
                    [0.959, -1.365],
                    [1.114, -1.077],
                    [1.187, -0.942],
                    [1.266, -0.792],
                    [1.464, -0.413],
                    [1.559, -0.228],
                    [1.762, 0.173],
                    [1.877, 0.403],
                    [1.915, 0.479],
                    [2.171, 1.003],
                    [2.208, 1.081],
                    [2.516, 1.722],
                    [0.125, 3.948],
                    [-0.454, 4.383],
                    [-0.395, 4.518],
                    [-0.326, 4.682],
                    [-0.27, 4.815],
                    [-0.159, 5.087],
                    [-0.098, 5.24],
                    [0.055, 5.628],
                    [0.104, 5.751],
                    [0.34, 6.369],
                    [0.401, 6.53],
                    [0.589, 7.039],
                    [0.659, 7.233],
                    [0.897, 7.905],
                    [1.192, 8.028],
                    [1.302, 8.073],
                    [1.584, 8.192],
                    [1.751, 8.263],
                    [2.054, 8.395],
                    [2.265, 8.487],
                    [2.567, 8.622],
                    [3.054, 8.845],
                    [3.298, 8.959],
                    [4.117, 9.354],
                    [4.644, 9.62],
                    [4.924, 9.762],
                    [5.796, 10.223],
                    [5.951, 10.743],
                    [5.984, 10.86],
                    [6.098, 11.267],
                    [6.301, 12.017],
                    [6.348, 12.201],
                    [6.499, 12.792],
                    [6.562, 13.046],
                    [6.587, 13.149],
                    [6.695, 13.601],
                    [6.711, 13.668],
                    [6.796, 14.044],
                    [6.805, 14.084],
                    [6.877, 14.427],
                    [8.201, 13.658],
                    [8.135, 13.339],
                    [8.129, 13.315],
                    [8.12, 13.274],
                    [8.037, 12.906],
                    [8.018, 12.827],
                    [7.911, 12.382],
                    [7.886, 12.276],
                    [7.852, 12.138],
                    [7.822, 12.023],
                    [7.672, 11.432],
                    [7.624, 11.247],
                    [7.422, 10.497],
                    [7.409, 10.448],
                    [7.31, 10.097],
                    [7.275, 9.973],
                    [7.133, 9.495],
                    [7.12, 9.454],
                    [6.248, 8.992],
                    [6.057, 8.896],
                    [5.442, 8.585],
                    [5.435, 8.581],
                    [4.622, 8.189],
                    [4.374, 8.074],
                    [3.893, 7.853],
                    [3.794, 7.808],
                    [3.59, 7.718],
                    [3.377, 7.624],
                    [3.076, 7.494],
                    [2.909, 7.423],
                    [2.603, 7.294],
                    [2.525, 7.262],
                    [2.221, 7.136],
                    [1.983, 6.464],
                    [1.957, 6.39],
                    [1.913, 6.27],
                    [1.725, 5.761],
                    [1.664, 5.599],
                    [1.428, 4.982],
                    [1.42, 4.962],
                    [1.379, 4.859],
                    [1.226, 4.471],
                    [1.165, 4.318],
                    [1.054, 4.046],
                    [1.022, 3.969],
                    [0.998, 3.911],
                    [0.93, 3.753],
                    [0.899, 3.678],
                    [0.87, 3.613],
                    [3.84, 0.953],
                    [3.529, 0.304],
                    [3.496, 0.237],
                    [3.239, -0.29],
                    [3.201, -0.366],
                    [3.15, -0.47],
                    [3.086, -0.595],
                    [2.883, -0.997],
                    [2.788, -1.183],
                    [2.589, -1.562],
                    [2.538, -1.66],
                    [2.511, -1.712],
                    [2.438, -1.847],
                    [2.283, -2.135],
                    [2.208, -2.273],
                    [2.022, -2.615],
                    [1.955, -2.736],
                    [1.694, -3.213],
                    [1.172, -3.258],
                    [1.155, -3.259],
                    [1.111, -3.261],
                    [0.265, -3.303],
                    [0.14, -3.307],
                    [-0.747, -3.319],
                    [-0.836, -3.32],
                    [-1.002, -3.318],
                    [-1.201, -3.316],
                    [-1.55, -3.308],
                    [-1.725, -3.303],
                    [-2.153, -3.283],
                    [-2.212, -3.281],
                    [-2.617, -3.251],
                    [-2.674, -3.246],
                    [-2.675, -3.246],
                    [-2.888, -3.589],
                    [-3.032, -3.821],
                    [-3.094, -3.921],
                    [-3.26, -4.184],
                    [-3.289, -4.23],
                    [-3.491, -4.546],
                    [-3.555, -4.644],
                    [-3.721, -4.897],
                    [-3.805, -5.023],
                    [-4.015, -5.337],
                    [-4.074, -5.424],
                    [-4.086, -5.443],
                    [-4.285, -5.732],
                    [-4.413, -5.919],
                    [-4.808, -6.483],
                    [-4.649, -6.921],
                    [-4.595, -7.074],
                    [-4.469, -7.435],
                    [-4.402, -7.633],
                    [-4.274, -8.021],
                    [-4.205, -8.236],
                    [-4.048, -8.751],
                    [-4.011, -8.874],
                    [-4.005, -8.894],
                    [-3.783, -9.699],
                    [-3.723, -9.933],
                    [-3.545, -10.663],
                    [-3.505, -10.827],
                    [-3.457, -11.041],
                    [-3.705, -11.363],
                    [-3.79, -11.472],
                    [-3.904, -11.618],
                    [-3.997, -11.735],
                    [-4.119, -11.889],
                    [-4.344, -12.17],
                    [-4.472, -12.328],
                    [-4.758, -12.68],
                    [-4.84, -12.781],
                    [-5.196, -13.211],
                    [-5.217, -13.236],
                    [-5.657, -13.76],
                    [-5.746, -13.863],
                    [-6.072, -14.241],
                    [-6.116, -14.212],
                    [-6.378, -14.428],
                    [-6.379, -14.428],
                    [-6.877, -14.228],
                    [-8.201, -13.498],
                    [-7.702, -13.797],
                    [-7.396, -13.461],
                    [-7.069, -13.088],
                    [-6.981, -12.988],
                    [-6.541, -12.466],
                    [-6.164, -12.011],
                    [-6.086, -11.915],
                    [-5.794, -11.556],
                    [-5.666, -11.398],
                    [-5.446, -11.124],
                    [-5.319, -10.964],
                    [-5.112, -10.7],
                    [-5.03, -10.595],
                    [-4.781, -10.272],
                    [-4.869, -9.893],
                    [-5.048, -9.161],
                    [-5.107, -8.931],
                    [-5.329, -8.124],
                    [-5.372, -7.983],
                    [-5.529, -7.465],
                    [-5.598, -7.253],
                    [-5.726, -6.863],
                    [-5.793, -6.666],
                    [-5.919, -6.304],
                    [-5.973, -6.152],
                    [-6.132, -5.714],
                    [-5.737, -5.15],
                    [-5.607, -4.961],
                    [-5.398, -4.655],
                    [-5.339, -4.567],
                    [-5.129, -4.255],
                    [-5.045, -4.128],
                    [-4.879, -3.875],
                    [-4.815, -3.776],
                    [-4.615, -3.464],
                    [-4.576, -3.403],
                    [-4.417, -3.148],
                    [-4.362, -3.061],
                    [-4.153, -2.726],
                    [-4.151, -2.722],
                    [-3.999, -2.476],
                    [-3.998, -2.476],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [354.252, 310.427], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 2",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.035, -0.024],
                    [-0.127, -0.09],
                    [-0.012, -0.009],
                    [-0.133, -0.097],
                    [-0.097, -0.072],
                    [-0.022, -0.017],
                    [-0.154, -0.118],
                    [-0.044, -0.034],
                    [-0.137, -0.107],
                    [-0.059, -0.047],
                    [-0.223, -0.178],
                    [-0.015, -0.124],
                    [-0.015, -0.136],
                    [-0.011, -0.111],
                    [-0.014, -0.144],
                    [-0.009, -0.097],
                    [-0.024, -0.287],
                    [-0.005, -0.059],
                    [-0.022, -0.33],
                    [-0.006, -0.104],
                    [-0.008, -0.151],
                    [-0.005, -0.106],
                    [-0.004, -0.136],
                    [-0.003, -0.149],
                    [-0.108, -0.099],
                    [-0.068, -0.062],
                    [-0.03, -0.028],
                    [-0.066, -0.061],
                    [-0.038, -0.035],
                    [-0.068, -0.064],
                    [-0.039, -0.037],
                    [-0.099, -0.097],
                    [-0.05, -0.049],
                    [-0.128, -0.131],
                    [-0.038, -0.038],
                    [-0.174, -0.182],
                    [0, 0],
                    [0.152, 0.155],
                    [0.006, 0.007],
                    [0.029, 0.03],
                    [0.115, 0.115],
                    [0.046, 0.045],
                    [0.091, 0.087],
                    [0.022, 0.021],
                    [0.016, 0.016],
                    [0.061, 0.057],
                    [0.04, 0.037],
                    [0.058, 0.053],
                    [0.035, 0.032],
                    [0.052, 0.048],
                    [0.117, 0.107],
                    [0.003, 0.108],
                    [0.002, 0.054],
                    [0.007, 0.144],
                    [0.006, 0.111],
                    [0.009, 0.157],
                    [0.004, 0.076],
                    [0.002, 0.031],
                    [0.026, 0.337],
                    [0.005, 0.056],
                    [0.026, 0.281],
                    [0.005, 0.048],
                    [0.005, 0.046],
                    [0.015, 0.14],
                    [0.012, 0.105],
                    [0.015, 0.128],
                    [0.009, 0.068],
                    [0.006, 0.049],
                    [0.157, 0.124],
                    [0.039, 0.03],
                    [0.055, 0.043],
                    [0.123, 0.095],
                    [0.04, 0.031],
                    [0.131, 0.099],
                    [0.022, 0.015],
                    [0.07, 0.052],
                    [0.021, 0.015],
                    [0.108, 0.077],
                    [0.017, 0.012],
                    [0.107, 0.074],
                    [0.007, 0.005],
                    [0.035, 0.024],
                    [0.106, 0.072],
                    [0.007, 0.005],
                    [0, 0],
                    [-0.125, -0.085],
                  ],
                  o: [
                    [0.109, 0.075],
                    [0.012, 0.009],
                    [0.113, 0.08],
                    [0.086, 0.064],
                    [0.022, 0.017],
                    [0.131, 0.098],
                    [0.042, 0.032],
                    [0.121, 0.093],
                    [0.057, 0.044],
                    [0.191, 0.15],
                    [0.015, 0.114],
                    [0.015, 0.127],
                    [0.012, 0.106],
                    [0.015, 0.137],
                    [0.009, 0.095],
                    [0.026, 0.277],
                    [0.004, 0.058],
                    [0.027, 0.335],
                    [0.007, 0.106],
                    [0.009, 0.157],
                    [0.006, 0.11],
                    [0.006, 0.145],
                    [0.006, 0.164],
                    [0.106, 0.097],
                    [0.065, 0.059],
                    [0.03, 0.027],
                    [0.063, 0.058],
                    [0.036, 0.034],
                    [0.063, 0.06],
                    [0.037, 0.036],
                    [0.09, 0.087],
                    [0.047, 0.047],
                    [0.114, 0.114],
                    [0.037, 0.036],
                    [0.152, 0.155],
                    [0, 0],
                    [-0.174, -0.181],
                    [-0.007, -0.007],
                    [-0.03, -0.03],
                    [-0.13, -0.132],
                    [-0.048, -0.048],
                    [-0.099, -0.098],
                    [-0.022, -0.021],
                    [-0.017, -0.016],
                    [-0.065, -0.062],
                    [-0.041, -0.038],
                    [-0.06, -0.056],
                    [-0.036, -0.033],
                    [-0.054, -0.05],
                    [-0.12, -0.109],
                    [-0.002, -0.101],
                    [-0.002, -0.053],
                    [-0.004, -0.136],
                    [-0.004, -0.107],
                    [-0.008, -0.151],
                    [-0.004, -0.075],
                    [-0.002, -0.031],
                    [-0.022, -0.331],
                    [-0.005, -0.056],
                    [-0.024, -0.29],
                    [-0.004, -0.048],
                    [-0.004, -0.047],
                    [-0.014, -0.147],
                    [-0.012, -0.11],
                    [-0.015, -0.136],
                    [-0.009, -0.071],
                    [-0.006, -0.051],
                    [-0.178, -0.142],
                    [-0.04, -0.032],
                    [-0.058, -0.045],
                    [-0.14, -0.109],
                    [-0.042, -0.032],
                    [-0.155, -0.118],
                    [-0.022, -0.016],
                    [-0.076, -0.058],
                    [-0.02, -0.015],
                    [-0.127, -0.092],
                    [-0.018, -0.012],
                    [-0.124, -0.088],
                    [-0.008, -0.005],
                    [-0.036, -0.024],
                    [-0.111, -0.075],
                    [-0.007, -0.005],
                    [0, 0],
                    [0.12, 0.081],
                    [0.034, 0.024],
                  ],
                  v: [
                    [-3.373, -4.701],
                    [-3.022, -4.457],
                    [-2.982, -4.428],
                    [-2.613, -4.162],
                    [-2.339, -3.959],
                    [-2.275, -3.911],
                    [-1.847, -3.586],
                    [-1.714, -3.483],
                    [-1.327, -3.183],
                    [-1.155, -3.048],
                    [-0.541, -2.562],
                    [-0.496, -2.194],
                    [-0.451, -1.804],
                    [-0.416, -1.476],
                    [-0.373, -1.057],
                    [-0.346, -0.768],
                    [-0.271, 0.079],
                    [-0.257, 0.254],
                    [-0.185, 1.255],
                    [-0.166, 1.57],
                    [-0.14, 2.032],
                    [-0.124, 2.355],
                    [-0.108, 2.777],
                    [-0.093, 3.255],
                    [0.227, 3.547],
                    [0.425, 3.728],
                    [0.517, 3.813],
                    [0.708, 3.99],
                    [0.821, 4.096],
                    [1.016, 4.281],
                    [1.132, 4.393],
                    [1.416, 4.67],
                    [1.56, 4.812],
                    [1.925, 5.18],
                    [2.032, 5.287],
                    [2.518, 5.789],
                    [3.842, 5.019],
                    [3.356, 4.518],
                    [3.334, 4.495],
                    [3.251, 4.412],
                    [2.882, 4.041],
                    [2.742, 3.903],
                    [2.457, 3.625],
                    [2.386, 3.556],
                    [2.338, 3.51],
                    [2.151, 3.333],
                    [2.029, 3.218],
                    [1.853, 3.055],
                    [1.745, 2.955],
                    [1.586, 2.809],
                    [1.231, 2.485],
                    [1.223, 2.172],
                    [1.216, 2.006],
                    [1.199, 1.587],
                    [1.184, 1.263],
                    [1.158, 0.801],
                    [1.146, 0.58],
                    [1.139, 0.485],
                    [1.067, -0.519],
                    [1.053, -0.684],
                    [0.978, -1.54],
                    [0.965, -1.688],
                    [0.951, -1.823],
                    [0.908, -2.25],
                    [0.873, -2.573],
                    [0.828, -2.964],
                    [0.802, -3.182],
                    [0.783, -3.331],
                    [0.28, -3.73],
                    [0.168, -3.818],
                    [0, -3.95],
                    [-0.395, -4.257],
                    [-0.521, -4.354],
                    [-0.951, -4.681],
                    [-1.013, -4.726],
                    [-1.237, -4.893],
                    [-1.293, -4.934],
                    [-1.645, -5.188],
                    [-1.701, -5.228],
                    [-2.044, -5.467],
                    [-2.068, -5.484],
                    [-2.171, -5.554],
                    [-2.497, -5.775],
                    [-2.518, -5.789],
                    [-3.842, -5.02],
                    [-3.476, -4.772],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [340.773, 293.059], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 3",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.001, 0.066],
                    [0, 0.03],
                    [0.001, 0.062],
                    [0.002, 0.122],
                    [0.117, 0.112],
                    [0.127, 0.12],
                    [0.027, 0.025],
                    [0.362, 0.334],
                    [0.036, 0.033],
                    [0.17, 0.153],
                    [0.016, 0.014],
                    [0.288, 0.253],
                    [0.034, 0.03],
                    [0.276, 0.234],
                    [0.035, 0.029],
                    [0.219, 0.18],
                    [0.024, 0.254],
                    [0.018, 0.166],
                    [0.006, 0.05],
                    [0.015, 0.123],
                    [0.004, 0.032],
                    [0.001, 0.013],
                    [0, 0.356],
                    [0, 0],
                    [0.025, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.024, -0.15],
                    [-0.045, -0.346],
                    [-0.005, -0.044],
                    [-0.013, -0.121],
                    [-0.006, -0.045],
                    [-0.014, -0.152],
                    [-0.013, -0.209],
                    [-0.241, -0.204],
                    [-0.254, -0.222],
                    [-0.034, -0.03],
                    [-0.258, -0.232],
                    [-0.169, -0.155],
                    [-0.034, -0.032],
                    [-0.268, -0.254],
                    [-0.021, -0.021],
                    [-0.104, -0.099],
                    [-0.047, -0.045],
                    [-0.036, -0.034],
                    [-0.002, -0.09],
                    [0, -0.093],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.006, -0.113],
                    [0.001, -0.028],
                    [0, -0.134],
                    [0, 0],
                    [0.011, -0.161],
                    [0.003, -0.039],
                    [0.013, -0.193],
                    [0, 0],
                    [-0.01, 0.162],
                    [-0.003, 0.045],
                    [0, 0.134],
                    [0, 0],
                    [-0.007, 0.115],
                    [-0.002, 0.027],
                    [0, 0.098],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, -0.063],
                    [0, -0.029],
                    [-0.001, -0.058],
                    [-0.002, -0.105],
                    [-0.08, -0.076],
                    [-0.1, -0.096],
                    [-0.026, -0.025],
                    [-0.267, -0.253],
                    [-0.035, -0.031],
                    [-0.155, -0.142],
                    [-0.016, -0.015],
                    [-0.258, -0.232],
                    [-0.034, -0.03],
                    [-0.255, -0.222],
                    [-0.034, -0.029],
                    [-0.208, -0.176],
                    [-0.013, -0.206],
                    [-0.015, -0.153],
                    [-0.005, -0.049],
                    [-0.013, -0.119],
                    [-0.004, -0.032],
                    [-0.002, -0.013],
                    [-0.045, -0.348],
                    [0, 0],
                    [0, -0.149],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.026, 0.148],
                    [0.056, 0.355],
                    [0.005, 0.045],
                    [0.016, 0.125],
                    [0.006, 0.047],
                    [0.018, 0.164],
                    [0.025, 0.257],
                    [0.256, 0.21],
                    [0.275, 0.233],
                    [0.034, 0.029],
                    [0.288, 0.252],
                    [0.186, 0.168],
                    [0.035, 0.032],
                    [0.365, 0.335],
                    [0.022, 0.021],
                    [0.131, 0.124],
                    [0.055, 0.053],
                    [0.043, 0.042],
                    [0.001, 0.102],
                    [0.002, 0.103],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0.098],
                    [-0.001, 0.028],
                    [-0.007, 0.116],
                    [0, 0],
                    [0, 0.138],
                    [-0.002, 0.037],
                    [-0.011, 0.166],
                    [0, 0],
                    [0.013, -0.187],
                    [0.004, -0.048],
                    [0.011, -0.157],
                    [0, 0],
                    [0, -0.135],
                    [0.001, -0.028],
                    [0.006, -0.113],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [2.837, 2.501],
                    [2.837, 2.497],
                    [2.837, 2.276],
                    [2.837, 2.217],
                    [3.055, 2.024],
                    [3.164, 1.935],
                    [3.216, 1.754],
                    [3.237, 1.417],
                    [2.953, 1.133],
                    [2.621, 0.809],
                    [2.546, 0.735],
                    [1.6, -0.149],
                    [1.497, -0.244],
                    [1.013, -0.684],
                    [0.962, -0.73],
                    [0.145, -1.456],
                    [0.039, -1.547],
                    [-0.758, -2.231],
                    [-0.857, -2.316],
                    [-1.497, -2.85],
                    [-1.553, -3.545],
                    [-1.603, -4.026],
                    [-1.62, -4.175],
                    [-1.663, -4.538],
                    [-1.674, -4.632],
                    [-1.846, -4.671],
                    [-2.163, -5.731],
                    [-2.163, -5.732],
                    [-1.984, -5.685],
                    [-1.944, -5.685],
                    [-1.924, -5.685],
                    [-3.238, -5.162],
                    [-3.159, -4.838],
                    [-3.007, -3.841],
                    [-2.99, -3.739],
                    [-2.944, -3.384],
                    [-2.927, -3.253],
                    [-2.879, -2.781],
                    [-2.822, -2.079],
                    [-2.079, -1.459],
                    [-1.285, -0.777],
                    [-1.18, -0.686],
                    [-0.362, 0.039],
                    [0.174, 0.526],
                    [0.277, 0.621],
                    [1.232, 1.51],
                    [1.298, 1.573],
                    [1.654, 1.912],
                    [1.809, 2.06],
                    [1.94, 2.186],
                    [1.388, 2.472],
                    [0.837, 2.765],
                    [0.837, 2.773],
                    [0.837, 2.986],
                    [0.837, 3.045],
                    [0.837, 3.266],
                    [0.837, 3.279],
                    [0.837, 3.994],
                    [0.837, 4.004],
                    [1.371, 4.322],
                    [1.112, 4.406],
                    [0.837, 4.777],
                    [0.837, 4.782],
                    [1.337, 5.234],
                    [1.594, 5.348],
                    [1.69, 5.881],
                    [3.079, 5.112],
                    [3.147, 4.594],
                    [2.983, 4.453],
                    [2.837, 4.013],
                    [2.837, 4.008],
                    [3.049, 3.637],
                    [2.938, 3.553],
                    [2.837, 3.234],
                    [2.837, 3.231],
                    [2.837, 3.224],
                    [2.837, 2.51],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [360.163, 330.685], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 4",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.137, -0.039],
                    [-0.01, -0.003],
                    [-0.147, -0.045],
                    [-0.043, -0.013],
                    [-0.16, -0.052],
                    [-0.051, -0.016],
                    [-0.197, -0.069],
                    [-0.022, -0.008],
                    [-0.207, -0.082],
                    [-0.04, -0.016],
                    [-0.126, -0.335],
                    [-0.044, -0.12],
                    [-0.071, -0.196],
                    [-0.025, -0.07],
                    [-0.087, -0.249],
                    [-0.037, -0.107],
                    [-0.063, -0.188],
                    [-0.041, -0.121],
                    [-0.029, -0.088],
                    [-0.043, -0.133],
                    [-0.024, -0.075],
                    [-0.044, -0.14],
                    [-0.015, -0.048],
                    [-0.054, -0.169],
                    [-0.19, -0.094],
                    [-0.103, -0.052],
                    [-0.102, -0.053],
                    [-0.1, -0.053],
                    [-0.102, -0.056],
                    [-0.01, -0.006],
                    [-0.158, -0.091],
                    [-0.05, -0.029],
                    [-0.048, -0.03],
                    [-0.037, -0.025],
                    [0, 0],
                    [0.027, 0.017],
                    [0.016, 0.01],
                    [0.055, 0.032],
                    [0.02, 0.011],
                    [0.035, 0.02],
                    [0.19, 0.103],
                    [0.01, 0.006],
                    [0.007, 0.003],
                    [0.095, 0.05],
                    [0.108, 0.055],
                    [0.093, 0.047],
                    [0.105, 0.052],
                    [0.005, 0.002],
                    [0.153, 0.072],
                    [0.058, 0.182],
                    [0.019, 0.06],
                    [0.045, 0.141],
                    [0.026, 0.082],
                    [0.045, 0.135],
                    [0.033, 0.097],
                    [0.025, 0.075],
                    [0.016, 0.046],
                    [0.069, 0.201],
                    [0.039, 0.111],
                    [0.097, 0.269],
                    [0.023, 0.061],
                    [0.004, 0.01],
                    [0.077, 0.207],
                    [0.046, 0.123],
                    [0.14, 0.361],
                    [0.022, 0.008],
                    [0.02, 0.008],
                    [0.23, 0.081],
                    [0.015, 0.005],
                    [0.008, 0.002],
                    [0.199, 0.065],
                    [0.052, 0.016],
                    [0.15, 0.046],
                    [0.027, 0.008],
                    [0.015, 0.004],
                    [0.133, 0.037],
                    [0.007, 0.002],
                    [0.108, 0.027],
                    [0.009, 0.002],
                    [0.054, 0.011],
                    [0, 0],
                    [-0.084, -0.021],
                  ],
                  o: [
                    [0.01, 0.003],
                    [0.13, 0.036],
                    [0.042, 0.012],
                    [0.15, 0.046],
                    [0.05, 0.016],
                    [0.198, 0.065],
                    [0.023, 0.008],
                    [0.229, 0.082],
                    [0.042, 0.016],
                    [0.141, 0.362],
                    [0.046, 0.123],
                    [0.077, 0.206],
                    [0.026, 0.072],
                    [0.097, 0.269],
                    [0.039, 0.111],
                    [0.069, 0.199],
                    [0.043, 0.126],
                    [0.03, 0.091],
                    [0.046, 0.14],
                    [0.025, 0.078],
                    [0.048, 0.149],
                    [0.015, 0.048],
                    [0.058, 0.185],
                    [0.157, 0.073],
                    [0.099, 0.049],
                    [0.1, 0.051],
                    [0.102, 0.053],
                    [0.107, 0.057],
                    [0.01, 0.005],
                    [0.189, 0.103],
                    [0.055, 0.032],
                    [0.055, 0.033],
                    [0.045, 0.028],
                    [0, 0],
                    [-0.024, -0.015],
                    [-0.016, -0.01],
                    [-0.048, -0.03],
                    [-0.019, -0.012],
                    [-0.033, -0.02],
                    [-0.158, -0.092],
                    [-0.01, -0.005],
                    [-0.006, -0.003],
                    [-0.092, -0.05],
                    [-0.107, -0.057],
                    [-0.094, -0.049],
                    [-0.111, -0.056],
                    [-0.005, -0.003],
                    [-0.184, -0.092],
                    [-0.053, -0.166],
                    [-0.018, -0.059],
                    [-0.042, -0.133],
                    [-0.026, -0.079],
                    [-0.041, -0.128],
                    [-0.03, -0.093],
                    [-0.024, -0.072],
                    [-0.015, -0.045],
                    [-0.063, -0.189],
                    [-0.037, -0.108],
                    [-0.087, -0.25],
                    [-0.022, -0.061],
                    [-0.003, -0.01],
                    [-0.071, -0.197],
                    [-0.044, -0.119],
                    [-0.126, -0.335],
                    [-0.022, -0.009],
                    [-0.02, -0.008],
                    [-0.207, -0.081],
                    [-0.015, -0.005],
                    [-0.008, -0.003],
                    [-0.198, -0.07],
                    [-0.052, -0.017],
                    [-0.16, -0.052],
                    [-0.028, -0.009],
                    [-0.015, -0.004],
                    [-0.153, -0.046],
                    [-0.007, -0.002],
                    [-0.138, -0.039],
                    [-0.008, -0.003],
                    [-0.073, -0.018],
                    [0, 0],
                    [0.06, 0.013],
                    [0.107, 0.027],
                  ],
                  v: [
                    [-4.161, -3.867],
                    [-4.129, -3.857],
                    [-3.711, -3.734],
                    [-3.582, -3.695],
                    [-3.115, -3.548],
                    [-2.964, -3.5],
                    [-2.368, -3.298],
                    [-2.301, -3.275],
                    [-1.644, -3.029],
                    [-1.516, -2.979],
                    [-1.117, -1.935],
                    [-0.986, -1.579],
                    [-0.76, -0.969],
                    [-0.686, -0.762],
                    [-0.409, 0.018],
                    [-0.297, 0.342],
                    [-0.098, 0.926],
                    [0.027, 1.297],
                    [0.116, 1.566],
                    [0.249, 1.974],
                    [0.324, 2.207],
                    [0.462, 2.639],
                    [0.509, 2.788],
                    [0.677, 3.32],
                    [1.204, 3.574],
                    [1.512, 3.73],
                    [1.811, 3.883],
                    [2.112, 4.042],
                    [2.43, 4.211],
                    [2.46, 4.227],
                    [2.987, 4.521],
                    [3.14, 4.611],
                    [3.294, 4.704],
                    [3.423, 4.786],
                    [4.747, 4.017],
                    [4.671, 3.967],
                    [4.618, 3.935],
                    [4.465, 3.842],
                    [4.412, 3.81],
                    [4.311, 3.751],
                    [3.783, 3.457],
                    [3.754, 3.44],
                    [3.735, 3.431],
                    [3.451, 3.28],
                    [3.133, 3.112],
                    [2.856, 2.971],
                    [2.528, 2.806],
                    [2.512, 2.797],
                    [2.001, 2.551],
                    [1.836, 2.029],
                    [1.779, 1.848],
                    [1.649, 1.44],
                    [1.57, 1.197],
                    [1.442, 0.805],
                    [1.347, 0.517],
                    [1.276, 0.302],
                    [1.227, 0.159],
                    [1.027, -0.427],
                    [0.915, -0.75],
                    [0.638, -1.53],
                    [0.576, -1.707],
                    [0.564, -1.737],
                    [0.339, -2.349],
                    [0.207, -2.704],
                    [-0.192, -3.749],
                    [-0.257, -3.775],
                    [-0.32, -3.798],
                    [-0.977, -4.043],
                    [-1.02, -4.06],
                    [-1.044, -4.068],
                    [-1.64, -4.27],
                    [-1.793, -4.319],
                    [-2.258, -4.465],
                    [-2.343, -4.492],
                    [-2.386, -4.504],
                    [-2.816, -4.629],
                    [-2.837, -4.635],
                    [-3.204, -4.733],
                    [-3.234, -4.742],
                    [-3.424, -4.786],
                    [-4.748, -4.017],
                    [-4.527, -3.965],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [331.81, 287.468], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 5",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.03, 0.067],
                    [-0.017, 0.039],
                    [-0.033, 0.077],
                    [-0.018, 0.041],
                    [-0.055, 0.129],
                    [0.176, 0.265],
                    [0.054, 0.081],
                    [0.104, 0.155],
                    [0.01, 0.015],
                    [0.196, 0.281],
                    [0.052, 0.074],
                    [0.143, 0.2],
                    [0.063, 0.088],
                    [0.133, 0.183],
                    [0.054, 0.073],
                    [0.179, 0.241],
                    [0.002, 0.003],
                    [0.146, 0.196],
                    [-0.041, 0.149],
                    [-0.012, 0.045],
                    [-0.023, 0.09],
                    [-0.01, 0.042],
                    [-0.018, 0.078],
                    [-0.008, 0.032],
                    [-0.002, 0.01],
                    [-0.005, 0.023],
                    [-0.02, 0.099],
                    [-0.006, 0.032],
                    [-0.016, 0.095],
                    [0, 0.004],
                    [0, 0],
                    [-0.023, 0.132],
                    [-0.003, 0],
                    [0, 0],
                    [0, 0],
                    [0.028, -0.163],
                    [0.002, -0.006],
                    [0.021, -0.108],
                    [0.007, -0.034],
                    [0.026, -0.114],
                    [0.008, -0.034],
                    [0.008, -0.032],
                    [0.022, -0.086],
                    [0.012, -0.044],
                    [0.027, -0.099],
                    [0.013, -0.047],
                    [0.05, -0.171],
                    [-0.15, -0.202],
                    [-0.182, -0.249],
                    [-0.054, -0.074],
                    [-0.136, -0.189],
                    [-0.064, -0.09],
                    [-0.148, -0.212],
                    [-0.05, -0.073],
                    [-0.205, -0.301],
                    [-0.115, -0.171],
                    [-0.057, -0.086],
                    [-0.183, -0.28],
                    [0.049, -0.115],
                    [0.017, -0.039],
                    [0.031, -0.072],
                    [0.018, -0.039],
                    [0.029, -0.062],
                    [0.018, -0.04],
                    [0.025, -0.053],
                    [0.03, -0.062],
                    [0.024, -0.046],
                    [0.034, -0.065],
                    [0.025, -0.044],
                    [0.044, -0.077],
                    [0.023, -0.04],
                    [0.083, -0.133],
                    [0, 0],
                    [-0.07, 0.119],
                    [-0.022, 0.038],
                    [-0.041, 0.073],
                    [-0.023, 0.043],
                    [-0.032, 0.063],
                    [-0.022, 0.045],
                    [-0.03, 0.063],
                    [-0.01, 0.02],
                    [-0.016, 0.033],
                    [-0.018, 0.038],
                  ],
                  o: [
                    [0.017, -0.038],
                    [0.032, -0.072],
                    [0.017, -0.039],
                    [0.049, -0.115],
                    [-0.183, -0.28],
                    [-0.055, -0.082],
                    [-0.107, -0.16],
                    [-0.011, -0.015],
                    [-0.203, -0.299],
                    [-0.053, -0.076],
                    [-0.146, -0.209],
                    [-0.064, -0.09],
                    [-0.136, -0.19],
                    [-0.054, -0.075],
                    [-0.182, -0.249],
                    [-0.002, -0.003],
                    [-0.148, -0.2],
                    [0.05, -0.17],
                    [0.013, -0.046],
                    [0.027, -0.099],
                    [0.012, -0.045],
                    [0.022, -0.086],
                    [0.008, -0.033],
                    [0.002, -0.01],
                    [0.006, -0.024],
                    [0.026, -0.115],
                    [0.007, -0.034],
                    [0.021, -0.107],
                    [0.001, -0.004],
                    [0, 0],
                    [0, -0.144],
                    [0.002, -0.017],
                    [0, 0],
                    [0, 0],
                    [-0.025, 0.15],
                    [-0.001, 0.006],
                    [-0.016, 0.095],
                    [-0.006, 0.032],
                    [-0.02, 0.099],
                    [-0.007, 0.033],
                    [-0.007, 0.031],
                    [-0.018, 0.079],
                    [-0.01, 0.043],
                    [-0.023, 0.09],
                    [-0.012, 0.045],
                    [-0.041, 0.148],
                    [0.148, 0.198],
                    [0.179, 0.241],
                    [0.054, 0.074],
                    [0.133, 0.183],
                    [0.063, 0.088],
                    [0.144, 0.202],
                    [0.05, 0.071],
                    [0.197, 0.282],
                    [0.112, 0.165],
                    [0.056, 0.084],
                    [0.177, 0.264],
                    [-0.055, 0.129],
                    [-0.018, 0.042],
                    [-0.034, 0.077],
                    [-0.018, 0.041],
                    [-0.029, 0.065],
                    [-0.018, 0.04],
                    [-0.025, 0.053],
                    [-0.03, 0.062],
                    [-0.023, 0.045],
                    [-0.032, 0.063],
                    [-0.024, 0.043],
                    [-0.041, 0.074],
                    [-0.022, 0.039],
                    [-0.07, 0.118],
                    [0, 0],
                    [0.082, -0.133],
                    [0.023, -0.039],
                    [0.044, -0.077],
                    [0.024, -0.044],
                    [0.035, -0.064],
                    [0.023, -0.045],
                    [0.031, -0.062],
                    [0.01, -0.02],
                    [0.015, -0.032],
                    [0.017, -0.038],
                    [0.029, -0.064],
                  ],
                  v: [
                    [2.297, 3.241],
                    [2.348, 3.126],
                    [2.446, 2.901],
                    [2.498, 2.784],
                    [2.654, 2.418],
                    [2.115, 1.603],
                    [1.953, 1.362],
                    [1.633, 0.885],
                    [1.602, 0.841],
                    [1.004, -0.026],
                    [0.848, -0.25],
                    [0.414, -0.863],
                    [0.224, -1.13],
                    [-0.18, -1.69],
                    [-0.342, -1.913],
                    [-0.883, -2.648],
                    [-0.889, -2.656],
                    [-1.33, -3.25],
                    [-1.196, -3.724],
                    [-1.16, -3.858],
                    [-1.085, -4.142],
                    [-1.052, -4.273],
                    [-0.992, -4.52],
                    [-0.968, -4.62],
                    [-0.961, -4.651],
                    [-0.947, -4.715],
                    [-0.879, -5.038],
                    [-0.859, -5.138],
                    [-0.994, -5.443],
                    [-1.183, -5.456],
                    [-1.183, -5.461],
                    [-0.922, -5.828],
                    [-0.818, -5.84],
                    [-0.77, -5.84],
                    [-2.07, -5.112],
                    [-2.135, -4.671],
                    [-2.133, -4.663],
                    [-2.186, -4.362],
                    [-2.204, -4.266],
                    [-2.272, -3.947],
                    [-2.293, -3.849],
                    [-2.316, -3.751],
                    [-2.376, -3.504],
                    [-2.409, -3.374],
                    [-2.484, -3.09],
                    [-2.52, -2.954],
                    [-2.654, -2.479],
                    [-2.207, -1.879],
                    [-1.666, -1.143],
                    [-1.504, -0.921],
                    [-1.1, -0.362],
                    [-0.91, -0.095],
                    [-0.471, 0.527],
                    [-0.322, 0.741],
                    [0.28, 1.613],
                    [0.622, 2.121],
                    [0.791, 2.372],
                    [1.33, 3.189],
                    [1.174, 3.552],
                    [1.123, 3.671],
                    [1.025, 3.896],
                    [0.971, 4.014],
                    [0.885, 4.207],
                    [0.83, 4.326],
                    [0.755, 4.483],
                    [0.664, 4.669],
                    [0.595, 4.806],
                    [0.494, 4.997],
                    [0.423, 5.128],
                    [0.294, 5.354],
                    [0.228, 5.47],
                    [0.001, 5.845],
                    [1.325, 5.076],
                    [1.552, 4.699],
                    [1.618, 4.586],
                    [1.747, 4.358],
                    [1.817, 4.228],
                    [1.919, 4.035],
                    [1.987, 3.901],
                    [2.079, 3.714],
                    [2.109, 3.654],
                    [2.155, 3.555],
                    [2.208, 3.44],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [359.183, 339.84], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 6",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.073, 0.018],
                    [-0.036, 0.009],
                    [-0.063, 0.015],
                    [-0.043, 0.009],
                    [-0.065, 0.014],
                    [-0.057, 0.01],
                    [-0.047, 0.008],
                    [-0.071, 0.012],
                    [-0.051, 0.007],
                    [-0.091, 0.011],
                    [-0.049, 0.006],
                    [-0.166, 0],
                    [0, 0],
                    [-0.221, -0.345],
                    [-0.04, -0.062],
                    [-0.216, -0.346],
                    [-0.042, -0.067],
                    [-0.228, -0.378],
                    [-0.017, -0.027],
                    [-0.227, -0.389],
                    [-0.042, -0.073],
                    [-0.187, -0.331],
                    [-0.177, -0.007],
                    [-0.045, -0.002],
                    [-0.146, -0.011],
                    [-0.066, -0.006],
                    [-0.148, -0.018],
                    [-0.065, -0.008],
                    [-0.215, -0.037],
                    [0, 0],
                    [0.184, 0.025],
                    [0.03, 0.004],
                    [0.063, 0.008],
                    [0.144, 0.013],
                    [0.063, 0.005],
                    [0.042, 0.002],
                    [0.097, 0.004],
                    [0.043, 0.002],
                    [0.134, 0],
                    [0, 0],
                    [0.18, 0.308],
                    [0.016, 0.027],
                    [0.026, 0.045],
                    [0.218, 0.363],
                    [0.017, 0.027],
                    [0.222, 0.358],
                    [0.042, 0.066],
                    [0.216, 0.337],
                    [0.04, 0.062],
                    [0.225, 0.346],
                    [0.144, -0.017],
                    [0.046, -0.005],
                    [0.083, -0.012],
                    [0.048, -0.008],
                    [0.066, -0.012],
                    [0.044, -0.008],
                    [0.035, -0.007],
                    [0.021, -0.004],
                    [0.061, -0.013],
                    [0.042, -0.01],
                    [0.062, -0.015],
                    [0.036, -0.009],
                    [0.074, -0.02],
                    [0.105, -0.028],
                    [0.024, -0.007],
                    [0, 0],
                    [-0.122, 0.032],
                  ],
                  o: [
                    [0.036, -0.009],
                    [0.062, -0.016],
                    [0.042, -0.009],
                    [0.061, -0.014],
                    [0.054, -0.011],
                    [0.045, -0.009],
                    [0.066, -0.011],
                    [0.048, -0.007],
                    [0.083, -0.012],
                    [0.047, -0.006],
                    [0.143, -0.017],
                    [0, 0],
                    [0.225, 1],
                    [0.04, 0.062],
                    [0.215, 0.338],
                    [0.042, 0.066],
                    [0.223, 0.359],
                    [0.017, 0.028],
                    [0.218, 0.363],
                    [0.042, 0.072],
                    [0.18, 0.309],
                    [0.144, -0.002],
                    [0.044, 0.002],
                    [0.134, 0.007],
                    [0.063, 0.005],
                    [0.142, 0.013],
                    [0.064, 0.007],
                    [0.212, 0.028],
                    [0, 0],
                    [-0.185, -0.032],
                    [-0.03, -0.004],
                    [-0.064, -0.008],
                    [-0.15, -0.017],
                    [-0.065, -0.006],
                    [-0.042, -0.004],
                    [-0.103, -0.008],
                    [-0.044, -0.003],
                    [-0.162, -0.007],
                    [0, 0],
                    [-0.187, 0],
                    [-0.016, -0.028],
                    [-0.026, -0.045],
                    [-0.227, -0.389],
                    [-0.017, -0.028],
                    [-0.228, -0.378],
                    [-0.042, -0.067],
                    [-0.216, -0.346],
                    [-0.039, -0.062],
                    [-0.222, -0.345],
                    [-0.168, 0.016],
                    [-0.049, 0.006],
                    [-0.091, 0.012],
                    [-0.051, 0.007],
                    [-0.071, 0.011],
                    [-0.047, 0.008],
                    [-0.036, 0.007],
                    [-0.021, 0.004],
                    [-0.064, 0.013],
                    [-0.043, 0.01],
                    [-0.063, 0.015],
                    [-0.036, 0.009],
                    [-0.073, 0.019],
                    [-0.099, 0.026],
                    [-0.023, 0.006],
                    [0, 0],
                    [0.132, -0.034],
                    [0.073, -0.019],
                  ],
                  v: [
                    [-4.505, -2.335],
                    [-4.395, -2.362],
                    [-4.208, -2.408],
                    [-4.079, -2.436],
                    [-3.89, -2.477],
                    [-3.722, -2.509],
                    [-3.586, -2.534],
                    [-3.378, -2.568],
                    [-3.233, -2.59],
                    [-2.969, -2.625],
                    [-2.83, -2.604],
                    [-2.368, -2.614],
                    [-2.364, -2.614],
                    [-1.695, -1.618],
                    [-1.575, -1.452],
                    [-0.928, -0.437],
                    [-0.802, -0.241],
                    [-0.127, 0.857],
                    [-0.076, 0.94],
                    [0.593, 2.068],
                    [0.718, 2.282],
                    [1.267, 3.237],
                    [1.755, 3.246],
                    [1.893, 3.253],
                    [2.312, 3.28],
                    [2.506, 3.297],
                    [2.943, 3.343],
                    [3.134, 3.365],
                    [3.777, 3.461],
                    [5.101, 2.692],
                    [4.546, 2.606],
                    [4.457, 2.596],
                    [4.268, 2.573],
                    [3.828, 2.527],
                    [3.637, 2.511],
                    [3.507, 2.5],
                    [3.213, 2.484],
                    [3.08, 2.436],
                    [2.632, 2.386],
                    [2.591, 2.386],
                    [2.042, 1.472],
                    [1.994, 1.408],
                    [1.917, 1.288],
                    [1.248, 0.165],
                    [1.197, 0.083],
                    [0.522, -1.016],
                    [0.396, -1.217],
                    [-0.252, -2.241],
                    [-0.371, -2.427],
                    [-1.04, -3.462],
                    [-1.506, -3.412],
                    [-1.644, -3.395],
                    [-1.909, -3.359],
                    [-2.054, -3.337],
                    [-2.262, -3.303],
                    [-2.397, -3.279],
                    [-2.507, -3.259],
                    [-2.567, -3.246],
                    [-2.755, -3.206],
                    [-2.884, -3.177],
                    [-3.071, -3.132],
                    [-3.181, -3.105],
                    [-3.403, -3.046],
                    [-3.709, -2.966],
                    [-3.777, -2.948],
                    [-5.101, -2.179],
                    [-4.724, -2.278],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [323.37, 285.614], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 7",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.041, 0.099],
                    [0.028, 0.067],
                    [0.196, 0.45],
                    [0.057, 0.129],
                    [0.012, 0.027],
                    [0.279, 0.595],
                    [0.005, 0.009],
                    [-0.551, 0.749],
                    [0, 0],
                    [0.403, -0.395],
                    [0.18, -0.174],
                    [-0.253, -0.57],
                    [-0.067, -0.155],
                    [-0.182, -0.435],
                    [-0.07, -0.168],
                    [-0.287, -0.736],
                    [0.329, -0.191],
                    [0, 0],
                    [-0.322, 0.215],
                    [0.307, 0.743],
                  ],
                  o: [
                    [-0.028, -0.067],
                    [-0.183, -0.437],
                    [-0.056, -0.128],
                    [-0.012, -0.027],
                    [-0.249, -0.561],
                    [-0.005, -0.01],
                    [0.719, -0.699],
                    [0, 0],
                    [-0.413, 0.562],
                    [-0.134, 0.131],
                    [0.284, 0.606],
                    [0.069, 0.158],
                    [0.196, 0.45],
                    [0.071, 0.17],
                    [0.306, 0.743],
                    [-0.322, 0.215],
                    [0, 0],
                    [0.328, -0.191],
                    [-0.287, -0.737],
                    [-0.04, -0.099],
                  ],
                  v: [
                    [0.988, 0.79],
                    [0.9, 0.584],
                    [0.328, -0.749],
                    [0.167, -1.127],
                    [0.129, -1.211],
                    [-0.659, -2.942],
                    [-0.672, -2.97],
                    [0.858, -4.649],
                    [-0.465, -3.88],
                    [-1.531, -2.652],
                    [-1.996, -2.201],
                    [-1.195, -0.442],
                    [-0.995, 0.021],
                    [-0.426, 1.351],
                    [-0.216, 1.857],
                    [0.673, 4.07],
                    [-0.225, 4.649],
                    [1.099, 3.88],
                    [1.996, 3.301],
                    [1.105, 1.085],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [354.808, 345.641], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 8",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.295, -0.171],
                    [0, 0],
                    [0.353, 0.381],
                    [0.003, 0.003],
                    [0.334, 0.35],
                    [0.102, 0.106],
                    [0.163, 0.165],
                    [0.102, 0.103],
                    [0.099, 0.099],
                    [0.395, 0.38],
                    [0, 0],
                    [-0.363, -0.362],
                    [-0.098, -0.099],
                    [-0.255, -0.265],
                    [-0.101, -0.106],
                    [-0.326, -0.351],
                    [-0.31, -0.341],
                    [-0.05, -0.054],
                    [0, 0],
                    [0.003, 0.004],
                    [-0.241, 0.107],
                  ],
                  o: [
                    [-0.532, 0.235],
                    [0, 0],
                    [-0.351, -0.387],
                    [-0.003, -0.004],
                    [-0.323, -0.348],
                    [-0.101, -0.106],
                    [-0.158, -0.164],
                    [-0.101, -0.103],
                    [-0.098, -0.098],
                    [-0.363, -0.362],
                    [0, 0],
                    [0.395, 0.38],
                    [0.1, 0.1],
                    [0.268, 0.27],
                    [0.103, 0.106],
                    [0.338, 0.354],
                    [0.313, 0.337],
                    [0.049, 0.055],
                    [0, 0],
                    [-0.004, -0.004],
                    [0.176, -0.087],
                    [0, 0],
                  ],
                  v: [
                    [3.924, 0.931],
                    [2.755, 1.509],
                    [1.967, 1.967],
                    [0.914, 0.817],
                    [0.905, 0.807],
                    [-0.081, -0.239],
                    [-0.389, -0.558],
                    [-0.865, -1.05],
                    [-1.175, -1.362],
                    [-1.47, -1.658],
                    [-2.6, -2.767],
                    [-3.924, -1.998],
                    [-2.795, -0.89],
                    [-2.498, -0.591],
                    [-1.714, 0.211],
                    [-1.405, 0.53],
                    [-0.41, 1.586],
                    [0.522, 2.604],
                    [0.671, 2.767],
                    [1.995, 1.998],
                    [1.984, 1.986],
                    [2.6, 1.7],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [317.529, 288.013], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 9",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.061, 0.028],
                    [-0.173, 0.101],
                    [0, 0],
                    [0.182, -0.085],
                    [0.061, -0.026],
                    [0.126, -0.045],
                    [0.036, -0.012],
                    [0.287, -0.059],
                    [0.033, -0.006],
                    [0.244, -0.024],
                    [0.074, -0.005],
                    [0.151, -0.002],
                    [0.095, 0.002],
                    [0.122, 0.007],
                    [0.105, 0.009],
                    [0.108, 0.012],
                    [0.115, 0.017],
                    [0.098, 0.018],
                    [0.123, 0.027],
                    [0.09, 0.022],
                    [0.137, 0.038],
                    [0.077, 0.023],
                    [0.185, 0.063],
                    [0.03, 0.011],
                    [0.216, 0.087],
                    [0.064, 0.027],
                    [0.155, 0.071],
                    [0.08, 0.038],
                    [0.143, 0.072],
                    [0.082, 0.043],
                    [0.202, 0.117],
                    [0.258, 0.165],
                    [0.041, 0.027],
                    [0.2, 0.139],
                    [0.082, 0.059],
                    [0.165, 0.124],
                    [0.086, 0.068],
                    [0.171, 0.14],
                    [0.076, 0.064],
                    [0.245, 0.22],
                    [0.01, 0.009],
                    [0.233, 0.225],
                    [0.075, 0.073],
                    [0.168, 0.173],
                    [0.082, 0.086],
                    [0.212, 0.239],
                    [0.164, 0.193],
                    [0.067, 0.08],
                    [0.146, 0.181],
                    [0.058, 0.075],
                    [0.105, 0.138],
                    [0.098, 0.132],
                    [0.019, 0.026],
                    [0.24, 0.352],
                    [0.053, 0.08],
                    [0.332, 0.56],
                    [0.036, 0.061],
                    [0.207, 0.386],
                    [0.076, 0.145],
                    [0.065, 0.13],
                    [0.096, 0.203],
                    [0.033, 0.072],
                    [0.119, 0.269],
                    [0.029, 0.069],
                    [0.083, 0.202],
                    [0.036, 0.091],
                    [0.07, 0.185],
                    [0.034, 0.093],
                    [0.071, 0.204],
                    [0.023, 0.07],
                    [0.084, 0.275],
                    [0.019, 0.062],
                    [0.059, 0.213],
                    [0.027, 0.1],
                    [0.043, 0.175],
                    [0.025, 0.109],
                    [0.035, 0.166],
                    [0.021, 0.107],
                    [0.03, 0.168],
                    [0.016, 0.1],
                    [0.026, 0.188],
                    [0.01, 0.074],
                    [0.024, 0.255],
                    [0.004, 0.046],
                    [0.012, 0.205],
                    [0.004, 0.086],
                    [0.003, 0.164],
                    [0, 0.091],
                    [-0.004, 0.159],
                    [-0.003, 0.086],
                    [-0.011, 0.172],
                    [-0.005, 0.067],
                    [-0.03, 0.233],
                    [-0.006, 0.041],
                    [-0.034, 0.188],
                    [-0.02, 0.099],
                    [-0.031, 0.13],
                    [-0.031, 0.114],
                    [-0.033, 0.11],
                    [-0.077, 0.21],
                    [-0.008, 0.021],
                    [-0.112, 0.239],
                    [-0.087, 0.159],
                    [-0.158, 0.232],
                    [-0.076, 0.1],
                    [-0.129, 0.144],
                    [-0.082, 0.083],
                    [-0.154, 0.131],
                    [-0.075, 0.059],
                    [-0.252, 0.146],
                    [0, 0],
                    [0.234, -0.182],
                    [0.074, -0.063],
                    [0.144, -0.147],
                    [0.078, -0.088],
                    [0.12, -0.159],
                    [0.072, -0.106],
                    [0.047, -0.075],
                    [0.096, -0.178],
                    [0.067, -0.14],
                    [0.011, -0.025],
                    [0.095, -0.253],
                    [0.001, -0.001],
                    [0.007, -0.02],
                    [0.065, -0.219],
                    [0.005, -0.017],
                    [0.025, -0.095],
                    [0.027, -0.116],
                    [0.004, -0.019],
                    [0.022, -0.113],
                    [0.018, -0.1],
                    [0.002, -0.015],
                    [0.024, -0.178],
                    [0.005, -0.04],
                    [0.001, -0.004],
                    [0.018, -0.22],
                    [0.001, -0.014],
                    [0.005, -0.068],
                    [0.006, -0.137],
                    [0.001, -0.036],
                    [0.001, -0.086],
                    [0.001, -0.095],
                    [-0.001, -0.066],
                    [-0.002, -0.091],
                    [-0.001, -0.063],
                    [-0.005, -0.102],
                    [-0.005, -0.086],
                    [-0.002, -0.034],
                    [-0.015, -0.173],
                    [-0.004, -0.046],
                    [-0.001, -0.007],
                    [-0.03, -0.233],
                    [-0.002, -0.018],
                    [-0.011, -0.074],
                    [-0.023, -0.143],
                    [-0.007, -0.045],
                    [-0.017, -0.101],
                    [-0.018, -0.098],
                    [-0.014, -0.071],
                    [-0.023, -0.107],
                    [-0.016, -0.07],
                    [-0.022, -0.097],
                    [-0.026, -0.109],
                    [-0.012, -0.049],
                    [-0.034, -0.128],
                    [-0.027, -0.099],
                    [-0.008, -0.029],
                    [-0.055, -0.185],
                    [-0.019, -0.063],
                    [-0.003, -0.007],
                    [-0.084, -0.253],
                    [-0.005, -0.015],
                    [-0.024, -0.07],
                    [-0.058, -0.16],
                    [-0.016, -0.045],
                    [-0.034, -0.092],
                    [-0.04, -0.102],
                    [-0.033, -0.083],
                    [-0.037, -0.091],
                    [-0.024, -0.059],
                    [-0.061, -0.143],
                    [-0.031, -0.07],
                    [-0.009, -0.022],
                    [-0.11, -0.235],
                    [-0.005, -0.011],
                    [-0.035, -0.072],
                    [-0.085, -0.17],
                    [-0.016, -0.031],
                    [-0.067, -0.129],
                    [-0.063, -0.118],
                    [-0.014, -0.027],
                    [-0.223, -0.381],
                    [-0.016, -0.028],
                    [-0.02, -0.032],
                    [-0.366, -0.546],
                    [-0.021, -0.03],
                    [-0.033, -0.048],
                    [-0.253, -0.344],
                    [-0.015, -0.021],
                    [-0.005, -0.006],
                    [-0.097, -0.128],
                    [-0.105, -0.133],
                    [-0.006, -0.007],
                    [-0.059, -0.073],
                    [-0.136, -0.163],
                    [-0.014, -0.017],
                    [-0.067, -0.079],
                    [-0.115, -0.13],
                    [-0.055, -0.061],
                    [-0.145, -0.156],
                    [-0.069, -0.074],
                    [-0.081, -0.084],
                    [-0.054, -0.055],
                    [-0.114, -0.112],
                    [-0.075, -0.073],
                    [-0.025, -0.024],
                    [-0.21, -0.19],
                    [-0.009, -0.008],
                    [-0.001, -0.001],
                    [-0.222, -0.188],
                    [-0.023, -0.019],
                    [-0.076, -0.063],
                    [-0.121, -0.096],
                    [-0.05, -0.039],
                    [-0.087, -0.065],
                    [-0.079, -0.059],
                    [-0.086, -0.062],
                    [-0.081, -0.056],
                    [-0.044, -0.031],
                    [-0.157, -0.102],
                    [-0.039, -0.025],
                    [-0.009, -0.006],
                    [-0.202, -0.119],
                    [-0.046, -0.027],
                    [-0.152, -0.083],
                    [-0.049, -0.026],
                    [-0.081, -0.041],
                    [-0.067, -0.033],
                    [-0.077, -0.037],
                    [-0.078, -0.036],
                    [-0.04, -0.018],
                    [-0.114, -0.049],
                    [-0.063, -0.025],
                    [-0.017, -0.006],
                    [-0.194, -0.069],
                    [-0.004, -0.001],
                    [-0.031, -0.01],
                    [-0.162, -0.048],
                    [-0.022, -0.006],
                    [-0.076, -0.021],
                    [-0.1, -0.025],
                    [-0.036, -0.009],
                    [-0.089, -0.019],
                    [-0.076, -0.015],
                    [-0.046, -0.008],
                    [-0.098, -0.015],
                    [-0.063, -0.009],
                    [-0.05, -0.006],
                    [-0.106, -0.009],
                    [-0.058, -0.004],
                    [-0.046, -0.003],
                    [-0.12, -0.002],
                    [-0.059, 0],
                    [-0.035, 0],
                    [-0.147, 0.01],
                    [-0.056, 0.005],
                    [-0.018, 0.002],
                    [-0.234, 0.045],
                    [-0.011, 0.002],
                    [-0.022, 0.005],
                    [-0.271, 0.093],
                    [-0.023, 0.008],
                    [-0.013, 0.005],
                    [-0.122, 0.053],
                  ],
                  o: [
                    [0.182, -0.084],
                    [0, 0],
                    [-0.173, 0.101],
                    [-0.06, 0.028],
                    [-0.124, 0.053],
                    [-0.036, 0.013],
                    [-0.272, 0.092],
                    [-0.033, 0.007],
                    [-0.234, 0.046],
                    [-0.074, 0.007],
                    [-0.149, 0.011],
                    [-0.094, 0.002],
                    [-0.12, -0.002],
                    [-0.104, -0.006],
                    [-0.106, -0.01],
                    [-0.113, -0.014],
                    [-0.097, -0.015],
                    [-0.122, -0.022],
                    [-0.09, -0.019],
                    [-0.135, -0.032],
                    [-0.076, -0.021],
                    [-0.183, -0.055],
                    [-0.03, -0.011],
                    [-0.215, -0.075],
                    [-0.064, -0.026],
                    [-0.153, -0.065],
                    [-0.079, -0.036],
                    [-0.142, -0.068],
                    [-0.081, -0.042],
                    [-0.201, -0.106],
                    [-0.257, -0.149],
                    [-0.041, -0.026],
                    [-0.2, -0.129],
                    [-0.082, -0.057],
                    [-0.165, -0.118],
                    [-0.087, -0.066],
                    [-0.172, -0.133],
                    [-0.076, -0.062],
                    [-0.246, -0.206],
                    [-0.009, -0.009],
                    [-0.234, -0.212],
                    [-0.075, -0.072],
                    [-0.168, -0.166],
                    [-0.081, -0.084],
                    [-0.214, -0.227],
                    [-0.169, -0.189],
                    [-0.068, -0.08],
                    [-0.15, -0.179],
                    [-0.059, -0.075],
                    [-0.108, -0.137],
                    [-0.1, -0.131],
                    [-0.019, -0.026],
                    [-0.253, -0.344],
                    [-0.054, -0.078],
                    [-0.366, -0.546],
                    [-0.036, -0.06],
                    [-0.222, -0.381],
                    [-0.078, -0.145],
                    [-0.067, -0.13],
                    [-0.102, -0.202],
                    [-0.035, -0.071],
                    [-0.126, -0.268],
                    [-0.03, -0.069],
                    [-0.088, -0.203],
                    [-0.037, -0.091],
                    [-0.074, -0.185],
                    [-0.035, -0.093],
                    [-0.076, -0.205],
                    [-0.024, -0.07],
                    [-0.093, -0.275],
                    [-0.019, -0.063],
                    [-0.064, -0.214],
                    [-0.027, -0.1],
                    [-0.046, -0.176],
                    [-0.026, -0.11],
                    [-0.038, -0.167],
                    [-0.023, -0.107],
                    [-0.034, -0.168],
                    [-0.018, -0.101],
                    [-0.031, -0.189],
                    [-0.011, -0.074],
                    [-0.034, -0.259],
                    [-0.004, -0.047],
                    [-0.018, -0.209],
                    [-0.005, -0.086],
                    [-0.008, -0.166],
                    [-0.002, -0.092],
                    [-0.001, -0.16],
                    [0.001, -0.087],
                    [0.006, -0.174],
                    [0.005, -0.067],
                    [0.019, -0.239],
                    [0.005, -0.041],
                    [0.026, -0.193],
                    [0.017, -0.1],
                    [0.027, -0.132],
                    [0.027, -0.115],
                    [0.029, -0.112],
                    [0.065, -0.219],
                    [0.008, -0.021],
                    [0.095, -0.253],
                    [0.078, -0.166],
                    [0.14, -0.259],
                    [0.072, -0.105],
                    [0.12, -0.158],
                    [0.078, -0.088],
                    [0.144, -0.147],
                    [0.074, -0.062],
                    [0.234, -0.181],
                    [0, 0],
                    [-0.252, 0.147],
                    [-0.075, 0.058],
                    [-0.154, 0.131],
                    [-0.081, 0.083],
                    [-0.129, 0.145],
                    [-0.076, 0.099],
                    [-0.049, 0.072],
                    [-0.106, 0.165],
                    [-0.074, 0.135],
                    [-0.012, 0.025],
                    [-0.113, 0.239],
                    [0, 0.001],
                    [-0.007, 0.02],
                    [-0.077, 0.21],
                    [-0.005, 0.017],
                    [-0.027, 0.094],
                    [-0.03, 0.114],
                    [-0.005, 0.018],
                    [-0.026, 0.111],
                    [-0.021, 0.099],
                    [-0.003, 0.016],
                    [-0.031, 0.174],
                    [-0.006, 0.04],
                    [0, 0.004],
                    [-0.027, 0.215],
                    [-0.001, 0.014],
                    [-0.005, 0.067],
                    [-0.008, 0.135],
                    [-0.001, 0.037],
                    [-0.003, 0.086],
                    [-0.002, 0.095],
                    [0, 0.065],
                    [0, 0.09],
                    [0.001, 0.063],
                    [0.004, 0.102],
                    [0.004, 0.086],
                    [0.002, 0.035],
                    [0.011, 0.172],
                    [0.004, 0.046],
                    [0.001, 0.007],
                    [0.022, 0.23],
                    [0.002, 0.018],
                    [0.01, 0.074],
                    [0.019, 0.142],
                    [0.007, 0.045],
                    [0.016, 0.101],
                    [0.018, 0.098],
                    [0.013, 0.07],
                    [0.021, 0.106],
                    [0.015, 0.07],
                    [0.021, 0.097],
                    [0.025, 0.108],
                    [0.012, 0.049],
                    [0.032, 0.128],
                    [0.026, 0.099],
                    [0.008, 0.029],
                    [0.051, 0.185],
                    [0.019, 0.063],
                    [0.002, 0.007],
                    [0.077, 0.252],
                    [0.005, 0.015],
                    [0.023, 0.07],
                    [0.055, 0.16],
                    [0.016, 0.045],
                    [0.034, 0.092],
                    [0.039, 0.103],
                    [0.033, 0.083],
                    [0.037, 0.091],
                    [0.024, 0.059],
                    [0.06, 0.143],
                    [0.03, 0.069],
                    [0.009, 0.021],
                    [0.105, 0.235],
                    [0.006, 0.011],
                    [0.034, 0.072],
                    [0.081, 0.171],
                    [0.016, 0.032],
                    [0.065, 0.13],
                    [0.062, 0.119],
                    [0.014, 0.027],
                    [0.207, 0.385],
                    [0.016, 0.028],
                    [0.019, 0.032],
                    [0.332, 0.561],
                    [0.02, 0.03],
                    [0.033, 0.049],
                    [0.239, 0.351],
                    [0.016, 0.021],
                    [0.005, 0.006],
                    [0.095, 0.129],
                    [0.103, 0.134],
                    [0.005, 0.007],
                    [0.058, 0.074],
                    [0.133, 0.166],
                    [0.014, 0.017],
                    [0.066, 0.079],
                    [0.112, 0.132],
                    [0.054, 0.062],
                    [0.143, 0.161],
                    [0.069, 0.075],
                    [0.082, 0.086],
                    [0.054, 0.056],
                    [0.114, 0.116],
                    [0.075, 0.074],
                    [0.025, 0.024],
                    [0.208, 0.2],
                    [0.009, 0.008],
                    [0.001, 0.001],
                    [0.221, 0.199],
                    [0.022, 0.019],
                    [0.076, 0.064],
                    [0.122, 0.099],
                    [0.05, 0.039],
                    [0.087, 0.067],
                    [0.079, 0.06],
                    [0.086, 0.063],
                    [0.081, 0.058],
                    [0.044, 0.031],
                    [0.158, 0.107],
                    [0.039, 0.026],
                    [0.009, 0.006],
                    [0.203, 0.128],
                    [0.046, 0.027],
                    [0.153, 0.088],
                    [0.05, 0.027],
                    [0.081, 0.043],
                    [0.066, 0.034],
                    [0.077, 0.038],
                    [0.078, 0.037],
                    [0.04, 0.018],
                    [0.115, 0.051],
                    [0.063, 0.026],
                    [0.017, 0.007],
                    [0.196, 0.079],
                    [0.003, 0.001],
                    [0.03, 0.011],
                    [0.162, 0.056],
                    [0.022, 0.007],
                    [0.076, 0.023],
                    [0.101, 0.028],
                    [0.037, 0.01],
                    [0.09, 0.021],
                    [0.076, 0.017],
                    [0.047, 0.009],
                    [0.098, 0.018],
                    [0.063, 0.01],
                    [0.051, 0.006],
                    [0.108, 0.013],
                    [0.058, 0.005],
                    [0.047, 0.003],
                    [0.122, 0.006],
                    [0.059, 0.001],
                    [0.036, -0.001],
                    [0.151, -0.003],
                    [0.058, -0.004],
                    [0.018, -0.002],
                    [0.243, -0.023],
                    [0.011, -0.002],
                    [0.023, -0.004],
                    [0.286, -0.059],
                    [0.024, -0.007],
                    [0.013, -0.005],
                    [0.126, -0.045],
                    [0.062, -0.026],
                  ],
                  v: [
                    [11.23, 17.728],
                    [11.763, 17.451],
                    [13.087, 16.682],
                    [12.554, 16.959],
                    [12.37, 17.035],
                    [11.997, 17.186],
                    [11.888, 17.221],
                    [11.05, 17.449],
                    [10.952, 17.471],
                    [10.235, 17.574],
                    [10.012, 17.589],
                    [9.563, 17.61],
                    [9.28, 17.609],
                    [8.917, 17.597],
                    [8.604, 17.575],
                    [8.283, 17.542],
                    [7.942, 17.495],
                    [7.649, 17.446],
                    [7.282, 17.372],
                    [7.012, 17.312],
                    [6.603, 17.205],
                    [6.374, 17.142],
                    [5.822, 16.965],
                    [5.732, 16.935],
                    [5.085, 16.69],
                    [4.894, 16.609],
                    [4.432, 16.407],
                    [4.194, 16.295],
                    [3.767, 16.085],
                    [3.523, 15.958],
                    [2.918, 15.626],
                    [2.145, 15.155],
                    [2.022, 15.074],
                    [1.423, 14.672],
                    [1.177, 14.497],
                    [0.683, 14.134],
                    [0.423, 13.935],
                    [-0.091, 13.524],
                    [-0.319, 13.338],
                    [-1.055, 12.7],
                    [-1.084, 12.673],
                    [-1.785, 12.016],
                    [-2.009, 11.795],
                    [-2.513, 11.287],
                    [-2.757, 11.031],
                    [-3.398, 10.336],
                    [-3.896, 9.76],
                    [-4.1, 9.522],
                    [-4.541, 8.98],
                    [-4.72, 8.757],
                    [-5.038, 8.342],
                    [-5.337, 7.95],
                    [-5.394, 7.871],
                    [-6.132, 6.826],
                    [-6.292, 6.589],
                    [-7.339, 4.929],
                    [-7.448, 4.748],
                    [-8.092, 3.598],
                    [-8.321, 3.162],
                    [-8.521, 2.773],
                    [-8.817, 2.167],
                    [-8.921, 1.953],
                    [-9.288, 1.147],
                    [-9.376, 0.94],
                    [-9.632, 0.333],
                    [-9.742, 0.059],
                    [-9.957, -0.496],
                    [-10.062, -0.776],
                    [-10.28, -1.389],
                    [-10.353, -1.6],
                    [-10.618, -2.425],
                    [-10.673, -2.613],
                    [-10.858, -3.254],
                    [-10.937, -3.554],
                    [-11.071, -4.081],
                    [-11.147, -4.409],
                    [-11.257, -4.908],
                    [-11.322, -5.229],
                    [-11.416, -5.733],
                    [-11.468, -6.035],
                    [-11.552, -6.599],
                    [-11.584, -6.821],
                    [-11.671, -7.593],
                    [-11.682, -7.731],
                    [-11.728, -8.352],
                    [-11.74, -8.609],
                    [-11.757, -9.105],
                    [-11.76, -9.379],
                    [-11.756, -9.857],
                    [-11.75, -10.117],
                    [-11.723, -10.635],
                    [-11.71, -10.837],
                    [-11.638, -11.544],
                    [-11.62, -11.665],
                    [-11.532, -12.238],
                    [-11.473, -12.535],
                    [-11.39, -12.93],
                    [-11.301, -13.271],
                    [-11.211, -13.606],
                    [-10.998, -14.25],
                    [-10.976, -14.315],
                    [-10.664, -15.051],
                    [-10.42, -15.541],
                    [-9.966, -16.268],
                    [-9.745, -16.576],
                    [-9.371, -17.028],
                    [-9.135, -17.288],
                    [-8.685, -17.701],
                    [-8.466, -17.89],
                    [-7.739, -18.385],
                    [-9.063, -17.616],
                    [-9.79, -17.12],
                    [-10.009, -16.931],
                    [-10.459, -16.519],
                    [-10.695, -16.259],
                    [-11.069, -15.806],
                    [-11.29, -15.498],
                    [-11.441, -15.287],
                    [-11.744, -14.772],
                    [-11.955, -14.359],
                    [-11.987, -14.282],
                    [-12.3, -13.545],
                    [-12.302, -13.541],
                    [-12.321, -13.481],
                    [-12.535, -12.837],
                    [-12.551, -12.788],
                    [-12.625, -12.502],
                    [-12.714, -12.16],
                    [-12.728, -12.105],
                    [-12.796, -11.766],
                    [-12.856, -11.469],
                    [-12.865, -11.424],
                    [-12.944, -10.895],
                    [-12.962, -10.775],
                    [-12.964, -10.764],
                    [-13.031, -10.111],
                    [-13.034, -10.068],
                    [-13.047, -9.865],
                    [-13.072, -9.459],
                    [-13.074, -9.348],
                    [-13.08, -9.089],
                    [-13.087, -8.806],
                    [-13.084, -8.608],
                    [-13.081, -8.336],
                    [-13.079, -8.148],
                    [-13.064, -7.84],
                    [-13.052, -7.583],
                    [-13.047, -7.48],
                    [-13.006, -6.962],
                    [-12.995, -6.824],
                    [-12.993, -6.802],
                    [-12.916, -6.107],
                    [-12.908, -6.052],
                    [-12.876, -5.829],
                    [-12.815, -5.402],
                    [-12.792, -5.266],
                    [-12.74, -4.964],
                    [-12.689, -4.67],
                    [-12.646, -4.459],
                    [-12.581, -4.139],
                    [-12.538, -3.929],
                    [-12.471, -3.638],
                    [-12.395, -3.313],
                    [-12.361, -3.166],
                    [-12.26, -2.782],
                    [-12.182, -2.485],
                    [-12.159, -2.399],
                    [-11.997, -1.844],
                    [-11.942, -1.655],
                    [-11.935, -1.633],
                    [-11.693, -0.876],
                    [-11.677, -0.831],
                    [-11.604, -0.621],
                    [-11.436, -0.14],
                    [-11.386, -0.005],
                    [-11.282, 0.272],
                    [-11.166, 0.58],
                    [-11.066, 0.829],
                    [-10.956, 1.102],
                    [-10.885, 1.279],
                    [-10.701, 1.708],
                    [-10.611, 1.917],
                    [-10.584, 1.982],
                    [-10.262, 2.688],
                    [-10.245, 2.722],
                    [-10.141, 2.937],
                    [-9.894, 3.448],
                    [-9.845, 3.542],
                    [-9.645, 3.931],
                    [-9.461, 4.287],
                    [-9.416, 4.368],
                    [-8.771, 5.518],
                    [-8.724, 5.603],
                    [-8.663, 5.698],
                    [-7.616, 7.359],
                    [-7.558, 7.451],
                    [-7.456, 7.595],
                    [-6.72, 8.638],
                    [-6.675, 8.701],
                    [-6.66, 8.72],
                    [-6.369, 9.103],
                    [-6.061, 9.506],
                    [-6.044, 9.527],
                    [-5.867, 9.746],
                    [-5.466, 10.242],
                    [-5.423, 10.292],
                    [-5.222, 10.527],
                    [-4.885, 10.921],
                    [-4.722, 11.106],
                    [-4.29, 11.581],
                    [-4.081, 11.801],
                    [-3.837, 12.056],
                    [-3.675, 12.226],
                    [-3.333, 12.564],
                    [-3.109, 12.786],
                    [-3.034, 12.86],
                    [-2.407, 13.443],
                    [-2.379, 13.469],
                    [-2.376, 13.472],
                    [-1.711, 14.052],
                    [-1.643, 14.107],
                    [-1.415, 14.294],
                    [-1.051, 14.591],
                    [-0.901, 14.705],
                    [-0.641, 14.903],
                    [-0.403, 15.085],
                    [-0.145, 15.268],
                    [0.098, 15.44],
                    [0.23, 15.535],
                    [0.702, 15.846],
                    [0.82, 15.924],
                    [0.848, 15.943],
                    [1.455, 16.314],
                    [1.594, 16.395],
                    [2.051, 16.651],
                    [2.2, 16.728],
                    [2.442, 16.853],
                    [2.642, 16.957],
                    [2.873, 17.066],
                    [3.107, 17.176],
                    [3.227, 17.233],
                    [3.571, 17.379],
                    [3.761, 17.459],
                    [3.811, 17.48],
                    [4.397, 17.701],
                    [4.408, 17.704],
                    [4.499, 17.734],
                    [4.986, 17.893],
                    [5.051, 17.911],
                    [5.277, 17.974],
                    [5.579, 18.057],
                    [5.689, 18.082],
                    [5.957, 18.141],
                    [6.186, 18.193],
                    [6.325, 18.215],
                    [6.619, 18.264],
                    [6.81, 18.296],
                    [6.959, 18.311],
                    [7.28, 18.344],
                    [7.456, 18.362],
                    [7.593, 18.367],
                    [7.956, 18.379],
                    [8.134, 18.385],
                    [8.239, 18.38],
                    [8.686, 18.359],
                    [8.859, 18.351],
                    [8.912, 18.343],
                    [9.628, 18.241],
                    [9.661, 18.236],
                    [9.727, 18.218],
                    [10.563, 17.99],
                    [10.635, 17.971],
                    [10.673, 17.955],
                    [11.044, 17.805],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [333.734, 317.628], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 10",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.012, 0.093],
                    [0.024, 0.175],
                    [0.024, 0.164],
                    [0.025, 0.162],
                    [0.012, 0.074],
                    [0.017, 0.107],
                    [0.008, 0.053],
                    [0.041, 0.23],
                    [0.008, 0.049],
                    [0.032, 0.175],
                    [0.007, 0.037],
                    [0.004, 0.02],
                    [0.029, 0.15],
                    [0.009, 0.047],
                    [0.021, 0.108],
                    [0.021, 0.107],
                    [0, 0],
                    [-0.03, -0.155],
                    [-0.027, -0.138],
                    [-0.008, -0.038],
                    [-0.037, -0.2],
                    [-0.006, -0.036],
                    [-0.036, -0.201],
                    [-0.007, -0.045],
                    [-0.042, -0.256],
                    [-0.009, -0.054],
                    [-0.029, -0.189],
                    [-0.025, -0.169],
                    [-0.024, -0.169],
                    [-0.024, -0.181],
                    [-0.034, -0.284],
                    [0, 0],
                    [0.023, 0.181],
                  ],
                  o: [
                    [-0.024, -0.182],
                    [-0.024, -0.171],
                    [-0.025, -0.169],
                    [-0.012, -0.076],
                    [-0.017, -0.112],
                    [-0.009, -0.055],
                    [-0.042, -0.254],
                    [-0.009, -0.05],
                    [-0.035, -0.196],
                    [-0.007, -0.038],
                    [-0.004, -0.02],
                    [-0.032, -0.174],
                    [-0.01, -0.049],
                    [-0.025, -0.13],
                    [-0.032, -0.158],
                    [0, 0],
                    [0.021, 0.105],
                    [0.023, 0.114],
                    [0.007, 0.036],
                    [0.032, 0.168],
                    [0.007, 0.035],
                    [0.034, 0.179],
                    [0.007, 0.043],
                    [0.041, 0.23],
                    [0.008, 0.052],
                    [0.029, 0.178],
                    [0.025, 0.162],
                    [0.024, 0.163],
                    [0.024, 0.175],
                    [0.035, 0.271],
                    [0, 0],
                    [-0.022, -0.186],
                    [-0.012, -0.094],
                  ],
                  v: [
                    [1.051, 1.818],
                    [0.979, 1.284],
                    [0.908, 0.783],
                    [0.833, 0.288],
                    [0.798, 0.055],
                    [0.746, -0.268],
                    [0.72, -0.431],
                    [0.596, -1.157],
                    [0.571, -1.301],
                    [0.47, -1.858],
                    [0.449, -1.971],
                    [0.437, -2.034],
                    [0.345, -2.517],
                    [0.318, -2.653],
                    [0.248, -3.009],
                    [0.168, -3.412],
                    [-1.156, -2.642],
                    [-1.077, -2.243],
                    [-1.001, -1.86],
                    [-0.98, -1.754],
                    [-0.875, -1.2],
                    [-0.855, -1.091],
                    [-0.751, -0.521],
                    [-0.729, -0.392],
                    [-0.604, 0.339],
                    [-0.578, 0.501],
                    [-0.491, 1.057],
                    [-0.416, 1.553],
                    [-0.345, 2.053],
                    [-0.273, 2.586],
                    [-0.168, 3.413],
                    [1.156, 2.643],
                    [1.088, 2.091],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [347.858, 348.634], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 11",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.191, -0.113],
                    [-0.142, -0.086],
                    [-0.126, -0.078],
                    [-0.15, -0.095],
                    [-0.123, -0.079],
                    [-0.351, -0.235],
                    [0, 0],
                    [0.331, 0.212],
                    [0.034, 0.021],
                    [0.087, 0.055],
                    [0.146, 0.09],
                    [0.123, 0.075],
                    [0.138, 0.082],
                    [0.05, 0.029],
                    [0.136, 0.079],
                    [0.288, 0.161],
                    [0.277, 0.151],
                    [0, 0],
                    [-0.603, -0.349],
                  ],
                  o: [
                    [0.138, 0.082],
                    [0.123, 0.074],
                    [0.146, 0.09],
                    [0.121, 0.076],
                    [0.331, 0.211],
                    [0, 0],
                    [-0.352, -0.235],
                    [-0.034, -0.022],
                    [-0.089, -0.057],
                    [-0.15, -0.094],
                    [-0.126, -0.077],
                    [-0.142, -0.085],
                    [-0.05, -0.029],
                    [-0.139, -0.082],
                    [-0.302, -0.174],
                    [-0.288, -0.16],
                    [0, 0],
                    [0.554, 0.3],
                    [0.185, 0.107],
                  ],
                  v: [
                    [-0.826, 0.207],
                    [-0.406, 0.46],
                    [-0.033, 0.686],
                    [0.413, 0.965],
                    [0.778, 1.195],
                    [1.798, 1.861],
                    [3.122, 1.091],
                    [2.102, 0.426],
                    [1.997, 0.358],
                    [1.737, 0.195],
                    [1.291, -0.083],
                    [0.918, -0.31],
                    [0.497, -0.563],
                    [0.345, -0.654],
                    [-0.068, -0.895],
                    [-0.952, -1.396],
                    [-1.799, -1.861],
                    [-3.123, -1.09],
                    [-1.392, -0.124],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [312.237, 294.452], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 12",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.039, -0.311],
                    [0.012, -0.092],
                    [0.03, -0.206],
                    [0.016, -0.103],
                    [0.028, -0.166],
                    [0.017, -0.091],
                    [0.024, -0.126],
                    [0.015, -0.08],
                    [0.023, -0.111],
                    [0.013, -0.063],
                    [0.021, -0.099],
                    [0.01, -0.042],
                    [0.021, -0.095],
                    [0, 0],
                    [-0.028, 0.127],
                    [-0.01, 0.046],
                    [-0.025, 0.118],
                    [-0.013, 0.065],
                    [-0.024, 0.127],
                    [-0.016, 0.086],
                    [-0.024, 0.139],
                    [-0.009, 0.049],
                    [-0.008, 0.046],
                    [-0.029, 0.182],
                    [-0.016, 0.106],
                    [-0.03, 0.227],
                    [-0.009, 0.075],
                    [-0.002, 0.017],
                    [-0.034, 0.341],
                  ],
                  o: [
                    [-0.034, 0.34],
                    [-0.011, 0.095],
                    [-0.029, 0.223],
                    [-0.016, 0.107],
                    [-0.028, 0.179],
                    [-0.016, 0.097],
                    [-0.024, 0.138],
                    [-0.016, 0.086],
                    [-0.025, 0.124],
                    [-0.013, 0.067],
                    [-0.024, 0.114],
                    [-0.01, 0.046],
                    [-0.028, 0.126],
                    [0, 0],
                    [0.021, -0.096],
                    [0.009, -0.043],
                    [0.022, -0.101],
                    [0.013, -0.061],
                    [0.023, -0.114],
                    [0.016, -0.081],
                    [0.024, -0.127],
                    [0.009, -0.048],
                    [0.008, -0.045],
                    [0.028, -0.167],
                    [0.015, -0.102],
                    [0.031, -0.21],
                    [0.009, -0.073],
                    [0.002, -0.017],
                    [0.039, -0.312],
                    [0, 0],
                  ],
                  v: [
                    [-0.252, -2.119],
                    [-0.362, -1.144],
                    [-0.398, -0.869],
                    [-0.487, -0.222],
                    [-0.535, 0.091],
                    [-0.619, 0.611],
                    [-0.668, 0.895],
                    [-0.74, 1.29],
                    [-0.787, 1.543],
                    [-0.858, 1.893],
                    [-0.899, 2.095],
                    [-0.966, 2.41],
                    [-0.997, 2.551],
                    [-1.071, 2.888],
                    [0.253, 2.119],
                    [0.327, 1.783],
                    [0.357, 1.645],
                    [0.426, 1.322],
                    [0.465, 1.129],
                    [0.536, 0.773],
                    [0.584, 0.522],
                    [0.656, 0.125],
                    [0.682, -0.013],
                    [0.704, -0.158],
                    [0.79, -0.681],
                    [0.837, -0.989],
                    [0.927, -1.646],
                    [0.956, -1.858],
                    [0.962, -1.911],
                    [1.072, -2.888],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [339.27, 346.816], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 13",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.094, -0.011],
                    [-0.153, -0.021],
                    [-0.02, -0.003],
                    [-0.184, -0.029],
                    [-0.05, -0.008],
                    [-0.169, -0.03],
                    [-0.005, -0.001],
                    [-0.154, -0.031],
                    [-0.055, -0.011],
                    [-0.092, -0.02],
                    [-0.043, -0.01],
                    [-0.08, -0.018],
                    [-0.03, -0.007],
                    [-0.063, -0.014],
                    [0, 0],
                    [0.134, 0.03],
                    [0.092, 0.02],
                    [0.05, 0.011],
                    [0.102, 0.02],
                    [0.061, 0.012],
                    [0.034, 0.006],
                    [0.137, 0.025],
                    [0.005, 0.001],
                    [0.188, 0.031],
                    [0.051, 0.008],
                    [0.198, 0.028],
                    [0.023, 0.004],
                    [0.005, 0.001],
                    [0.148, 0.018],
                    [0.104, 0.012],
                    [0.279, 0.022],
                    [0, 0],
                    [-0.261, -0.029],
                  ],
                  o: [
                    [0.161, 0.019],
                    [0.021, 0.003],
                    [0.199, 0.027],
                    [0.052, 0.008],
                    [0.188, 0.031],
                    [0.005, 0.001],
                    [0.174, 0.032],
                    [0.058, 0.011],
                    [0.103, 0.021],
                    [0.046, 0.009],
                    [0.095, 0.02],
                    [0.033, 0.007],
                    [0.085, 0.019],
                    [0, 0],
                    [-0.091, -0.02],
                    [-0.078, -0.017],
                    [-0.046, -0.01],
                    [-0.09, -0.019],
                    [-0.057, -0.012],
                    [-0.032, -0.006],
                    [-0.125, -0.024],
                    [-0.005, -0.001],
                    [-0.17, -0.031],
                    [-0.05, -0.008],
                    [-0.183, -0.029],
                    [-0.023, -0.003],
                    [-0.006, 0],
                    [-0.142, -0.019],
                    [-0.101, -0.012],
                    [-0.261, -0.028],
                    [0, 0],
                    [0.279, 0.023],
                    [0.097, 0.01],
                  ],
                  v: [
                    [-1.868, 0.129],
                    [-1.39, 0.189],
                    [-1.33, 0.198],
                    [-0.756, 0.283],
                    [-0.604, 0.306],
                    [-0.065, 0.398],
                    [-0.05, 0.401],
                    [0.439, 0.496],
                    [0.607, 0.529],
                    [0.902, 0.591],
                    [1.039, 0.62],
                    [1.304, 0.677],
                    [1.406, 0.701],
                    [1.636, 0.751],
                    [2.96, -0.017],
                    [2.617, -0.095],
                    [2.364, -0.15],
                    [2.222, -0.181],
                    [1.935, -0.24],
                    [1.762, -0.275],
                    [1.668, -0.293],
                    [1.274, -0.367],
                    [1.259, -0.371],
                    [0.72, -0.462],
                    [0.569, -0.487],
                    [0, -0.572],
                    [-0.067, -0.582],
                    [-0.083, -0.583],
                    [-0.524, -0.638],
                    [-0.824, -0.674],
                    [-1.635, -0.751],
                    [-2.959, 0.017],
                    [-2.148, 0.095],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [311.087, 303.946], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 14",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.066, -0.103],
                    [0.042, -0.062],
                    [0.053, -0.077],
                    [0.045, -0.064],
                    [0.059, -0.082],
                    [0.047, -0.064],
                    [0.062, -0.082],
                    [0.048, -0.062],
                    [0.064, -0.081],
                    [0.047, -0.059],
                    [0.064, -0.078],
                    [0.045, -0.053],
                    [0.07, -0.08],
                    [0.034, -0.038],
                    [0.095, -0.097],
                    [0, 0],
                    [-0.104, 0.116],
                    [-0.035, 0.04],
                    [-0.073, 0.086],
                    [-0.045, 0.054],
                    [-0.066, 0.082],
                    [-0.048, 0.06],
                    [-0.063, 0.083],
                    [-0.048, 0.063],
                    [-0.061, 0.083],
                    [-0.046, 0.063],
                    [-0.057, 0.081],
                    [-0.043, 0.062],
                    [-0.05, 0.075],
                    [-0.039, 0.06],
                    [-0.025, 0.039],
                    [-0.036, 0.059],
                    [0, 0],
                  ],
                  o: [
                    [-0.039, 0.06],
                    [-0.05, 0.075],
                    [-0.043, 0.063],
                    [-0.057, 0.08],
                    [-0.046, 0.063],
                    [-0.061, 0.083],
                    [-0.048, 0.062],
                    [-0.064, 0.083],
                    [-0.048, 0.06],
                    [-0.065, 0.081],
                    [-0.046, 0.056],
                    [-0.073, 0.086],
                    [-0.035, 0.04],
                    [-0.103, 0.115],
                    [0, 0],
                    [0.095, -0.098],
                    [0.034, -0.038],
                    [0.07, -0.08],
                    [0.044, -0.053],
                    [0.066, -0.08],
                    [0.048, -0.059],
                    [0.064, -0.081],
                    [0.049, -0.063],
                    [0.062, -0.082],
                    [0.047, -0.063],
                    [0.059, -0.082],
                    [0.045, -0.064],
                    [0.053, -0.078],
                    [0.042, -0.062],
                    [0.026, -0.04],
                    [0.039, -0.061],
                    [0, 0],
                    [-0.058, 0.096],
                  ],
                  v: [
                    [0.407, -0.957],
                    [0.287, -0.773],
                    [0.132, -0.546],
                    [0.001, -0.357],
                    [-0.173, -0.113],
                    [-0.312, 0.077],
                    [-0.497, 0.324],
                    [-0.64, 0.51],
                    [-0.832, 0.755],
                    [-0.975, 0.936],
                    [-1.169, 1.173],
                    [-1.306, 1.339],
                    [-1.519, 1.583],
                    [-1.624, 1.706],
                    [-1.922, 2.026],
                    [-0.598, 1.258],
                    [-0.3, 0.936],
                    [-0.195, 0.815],
                    [0.018, 0.569],
                    [0.152, 0.406],
                    [0.349, 0.167],
                    [0.492, -0.013],
                    [0.683, -0.258],
                    [0.827, -0.447],
                    [1.012, -0.694],
                    [1.15, -0.883],
                    [1.325, -1.126],
                    [1.456, -1.314],
                    [1.611, -1.543],
                    [1.731, -1.725],
                    [1.81, -1.846],
                    [1.922, -2.026],
                    [0.598, -1.258],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [330.205, 340.991], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 15",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.011, -0.004],
                    [0.023, -0.008],
                    [0.231, -0.093],
                    [0.026, -0.011],
                    [0.219, -0.101],
                    [0.04, -0.019],
                    [0.202, -0.103],
                    [0.037, -0.018],
                    [0.202, -0.11],
                    [0, 0],
                    [-0.205, 0.106],
                    [-0.036, 0.018],
                    [-0.212, 0.099],
                    [-0.041, 0.019],
                    [-0.236, 0.097],
                    [-0.026, 0.01],
                    [-0.253, 0.09],
                    [-0.035, 0.012],
                    [-0.206, 0.064],
                    [0, 0],
                    [0.19, -0.066],
                  ],
                  o: [
                    [-0.023, 0.008],
                    [-0.253, 0.09],
                    [-0.026, 0.011],
                    [-0.237, 0.097],
                    [-0.041, 0.019],
                    [-0.212, 0.099],
                    [-0.036, 0.018],
                    [-0.205, 0.106],
                    [0, 0],
                    [0.202, -0.109],
                    [0.037, -0.019],
                    [0.202, -0.103],
                    [0.04, -0.019],
                    [0.219, -0.101],
                    [0.026, -0.011],
                    [0.231, -0.094],
                    [0.035, -0.012],
                    [0.19, -0.066],
                    [0, 0],
                    [-0.206, 0.064],
                    [-0.012, 0.004],
                  ],
                  v: [
                    [1.856, -0.964],
                    [1.79, -0.939],
                    [1.064, -0.665],
                    [0.984, -0.633],
                    [0.303, -0.336],
                    [0.181, -0.28],
                    [-0.44, 0.024],
                    [-0.549, 0.079],
                    [-1.16, 0.402],
                    [-2.484, 1.171],
                    [-1.873, 0.849],
                    [-1.764, 0.793],
                    [-1.143, 0.49],
                    [-1.021, 0.433],
                    [-0.34, 0.136],
                    [-0.26, 0.105],
                    [0.466, -0.17],
                    [0.568, -0.206],
                    [1.16, -0.401],
                    [2.484, -1.171],
                    [1.892, -0.976],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [313.264, 315.21], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 16",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-2.404, 4.431],
                    [7.233, 8.11],
                    [2.114, 1.22],
                    [2.404, -4.431],
                    [-7.233, -8.115],
                    [-2.114, -1.221],
                  ],
                  o: [
                    [3.353, -6.181],
                    [-2.048, -2.297],
                    [-5.355, -3.091],
                    [-3.353, 6.18],
                    [2.047, 2.297],
                    [5.354, 3.091],
                  ],
                  v: [
                    [13.04, 14.74],
                    [6.018, -11.138],
                    [-0.298, -16.427],
                    [-13.149, -14.639],
                    [-6.126, 11.239],
                    [0.19, 16.528],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.163, -2.294],
                    [1.548, 0.691],
                    [0.433, -1.49],
                    [-1.508, -2.306],
                    [0.619, -1],
                    [1.513, 1.334],
                    [0.719, -0.698],
                    [-0.787, -2.015],
                    [1.335, -0.591],
                    [1.48, 2.069],
                    [0.99, -0.073],
                    [-0.352, -2.977],
                    [1.229, 0.212],
                    [0.864, 1.99],
                    [0.896, 0.366],
                    [0.166, -0.755],
                    [0.756, 0.436],
                    [0.149, 0.091],
                    [0.347, 1.921],
                    [0.72, 0.571],
                    [0.775, -0.804],
                    [1.297, 1.38],
                    [-0.294, 1.951],
                    [0.678, 0.907],
                    [0.541, -0.111],
                    [0.379, 0.609],
                    [-0.728, 0.96],
                    [0.859, 1.808],
                    [1.904, 0.626],
                    [0.532, 1.555],
                    [-1.519, 0.471],
                    [0.126, 0.657],
                    [1.598, 1.198],
                    [0.037, 0.905],
                    [-0.632, -0.139],
                    [-0.042, 0.592],
                    [1.389, 1.676],
                    [-0.506, 1.747],
                    [-0.604, -0.348],
                    [-1.173, -0.785],
                    [-0.39, 0.599],
                    [-0.002, 0.003],
                    [0.856, 1.535],
                    [0.04, 0.078],
                    [-0.449, 0.426],
                    [-1.429, -1.58],
                    [-0.999, 0.44],
                    [0.602, 2.367],
                    [-1.406, 0.136],
                    [-1.323, -2.332],
                    [-0.937, -0.163],
                    [-0.069, 1.773],
                    [-1.076, -0.437],
                    [-0.453, -1.444],
                    [-0.655, -0.378],
                    [-0.11, -0.073],
                    [-0.612, 1.256],
                    [-2.002, -1.597],
                    [-0.032, -1.638],
                    [-1.317, -1.373],
                    [-1.74, 0.941],
                    [-0.634, -0.84],
                    [0.367, -1.007],
                    [-0.55, -0.884],
                    [-1.256, -0.132],
                    [-0.963, -2.019],
                    [1.613, -1.158],
                    [-0.481, -1.4],
                    [-2.41, -1.305],
                    [-0.154, -0.789],
                    [1.394, -0.01],
                    [-0.054, -0.836],
                    [-0.623, -0.594],
                  ],
                  o: [
                    [-1.796, -0.567],
                    [-0.101, 0.595],
                    [1.217, 1.627],
                    [-0.484, 1.129],
                    [-1.639, -1.213],
                    [-0.551, 0.75],
                    [1.067, 2.277],
                    [-0.654, 0.436],
                    [-1.369, -1.674],
                    [-0.509, 0.13],
                    [0.158, 0.8],
                    [-0.46, 0.024],
                    [-1.047, -2.018],
                    [-0.452, -0.095],
                    [-0.251, 2.519],
                    [-1.012, -0.47],
                    [-0.19, -0.11],
                    [-0.625, -2.395],
                    [-1.442, -0.985],
                    [-0.615, 1.011],
                    [-0.567, -0.521],
                    [0.069, -1.708],
                    [-0.467, -0.514],
                    [-1.816, 0.741],
                    [-1.197, -1.657],
                    [0.495, -1.053],
                    [-0.45, -0.822],
                    [-1.413, -0.268],
                    [-0.477, -1.099],
                    [1.063, -0.573],
                    [-0.334, -1.076],
                    [-1.567, -0.96],
                    [-0.181, -1.076],
                    [2.253, 0.183],
                    [-0.04, -1.394],
                    [-1.661, -1.751],
                    [0.171, -0.992],
                    [0.554, 0.301],
                    [0.969, 0.56],
                    [0.181, -0.406],
                    [0.061, -0.094],
                    [-0.044, -0.074],
                    [-0.837, -1.504],
                    [0.775, -1.048],
                    [1.765, 1.695],
                    [0.602, -0.407],
                    [-0.655, -1.975],
                    [0.814, -0.211],
                    [1.196, 1.836],
                    [0.613, -0.005],
                    [-0.123, -1.865],
                    [0.544, 0.117],
                    [1.105, 2.842],
                    [0.643, 0.299],
                    [0.176, 0.101],
                    [0.53, -1.677],
                    [0.672, 0.456],
                    [0.208, 1.579],
                    [0.655, 0.599],
                    [0.414, -0.341],
                    [0.87, 0.962],
                    [-0.474, 2.142],
                    [1.16, 1.645],
                    [1.157, -0.11],
                    [0.849, 1.549],
                    [-0.277, 0.296],
                    [0.195, 0.425],
                    [0.736, 0.306],
                    [0.413, 1.338],
                    [-1.319, 0.265],
                    [0.205, 1.19],
                    [2.369, 1.952],
                    [0.021, 1.162],
                  ],
                  v: [
                    [26.846, 19.071],
                    [21.998, 17.193],
                    [21.39, 19.865],
                    [25.375, 25.533],
                    [24.046, 28.189],
                    [19.204, 24.266],
                    [17.673, 25.945],
                    [20.342, 32.217],
                    [18.004, 33.508],
                    [13.823, 28.075],
                    [11.563, 28.496],
                    [12.551, 34.551],
                    [9.513, 34.279],
                    [6.551, 28.059],
                    [3.879, 27.202],
                    [3.06, 32.209],
                    [0.249, 30.741],
                    [-0.263, 30.438],
                    [-1.607, 24.27],
                    [-4.336, 22.239],
                    [-6.856, 25.523],
                    [-10.022, 22.452],
                    [-9.555, 17.187],
                    [-11.973, 14.245],
                    [-15.848, 15.371],
                    [-18.432, 11.45],
                    [-16.231, 7.779],
                    [-18.003, 4.34],
                    [-22.721, 3.139],
                    [-24.359, -1.115],
                    [-20.714, -2.687],
                    [-21.609, -6.167],
                    [-26.521, -9.513],
                    [-27.01, -13.532],
                    [-22.416, -12.798],
                    [-22.319, -15.852],
                    [-26.761, -20.897],
                    [-26.023, -24.134],
                    [-24.292, -23.168],
                    [-21.102, -21.182],
                    [-20.1, -23.226],
                    [-19.999, -23.379],
                    [-21.744, -26.389],
                    [-23.381, -29.45],
                    [-21.534, -31.48],
                    [-16.938, -26.715],
                    [-15.009, -27.782],
                    [-16.87, -34.06],
                    [-14.133, -34.574],
                    [-10.502, -28.645],
                    [-7.992, -28.42],
                    [-8.076, -34.045],
                    [-4.844, -33.007],
                    [-2.651, -26.707],
                    [-0.341, -25.507],
                    [0.095, -25.241],
                    [1.793, -29.455],
                    [5.094, -26.998],
                    [5.542, -21.181],
                    [8.153, -18.647],
                    [11.411, -20.884],
                    [14.332, -17.339],
                    [12.982, -12.781],
                    [15.115, -9.543],
                    [19.483, -9.511],
                    [21.63, -5.345],
                    [18.659, -2.684],
                    [20.011, 0.838],
                    [24.909, 3.156],
                    [25.991, 7.36],
                    [21.797, 7.782],
                    [22.204, 11.109],
                    [26.965, 15.376],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [335.138, 317.496], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 17",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.009, -3.371],
                    [0, 0],
                    [2.934, 1.694],
                    [0, 0],
                    [-0.009, 3.372],
                    [0, 0],
                    [-2.935, -1.694],
                  ],
                  o: [
                    [2.934, 1.694],
                    [0, 0],
                    [-0.01, 3.372],
                    [0, 0],
                    [-2.935, -1.694],
                    [0, 0],
                    [0.01, -3.371],
                    [0, 0],
                  ],
                  v: [
                    [50.932, -9.079],
                    [56.247, 0.123],
                    [56.065, 64.715],
                    [50.714, 67.764],
                    [-50.931, 9.079],
                    [-56.247, -0.128],
                    [-56.064, -64.72],
                    [-50.714, -67.764],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.0706, 0.6392, 0.7373] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [341.661, 319.061], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 18",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.003, 0.079],
                    [0.005, 0.086],
                    [0.003, 0.04],
                    [0.001, 0.016],
                    [0.003, 0.029],
                    [0.011, 0.087],
                    [0.014, 0.089],
                    [0, 0.005],
                    [0.001, 0.005],
                    [0.015, 0.08],
                    [0.021, 0.093],
                    [0.009, 0.036],
                    [0.004, 0.019],
                    [0.009, 0.038],
                    [0.025, 0.095],
                    [0.022, 0.073],
                    [0.003, 0.011],
                    [0.003, 0.009],
                    [0.031, 0.092],
                    [0.033, 0.091],
                    [0.007, 0.02],
                    [0.006, 0.015],
                    [0.02, 0.052],
                    [0.036, 0.087],
                    [0.028, 0.066],
                    [0.004, 0.01],
                    [0.005, 0.01],
                    [0.04, 0.086],
                    [0.047, 0.094],
                    [0.008, 0.016],
                    [0.01, 0.02],
                    [0.038, 0.071],
                    [0.052, 0.092],
                    [0.028, 0.048],
                    [0.01, 0.018],
                    [0.048, 0.077],
                    [0.075, 0.115],
                    [0.012, 0.018],
                    [0.112, 0.152],
                    [0, 0.005],
                    [0, 0],
                    [0.081, 0.102],
                    [0.064, 0.078],
                    [0.003, 0.003],
                    [0.001, 0.002],
                    [0.07, 0.079],
                    [0.07, 0.076],
                    [0.01, 0.011],
                    [0.012, 0.012],
                    [0.052, 0.053],
                    [0.076, 0.073],
                    [0.023, 0.021],
                    [0.017, 0.016],
                    [0.039, 0.035],
                    [0.079, 0.067],
                    [0.034, 0.028],
                    [0.017, 0.014],
                    [0.028, 0.022],
                    [0.078, 0.058],
                    [0.056, 0.039],
                    [0.01, 0.007],
                    [0.011, 0.007],
                    [0.075, 0.048],
                    [0.075, 0.044],
                    [0.017, 0.01],
                    [0, 0],
                    [0.056, 0.03],
                    [0.072, 0.035],
                    [0.048, 0.022],
                    [0.011, 0.005],
                    [0.013, 0.006],
                    [0.071, 0.028],
                    [0.071, 0.025],
                    [0.014, 0.005],
                    [0.012, 0.004],
                    [0.045, 0.013],
                    [0.072, 0.018],
                    [0.037, 0.008],
                    [0.015, 0.003],
                    [0.021, 0.004],
                    [0.077, 0.011],
                    [0.049, 0.005],
                    [0.015, 0.001],
                    [0.014, 0.001],
                    [0.083, -0.001],
                    [0.037, -0.002],
                    [0.027, -0.002],
                    [0.027, -0.003],
                    [0.101, -0.021],
                    [0.002, -0.001],
                    [0.028, -0.007],
                    [0.092, -0.033],
                    [0.009, -0.002],
                    [0.13, -0.075],
                    [0, 0],
                    [-0.143, 0.052],
                    [-0.106, 0.025],
                    [-0.028, 0.005],
                    [-0.136, 0.009],
                    [-0.028, 0.001],
                    [-0.138, -0.011],
                    [-0.016, -0.001],
                    [-0.151, -0.032],
                    [-0.015, -0.003],
                    [-0.159, -0.053],
                    [-0.011, -0.004],
                    [-0.17, -0.078],
                    [-0.011, -0.004],
                    [-0.178, -0.103],
                    [0, 0],
                    [-0.175, -0.121],
                    [-0.011, -0.008],
                    [-0.158, -0.128],
                    [-0.018, -0.014],
                    [-0.149, -0.138],
                    [-0.017, -0.017],
                    [-0.144, -0.153],
                    [-0.012, -0.012],
                    [-0.146, -0.176],
                    [-0.003, -0.003],
                    [-0.139, -0.189],
                    [-0.004, -0.006],
                    [-0.117, -0.177],
                    [-0.072, -0.116],
                    [-0.057, -0.097],
                    [-0.026, -0.046],
                    [-0.085, -0.167],
                    [-0.009, -0.019],
                    [-0.092, -0.209],
                    [-0.003, -0.008],
                    [-0.077, -0.207],
                    [-0.005, -0.015],
                    [-0.064, -0.212],
                    [-0.003, -0.01],
                    [-0.049, -0.206],
                    [0, 0],
                    [-0.033, -0.207],
                    [-0.001, -0.005],
                    [-0.018, -0.207],
                    [-0.001, -0.017],
                    [0, -0.202],
                    [0, 0],
                    [0.017, -0.186],
                    [0.002, -0.018],
                    [0.03, -0.158],
                    [0.004, -0.021],
                    [0.045, -0.14],
                    [0.009, -0.027],
                    [0.057, -0.123],
                    [0.004, -0.01],
                    [0.078, -0.12],
                    [0.134, -0.138],
                    [0.033, -0.032],
                    [0.163, -0.094],
                    [0, 0],
                    [-0.141, 0.131],
                    [-0.032, 0.033],
                    [-0.11, 0.171],
                    [-0.002, 0.004],
                    [-0.061, 0.129],
                    [-0.001, 0.004],
                    [-0.004, 0.009],
                    [-0.034, 0.092],
                    [-0.014, 0.041],
                    [-0.009, 0.027],
                    [-0.008, 0.027],
                    [-0.021, 0.093],
                    [-0.007, 0.032],
                    [-0.004, 0.021],
                    [-0.006, 0.035],
                    [-0.01, 0.086],
                    [-0.005, 0.047],
                    [-0.001, 0.017],
                    [-0.002, 0.021],
                    [-0.003, 0.084],
                    [-0.001, 0.07],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, -0.079],
                    [-0.002, -0.085],
                    [-0.003, -0.04],
                    [-0.002, -0.017],
                    [-0.003, -0.029],
                    [-0.008, -0.086],
                    [-0.011, -0.088],
                    [0, -0.004],
                    [-0.001, -0.005],
                    [-0.013, -0.079],
                    [-0.018, -0.092],
                    [-0.007, -0.036],
                    [-0.004, -0.019],
                    [-0.009, -0.038],
                    [-0.024, -0.094],
                    [-0.021, -0.073],
                    [-0.003, -0.011],
                    [-0.003, -0.01],
                    [-0.028, -0.092],
                    [-0.031, -0.09],
                    [-0.007, -0.019],
                    [-0.005, -0.015],
                    [-0.02, -0.053],
                    [-0.034, -0.087],
                    [-0.027, -0.066],
                    [-0.004, -0.01],
                    [-0.004, -0.01],
                    [-0.038, -0.086],
                    [-0.045, -0.094],
                    [-0.008, -0.016],
                    [-0.01, -0.019],
                    [-0.037, -0.072],
                    [-0.05, -0.093],
                    [-0.027, -0.048],
                    [-0.01, -0.017],
                    [-0.046, -0.078],
                    [-0.072, -0.117],
                    [-0.012, -0.018],
                    [-0.105, -0.158],
                    [-0.004, -0.006],
                    [0, 0],
                    [0, -0.105],
                    [-0.063, -0.081],
                    [-0.003, -0.004],
                    [-0.002, -0.002],
                    [-0.068, -0.081],
                    [-0.068, -0.078],
                    [-0.011, -0.011],
                    [-0.012, -0.013],
                    [-0.051, -0.054],
                    [-0.074, -0.075],
                    [-0.022, -0.021],
                    [-0.017, -0.016],
                    [-0.039, -0.036],
                    [-0.078, -0.07],
                    [-0.034, -0.028],
                    [-0.017, -0.014],
                    [-0.028, -0.023],
                    [-0.077, -0.061],
                    [-0.055, -0.04],
                    [-0.01, -0.008],
                    [-0.011, -0.007],
                    [-0.075, -0.051],
                    [-0.074, -0.047],
                    [-0.018, -0.01],
                    [0, 0],
                    [-0.056, -0.033],
                    [-0.072, -0.039],
                    [-0.048, -0.024],
                    [-0.01, -0.005],
                    [-0.013, -0.006],
                    [-0.071, -0.032],
                    [-0.071, -0.029],
                    [-0.013, -0.004],
                    [-0.012, -0.004],
                    [-0.046, -0.016],
                    [-0.074, -0.023],
                    [-0.038, -0.009],
                    [-0.015, -0.004],
                    [-0.021, -0.005],
                    [-0.078, -0.016],
                    [-0.05, -0.007],
                    [-0.015, -0.001],
                    [-0.014, -0.001],
                    [-0.085, -0.006],
                    [-0.038, 0],
                    [-0.028, 0.001],
                    [-0.028, 0.002],
                    [-0.105, 0.01],
                    [-0.002, 0],
                    [-0.028, 0.005],
                    [-0.097, 0.023],
                    [-0.008, 0.003],
                    [-0.143, 0.053],
                    [0, 0],
                    [0.13, -0.076],
                    [0.1, -0.037],
                    [0.027, -0.007],
                    [0.128, -0.026],
                    [0.028, -0.002],
                    [0.133, -0.005],
                    [0.016, 0.002],
                    [0.145, 0.014],
                    [0.015, 0.003],
                    [0.153, 0.034],
                    [0.011, 0.004],
                    [0.166, 0.057],
                    [0.011, 0.005],
                    [0.174, 0.081],
                    [0, 0],
                    [0.179, 0.104],
                    [0.011, 0.008],
                    [0.163, 0.113],
                    [0.018, 0.014],
                    [0.153, 0.126],
                    [0.017, 0.016],
                    [0.151, 0.142],
                    [0.012, 0.012],
                    [0.153, 0.164],
                    [0.003, 0.004],
                    [0.148, 0.179],
                    [0.004, 0.005],
                    [0.124, 0.17],
                    [0.075, 0.114],
                    [0.059, 0.096],
                    [0.026, 0.046],
                    [0.093, 0.164],
                    [0.01, 0.019],
                    [0.104, 0.205],
                    [0.003, 0.008],
                    [0.09, 0.205],
                    [0.005, 0.015],
                    [0.078, 0.211],
                    [0.003, 0.01],
                    [0.062, 0.206],
                    [0, 0],
                    [0.048, 0.209],
                    [0.001, 0.005],
                    [0.033, 0.21],
                    [0.001, 0.017],
                    [0.016, 0.207],
                    [0, 0],
                    [-0.001, 0.202],
                    [-0.002, 0.018],
                    [-0.017, 0.17],
                    [-0.004, 0.022],
                    [-0.032, 0.154],
                    [-0.008, 0.028],
                    [-0.046, 0.135],
                    [-0.004, 0.01],
                    [-0.064, 0.136],
                    [-0.109, 0.171],
                    [-0.032, 0.033],
                    [-0.141, 0.13],
                    [0, 0],
                    [0.163, -0.095],
                    [0.034, -0.031],
                    [0.135, -0.138],
                    [0.002, -0.003],
                    [0.073, -0.114],
                    [0.002, -0.003],
                    [0.004, -0.008],
                    [0.04, -0.086],
                    [0.015, -0.041],
                    [0.008, -0.027],
                    [0.008, -0.025],
                    [0.026, -0.087],
                    [0.008, -0.031],
                    [0.004, -0.02],
                    [0.007, -0.034],
                    [0.014, -0.082],
                    [0.006, -0.046],
                    [0.002, -0.017],
                    [0.002, -0.021],
                    [0.007, -0.082],
                    [0.003, -0.069],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [56.349, 64.271],
                    [56.349, 64.271],
                    [56.349, 64.256],
                    [56.497, -0.336],
                    [56.477, -0.573],
                    [56.456, -0.829],
                    [56.443, -0.95],
                    [56.436, -1],
                    [56.428, -1.086],
                    [56.398, -1.345],
                    [56.361, -1.611],
                    [56.358, -1.625],
                    [56.355, -1.64],
                    [56.314, -1.879],
                    [56.258, -2.156],
                    [56.232, -2.263],
                    [56.219, -2.321],
                    [56.193, -2.434],
                    [56.12, -2.717],
                    [56.055, -2.936],
                    [56.046, -2.969],
                    [56.036, -2.998],
                    [55.948, -3.274],
                    [55.853, -3.546],
                    [55.831, -3.604],
                    [55.814, -3.649],
                    [55.755, -3.807],
                    [55.65, -4.067],
                    [55.566, -4.264],
                    [55.553, -4.293],
                    [55.54, -4.323],
                    [55.423, -4.582],
                    [55.286, -4.864],
                    [55.262, -4.911],
                    [55.232, -4.969],
                    [55.121, -5.184],
                    [54.965, -5.461],
                    [54.885, -5.604],
                    [54.857, -5.656],
                    [54.712, -5.885],
                    [54.493, -6.235],
                    [54.458, -6.29],
                    [54.246, -6.755],
                    [54.349, -6.772],
                    [54.349, -6.774],
                    [53.995, -7.086],
                    [53.746, -7.324],
                    [53.708, -7.335],
                    [53.689, -7.341],
                    [53.475, -7.582],
                    [53.264, -7.813],
                    [53.232, -7.845],
                    [53.195, -7.882],
                    [53.042, -8.042],
                    [52.818, -8.264],
                    [52.75, -8.326],
                    [52.699, -8.374],
                    [52.583, -8.482],
                    [52.348, -8.688],
                    [52.245, -8.771],
                    [52.193, -8.813],
                    [52.111, -8.881],
                    [51.878, -9.059],
                    [51.711, -9.177],
                    [51.68, -9.2],
                    [51.647, -9.222],
                    [51.422, -9.371],
                    [51.199, -9.508],
                    [51.147, -9.538],
                    [-50.499, -68.223],
                    [-50.667, -68.317],
                    [-50.883, -68.429],
                    [-51.026, -68.497],
                    [-51.059, -68.512],
                    [-51.098, -68.53],
                    [-51.31, -68.62],
                    [-51.523, -68.701],
                    [-51.564, -68.714],
                    [-51.599, -68.725],
                    [-51.736, -68.77],
                    [-51.956, -68.831],
                    [-52.066, -68.855],
                    [-52.112, -68.865],
                    [-52.174, -68.879],
                    [-52.408, -68.918],
                    [-52.556, -68.933],
                    [-52.602, -68.938],
                    [-52.645, -68.942],
                    [-52.897, -68.95],
                    [-53.008, -68.945],
                    [-53.091, -68.941],
                    [-53.173, -68.937],
                    [-53.483, -68.892],
                    [-53.488, -68.89],
                    [-53.57, -68.868],
                    [-53.857, -68.789],
                    [-53.88, -68.779],
                    [-54.29, -68.589],
                    [-56.497, -67.306],
                    [-56.086, -67.496],
                    [-55.776, -67.586],
                    [-55.695, -67.608],
                    [-55.298, -67.659],
                    [-55.215, -67.663],
                    [-54.809, -67.656],
                    [-54.762, -67.651],
                    [-54.318, -67.583],
                    [-54.272, -67.573],
                    [-53.805, -67.443],
                    [-53.771, -67.432],
                    [-53.266, -67.23],
                    [-53.233, -67.215],
                    [-52.704, -66.941],
                    [48.94, -8.256],
                    [49.473, -7.918],
                    [49.505, -7.894],
                    [49.986, -7.531],
                    [50.039, -7.489],
                    [50.492, -7.092],
                    [50.544, -7.043],
                    [50.987, -6.6],
                    [51.023, -6.563],
                    [51.474, -6.053],
                    [51.482, -6.042],
                    [51.913, -5.49],
                    [51.926, -5.473],
                    [52.286, -4.953],
                    [52.504, -4.606],
                    [52.68, -4.32],
                    [52.758, -4.182],
                    [53.025, -3.686],
                    [53.055, -3.629],
                    [53.349, -3.009],
                    [53.358, -2.984],
                    [53.608, -2.366],
                    [53.624, -2.322],
                    [53.839, -1.686],
                    [53.848, -1.656],
                    [54.013, -1.038],
                    [54.026, -0.982],
                    [54.148, -0.358],
                    [54.151, -0.343],
                    [54.228, 0.282],
                    [54.232, 0.333],
                    [54.257, 0.947],
                    [54.073, 65.538],
                    [54.046, 66.119],
                    [54.039, 66.173],
                    [53.967, 66.666],
                    [53.954, 66.73],
                    [53.84, 67.17],
                    [53.814, 67.251],
                    [53.66, 67.639],
                    [53.648, 67.669],
                    [53.435, 68.052],
                    [53.065, 68.511],
                    [52.966, 68.607],
                    [52.513, 68.951],
                    [54.72, 67.669],
                    [55.172, 67.324],
                    [55.271, 67.229],
                    [55.642, 66.77],
                    [55.65, 66.761],
                    [55.85, 66.396],
                    [55.854, 66.386],
                    [55.866, 66.359],
                    [55.978, 66.093],
                    [56.021, 65.968],
                    [56.047, 65.889],
                    [56.072, 65.813],
                    [56.142, 65.542],
                    [56.161, 65.447],
                    [56.173, 65.385],
                    [56.194, 65.282],
                    [56.232, 65.03],
                    [56.246, 64.889],
                    [56.251, 64.838],
                    [56.258, 64.775],
                    [56.273, 64.526],
                    [56.279, 64.316],
                    [56.349, 64.271],
                    [56.349, 64.256],
                    [56.349, 64.301],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.0706, 0.6392, 0.702] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [343.651, 318.238], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 6,
    },
    {
      ty: 4,
      nm: "symbol_02",
      sr: 1,
      st: 0,
      op: 120,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [498, 517, 0] },
        s: {
          a: 1,
          k: [
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [0, 0, 100],
              t: 6,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [100, 100, 100],
              t: 14,
            },
            {
              o: { x: 0.167, y: 0 },
              i: { x: 0.833, y: 1 },
              s: [100, 100, 100],
              t: 88,
            },
            { s: [0, 0, 100], t: 94 },
          ],
        },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [498, 517, 0] },
        r: { a: 0, k: 0 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 1",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0.002, 0.08],
                    [0.006, 0.086],
                    [0.003, 0.041],
                    [0.002, 0.017],
                    [0.002, 0.028],
                    [0.011, 0.086],
                    [0.014, 0.089],
                    [0, 0.005],
                    [0.001, 0.005],
                    [0.015, 0.08],
                    [0.02, 0.093],
                    [0.009, 0.036],
                    [0.004, 0.019],
                    [0.009, 0.038],
                    [0.027, 0.094],
                    [0.022, 0.074],
                    [0.003, 0.01],
                    [0.003, 0.01],
                    [0.031, 0.092],
                    [0.033, 0.09],
                    [0.007, 0.019],
                    [0.006, 0.016],
                    [0.021, 0.052],
                    [0.036, 0.087],
                    [0.029, 0.065],
                    [0.005, 0.01],
                    [0.005, 0.01],
                    [0.04, 0.086],
                    [0.047, 0.094],
                    [0.008, 0.015],
                    [0.009, 0.019],
                    [0.038, 0.072],
                    [0.052, 0.092],
                    [0.028, 0.047],
                    [0.01, 0.018],
                    [0.046, 0.075],
                    [0.076, 0.116],
                    [0.013, 0.019],
                    [0.105, 0.144],
                    [0.01, 0.014],
                    [0, 0.001],
                    [0.081, 0.102],
                    [0.063, 0.076],
                    [0.004, 0.004],
                    [0.002, 0.003],
                    [0.069, 0.079],
                    [0.07, 0.075],
                    [0.01, 0.01],
                    [0.01, 0.011],
                    [0.053, 0.055],
                    [0.075, 0.073],
                    [0.022, 0.02],
                    [0.017, 0.015],
                    [0.04, 0.037],
                    [0.08, 0.066],
                    [0.033, 0.027],
                    [0.018, 0.014],
                    [0.028, 0.022],
                    [0.077, 0.057],
                    [0.055, 0.038],
                    [0.011, 0.007],
                    [0.011, 0.007],
                    [0.075, 0.048],
                    [0.075, 0.044],
                    [0.017, 0.01],
                    [0, 0],
                    [0.056, 0.031],
                    [0.072, 0.035],
                    [0.048, 0.022],
                    [0.01, 0.005],
                    [0.012, 0.006],
                    [0.07, 0.027],
                    [0.071, 0.025],
                    [0.014, 0.005],
                    [0.013, 0.004],
                    [0.044, 0.013],
                    [0.072, 0.018],
                    [0.037, 0.009],
                    [0.014, 0.002],
                    [0.021, 0.004],
                    [0.077, 0.01],
                    [0.05, 0.005],
                    [0.015, 0.001],
                    [0.013, 0.001],
                    [0.083, 0],
                    [0.037, -0.001],
                    [0.027, -0.002],
                    [0.027, -0.002],
                    [0.1, -0.02],
                    [0.003, 0],
                    [0.028, -0.007],
                    [0.092, -0.033],
                    [0.008, -0.003],
                    [0.13, -0.076],
                    [0, 0],
                    [-0.142, 0.052],
                    [-0.105, 0.025],
                    [-0.028, 0.005],
                    [-0.136, 0.009],
                    [-0.028, 0.001],
                    [-0.139, -0.011],
                    [-0.015, -0.001],
                    [-0.151, -0.032],
                    [-0.015, -0.004],
                    [-0.159, -0.053],
                    [-0.012, -0.004],
                    [-0.171, -0.077],
                    [-0.01, -0.005],
                    [-0.178, -0.102],
                    [0, 0],
                    [-0.175, -0.121],
                    [-0.011, -0.008],
                    [-0.158, -0.128],
                    [-0.018, -0.015],
                    [-0.149, -0.138],
                    [-0.016, -0.016],
                    [-0.145, -0.155],
                    [-0.011, -0.011],
                    [-0.146, -0.176],
                    [-0.004, -0.004],
                    [-0.139, -0.187],
                    [-0.008, -0.011],
                    [-0.113, -0.17],
                    [-0.072, -0.116],
                    [-0.056, -0.097],
                    [-0.027, -0.047],
                    [-0.085, -0.167],
                    [-0.009, -0.019],
                    [-0.091, -0.208],
                    [-0.005, -0.01],
                    [-0.076, -0.206],
                    [-0.005, -0.014],
                    [-0.065, -0.212],
                    [-0.003, -0.011],
                    [-0.048, -0.204],
                    [-0.005, -0.019],
                    [-0.033, -0.206],
                    [-0.001, -0.006],
                    [-0.018, -0.206],
                    [-0.001, -0.017],
                    [0, -0.202],
                    [0, 0],
                    [0.07, -0.496],
                    [0.019, -0.118],
                    [0.042, -0.133],
                    [0.009, -0.026],
                    [0.058, -0.123],
                    [0.005, -0.009],
                    [0.077, -0.119],
                    [0.134, -0.138],
                    [0.033, -0.032],
                    [0.164, -0.094],
                    [0, 0],
                    [-0.141, 0.13],
                    [-0.032, 0.033],
                    [-0.11, 0.171],
                    [-0.003, 0.004],
                    [-0.061, 0.129],
                    [-0.001, 0.002],
                    [-0.004, 0.009],
                    [-0.034, 0.091],
                    [-0.014, 0.041],
                    [-0.009, 0.027],
                    [-0.007, 0.026],
                    [-0.022, 0.093],
                    [-0.007, 0.031],
                    [-0.004, 0.021],
                    [-0.006, 0.035],
                    [-0.01, 0.085],
                    [-0.005, 0.046],
                    [-0.001, 0.018],
                    [-0.002, 0.022],
                    [-0.003, 0.084],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.001, -0.078],
                    [-0.002, -0.084],
                    [-0.002, -0.04],
                    [-0.001, -0.016],
                    [-0.002, -0.028],
                    [-0.009, -0.086],
                    [-0.011, -0.088],
                    [-0.001, -0.004],
                    [-0.001, -0.006],
                    [-0.013, -0.079],
                    [-0.017, -0.092],
                    [-0.008, -0.036],
                    [-0.004, -0.019],
                    [-0.009, -0.038],
                    [-0.024, -0.094],
                    [-0.02, -0.074],
                    [-0.003, -0.01],
                    [-0.003, -0.01],
                    [-0.028, -0.092],
                    [-0.031, -0.09],
                    [-0.007, -0.019],
                    [-0.005, -0.016],
                    [-0.02, -0.052],
                    [-0.034, -0.087],
                    [-0.027, -0.065],
                    [-0.004, -0.01],
                    [-0.004, -0.01],
                    [-0.038, -0.087],
                    [-0.045, -0.094],
                    [-0.008, -0.015],
                    [-0.01, -0.019],
                    [-0.037, -0.072],
                    [-0.05, -0.093],
                    [-0.027, -0.048],
                    [-0.01, -0.019],
                    [-0.046, -0.077],
                    [-0.073, -0.119],
                    [-0.012, -0.019],
                    [-0.1, -0.149],
                    [-0.01, -0.014],
                    [-0.001, -0.001],
                    [-0.078, -0.106],
                    [-0.061, -0.078],
                    [-0.004, -0.005],
                    [-0.002, -0.004],
                    [-0.068, -0.081],
                    [-0.069, -0.078],
                    [-0.009, -0.01],
                    [-0.01, -0.011],
                    [-0.052, -0.056],
                    [-0.074, -0.075],
                    [-0.022, -0.02],
                    [-0.016, -0.016],
                    [-0.04, -0.037],
                    [-0.078, -0.07],
                    [-0.032, -0.028],
                    [-0.018, -0.014],
                    [-0.028, -0.023],
                    [-0.077, -0.061],
                    [-0.055, -0.04],
                    [-0.011, -0.008],
                    [-0.011, -0.008],
                    [-0.074, -0.051],
                    [-0.074, -0.047],
                    [-0.017, -0.01],
                    [0, 0],
                    [-0.056, -0.032],
                    [-0.072, -0.039],
                    [-0.048, -0.024],
                    [-0.011, -0.005],
                    [-0.013, -0.006],
                    [-0.071, -0.032],
                    [-0.071, -0.029],
                    [-0.014, -0.005],
                    [-0.012, -0.004],
                    [-0.045, -0.015],
                    [-0.074, -0.022],
                    [-0.038, -0.009],
                    [-0.015, -0.003],
                    [-0.021, -0.005],
                    [-0.078, -0.015],
                    [-0.05, -0.007],
                    [-0.015, -0.001],
                    [-0.014, -0.002],
                    [-0.085, -0.005],
                    [-0.038, 0],
                    [-0.028, 0.001],
                    [-0.027, 0.002],
                    [-0.105, 0.01],
                    [-0.003, 0],
                    [-0.028, 0.006],
                    [-0.097, 0.022],
                    [-0.009, 0.003],
                    [-0.143, 0.052],
                    [0, 0],
                    [0.13, -0.075],
                    [0.1, -0.037],
                    [0.028, -0.007],
                    [0.128, -0.027],
                    [0.027, -0.002],
                    [0.132, -0.006],
                    [0.014, 0.001],
                    [0.145, 0.014],
                    [0.015, 0.003],
                    [0.153, 0.033],
                    [0.012, 0.004],
                    [0.166, 0.057],
                    [0.01, 0.005],
                    [0.174, 0.08],
                    [0, 0],
                    [0.179, 0.103],
                    [0.011, 0.008],
                    [0.162, 0.113],
                    [0.018, 0.014],
                    [0.153, 0.126],
                    [0.017, 0.016],
                    [0.152, 0.143],
                    [0.011, 0.011],
                    [0.154, 0.165],
                    [0.004, 0.005],
                    [0.147, 0.178],
                    [0.008, 0.011],
                    [0.12, 0.164],
                    [0.075, 0.114],
                    [0.059, 0.096],
                    [0.028, 0.047],
                    [0.092, 0.163],
                    [0.01, 0.019],
                    [0.103, 0.204],
                    [0.004, 0.01],
                    [0.089, 0.204],
                    [0.006, 0.014],
                    [0.078, 0.211],
                    [0.003, 0.011],
                    [0.061, 0.205],
                    [0.005, 0.019],
                    [0.048, 0.208],
                    [0.001, 0.007],
                    [0.034, 0.209],
                    [0.001, 0.017],
                    [0.016, 0.207],
                    [0, 0],
                    [0, 0],
                    [-0.033, 0.235],
                    [-0.031, 0.144],
                    [-0.008, 0.026],
                    [-0.045, 0.136],
                    [-0.004, 0.009],
                    [-0.064, 0.136],
                    [-0.109, 0.171],
                    [-0.033, 0.033],
                    [-0.142, 0.13],
                    [0, 0],
                    [0.163, -0.095],
                    [0.034, -0.032],
                    [0.135, -0.138],
                    [0.003, -0.003],
                    [0.072, -0.114],
                    [0.001, -0.003],
                    [0.005, -0.009],
                    [0.04, -0.086],
                    [0.015, -0.041],
                    [0.009, -0.026],
                    [0.008, -0.025],
                    [0.026, -0.088],
                    [0.007, -0.03],
                    [0.004, -0.02],
                    [0.007, -0.034],
                    [0.014, -0.083],
                    [0.006, -0.046],
                    [0.002, -0.018],
                    [0.002, -0.021],
                    [0.006, -0.081],
                    [0.004, -0.083],
                    [0, 0],
                  ],
                  v: [
                    [56.289, 64.032],
                    [56.474, -0.338],
                    [56.474, -0.575],
                    [56.462, -0.83],
                    [56.453, -0.952],
                    [56.449, -1.001],
                    [56.443, -1.086],
                    [56.414, -1.345],
                    [56.376, -1.611],
                    [56.375, -1.625],
                    [56.371, -1.641],
                    [56.331, -1.879],
                    [56.274, -2.156],
                    [56.248, -2.264],
                    [56.235, -2.321],
                    [56.209, -2.434],
                    [56.134, -2.716],
                    [56.07, -2.938],
                    [56.061, -2.968],
                    [56.052, -2.998],
                    [55.964, -3.274],
                    [55.87, -3.546],
                    [55.847, -3.603],
                    [55.831, -3.65],
                    [55.771, -3.808],
                    [55.667, -4.069],
                    [55.582, -4.263],
                    [55.569, -4.293],
                    [55.556, -4.324],
                    [55.439, -4.583],
                    [55.302, -4.865],
                    [55.279, -4.911],
                    [55.25, -4.968],
                    [55.138, -5.185],
                    [54.982, -5.461],
                    [54.901, -5.604],
                    [54.871, -5.659],
                    [54.729, -5.885],
                    [54.508, -6.239],
                    [54.471, -6.296],
                    [54.164, -6.734],
                    [54.134, -6.775],
                    [54.133, -6.779],
                    [53.894, -7.091],
                    [53.707, -7.322],
                    [53.695, -7.336],
                    [53.688, -7.346],
                    [53.482, -7.587],
                    [53.274, -7.817],
                    [53.245, -7.847],
                    [53.214, -7.88],
                    [53.056, -8.046],
                    [52.832, -8.268],
                    [52.767, -8.327],
                    [52.719, -8.373],
                    [52.599, -8.485],
                    [52.362, -8.69],
                    [52.264, -8.771],
                    [52.211, -8.814],
                    [52.128, -8.883],
                    [51.896, -9.06],
                    [51.732, -9.176],
                    [51.698, -9.2],
                    [51.665, -9.223],
                    [51.441, -9.371],
                    [51.219, -9.508],
                    [51.167, -9.538],
                    [-50.483, -68.225],
                    [-50.65, -68.319],
                    [-50.866, -68.43],
                    [-51.009, -68.498],
                    [-51.04, -68.513],
                    [-51.078, -68.531],
                    [-51.29, -68.62],
                    [-51.504, -68.701],
                    [-51.544, -68.714],
                    [-51.582, -68.726],
                    [-51.715, -68.77],
                    [-51.935, -68.831],
                    [-52.047, -68.856],
                    [-52.091, -68.865],
                    [-52.154, -68.879],
                    [-52.387, -68.917],
                    [-52.536, -68.933],
                    [-52.582, -68.937],
                    [-52.622, -68.942],
                    [-52.875, -68.95],
                    [-52.987, -68.945],
                    [-53.07, -68.941],
                    [-53.151, -68.937],
                    [-53.46, -68.892],
                    [-53.469, -68.89],
                    [-53.549, -68.867],
                    [-53.835, -68.789],
                    [-53.858, -68.778],
                    [-54.27, -68.588],
                    [-56.475, -67.306],
                    [-56.065, -67.495],
                    [-55.757, -67.585],
                    [-55.674, -67.607],
                    [-55.276, -67.658],
                    [-55.194, -67.662],
                    [-54.788, -67.655],
                    [-54.742, -67.651],
                    [-54.298, -67.583],
                    [-54.254, -67.573],
                    [-53.788, -67.444],
                    [-53.751, -67.432],
                    [-53.246, -67.231],
                    [-53.216, -67.216],
                    [-52.689, -66.943],
                    [48.961, -8.255],
                    [49.491, -7.918],
                    [49.524, -7.894],
                    [50.004, -7.532],
                    [50.058, -7.488],
                    [50.511, -7.092],
                    [50.561, -7.045],
                    [51.008, -6.598],
                    [51.039, -6.565],
                    [51.49, -6.054],
                    [51.501, -6.04],
                    [51.93, -5.492],
                    [51.952, -5.459],
                    [52.301, -4.956],
                    [52.519, -4.608],
                    [52.695, -4.321],
                    [52.776, -4.18],
                    [53.042, -3.686],
                    [53.071, -3.629],
                    [53.363, -3.011],
                    [53.376, -2.981],
                    [53.625, -2.365],
                    [53.641, -2.323],
                    [53.854, -1.687],
                    [53.865, -1.654],
                    [54.029, -1.039],
                    [54.042, -0.982],
                    [54.164, -0.361],
                    [54.167, -0.342],
                    [54.243, 0.281],
                    [54.247, 0.331],
                    [54.272, 0.945],
                    [54.09, 65.254],
                    [54.053, 66.235],
                    [53.975, 66.756],
                    [53.862, 67.172],
                    [53.832, 67.25],
                    [53.677, 67.64],
                    [53.664, 67.669],
                    [53.452, 68.051],
                    [53.082, 68.51],
                    [52.983, 68.606],
                    [52.529, 68.95],
                    [54.737, 67.668],
                    [55.189, 67.324],
                    [55.289, 67.228],
                    [55.659, 66.769],
                    [55.667, 66.76],
                    [55.867, 66.395],
                    [55.87, 66.387],
                    [55.883, 66.358],
                    [55.995, 66.093],
                    [56.036, 65.968],
                    [56.063, 65.889],
                    [56.087, 65.813],
                    [56.159, 65.542],
                    [56.177, 65.448],
                    [56.189, 65.386],
                    [56.211, 65.283],
                    [56.247, 65.031],
                    [56.263, 64.892],
                    [56.267, 64.839],
                    [56.274, 64.776],
                    [56.289, 64.407],
                    [56.292, 64.032],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.0706, 0.6392, 0.702] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [505.712, 480.968], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 2",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-11.613, -15.496],
                    [11.693, 8.174],
                    [11.671, 15.497],
                    [-11.693, 12.221],
                    [-11.673, 5.18],
                    [4.673, 7.789],
                    [-11.634, -8.509],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [519.373, 488.502], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 3",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-12.315, -11.451],
                    [-10.991, -12.22],
                    [12.315, 11.451],
                    [10.991, 12.22],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [520.074, 484.456], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 4",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-0.651, -3.276],
                    [0.673, -4.046],
                    [0.652, 3.276],
                    [-0.672, 4.046],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [531.717, 499.952], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 5",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [11.571, -19.895],
                    [-4.758, 23.83],
                    [-11.57, 19.897],
                    [4.755, -23.83],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [497.943, 480.036], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 6",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [7.503, -21.478],
                    [8.827, -22.247],
                    [-7.502, 21.478],
                    [-8.826, 22.247],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [502.011, 481.619], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 7",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-4.07, -1.583],
                    [-2.746, -2.352],
                    [4.07, 1.583],
                    [2.746, 2.352],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [506.768, 457.789], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 8",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-8.835, -0.92],
                    [-7.511, -1.689],
                    [8.835, 0.92],
                    [7.511, 1.689],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [516.536, 494.602], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 9",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [11.692, -12.222],
                    [11.672, -5.235],
                    [-4.674, -7.789],
                    [11.633, 8.454],
                    [11.613, 15.496],
                    [-11.692, -8.174],
                    [-11.671, -15.496],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [476.015, 473.65], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 10",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-12.344, -1.253],
                    [-11.02, -2.021],
                    [12.343, 1.253],
                    [11.019, 2.022],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [476.687, 459.406], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 11",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-0.652, -3.109],
                    [0.672, -3.878],
                    [0.652, 3.109],
                    [-0.672, 3.878],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [488.359, 464.537], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 12",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-0.652, -3.137],
                    [0.672, -3.906],
                    [0.652, 3.136],
                    [-0.672, 3.905],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [488.3, 485.241], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 13",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-8.815, -7.737],
                    [-7.492, -8.506],
                    [8.815, 7.737],
                    [7.491, 8.506],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [480.157, 473.598], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 14",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.009, -3.367],
                    [0, 0],
                    [2.93, 1.692],
                    [0, 0],
                    [-0.009, 3.368],
                    [0, 0],
                    [-2.931, -1.692],
                  ],
                  o: [
                    [2.93, 1.692],
                    [0, 0],
                    [-0.009, 3.367],
                    [0, 0],
                    [-2.931, -1.691],
                    [0, 0],
                    [0.01, -3.367],
                    [0, 0],
                  ],
                  v: [
                    [50.934, -9.078],
                    [56.245, 0.122],
                    [56.062, 64.718],
                    [50.716, 67.765],
                    [-50.933, 9.077],
                    [-56.245, -0.123],
                    [-56.062, -64.719],
                    [-50.715, -67.765],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.0706, 0.6392, 0.7373] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [503.739, 481.791], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 7,
    },
    {
      ty: 4,
      nm: "symbol_03",
      sr: 1,
      st: 0,
      op: 120,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [267, 468, 0] },
        s: {
          a: 1,
          k: [
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [0, 0, 100],
              t: 8,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [100, 100, 100],
              t: 16,
            },
            {
              o: { x: 0.167, y: 0 },
              i: { x: 0.833, y: 1 },
              s: [100, 100, 100],
              t: 86,
            },
            { s: [0, 0, 100], t: 92 },
          ],
        },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [267, 468, 0] },
        r: { a: 0, k: 0 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 1",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-1.682, -2.835],
                    [0.01, -3.514],
                    [0, 0],
                    [-0.406, -0.717],
                    [-0.739, -0.427],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.405, -0.254],
                    [0.003, -0.928],
                    [0, 0],
                    [1.692, -0.882],
                    [3.266, 1.885],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.654, 0.391],
                    [-0.004, 1.452],
                    [0, 0],
                    [-0.566, 0.686],
                    [-1.233, -0.265],
                    [0.557, 1.334],
                    [-0.006, 2.058],
                    [0, 0],
                    [0.646, 1.141],
                    [1.268, 0.732],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [3.266, 1.886],
                    [1.682, 2.83],
                    [0, 0],
                    [-0.003, 0.928],
                    [0.402, 0.721],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.739, -0.427],
                    [-0.409, 0.247],
                    [0, 0],
                    [-0.01, 3.509],
                    [-1.692, 0.882],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [1.268, 0.732],
                    [0.65, -0.393],
                    [0, 0],
                    [0.006, -2.058],
                    [0.563, -0.687],
                    [-1.23, -1.152],
                    [-0.56, -1.336],
                    [0, 0],
                    [0.004, -1.456],
                    [-0.649, -1.144],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-5.363, -28.649],
                    [2.056, -21.572],
                    [4.564, -12.059],
                    [4.533, -1.098],
                    [5.134, 1.37],
                    [6.843, 3.084],
                    [8.582, 4.088],
                    [8.561, 11.475],
                    [6.822, 10.471],
                    [5.109, 10.213],
                    [4.495, 11.98],
                    [4.464, 22.941],
                    [1.911, 29.532],
                    [-5.525, 28.028],
                    [-8.583, 26.262],
                    [-8.562, 18.875],
                    [-7.615, 19.422],
                    [-4.737, 19.933],
                    [-3.751, 17.166],
                    [-3.725, 7.478],
                    [-2.868, 3.362],
                    [-0.174, 2.731],
                    [-2.855, -0.997],
                    [-3.686, -6.087],
                    [-3.659, -15.775],
                    [-4.625, -19.668],
                    [-7.496, -22.48],
                    [-8.443, -23.028],
                    [-8.422, -30.415],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [281.147, 434.926], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 2",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.654, -0.391],
                    [0.004, -1.456],
                    [0, 0],
                    [0.567, -0.685],
                    [1.228, 0.267],
                    [-0.557, -1.334],
                    [0.006, -2.058],
                    [0, 0],
                    [-0.65, -1.143],
                    [-1.263, -0.73],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [1.678, 2.827],
                    [-0.01, 3.51],
                    [0, 0],
                    [0.402, 0.716],
                    [0.736, 0.425],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.405, 0.254],
                    [-0.003, 0.928],
                    [0, 0],
                    [-1.692, 0.882],
                    [-3.266, -1.885],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-1.264, -0.729],
                    [-0.654, 0.391],
                    [0, 0],
                    [-0.006, 2.058],
                    [-0.562, 0.688],
                    [1.226, 1.154],
                    [0.561, 1.336],
                    [0, 0],
                    [-0.004, 1.451],
                    [0.649, 1.143],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-3.266, -1.886],
                    [-1.681, -2.83],
                    [0, 0],
                    [0.002, -0.928],
                    [-0.402, -0.72],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.736, 0.425],
                    [0.405, -0.249],
                    [0, 0],
                    [0.01, -3.514],
                    [1.689, -0.888],
                    [0, 0],
                  ],
                  v: [
                    [8.583, -26.262],
                    [8.561, -18.874],
                    [7.61, -19.424],
                    [4.736, -19.928],
                    [3.751, -17.161],
                    [3.725, -7.473],
                    [2.867, -3.357],
                    [0.175, -2.73],
                    [2.855, 1.003],
                    [3.686, 6.092],
                    [3.658, 15.781],
                    [4.624, 19.674],
                    [7.492, 22.48],
                    [8.442, 23.029],
                    [8.421, 30.415],
                    [5.363, 28.65],
                    [-2.057, 21.578],
                    [-4.564, 12.064],
                    [-4.533, 1.104],
                    [-5.135, -1.365],
                    [-6.844, -3.083],
                    [-8.583, -4.087],
                    [-8.562, -11.474],
                    [-6.823, -10.47],
                    [-5.109, -10.208],
                    [-4.495, -11.975],
                    [-4.465, -22.935],
                    [-1.911, -29.527],
                    [5.524, -28.027],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [253.237, 424.477], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 3",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.161, 0.122],
                    [0.006, 0.004],
                    [0.139, 0.139],
                    [0.002, 0.003],
                    [0.119, 0.158],
                    [0, 0],
                    [0.1, 0.179],
                    [0.076, 0.191],
                    [0, 0.002],
                    [0.05, 0.204],
                    [0.001, 0.003],
                    [0.024, 0.213],
                    [0, 0.007],
                    [0, 0.228],
                    [0, 0],
                    [0.007, 0.232],
                    [0.009, 0.176],
                    [0.002, 0.033],
                    [0.001, 0.02],
                    [0.021, 0.213],
                    [0.02, 0.165],
                    [0.003, 0.02],
                    [0.002, 0.013],
                    [0.03, 0.188],
                    [0.034, 0.179],
                    [0.003, 0.017],
                    [0.004, 0.019],
                    [0.03, 0.136],
                    [0.041, 0.165],
                    [0.018, 0.07],
                    [0.009, 0.032],
                    [0.016, 0.058],
                    [0.046, 0.151],
                    [0.048, 0.143],
                    [0.006, 0.018],
                    [0.005, 0.014],
                    [0.037, 0.103],
                    [0.05, 0.128],
                    [0.05, 0.122],
                    [0.006, 0.015],
                    [0.004, 0.01],
                    [0.041, 0.093],
                    [0.055, 0.118],
                    [0.062, 0.124],
                    [0.01, 0.02],
                    [0.009, 0.018],
                    [0.055, 0.104],
                    [0.119, 0.204],
                    [0.022, 0.037],
                    [0.199, 0.293],
                    [0.022, 0.032],
                    [0.033, 0.048],
                    [0.089, 0.119],
                    [0.112, 0.142],
                    [0.022, 0.028],
                    [0.018, 0.022],
                    [0.065, 0.078],
                    [0.108, 0.123],
                    [0.084, 0.091],
                    [0.015, 0.016],
                    [0.016, 0.017],
                    [0.126, 0.128],
                    [0.14, 0.135],
                    [0.009, 0.01],
                    [0.015, 0.014],
                    [0.129, 0.117],
                    [0.171, 0.143],
                    [0.011, 0.009],
                    [0.019, 0.016],
                    [0.154, 0.121],
                    [0.171, 0.126],
                    [0.018, 0.014],
                    [0.009, 0.006],
                    [0.215, 0.148],
                    [0.108, 0.071],
                    [0.067, 0.043],
                    [0.056, 0.036],
                    [0.257, 0.151],
                    [0.061, 0.035],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.357, -0.232],
                    [-0.065, -0.042],
                    [-0.319, -0.235],
                    [-0.018, -0.014],
                    [-0.308, -0.257],
                    [-0.019, -0.016],
                    [-0.295, -0.28],
                    [-0.015, -0.014],
                    [-0.275, -0.299],
                    [-0.014, -0.016],
                    [-0.242, -0.301],
                    [-0.017, -0.021],
                    [-0.209, -0.298],
                    [-0.032, -0.048],
                    [-0.2, -0.337],
                    [-0.179, -0.353],
                    [-0.009, -0.018],
                    [-0.152, -0.361],
                    [-0.004, -0.01],
                    [-0.127, -0.372],
                    [-0.005, -0.014],
                    [-0.1, -0.375],
                    [-0.008, -0.032],
                    [-0.074, -0.377],
                    [-0.004, -0.019],
                    [-0.051, -0.404],
                    [-0.002, -0.02],
                    [-0.024, -0.404],
                    [-0.002, -0.032],
                    [0.001, -0.417],
                    [0, 0],
                    [-0.002, -0.067],
                    [-0.005, -0.066],
                    [-0.006, -0.06],
                    [-0.003, -0.026],
                    [-0.001, -0.007],
                    [-0.003, -0.022],
                    [-0.008, -0.052],
                    [-0.01, -0.049],
                    [-0.01, -0.047],
                    [-0.008, -0.035],
                    [-0.001, -0.003],
                    [-0.001, -0.006],
                    [-0.012, -0.042],
                    [-0.012, -0.04],
                    [-0.013, -0.037],
                    [-0.012, -0.035],
                    [-0.013, -0.032],
                    [-0.002, -0.003],
                    [0, -0.001],
                    [-0.011, -0.025],
                    [-0.013, -0.03],
                    [-0.014, -0.029],
                    [-0.015, -0.03],
                    [-0.019, -0.035],
                    [-0.019, -0.034],
                    [-0.015, -0.026],
                    [-0.056, -0.083],
                    [-0.036, -0.05],
                    [-0.006, -0.009],
                    [-0.002, -0.003],
                    [-0.018, -0.023],
                    [-0.025, -0.03],
                    [-0.025, -0.029],
                    [-0.027, -0.029],
                    [-0.029, -0.03],
                    [-0.007, -0.007],
                    [-0.003, -0.003],
                    [-0.021, -0.021],
                    [-0.034, -0.031],
                    [-0.037, -0.032],
                    [-0.04, -0.031],
                    [-0.018, -0.014],
                    [-0.005, -0.004],
                    [-0.018, -0.014],
                    [-0.045, -0.031],
                    [-0.048, -0.03],
                    [-0.051, -0.03],
                    [-0.012, -0.007],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [-0.182, -0.105],
                    [-0.005, -0.004],
                    [-0.159, -0.122],
                    [-0.003, -0.003],
                    [-0.14, -0.14],
                    [0, 0],
                    [-0.12, -0.159],
                    [-0.101, -0.178],
                    [0, -0.002],
                    [-0.075, -0.191],
                    [-0.001, -0.003],
                    [-0.049, -0.201],
                    [-0.001, -0.007],
                    [-0.024, -0.216],
                    [0, 0],
                    [0.001, -0.236],
                    [-0.005, -0.178],
                    [-0.002, -0.032],
                    [-0.001, -0.02],
                    [-0.014, -0.215],
                    [-0.016, -0.166],
                    [-0.002, -0.02],
                    [-0.001, -0.014],
                    [-0.024, -0.19],
                    [-0.028, -0.181],
                    [-0.003, -0.017],
                    [-0.004, -0.019],
                    [-0.026, -0.138],
                    [-0.036, -0.167],
                    [-0.017, -0.07],
                    [-0.008, -0.031],
                    [-0.015, -0.058],
                    [-0.042, -0.152],
                    [-0.044, -0.144],
                    [-0.006, -0.017],
                    [-0.005, -0.014],
                    [-0.035, -0.104],
                    [-0.047, -0.128],
                    [-0.047, -0.122],
                    [-0.006, -0.014],
                    [-0.004, -0.01],
                    [-0.039, -0.093],
                    [-0.053, -0.12],
                    [-0.059, -0.126],
                    [-0.009, -0.02],
                    [-0.009, -0.018],
                    [-0.054, -0.105],
                    [-0.111, -0.208],
                    [-0.022, -0.037],
                    [-0.181, -0.305],
                    [-0.022, -0.032],
                    [-0.032, -0.047],
                    [-0.086, -0.121],
                    [-0.107, -0.145],
                    [-0.022, -0.028],
                    [-0.018, -0.022],
                    [-0.063, -0.079],
                    [-0.103, -0.124],
                    [-0.081, -0.093],
                    [-0.014, -0.016],
                    [-0.015, -0.017],
                    [-0.122, -0.131],
                    [-0.135, -0.138],
                    [-0.01, -0.009],
                    [-0.015, -0.013],
                    [-0.126, -0.12],
                    [-0.165, -0.148],
                    [-0.01, -0.01],
                    [-0.019, -0.016],
                    [-0.149, -0.124],
                    [-0.165, -0.13],
                    [-0.018, -0.014],
                    [-0.01, -0.007],
                    [-0.208, -0.153],
                    [-0.107, -0.072],
                    [-0.066, -0.044],
                    [-0.055, -0.036],
                    [-0.247, -0.157],
                    [-0.061, -0.035],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.379, 0.219],
                    [0.066, 0.042],
                    [0.337, 0.224],
                    [0.018, 0.014],
                    [0.33, 0.245],
                    [0.019, 0.016],
                    [0.317, 0.267],
                    [0.015, 0.014],
                    [0.299, 0.285],
                    [0.015, 0.016],
                    [0.264, 0.288],
                    [0.017, 0.021],
                    [0.229, 0.287],
                    [0.033, 0.047],
                    [0.223, 0.323],
                    [0.204, 0.343],
                    [0.009, 0.018],
                    [0.177, 0.351],
                    [0.004, 0.011],
                    [0.152, 0.363],
                    [0.005, 0.014],
                    [0.124, 0.365],
                    [0.009, 0.032],
                    [0.096, 0.368],
                    [0.003, 0.019],
                    [0.075, 0.395],
                    [0.002, 0.02],
                    [0.048, 0.395],
                    [0.002, 0.032],
                    [0.022, 0.407],
                    [0, 0],
                    [0, 0.069],
                    [0.002, 0.067],
                    [0.003, 0.06],
                    [0.003, 0.026],
                    [0.001, 0.007],
                    [0.003, 0.023],
                    [0.006, 0.053],
                    [0.008, 0.05],
                    [0.008, 0.047],
                    [0.008, 0.036],
                    [0.001, 0.003],
                    [0.002, 0.006],
                    [0.011, 0.042],
                    [0.011, 0.04],
                    [0.012, 0.037],
                    [0.011, 0.035],
                    [0.012, 0.033],
                    [0.001, 0.004],
                    [0, 0.002],
                    [0.011, 0.026],
                    [0.012, 0.031],
                    [0.014, 0.03],
                    [0.015, 0.032],
                    [0.017, 0.035],
                    [0.018, 0.034],
                    [0.015, 0.026],
                    [0.051, 0.087],
                    [0.034, 0.051],
                    [0.006, 0.008],
                    [0.002, 0.003],
                    [0.018, 0.023],
                    [0.024, 0.03],
                    [0.025, 0.029],
                    [0.026, 0.03],
                    [0.028, 0.03],
                    [0.007, 0.007],
                    [0.002, 0.003],
                    [0.021, 0.02],
                    [0.033, 0.032],
                    [0.036, 0.032],
                    [0.038, 0.032],
                    [0.018, 0.014],
                    [0.006, 0.004],
                    [0.018, 0.013],
                    [0.043, 0.031],
                    [0.046, 0.032],
                    [0.049, 0.032],
                    [0.013, 0.007],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [7.425, 12.169],
                    [6.911, 11.829],
                    [6.895, 11.816],
                    [6.449, 11.427],
                    [6.441, 11.419],
                    [6.053, 10.971],
                    [6.046, 10.963],
                    [5.716, 10.456],
                    [5.451, 9.901],
                    [5.45, 9.896],
                    [5.263, 9.303],
                    [5.261, 9.296],
                    [5.152, 8.674],
                    [5.15, 8.652],
                    [5.114, 7.988],
                    [5.145, -2.973],
                    [5.136, -3.675],
                    [5.112, -4.206],
                    [5.107, -4.305],
                    [5.104, -4.365],
                    [5.053, -5.007],
                    [4.998, -5.503],
                    [4.991, -5.563],
                    [4.987, -5.603],
                    [4.906, -6.171],
                    [4.813, -6.711],
                    [4.803, -6.762],
                    [4.791, -6.818],
                    [4.708, -7.23],
                    [4.592, -7.728],
                    [4.537, -7.938],
                    [4.512, -8.033],
                    [4.467, -8.206],
                    [4.334, -8.66],
                    [4.196, -9.09],
                    [4.178, -9.143],
                    [4.163, -9.185],
                    [4.055, -9.496],
                    [3.91, -9.88],
                    [3.764, -10.245],
                    [3.745, -10.288],
                    [3.732, -10.319],
                    [3.612, -10.598],
                    [3.45, -10.956],
                    [3.268, -11.331],
                    [3.238, -11.389],
                    [3.211, -11.443],
                    [3.049, -11.757],
                    [2.704, -12.374],
                    [2.638, -12.486],
                    [2.069, -13.382],
                    [2.002, -13.477],
                    [1.902, -13.619],
                    [1.644, -13.982],
                    [1.316, -14.413],
                    [1.249, -14.495],
                    [1.196, -14.56],
                    [1.005, -14.796],
                    [0.688, -15.167],
                    [0.438, -15.443],
                    [0.395, -15.491],
                    [0.349, -15.543],
                    [-0.023, -15.932],
                    [-0.435, -16.34],
                    [-0.465, -16.368],
                    [-0.51, -16.409],
                    [-0.892, -16.766],
                    [-1.395, -17.202],
                    [-1.427, -17.229],
                    [-1.485, -17.276],
                    [-1.938, -17.645],
                    [-2.442, -18.029],
                    [-2.496, -18.072],
                    [-2.523, -18.091],
                    [-3.157, -18.542],
                    [-3.482, -18.757],
                    [-3.68, -18.887],
                    [-3.844, -18.995],
                    [-4.599, -19.457],
                    [-4.782, -19.563],
                    [-7.84, -21.329],
                    [-9.164, -20.56],
                    [-6.106, -18.794],
                    [-5.003, -18.116],
                    [-4.807, -17.988],
                    [-3.82, -17.302],
                    [-3.766, -17.26],
                    [-2.809, -16.507],
                    [-2.751, -16.46],
                    [-1.834, -15.641],
                    [-1.789, -15.598],
                    [-0.929, -14.723],
                    [-0.886, -14.674],
                    [-0.127, -13.79],
                    [-0.076, -13.727],
                    [0.578, -12.849],
                    [0.678, -12.707],
                    [1.314, -11.717],
                    [1.887, -10.673],
                    [1.914, -10.619],
                    [2.408, -9.55],
                    [2.421, -9.52],
                    [2.839, -8.417],
                    [2.854, -8.374],
                    [3.188, -7.263],
                    [3.213, -7.167],
                    [3.468, -6.049],
                    [3.479, -5.992],
                    [3.668, -4.793],
                    [3.674, -4.733],
                    [3.783, -3.534],
                    [3.788, -3.438],
                    [3.822, -2.203],
                    [3.79, 8.757],
                    [3.793, 8.962],
                    [3.803, 9.161],
                    [3.817, 9.342],
                    [3.826, 9.422],
                    [3.828, 9.443],
                    [3.836, 9.51],
                    [3.858, 9.668],
                    [3.884, 9.816],
                    [3.912, 9.958],
                    [3.937, 10.065],
                    [3.939, 10.074],
                    [3.943, 10.092],
                    [3.977, 10.219],
                    [4.011, 10.339],
                    [4.048, 10.451],
                    [4.084, 10.556],
                    [4.121, 10.654],
                    [4.126, 10.665],
                    [4.127, 10.669],
                    [4.159, 10.747],
                    [4.197, 10.838],
                    [4.238, 10.927],
                    [4.283, 11.02],
                    [4.337, 11.125],
                    [4.392, 11.226],
                    [4.436, 11.303],
                    [4.597, 11.557],
                    [4.703, 11.708],
                    [4.722, 11.733],
                    [4.729, 11.742],
                    [4.782, 11.812],
                    [4.856, 11.902],
                    [4.931, 11.99],
                    [5.01, 12.078],
                    [5.095, 12.167],
                    [5.117, 12.188],
                    [5.125, 12.197],
                    [5.188, 12.259],
                    [5.289, 12.353],
                    [5.399, 12.449],
                    [5.516, 12.544],
                    [5.571, 12.586],
                    [5.587, 12.598],
                    [5.64, 12.639],
                    [5.772, 12.732],
                    [5.913, 12.825],
                    [6.064, 12.918],
                    [6.101, 12.939],
                    [7.84, 13.943],
                    [7.819, 21.329],
                    [9.143, 20.561],
                    [9.164, 13.174],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [281.89, 425.072], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 4",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.009, -0.005],
                    [0.071, -0.09],
                    [0.012, -0.017],
                    [0.01, -0.014],
                    [0.024, -0.051],
                    [0.017, -0.047],
                    [0.004, -0.011],
                    [0.002, -0.006],
                    [0.009, -0.03],
                    [0.011, -0.048],
                    [0.008, -0.052],
                    [0.005, -0.039],
                    [0.001, -0.006],
                    [0.001, -0.01],
                    [0.005, -0.06],
                    [0.002, -0.066],
                    [0, -0.074],
                    [0, 0],
                    [0, 0],
                    [0.022, -0.356],
                    [0.004, -0.059],
                    [0.043, -0.305],
                    [0.008, -0.051],
                    [0.065, -0.272],
                    [0.01, -0.041],
                    [0.091, -0.247],
                    [0.001, -0.003],
                    [0.105, -0.204],
                    [0.041, -0.071],
                    [0.109, -0.145],
                    [0.019, -0.024],
                    [0.151, -0.139],
                    [0.036, -0.03],
                    [0.178, -0.104],
                    [0, 0],
                    [-0.158, 0.134],
                    [-0.034, 0.032],
                    [-0.132, 0.169],
                    [-0.018, 0.025],
                    [-0.062, 0.097],
                    [-0.037, 0.063],
                    [-0.038, 0.075],
                    [-0.024, 0.05],
                    [0, 0.178],
                    [0, 0],
                    [-0.001, 0.002],
                    [-0.05, 0.171],
                    [-0.025, 0.101],
                    [-0.01, 0.041],
                    [-0.007, 0.032],
                    [-0.032, 0.185],
                    [-0.012, 0.077],
                    [-0.008, 0.052],
                    [-0.007, 0.062],
                    [-0.017, 0.211],
                    [-0.004, 0.053],
                    [-0.004, 0.06],
                    [-0.004, 0.113],
                    [0, 0.252],
                    [0, 0],
                    [0, 0],
                    [-0.025, 0.188],
                    [-0.001, 0.006],
                    [-0.048, 0.143],
                    [-0.002, 0.005],
                    [-0.069, 0.098],
                    [-0.012, 0.016],
                    [-0.092, 0.055],
                    [-0.009, 0.005],
                    [0, 0],
                  ],
                  o: [
                    [-0.092, 0.056],
                    [-0.013, 0.017],
                    [-0.01, 0.014],
                    [-0.028, 0.045],
                    [-0.02, 0.042],
                    [-0.004, 0.01],
                    [-0.002, 0.005],
                    [-0.009, 0.028],
                    [-0.013, 0.045],
                    [-0.011, 0.048],
                    [-0.007, 0.036],
                    [-0.001, 0.005],
                    [-0.001, 0.01],
                    [-0.007, 0.057],
                    [-0.005, 0.062],
                    [-0.003, 0.07],
                    [0, 0],
                    [0, 0],
                    [-0.001, 0.388],
                    [-0.003, 0.061],
                    [-0.024, 0.334],
                    [-0.007, 0.052],
                    [-0.046, 0.302],
                    [-0.01, 0.042],
                    [-0.071, 0.279],
                    [-0.001, 0.003],
                    [-0.086, 0.233],
                    [-0.038, 0.075],
                    [-0.095, 0.166],
                    [-0.018, 0.024],
                    [-0.132, 0.169],
                    [-0.034, 0.032],
                    [-0.158, 0.135],
                    [0, 0],
                    [0.178, -0.103],
                    [0.036, -0.03],
                    [0.151, -0.139],
                    [0.019, -0.024],
                    [0.067, -0.09],
                    [0.038, -0.061],
                    [0.04, -0.071],
                    [0.025, -0.048],
                    [0.076, -0.161],
                    [0, 0],
                    [0, -0.002],
                    [0.058, -0.158],
                    [0.028, -0.096],
                    [0.011, -0.041],
                    [0.007, -0.031],
                    [0.04, -0.173],
                    [0.013, -0.076],
                    [0.007, -0.051],
                    [0.008, -0.062],
                    [0.025, -0.199],
                    [0.004, -0.052],
                    [0.004, -0.059],
                    [0.007, -0.11],
                    [0.01, -0.239],
                    [0, 0],
                    [0, 0],
                    [0.001, -0.229],
                    [0.001, -0.006],
                    [0.025, -0.182],
                    [0.002, -0.005],
                    [0.047, -0.136],
                    [0.012, -0.018],
                    [0.072, -0.091],
                    [0.009, -0.006],
                    [0, 0],
                    [-0.009, 0.005],
                  ],
                  v: [
                    [0.854, -9.229],
                    [0.612, -9.004],
                    [0.573, -8.954],
                    [0.543, -8.917],
                    [0.465, -8.773],
                    [0.409, -8.639],
                    [0.397, -8.607],
                    [0.392, -8.59],
                    [0.363, -8.504],
                    [0.328, -8.366],
                    [0.298, -8.216],
                    [0.281, -8.103],
                    [0.279, -8.086],
                    [0.275, -8.057],
                    [0.257, -7.883],
                    [0.423, -7.69],
                    [0.596, -7.474],
                    [0.596, -7.461],
                    [0.388, 3.499],
                    [0.26, 4.609],
                    [0.206, 4.79],
                    [0.082, 5.749],
                    [0.048, 5.902],
                    [-0.122, 6.766],
                    [-0.156, 6.888],
                    [-0.398, 7.681],
                    [-0.401, 7.689],
                    [-0.694, 8.337],
                    [-0.813, 8.555],
                    [-1.116, 9.026],
                    [-1.171, 9.1],
                    [-1.598, 9.559],
                    [-1.703, 9.651],
                    [-2.203, 10.013],
                    [-0.88, 9.243],
                    [-0.379, 8.882],
                    [-0.273, 8.789],
                    [0.154, 8.332],
                    [0.207, 8.255],
                    [0.405, 7.979],
                    [0.512, 7.785],
                    [0.63, 7.569],
                    [0.546, 7.428],
                    [0.596, 6.921],
                    [0.596, 6.918],
                    [0.762, 6.911],
                    [1.005, 6.418],
                    [1.124, 6.117],
                    [1.176, 5.996],
                    [1.21, 5.905],
                    [1.322, 5.367],
                    [1.359, 5.134],
                    [1.384, 4.978],
                    [1.41, 4.797],
                    [1.474, 4.181],
                    [1.484, 4.021],
                    [1.496, 3.841],
                    [1.547, 3.512],
                    [1.596, 2.775],
                    [1.596, 2.729],
                    [1.595, -8.23],
                    [1.618, -8.855],
                    [1.613, -8.872],
                    [1.72, -9.359],
                    [1.724, -9.375],
                    [1.898, -9.724],
                    [1.935, -9.772],
                    [2.177, -9.997],
                    [2.203, -10.013],
                    [0.879, -9.245],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [285.403, 454.368], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 5",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.097, 0.013],
                    [0.067, 0.006],
                    [0.014, 0.001],
                    [0.012, 0],
                    [0.089, 0],
                    [0.077, -0.006],
                    [0.007, 0],
                    [0, 0],
                    [0.089, -0.018],
                    [0.051, -0.014],
                    [0.03, -0.009],
                    [0.02, -0.008],
                    [0.107, -0.062],
                    [0, 0],
                    [-0.145, 0.046],
                    [-0.031, 0.009],
                    [-0.157, 0],
                    [0, 0],
                    [-0.192, -0.015],
                    [-0.014, -0.001],
                    [-0.216, -0.046],
                    [0, 0],
                    [0.037, 0.007],
                  ],
                  o: [
                    [-0.07, -0.01],
                    [-0.014, -0.002],
                    [-0.011, -0.001],
                    [-0.093, -0.007],
                    [-0.081, 0],
                    [-0.007, 0.001],
                    [0, 0],
                    [-0.096, 0],
                    [-0.053, 0.011],
                    [-0.03, 0.008],
                    [-0.021, 0.007],
                    [-0.122, 0.042],
                    [0, 0],
                    [0.126, -0.073],
                    [0.03, -0.009],
                    [0.14, -0.037],
                    [0, 0],
                    [0.172, 0],
                    [0.014, 0.001],
                    [0.195, 0.017],
                    [0, 0],
                    [-0.038, -0.008],
                    [-0.102, -0.02],
                  ],
                  v: [
                    [1.336, -0.573],
                    [1.131, -0.594],
                    [1.089, -0.598],
                    [1.053, -0.602],
                    [0.78, -0.611],
                    [0.543, -0.495],
                    [0.522, -0.388],
                    [0.509, -0.388],
                    [0.231, -0.454],
                    [0.078, -0.465],
                    [-0.014, -0.467],
                    [-0.079, -0.462],
                    [-0.422, -0.313],
                    [-1.746, 0.453],
                    [-1.338, 0.277],
                    [-1.245, 0.473],
                    [-0.802, 0.612],
                    [-0.782, 0.612],
                    [-0.235, 0.393],
                    [-0.193, 0.286],
                    [0.422, 0.324],
                    [1.746, -0.473],
                    [1.634, -0.509],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [280.55, 437.389], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 6",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.007, -0.002],
                    [0.084, -0.013],
                    [0.016, -0.002],
                    [0.095, 0.002],
                    [0.013, 0.001],
                    [0.107, 0.018],
                    [0.01, 0.001],
                    [0.123, 0.037],
                    [0.003, 0.001],
                    [0.136, 0.055],
                    [0.007, 0.003],
                    [0.142, 0.071],
                    [0.006, 0.003],
                    [0.154, 0.089],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.071, -0.038],
                    [-0.075, -0.038],
                    [-0.005, -0.003],
                    [-0.003, -0.002],
                    [-0.076, -0.034],
                    [-0.057, -0.024],
                    [-0.008, -0.004],
                    [-0.004, -0.002],
                    [-0.064, -0.022],
                    [-0.06, -0.018],
                    [-0.002, -0.001],
                    [-0.003, -0.001],
                    [-0.051, -0.013],
                    [-0.054, -0.01],
                    [-0.012, -0.002],
                    [-0.011, -0.002],
                    [-0.031, -0.004],
                    [-0.05, -0.004],
                    [-0.02, -0.001],
                    [-0.012, 0],
                    [-0.019, 0],
                    [-0.052, 0.004],
                    [-0.018, 0.002],
                    [-0.016, 0.002],
                    [-0.023, 0.005],
                    [-0.057, 0.019],
                    [-0.007, 0.003],
                    [-0.002, 0.001],
                    [-0.079, 0.047],
                    [0, 0],
                    [0.09, -0.031],
                  ],
                  o: [
                    [-0.078, 0.026],
                    [-0.017, 0.003],
                    [-0.089, 0.01],
                    [-0.012, -0.001],
                    [-0.099, -0.004],
                    [-0.01, -0.002],
                    [-0.114, -0.021],
                    [-0.002, -0.001],
                    [-0.127, -0.039],
                    [-0.007, -0.003],
                    [-0.133, -0.055],
                    [-0.006, -0.003],
                    [-0.144, -0.072],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.073, 0.042],
                    [0.078, 0.043],
                    [0.006, 0.002],
                    [0.003, 0.001],
                    [0.078, 0.038],
                    [0.058, 0.026],
                    [0.008, 0.003],
                    [0.004, 0.001],
                    [0.066, 0.027],
                    [0.061, 0.022],
                    [0.001, 0],
                    [0.003, 0.001],
                    [0.052, 0.016],
                    [0.056, 0.014],
                    [0.012, 0.003],
                    [0.01, 0.002],
                    [0.031, 0.005],
                    [0.053, 0.008],
                    [0.021, 0.001],
                    [0.013, 0],
                    [0.02, 0],
                    [0.055, 0],
                    [0.019, -0.002],
                    [0.016, -0.002],
                    [0.023, -0.004],
                    [0.06, -0.012],
                    [0.007, -0.002],
                    [0.002, 0],
                    [0.088, -0.031],
                    [0, 0],
                    [-0.081, 0.049],
                    [-0.007, 0.002],
                  ],
                  v: [
                    [2.297, 0.167],
                    [2.052, 0.224],
                    [2.003, 0.232],
                    [1.728, 0.245],
                    [1.691, 0.243],
                    [1.383, 0.21],
                    [1.353, 0.205],
                    [0.997, 0.119],
                    [0.99, 0.117],
                    [0.595, -0.023],
                    [0.573, -0.032],
                    [0.161, -0.219],
                    [0.143, -0.228],
                    [-0.303, -0.469],
                    [-1.251, -1.016],
                    [-2.574, -0.246],
                    [-1.626, 0.301],
                    [-1.41, 0.422],
                    [-1.18, 0.542],
                    [-1.163, 0.551],
                    [-1.155, 0.555],
                    [-0.923, 0.663],
                    [-0.751, 0.737],
                    [-0.728, 0.747],
                    [-0.715, 0.752],
                    [-0.521, 0.825],
                    [-0.34, 0.885],
                    [-0.335, 0.886],
                    [-0.326, 0.888],
                    [-0.169, 0.932],
                    [-0.005, 0.968],
                    [0.029, 0.974],
                    [0.059, 0.979],
                    [0.152, 0.994],
                    [0.307, 1.011],
                    [0.367, 1.013],
                    [0.404, 1.014],
                    [0.464, 1.016],
                    [0.624, 1.009],
                    [0.68, 1.001],
                    [0.728, 0.994],
                    [0.797, 0.984],
                    [0.973, 0.936],
                    [0.995, 0.93],
                    [1, 0.929],
                    [1.25, 0.812],
                    [2.574, 0.042],
                    [2.318, 0.161],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [275.16, 454.048], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 7",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-0.672, 4.078],
                    [0.651, 3.309],
                    [0.672, -4.078],
                    [-0.651, -3.309],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [262.471, 401.524], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 8",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.061, 0.016],
                    [-0.215, 0.021],
                    [-0.009, 0.001],
                    [-0.278, 0],
                    [0, 0],
                    [-0.302, -0.06],
                    [-0.016, -0.003],
                    [-0.309, -0.097],
                    [-0.032, -0.01],
                    [-0.32, -0.131],
                    [-0.043, -0.019],
                    [-0.343, -0.172],
                    [-0.041, -0.02],
                    [-0.378, -0.218],
                    [0, 0],
                    [0.195, 0.105],
                    [0.168, 0.085],
                    [0.041, 0.02],
                    [0.021, 0.01],
                    [0.203, 0.091],
                    [0.104, 0.044],
                    [0.043, 0.018],
                    [0.039, 0.016],
                    [0.171, 0.06],
                    [0.096, 0.032],
                    [0.032, 0.01],
                    [0.031, 0.01],
                    [0.149, 0.038],
                    [0.114, 0.024],
                    [0.016, 0.004],
                    [0.012, 0.002],
                    [0.136, 0.018],
                    [0.134, 0],
                    [0, 0],
                    [0, 0],
                    [0.123, 0],
                    [0.134, 0],
                    [0, 0],
                    [0.009, -0.001],
                    [0.133, -0.027],
                    [0.069, -0.018],
                    [0.06, -0.019],
                    [0.041, -0.014],
                    [0.15, -0.079],
                    [0.046, -0.027],
                    [0, 0],
                    [-0.048, 0.024],
                    [-0.207, 0.065],
                  ],
                  o: [
                    [0.198, -0.051],
                    [0.009, -0.001],
                    [0.254, -0.023],
                    [0, 0],
                    [0.279, 0],
                    [0.016, 0.003],
                    [0.288, 0.06],
                    [0.032, 0.01],
                    [0.301, 0.097],
                    [0.044, 0.018],
                    [0.324, 0.136],
                    [0.041, 0.02],
                    [0.356, 0.181],
                    [0, 0],
                    [-0.201, -0.116],
                    [-0.171, -0.093],
                    [-0.041, -0.021],
                    [-0.021, -0.011],
                    [-0.212, -0.105],
                    [-0.107, -0.048],
                    [-0.044, -0.018],
                    [-0.039, -0.016],
                    [-0.176, -0.07],
                    [-0.098, -0.035],
                    [-0.033, -0.01],
                    [-0.032, -0.01],
                    [-0.154, -0.047],
                    [-0.117, -0.029],
                    [-0.016, -0.003],
                    [-0.012, -0.002],
                    [-0.142, -0.028],
                    [-0.139, -0.019],
                    [0, 0],
                    [0, 0],
                    [-0.128, 0],
                    [-0.141, 0.001],
                    [0, 0],
                    [-0.009, 0],
                    [-0.141, 0.014],
                    [-0.071, 0.013],
                    [-0.061, 0.015],
                    [-0.041, 0.013],
                    [-0.163, 0.058],
                    [-0.048, 0.025],
                    [0, 0],
                    [0.046, -0.027],
                    [0.188, -0.099],
                    [0.06, -0.018],
                  ],
                  v: [
                    [-3.53, -0.488],
                    [-2.913, -0.602],
                    [-2.888, -0.874],
                    [-2.089, -1.15],
                    [-2.07, -1.15],
                    [-1.199, -0.761],
                    [-1.149, -0.616],
                    [-0.253, -0.314],
                    [-0.156, -0.249],
                    [0.773, 0.11],
                    [0.905, 0.174],
                    [1.904, 0.638],
                    [2.029, 0.704],
                    [3.126, 1.299],
                    [4.451, 0.53],
                    [3.857, 0.199],
                    [3.352, -0.063],
                    [3.228, -0.127],
                    [3.164, -0.16],
                    [2.542, -0.454],
                    [2.228, -0.587],
                    [2.098, -0.643],
                    [1.978, -0.694],
                    [1.457, -0.889],
                    [1.168, -0.985],
                    [1.07, -1.016],
                    [0.974, -1.048],
                    [0.52, -1.174],
                    [0.174, -1.251],
                    [0.126, -1.262],
                    [0.089, -1.27],
                    [-0.328, -1.223],
                    [-0.736, -1.15],
                    [-0.746, -1.15],
                    [-0.765, -1.15],
                    [-1.143, -1.167],
                    [-1.555, -1.15],
                    [-1.564, -1.15],
                    [-1.589, -1.259],
                    [-2.001, -1.256],
                    [-2.206, -1.229],
                    [-2.388, -1.194],
                    [-2.515, -1.167],
                    [-2.985, -0.966],
                    [-3.126, -0.89],
                    [-4.451, -0.121],
                    [-4.309, -0.199],
                    [-3.712, -0.439],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [255.635, 395.15], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 9",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-0.15, 0.183],
                    [-0.047, 0.074],
                    [-0.01, 0.015],
                    [-0.021, 0.039],
                    [-0.02, 0.043],
                    [-0.015, 0.033],
                    [-0.014, 0.035],
                    [-0.01, 0.028],
                    [-0.027, 0.089],
                    [-0.004, 0.013],
                    [-0.001, 0.004],
                    [-0.027, 0.118],
                    [-0.006, 0.026],
                    [-0.008, 0.044],
                    [-0.01, 0.059],
                    [-0.01, 0.066],
                    [-0.007, 0.054],
                    [-0.003, 0.029],
                    [-0.012, 0.127],
                    [-0.003, 0.039],
                    [-0.001, 0.008],
                    [0, 0.21],
                    [0, 0],
                    [-0.002, 0.104],
                    [0, 0.137],
                    [0, 0],
                    [0, 0],
                    [-0.009, 0.151],
                    [-0.002, 0.029],
                    [-0.017, 0.13],
                    [-0.003, 0.021],
                    [-0.027, 0.118],
                    [-0.003, 0.01],
                    [-0.035, 0.1],
                    [-0.005, 0.013],
                    [-0.043, 0.087],
                    [-0.015, 0.028],
                    [-0.031, 0.047],
                    [-0.052, 0.057],
                    [-0.025, 0.024],
                    [-0.092, 0.055],
                    [0, 0],
                    [0.079, -0.076],
                    [0.024, -0.026],
                    [0.045, -0.068],
                    [0.011, -0.017],
                    [0.019, -0.034],
                    [0.015, -0.03],
                    [0.007, -0.014],
                    [0.028, -0.075],
                    [0.003, -0.008],
                    [0.005, -0.014],
                    [0.014, -0.05],
                    [0.014, -0.06],
                    [0.002, -0.01],
                    [0.001, -0.005],
                    [0.014, -0.079],
                    [0.006, -0.044],
                    [0.003, -0.021],
                    [0.003, -0.018],
                    [0.007, -0.092],
                    [0.002, -0.028],
                    [0.002, -0.029],
                    [0.002, -0.043],
                    [0, -0.113],
                    [0, 0],
                    [0, 0],
                    [0.003, -0.142],
                    [0.004, -0.104],
                    [0.017, -0.204],
                    [0.003, -0.039],
                    [0.02, -0.146],
                    [0.008, -0.051],
                    [0.023, -0.118],
                    [0.009, -0.043],
                    [0.039, -0.133],
                    [0.004, -0.014],
                    [0.041, -0.105],
                    [0.015, -0.034],
                    [0.037, -0.069],
                    [0.022, -0.036],
                    [0.063, -0.076],
                    [0.087, -0.074],
                    [0.099, -0.057],
                  ],
                  o: [
                    [0.198, -0.115],
                    [0.052, -0.063],
                    [0.009, -0.015],
                    [0.022, -0.037],
                    [0.022, -0.04],
                    [0.015, -0.032],
                    [0.015, -0.034],
                    [0.011, -0.027],
                    [0.03, -0.081],
                    [0.004, -0.013],
                    [0.001, -0.004],
                    [0.031, -0.107],
                    [0.006, -0.026],
                    [0.009, -0.042],
                    [0.011, -0.057],
                    [0.011, -0.063],
                    [0.008, -0.051],
                    [0.004, -0.028],
                    [0.015, -0.119],
                    [0.003, -0.039],
                    [0.001, -0.008],
                    [0.016, -0.192],
                    [0, 0],
                    [0, -0.1],
                    [0.004, -0.131],
                    [0, 0],
                    [0, 0],
                    [0.001, -0.165],
                    [0.002, -0.029],
                    [0.01, -0.141],
                    [0.003, -0.021],
                    [0.02, -0.131],
                    [0.002, -0.01],
                    [0.027, -0.113],
                    [0.005, -0.014],
                    [0.035, -0.099],
                    [0.015, -0.029],
                    [0.029, -0.052],
                    [0.046, -0.067],
                    [0.023, -0.026],
                    [0.079, -0.076],
                    [0, 0],
                    [-0.092, 0.055],
                    [-0.025, 0.024],
                    [-0.052, 0.057],
                    [-0.011, 0.016],
                    [-0.02, 0.032],
                    [-0.016, 0.028],
                    [-0.007, 0.014],
                    [-0.032, 0.067],
                    [-0.002, 0.007],
                    [-0.005, 0.014],
                    [-0.016, 0.048],
                    [-0.017, 0.057],
                    [-0.002, 0.01],
                    [-0.001, 0.005],
                    [-0.017, 0.074],
                    [-0.007, 0.042],
                    [-0.003, 0.02],
                    [-0.002, 0.018],
                    [-0.01, 0.087],
                    [-0.003, 0.028],
                    [-0.002, 0.029],
                    [-0.002, 0.041],
                    [-0.004, 0.106],
                    [0, 0],
                    [0, 0],
                    [0, 0.15],
                    [-0.003, 0.108],
                    [-0.009, 0.223],
                    [-0.003, 0.039],
                    [-0.015, 0.157],
                    [-0.007, 0.053],
                    [-0.019, 0.127],
                    [-0.008, 0.043],
                    [-0.032, 0.15],
                    [-0.004, 0.014],
                    [-0.036, 0.119],
                    [-0.015, 0.036],
                    [-0.034, 0.078],
                    [-0.021, 0.04],
                    [-0.056, 0.092],
                    [-0.075, 0.092],
                    [-0.087, 0.075],
                    [0, 0],
                  ],
                  v: [
                    [-0.533, 8.124],
                    [-0.012, 7.677],
                    [0.138, 7.471],
                    [0.165, 7.422],
                    [0.228, 7.306],
                    [0.293, 7.188],
                    [0.336, 7.086],
                    [0.379, 6.982],
                    [0.411, 6.904],
                    [0.497, 6.646],
                    [0.51, 6.608],
                    [0.513, 6.598],
                    [0.6, 6.26],
                    [0.616, 6.181],
                    [0.642, 6.049],
                    [0.676, 5.88],
                    [0.704, 5.682],
                    [0.728, 5.526],
                    [0.74, 5.444],
                    [0.779, 5.072],
                    [0.79, 4.957],
                    [0.658, 4.935],
                    [0.56, 4.332],
                    [0.56, 4.315],
                    [0.559, 4.001],
                    [0.56, 3.607],
                    [0.56, 3.561],
                    [0.729, -6.126],
                    [0.816, -6.598],
                    [0.858, -6.686],
                    [0.915, -7.092],
                    [0.934, -7.155],
                    [1.008, -7.531],
                    [1.018, -7.56],
                    [1.112, -7.879],
                    [1.126, -7.922],
                    [1.246, -8.199],
                    [1.292, -8.283],
                    [1.384, -8.431],
                    [1.531, -8.616],
                    [1.603, -8.695],
                    [1.858, -8.893],
                    [0.534, -8.125],
                    [0.279, -7.926],
                    [0.208, -7.846],
                    [0.061, -7.66],
                    [0.025, -7.617],
                    [-0.031, -7.513],
                    [-0.078, -7.428],
                    [-0.1, -7.388],
                    [-0.189, -7.177],
                    [-0.197, -7.152],
                    [-0.211, -7.109],
                    [-0.258, -6.966],
                    [-0.305, -6.789],
                    [-0.311, -6.762],
                    [-0.315, -6.746],
                    [-0.361, -6.517],
                    [-0.38, -6.384],
                    [-0.391, -6.323],
                    [-0.398, -6.27],
                    [-0.426, -6.001],
                    [-0.43, -5.915],
                    [-0.436, -5.829],
                    [-0.439, -5.706],
                    [-0.44, -5.378],
                    [-0.44, -5.358],
                    [-0.473, 4.331],
                    [-0.484, 4.76],
                    [-0.493, 5.086],
                    [-0.533, 5.727],
                    [-0.546, 5.84],
                    [-0.596, 6.297],
                    [-0.62, 6.452],
                    [-0.682, 6.819],
                    [-0.708, 6.951],
                    [-0.814, 7.378],
                    [-0.827, 7.417],
                    [-0.944, 7.751],
                    [-0.988, 7.855],
                    [-1.096, 8.076],
                    [-1.159, 8.191],
                    [-1.335, 8.447],
                    [-1.578, 8.695],
                    [-1.857, 8.893],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [257.44, 412.673], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 10",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.005, -0.001],
                    [0.136, 0.022],
                    [0.003, 0],
                    [0.157, 0.063],
                    [0.001, 0],
                    [0.179, 0.103],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.039, -0.021],
                    [-0.045, -0.022],
                    [-0.043, -0.019],
                    [-0.039, -0.016],
                    [-0.002, -0.001],
                    [-0.001, 0],
                    [-0.034, -0.012],
                    [-0.034, -0.01],
                    [-0.033, -0.009],
                    [-0.031, -0.006],
                    [-0.014, -0.002],
                    [-0.003, -0.001],
                    [-0.014, -0.002],
                    [-0.031, -0.002],
                    [-0.031, 0],
                    [-0.033, 0.003],
                    [-0.016, 0.003],
                    [-0.005, 0.001],
                    [-0.014, 0.003],
                    [-0.041, 0.014],
                    [-0.048, 0.029],
                    [0, 0],
                    [0.118, -0.018],
                  ],
                  o: [
                    [-0.117, 0.016],
                    [-0.003, 0],
                    [-0.139, -0.024],
                    [-0.002, -0.001],
                    [-0.16, -0.064],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.04, 0.023],
                    [0.047, 0.026],
                    [0.044, 0.022],
                    [0.04, 0.018],
                    [0.002, 0.001],
                    [0.001, 0],
                    [0.034, 0.014],
                    [0.035, 0.012],
                    [0.034, 0.01],
                    [0.032, 0.008],
                    [0.014, 0.003],
                    [0.003, 0.001],
                    [0.014, 0.002],
                    [0.032, 0.004],
                    [0.032, 0.003],
                    [0.034, 0],
                    [0.016, -0.001],
                    [0.005, 0],
                    [0.015, -0.002],
                    [0.044, -0.009],
                    [0.054, -0.019],
                    [0, 0],
                    [-0.098, 0.057],
                    [-0.005, 0],
                  ],
                  v: [
                    [2.034, 0.307],
                    [1.655, 0.298],
                    [1.645, 0.297],
                    [1.201, 0.168],
                    [1.197, 0.167],
                    [0.688, -0.084],
                    [-1.051, -1.088],
                    [-2.375, -0.319],
                    [-0.636, 0.685],
                    [-0.518, 0.751],
                    [-0.379, 0.823],
                    [-0.25, 0.884],
                    [-0.132, 0.934],
                    [-0.127, 0.936],
                    [-0.123, 0.937],
                    [-0.021, 0.976],
                    [0.083, 1.01],
                    [0.183, 1.038],
                    [0.279, 1.059],
                    [0.321, 1.066],
                    [0.331, 1.068],
                    [0.374, 1.075],
                    [0.468, 1.084],
                    [0.563, 1.088],
                    [0.662, 1.083],
                    [0.71, 1.076],
                    [0.726, 1.074],
                    [0.77, 1.068],
                    [0.898, 1.033],
                    [1.051, 0.962],
                    [2.375, 0.193],
                    [2.05, 0.305],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [247.051, 413.322], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 11",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.274, 0.205],
                    [0.008, 0.006],
                    [0.233, 0.227],
                    [0.005, 0.005],
                    [0.196, 0.254],
                    [0.004, 0.005],
                    [0.161, 0.283],
                    [0.121, 0.303],
                    [0.001, 0.003],
                    [0.079, 0.681],
                    [0.001, 0.01],
                    [-0.001, 0.357],
                    [0, 0],
                    [0.006, 0.227],
                    [0.013, 0.205],
                    [0.002, 0.038],
                    [0.002, 0.029],
                    [0.011, 0.111],
                    [0.02, 0.153],
                    [0.021, 0.136],
                    [0.003, 0.019],
                    [0.002, 0.012],
                    [0.018, 0.092],
                    [0.024, 0.111],
                    [0.024, 0.1],
                    [0.024, 0.086],
                    [0, 0.002],
                    [0.001, 0.004],
                    [0.025, 0.082],
                    [0.025, 0.074],
                    [0.024, 0.066],
                    [0.024, 0.061],
                    [0.022, 0.055],
                    [0.004, 0.009],
                    [0.024, 0.056],
                    [0.029, 0.063],
                    [0.033, 0.066],
                    [0.039, 0.075],
                    [0.032, 0.058],
                    [0.011, 0.019],
                    [0.02, 0.036],
                    [0.128, 0.188],
                    [0.049, 0.068],
                    [0.016, 0.023],
                    [0.024, 0.032],
                    [0.07, 0.09],
                    [0.067, 0.081],
                    [0.071, 0.081],
                    [0.008, 0.009],
                    [0.005, 0.006],
                    [0.065, 0.07],
                    [0.089, 0.09],
                    [0.101, 0.097],
                    [0.033, 0.031],
                    [0, 0],
                    [-0.263, -0.297],
                    [-0.005, -0.006],
                    [-0.216, -0.299],
                    [-0.015, -0.022],
                    [-0.17, -0.3],
                    [-0.01, -0.019],
                    [-0.134, -0.323],
                    [-0.105, -0.378],
                    [0, -0.002],
                    [-0.069, -0.416],
                    [-0.002, -0.012],
                    [-0.034, -0.445],
                    [-0.002, -0.029],
                    [0.001, -0.501],
                    [0, 0],
                    [-0.003, -0.103],
                    [-0.007, -0.1],
                    [-0.009, -0.092],
                    [-0.005, -0.05],
                    [-0.001, -0.01],
                    [-0.004, -0.027],
                    [-0.012, -0.081],
                    [-0.014, -0.077],
                    [-0.016, -0.074],
                    [-0.017, -0.07],
                    [-0.018, -0.066],
                    [-0.019, -0.063],
                    [-0.019, -0.059],
                    [-0.02, -0.055],
                    [-0.02, -0.052],
                    [-0.005, -0.014],
                    [-0.001, -0.003],
                    [-0.013, -0.032],
                    [-0.022, -0.048],
                    [-0.023, -0.048],
                    [-0.024, -0.049],
                    [-0.029, -0.056],
                    [-0.033, -0.059],
                    [-0.016, -0.027],
                    [-0.085, -0.125],
                    [-0.058, -0.079],
                    [-0.027, -0.036],
                    [-0.003, -0.005],
                    [-0.012, -0.015],
                    [-0.041, -0.049],
                    [-0.041, -0.048],
                    [-0.044, -0.047],
                    [-0.048, -0.049],
                    [-0.032, -0.032],
                    [-0.005, -0.005],
                    [-0.015, -0.014],
                    [-0.059, -0.053],
                    [-0.064, -0.054],
                    [-0.069, -0.054],
                    [-0.048, -0.036],
                    [-0.008, -0.006],
                    [-0.017, -0.013],
                    [-0.079, -0.055],
                    [-0.086, -0.055],
                    [-0.094, -0.055],
                    [-0.023, -0.013],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [-0.008, -0.006],
                    [-0.269, -0.203],
                    [-0.005, -0.005],
                    [-0.233, -0.229],
                    [-0.004, -0.005],
                    [-0.198, -0.257],
                    [-0.161, -0.284],
                    [-0.001, -0.003],
                    [-0.24, -0.605],
                    [-0.002, -0.01],
                    [-0.038, -0.338],
                    [0, 0],
                    [0.001, -0.237],
                    [-0.007, -0.213],
                    [-0.003, -0.039],
                    [-0.002, -0.029],
                    [-0.009, -0.113],
                    [-0.015, -0.159],
                    [-0.018, -0.141],
                    [-0.004, -0.019],
                    [-0.002, -0.011],
                    [-0.016, -0.094],
                    [-0.021, -0.114],
                    [-0.022, -0.103],
                    [-0.022, -0.088],
                    [0, -0.002],
                    [-0.001, -0.003],
                    [-0.023, -0.084],
                    [-0.023, -0.076],
                    [-0.023, -0.069],
                    [-0.022, -0.063],
                    [-0.022, -0.056],
                    [-0.004, -0.009],
                    [-0.023, -0.056],
                    [-0.028, -0.063],
                    [-0.031, -0.066],
                    [-0.037, -0.075],
                    [-0.031, -0.058],
                    [-0.011, -0.019],
                    [-0.021, -0.036],
                    [-0.113, -0.192],
                    [-0.047, -0.069],
                    [-0.015, -0.022],
                    [-0.023, -0.031],
                    [-0.067, -0.09],
                    [-0.065, -0.082],
                    [-0.068, -0.082],
                    [-0.008, -0.009],
                    [-0.005, -0.005],
                    [-0.063, -0.071],
                    [-0.085, -0.091],
                    [-0.097, -0.099],
                    [-0.033, -0.031],
                    [0, 0],
                    [0.303, 0.285],
                    [0.005, 0.005],
                    [0.255, 0.288],
                    [0.016, 0.022],
                    [0.207, 0.291],
                    [0.011, 0.02],
                    [0.175, 0.312],
                    [0.14, 0.334],
                    [0, 0.002],
                    [0.103, 0.371],
                    [0.002, 0.011],
                    [0.065, 0.404],
                    [0.002, 0.029],
                    [0.032, 0.459],
                    [0, 0],
                    [0, 0.105],
                    [0.003, 0.102],
                    [0.006, 0.094],
                    [0.005, 0.05],
                    [0.002, 0.01],
                    [0.003, 0.026],
                    [0.01, 0.082],
                    [0.013, 0.078],
                    [0.014, 0.075],
                    [0.016, 0.071],
                    [0.017, 0.068],
                    [0.018, 0.064],
                    [0.018, 0.06],
                    [0.019, 0.056],
                    [0.019, 0.053],
                    [0.006, 0.015],
                    [0.002, 0.004],
                    [0.013, 0.033],
                    [0.021, 0.049],
                    [0.021, 0.048],
                    [0.023, 0.05],
                    [0.029, 0.057],
                    [0.032, 0.06],
                    [0.016, 0.027],
                    [0.077, 0.131],
                    [0.055, 0.08],
                    [0.026, 0.036],
                    [0.004, 0.004],
                    [0.012, 0.015],
                    [0.039, 0.05],
                    [0.04, 0.048],
                    [0.043, 0.048],
                    [0.046, 0.051],
                    [0.032, 0.033],
                    [0.005, 0.005],
                    [0.015, 0.015],
                    [0.057, 0.055],
                    [0.061, 0.055],
                    [0.066, 0.056],
                    [0.047, 0.038],
                    [0.008, 0.006],
                    [0.017, 0.013],
                    [0.077, 0.056],
                    [0.083, 0.057],
                    [0.09, 0.057],
                    [0.022, 0.014],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.311, -0.18],
                  ],
                  v: [
                    [2.966, 7.674],
                    [2.942, 7.656],
                    [2.188, 7.011],
                    [2.173, 6.996],
                    [1.528, 6.271],
                    [1.516, 6.256],
                    [0.977, 5.446],
                    [0.554, 4.565],
                    [0.55, 4.555],
                    [0.072, 2.626],
                    [0.068, 2.596],
                    [0.011, 1.553],
                    [0.039, -8.135],
                    [0.03, -8.832],
                    [0.001, -9.459],
                    [-0.008, -9.573],
                    [-0.015, -9.659],
                    [-0.042, -9.997],
                    [-0.094, -10.465],
                    [-0.152, -10.88],
                    [-0.162, -10.936],
                    [-0.168, -10.971],
                    [-0.217, -11.251],
                    [-0.285, -11.587],
                    [-0.355, -11.892],
                    [-0.424, -12.152],
                    [-0.425, -12.158],
                    [-0.428, -12.169],
                    [-0.501, -12.418],
                    [-0.573, -12.643],
                    [-0.644, -12.846],
                    [-0.713, -13.031],
                    [-0.78, -13.198],
                    [-0.792, -13.225],
                    [-0.863, -13.392],
                    [-0.949, -13.581],
                    [-1.045, -13.779],
                    [-1.16, -14.003],
                    [-1.257, -14.177],
                    [-1.29, -14.235],
                    [-1.35, -14.344],
                    [-1.711, -14.915],
                    [-1.857, -15.121],
                    [-1.904, -15.188],
                    [-1.972, -15.284],
                    [-2.178, -15.554],
                    [-2.376, -15.798],
                    [-2.585, -16.042],
                    [-2.609, -16.069],
                    [-2.624, -16.086],
                    [-2.816, -16.298],
                    [-3.076, -16.57],
                    [-3.373, -16.864],
                    [-3.472, -16.958],
                    [-4.796, -16.189],
                    [-3.948, -15.316],
                    [-3.933, -15.299],
                    [-3.228, -14.418],
                    [-3.181, -14.352],
                    [-2.614, -13.466],
                    [-2.582, -13.408],
                    [-2.116, -12.456],
                    [-1.749, -11.388],
                    [-1.748, -11.382],
                    [-1.492, -10.201],
                    [-1.486, -10.166],
                    [-1.339, -8.89],
                    [-1.332, -8.804],
                    [-1.285, -7.366],
                    [-1.313, 2.322],
                    [-1.308, 2.633],
                    [-1.294, 2.937],
                    [-1.272, 3.216],
                    [-1.256, 3.366],
                    [-1.252, 3.396],
                    [-1.243, 3.476],
                    [-1.209, 3.72],
                    [-1.169, 3.952],
                    [-1.124, 4.174],
                    [-1.075, 4.385],
                    [-1.022, 4.586],
                    [-0.966, 4.777],
                    [-0.909, 4.956],
                    [-0.85, 5.123],
                    [-0.791, 5.281],
                    [-0.774, 5.324],
                    [-0.77, 5.334],
                    [-0.731, 5.431],
                    [-0.668, 5.577],
                    [-0.602, 5.721],
                    [-0.531, 5.87],
                    [-0.444, 6.039],
                    [-0.347, 6.216],
                    [-0.3, 6.297],
                    [-0.058, 6.681],
                    [0.111, 6.919],
                    [0.192, 7.026],
                    [0.203, 7.04],
                    [0.238, 7.085],
                    [0.358, 7.234],
                    [0.48, 7.377],
                    [0.61, 7.52],
                    [0.752, 7.669],
                    [0.849, 7.765],
                    [0.864, 7.78],
                    [0.909, 7.824],
                    [1.082, 7.985],
                    [1.27, 8.149],
                    [1.473, 8.314],
                    [1.618, 8.425],
                    [1.642, 8.443],
                    [1.691, 8.481],
                    [1.925, 8.647],
                    [2.178, 8.814],
                    [2.454, 8.982],
                    [2.521, 9.021],
                    [3.472, 9.571],
                    [3.451, 16.957],
                    [4.775, 16.188],
                    [4.796, 8.801],
                    [3.844, 8.252],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [258.208, 437.934], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 12",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-2.191, -0.499],
                    [-0.867, -1.268],
                    [2.191, 0.497],
                    [0.867, 1.267],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [260.952, 396.948], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 13",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.01, -3.372],
                    [0, 0],
                    [2.93, 1.692],
                    [0, 0],
                    [-0.009, 3.372],
                    [0, 0],
                    [-2.931, -1.692],
                  ],
                  o: [
                    [2.93, 1.692],
                    [0, 0],
                    [-0.009, 3.372],
                    [0, 0],
                    [-2.931, -1.692],
                    [0, 0],
                    [0.01, -3.372],
                    [0, 0],
                  ],
                  v: [
                    [50.934, -9.08],
                    [56.244, 0.125],
                    [56.061, 64.716],
                    [50.715, 67.767],
                    [-50.934, 9.08],
                    [-56.245, -0.125],
                    [-56.063, -64.716],
                    [-50.715, -67.767],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.0706, 0.6392, 0.7373] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [272.694, 430.247], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 14",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.003, 0.079],
                    [0.005, 0.086],
                    [0.004, 0.041],
                    [0.002, 0.017],
                    [0.003, 0.029],
                    [0.011, 0.086],
                    [0.014, 0.09],
                    [0.001, 0.005],
                    [0.001, 0.005],
                    [0.015, 0.08],
                    [0.02, 0.092],
                    [0.008, 0.036],
                    [0.004, 0.02],
                    [0.009, 0.038],
                    [0.026, 0.094],
                    [0.022, 0.072],
                    [0.003, 0.011],
                    [0.003, 0.01],
                    [0.031, 0.092],
                    [0.033, 0.091],
                    [0.007, 0.019],
                    [0.006, 0.014],
                    [0.021, 0.055],
                    [0.036, 0.087],
                    [0.028, 0.064],
                    [0.004, 0.01],
                    [0.005, 0.011],
                    [0.04, 0.087],
                    [0.046, 0.093],
                    [0.007, 0.014],
                    [0.009, 0.018],
                    [0.039, 0.074],
                    [0.051, 0.09],
                    [0.027, 0.047],
                    [0.011, 0.019],
                    [0.047, 0.077],
                    [0.076, 0.115],
                    [0.012, 0.018],
                    [0.105, 0.142],
                    [0.011, 0.015],
                    [0.001, 0.001],
                    [0.08, 0.103],
                    [0.063, 0.076],
                    [0.004, 0.005],
                    [0.003, 0.004],
                    [0.07, 0.079],
                    [0.069, 0.076],
                    [0.01, 0.01],
                    [0.01, 0.011],
                    [0.053, 0.054],
                    [0.076, 0.072],
                    [0.021, 0.019],
                    [0.017, 0.015],
                    [0.04, 0.036],
                    [0.079, 0.067],
                    [0.033, 0.027],
                    [0.019, 0.015],
                    [0.029, 0.023],
                    [0.078, 0.057],
                    [0.055, 0.038],
                    [0.011, 0.008],
                    [0.011, 0.008],
                    [0.075, 0.048],
                    [0.075, 0.044],
                    [0.018, 0.01],
                    [0, 0],
                    [0.055, 0.03],
                    [0.072, 0.036],
                    [0.048, 0.022],
                    [0.011, 0.005],
                    [0.013, 0.005],
                    [0.07, 0.028],
                    [0.07, 0.025],
                    [0.014, 0.005],
                    [0.012, 0.004],
                    [0.045, 0.014],
                    [0.073, 0.018],
                    [0.037, 0.008],
                    [0.014, 0.003],
                    [0.021, 0.004],
                    [0.076, 0.01],
                    [0.05, 0.004],
                    [0.015, 0.001],
                    [0.014, 0.001],
                    [0.083, -0.001],
                    [0.038, -0.001],
                    [0.027, -0.002],
                    [0.026, -0.003],
                    [0.101, -0.02],
                    [0.003, -0.001],
                    [0.029, -0.006],
                    [0.092, -0.033],
                    [0.008, -0.003],
                    [0.13, -0.076],
                    [0, 0],
                    [-0.143, 0.052],
                    [-0.105, 0.025],
                    [-0.029, 0.006],
                    [-0.136, 0.009],
                    [-0.028, 0.001],
                    [-0.139, -0.011],
                    [-0.016, -0.001],
                    [-0.15, -0.031],
                    [-0.015, -0.003],
                    [-0.159, -0.053],
                    [-0.012, -0.003],
                    [-0.17, -0.078],
                    [-0.011, -0.005],
                    [-0.178, -0.103],
                    [0, 0],
                    [-0.175, -0.121],
                    [-0.012, -0.008],
                    [-0.157, -0.128],
                    [-0.017, -0.015],
                    [-0.149, -0.138],
                    [-0.017, -0.016],
                    [-0.145, -0.154],
                    [-0.011, -0.012],
                    [-0.146, -0.175],
                    [0, 0],
                    [-0.138, -0.187],
                    [-0.009, -0.012],
                    [-0.112, -0.17],
                    [-0.072, -0.118],
                    [-0.056, -0.096],
                    [-0.027, -0.048],
                    [-0.085, -0.167],
                    [-0.009, -0.019],
                    [-0.092, -0.208],
                    [-0.004, -0.01],
                    [-0.077, -0.206],
                    [-0.005, -0.014],
                    [-0.064, -0.212],
                    [-0.003, -0.011],
                    [-0.049, -0.205],
                    [-0.004, -0.02],
                    [-0.033, -0.206],
                    [-0.001, -0.006],
                    [-0.018, -0.206],
                    [-0.002, -0.017],
                    [0.001, -0.203],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.017, -0.188],
                    [0.002, -0.017],
                    [0.031, -0.159],
                    [0.004, -0.02],
                    [0.044, -0.141],
                    [0.009, -0.027],
                    [0.057, -0.123],
                    [0.005, -0.01],
                    [0.077, -0.12],
                    [0.135, -0.137],
                    [0.033, -0.031],
                    [0.164, -0.095],
                    [0, 0],
                    [-0.141, 0.131],
                    [-0.033, 0.034],
                    [-0.11, 0.171],
                    [-0.002, 0.004],
                    [-0.061, 0.129],
                    [-0.002, 0.003],
                    [-0.005, 0.009],
                    [-0.035, 0.092],
                    [-0.014, 0.041],
                    [-0.009, 0.027],
                    [-0.007, 0.026],
                    [-0.021, 0.093],
                    [-0.007, 0.031],
                    [-0.004, 0.022],
                    [-0.006, 0.034],
                    [-0.011, 0.086],
                    [-0.004, 0.046],
                    [-0.002, 0.018],
                    [-0.001, 0.021],
                    [-0.004, 0.084],
                    [-0.001, 0.078],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, -0.08],
                    [-0.002, -0.085],
                    [-0.003, -0.04],
                    [-0.001, -0.017],
                    [-0.002, -0.029],
                    [-0.008, -0.086],
                    [-0.011, -0.089],
                    [-0.001, -0.004],
                    [0, -0.005],
                    [-0.013, -0.08],
                    [-0.017, -0.092],
                    [-0.008, -0.036],
                    [-0.004, -0.019],
                    [-0.009, -0.038],
                    [-0.023, -0.094],
                    [-0.02, -0.072],
                    [-0.003, -0.012],
                    [-0.003, -0.01],
                    [-0.028, -0.092],
                    [-0.031, -0.091],
                    [-0.007, -0.018],
                    [-0.006, -0.014],
                    [-0.02, -0.054],
                    [-0.033, -0.086],
                    [-0.027, -0.064],
                    [-0.004, -0.01],
                    [-0.005, -0.011],
                    [-0.039, -0.087],
                    [-0.045, -0.094],
                    [-0.008, -0.015],
                    [-0.009, -0.018],
                    [-0.038, -0.075],
                    [-0.049, -0.091],
                    [-0.027, -0.048],
                    [-0.011, -0.018],
                    [-0.045, -0.077],
                    [-0.072, -0.117],
                    [-0.013, -0.019],
                    [-0.099, -0.148],
                    [-0.01, -0.015],
                    [-0.001, -0.001],
                    [-0.078, -0.105],
                    [-0.061, -0.077],
                    [-0.004, -0.005],
                    [-0.003, -0.003],
                    [-0.068, -0.081],
                    [-0.068, -0.078],
                    [-0.01, -0.01],
                    [-0.01, -0.011],
                    [-0.052, -0.056],
                    [-0.075, -0.076],
                    [-0.021, -0.02],
                    [-0.017, -0.016],
                    [-0.04, -0.038],
                    [-0.078, -0.071],
                    [-0.032, -0.027],
                    [-0.018, -0.015],
                    [-0.028, -0.023],
                    [-0.077, -0.06],
                    [-0.054, -0.041],
                    [-0.011, -0.008],
                    [-0.011, -0.007],
                    [-0.075, -0.051],
                    [-0.074, -0.047],
                    [-0.017, -0.01],
                    [0, 0],
                    [-0.056, -0.032],
                    [-0.072, -0.039],
                    [-0.048, -0.024],
                    [-0.01, -0.005],
                    [-0.012, -0.005],
                    [-0.07, -0.032],
                    [-0.071, -0.029],
                    [-0.014, -0.005],
                    [-0.012, -0.004],
                    [-0.045, -0.015],
                    [-0.073, -0.022],
                    [-0.038, -0.01],
                    [-0.015, -0.004],
                    [-0.021, -0.004],
                    [-0.078, -0.015],
                    [-0.051, -0.007],
                    [-0.015, -0.002],
                    [-0.013, -0.001],
                    [-0.085, -0.006],
                    [-0.038, 0],
                    [-0.027, 0.001],
                    [-0.027, 0.002],
                    [-0.106, 0.009],
                    [-0.003, 0.001],
                    [-0.028, 0.006],
                    [-0.096, 0.023],
                    [-0.008, 0.002],
                    [-0.143, 0.052],
                    [0, 0],
                    [0.131, -0.076],
                    [0.099, -0.036],
                    [0.028, -0.007],
                    [0.128, -0.026],
                    [0.027, -0.002],
                    [0.132, -0.005],
                    [0.015, 0.002],
                    [0.145, 0.014],
                    [0.015, 0.003],
                    [0.153, 0.034],
                    [0.012, 0.004],
                    [0.165, 0.057],
                    [0.01, 0.004],
                    [0.173, 0.08],
                    [0, 0],
                    [0.179, 0.103],
                    [0.011, 0.008],
                    [0.162, 0.113],
                    [0.018, 0.014],
                    [0.154, 0.126],
                    [0.017, 0.016],
                    [0.151, 0.142],
                    [0.011, 0.012],
                    [0.154, 0.165],
                    [0, 0],
                    [0.147, 0.177],
                    [0.009, 0.012],
                    [0.119, 0.163],
                    [0.076, 0.114],
                    [0.058, 0.094],
                    [0.028, 0.048],
                    [0.092, 0.163],
                    [0.01, 0.019],
                    [0.103, 0.204],
                    [0.004, 0.01],
                    [0.089, 0.204],
                    [0.005, 0.014],
                    [0.078, 0.212],
                    [0.003, 0.01],
                    [0.062, 0.205],
                    [0.005, 0.02],
                    [0.048, 0.208],
                    [0.001, 0.006],
                    [0.033, 0.21],
                    [0.001, 0.017],
                    [0.016, 0.207],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0.202],
                    [-0.002, 0.017],
                    [-0.017, 0.172],
                    [-0.004, 0.02],
                    [-0.032, 0.154],
                    [-0.009, 0.026],
                    [-0.045, 0.135],
                    [-0.005, 0.01],
                    [-0.064, 0.136],
                    [-0.11, 0.171],
                    [-0.032, 0.033],
                    [-0.142, 0.131],
                    [0, 0],
                    [0.163, -0.095],
                    [0.034, -0.031],
                    [0.134, -0.137],
                    [0.002, -0.003],
                    [0.073, -0.115],
                    [0.001, -0.002],
                    [0.004, -0.009],
                    [0.039, -0.086],
                    [0.015, -0.041],
                    [0.009, -0.027],
                    [0.008, -0.025],
                    [0.026, -0.088],
                    [0.007, -0.031],
                    [0.004, -0.021],
                    [0.007, -0.034],
                    [0.015, -0.083],
                    [0.006, -0.046],
                    [0.002, -0.018],
                    [0.002, -0.021],
                    [0.007, -0.082],
                    [0.003, -0.076],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [56.323, 64.271],
                    [56.323, 64.271],
                    [56.323, 64.257],
                    [56.486, -0.335],
                    [56.473, -0.572],
                    [56.457, -0.828],
                    [56.444, -0.949],
                    [56.439, -0.999],
                    [56.432, -1.085],
                    [56.404, -1.344],
                    [56.366, -1.611],
                    [56.362, -1.624],
                    [56.361, -1.639],
                    [56.318, -1.879],
                    [56.262, -2.156],
                    [56.237, -2.263],
                    [56.224, -2.322],
                    [56.198, -2.435],
                    [56.124, -2.718],
                    [56.06, -2.935],
                    [56.049, -2.969],
                    [56.04, -3],
                    [55.952, -3.276],
                    [55.857, -3.549],
                    [55.836, -3.605],
                    [55.819, -3.648],
                    [55.758, -3.811],
                    [55.654, -4.072],
                    [55.572, -4.263],
                    [55.559, -4.294],
                    [55.544, -4.327],
                    [55.426, -4.587],
                    [55.289, -4.869],
                    [55.266, -4.913],
                    [55.238, -4.967],
                    [55.124, -5.191],
                    [54.97, -5.464],
                    [54.891, -5.606],
                    [54.858, -5.662],
                    [54.715, -5.891],
                    [54.496, -6.24],
                    [54.46, -6.297],
                    [54.154, -6.732],
                    [54.124, -6.777],
                    [54.12, -6.781],
                    [53.883, -7.093],
                    [53.697, -7.323],
                    [53.685, -7.339],
                    [53.676, -7.348],
                    [53.47, -7.589],
                    [53.263, -7.819],
                    [53.233, -7.849],
                    [53.203, -7.882],
                    [53.046, -8.047],
                    [52.82, -8.27],
                    [52.758, -8.328],
                    [52.706, -8.375],
                    [52.587, -8.486],
                    [52.352, -8.692],
                    [52.254, -8.772],
                    [52.199, -8.816],
                    [52.116, -8.885],
                    [51.883, -9.061],
                    [51.719, -9.178],
                    [51.686, -9.202],
                    [51.654, -9.225],
                    [51.43, -9.373],
                    [51.207, -9.509],
                    [51.155, -9.539],
                    [-50.493, -68.227],
                    [-50.66, -68.32],
                    [-50.876, -68.432],
                    [-51.02, -68.499],
                    [-51.052, -68.516],
                    [-51.09, -68.533],
                    [-51.301, -68.622],
                    [-51.514, -68.702],
                    [-51.556, -68.716],
                    [-51.591, -68.727],
                    [-51.727, -68.772],
                    [-51.946, -68.832],
                    [-52.058, -68.857],
                    [-52.102, -68.867],
                    [-52.164, -68.882],
                    [-52.396, -68.919],
                    [-52.546, -68.934],
                    [-52.592, -68.939],
                    [-52.633, -68.943],
                    [-52.885, -68.952],
                    [-52.998, -68.947],
                    [-53.08, -68.943],
                    [-53.161, -68.939],
                    [-53.47, -68.895],
                    [-53.479, -68.891],
                    [-53.561, -68.87],
                    [-53.845, -68.79],
                    [-53.868, -68.779],
                    [-54.279, -68.589],
                    [-56.486, -67.307],
                    [-56.076, -67.497],
                    [-55.767, -67.586],
                    [-55.684, -67.609],
                    [-55.287, -67.66],
                    [-55.204, -67.664],
                    [-54.798, -67.658],
                    [-54.752, -67.652],
                    [-54.309, -67.585],
                    [-54.265, -67.576],
                    [-53.798, -67.445],
                    [-53.762, -67.434],
                    [-53.258, -67.232],
                    [-53.227, -67.217],
                    [-52.699, -66.944],
                    [48.949, -8.257],
                    [49.479, -7.921],
                    [49.514, -7.895],
                    [49.993, -7.533],
                    [50.047, -7.49],
                    [50.5, -7.093],
                    [50.551, -7.046],
                    [50.995, -6.602],
                    [51.029, -6.566],
                    [51.478, -6.056],
                    [51.49, -6.042],
                    [51.917, -5.494],
                    [51.943, -5.457],
                    [52.289, -4.957],
                    [52.509, -4.607],
                    [52.684, -4.324],
                    [52.764, -4.18],
                    [53.031, -3.686],
                    [53.06, -3.63],
                    [53.353, -3.011],
                    [53.365, -2.982],
                    [53.613, -2.366],
                    [53.629, -2.323],
                    [53.844, -1.686],
                    [53.853, -1.655],
                    [54.017, -1.039],
                    [54.03, -0.98],
                    [54.154, -0.358],
                    [54.156, -0.341],
                    [54.232, 0.283],
                    [54.236, 0.333],
                    [54.26, 0.948],
                    [54.078, 65.481],
                    [54.193, 65.486],
                    [54.323, 65.487],
                    [54.323, 65.539],
                    [54.172, 66.122],
                    [54.105, 66.172],
                    [54.003, 66.669],
                    [53.974, 66.729],
                    [53.853, 67.172],
                    [53.822, 67.252],
                    [53.667, 67.64],
                    [53.654, 67.671],
                    [53.44, 68.054],
                    [53.069, 68.513],
                    [52.971, 68.608],
                    [52.517, 68.953],
                    [54.724, 67.671],
                    [55.176, 67.326],
                    [55.276, 67.229],
                    [55.646, 66.771],
                    [55.654, 66.763],
                    [55.855, 66.396],
                    [55.859, 66.388],
                    [55.871, 66.359],
                    [55.983, 66.095],
                    [56.025, 65.97],
                    [56.051, 65.89],
                    [56.076, 65.815],
                    [56.146, 65.544],
                    [56.165, 65.448],
                    [56.178, 65.385],
                    [56.198, 65.284],
                    [56.236, 65.031],
                    [56.25, 64.892],
                    [56.257, 64.838],
                    [56.262, 64.776],
                    [56.279, 64.526],
                    [56.284, 64.297],
                    [56.323, 64.271],
                    [56.323, 64.257],
                    [56.323, 64.278],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.0706, 0.6392, 0.702] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [274.677, 429.425], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 8,
    },
    {
      ty: 4,
      nm: "middle_object Outlines",
      sr: 1,
      st: 0,
      op: 120,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [576, 360, 0] },
        s: { a: 0, k: [100, 100, 100] },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [576, 360, 0] },
        r: { a: 0, k: 0 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 1",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [1.15, 0],
                    [0.787, -0.457],
                    [0, 0],
                    [0, -0.504],
                    [-0.689, -0.397],
                    [0, 0],
                    [-1.151, 0],
                    [-0.788, 0.457],
                    [0, 0],
                    [0, 0.503],
                    [0.691, 0.399],
                    [0, 0],
                  ],
                  o: [
                    [-1.15, 0],
                    [0, 0],
                    [-0.689, 0.4],
                    [0.001, 0.501],
                    [0, 0],
                    [0.794, 0.458],
                    [1.15, 0],
                    [0, 0],
                    [0.688, -0.4],
                    [-0.001, -0.501],
                    [0, 0],
                    [-0.793, -0.458],
                  ],
                  v: [
                    [-0.113, -19.543],
                    [-3.161, -18.824],
                    [-33.126, -1.409],
                    [-34.195, -0.007],
                    [-33.109, 1.407],
                    [-2.946, 18.822],
                    [0.114, 19.543],
                    [3.162, 18.823],
                    [33.128, 1.408],
                    [34.195, 0.008],
                    [33.106, -1.409],
                    [2.943, -18.823],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [1.385, 0],
                    [0.993, 0.574],
                    [0, 0],
                    [0.001, 0.974],
                    [-1.092, 0.634],
                    [0, 0],
                    [-1.384, 0],
                    [-0.992, -0.572],
                    [0, 0],
                    [-0.001, -0.974],
                    [1.089, -0.634],
                    [0, 0],
                  ],
                  o: [
                    [-1.384, 0],
                    [0, 0],
                    [-1.103, -0.636],
                    [-0.001, -0.97],
                    [0, 0],
                    [0.986, -0.573],
                    [1.383, 0],
                    [0, 0],
                    [1.104, 0.638],
                    [0.001, 0.969],
                    [0, 0],
                    [-0.987, 0.574],
                  ],
                  v: [
                    [0.114, 20.795],
                    [-3.573, 19.906],
                    [-33.734, 2.492],
                    [-35.446, -0.005],
                    [-33.755, -2.492],
                    [-3.789, -19.907],
                    [-0.113, -20.795],
                    [3.57, -19.908],
                    [33.732, -2.494],
                    [35.447, 0.006],
                    [33.758, 2.491],
                    [3.792, 19.905],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5255, 0.5255, 0.5255] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [588.39, 326.329], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 2",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [2.077, 0],
                    [1.435, -0.834],
                    [0, 0],
                    [-0.002, -1.03],
                    [-1.342, -0.775],
                    [0, 0],
                    [-2.078, 0],
                    [-1.437, 0.835],
                    [0, 0],
                    [0.002, 1.028],
                    [1.346, 0.776],
                    [0, 0],
                  ],
                  o: [
                    [-2.077, 0],
                    [0, 0],
                    [-1.325, 0.77],
                    [0.001, 1.038],
                    [0, 0],
                    [1.448, 0.836],
                    [2.078, 0],
                    [0, 0],
                    [1.322, -0.768],
                    [-0.001, -1.039],
                    [0, 0],
                    [-1.445, -0.834],
                  ],
                  v: [
                    [-0.194, -33.944],
                    [-5.641, -32.65],
                    [-57.003, -2.802],
                    [-59.055, -0.012],
                    [-56.97, 2.799],
                    [-5.273, 32.646],
                    [0.195, 33.943],
                    [5.646, 32.647],
                    [57.007, 2.799],
                    [59.055, 0.013],
                    [56.966, -2.801],
                    [5.268, -32.65],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [2.295, 0],
                    [1.635, 0.944],
                    [0, 0],
                    [0.002, 1.503],
                    [-1.728, 1.004],
                    [0, 0],
                    [-2.295, 0],
                    [-1.633, -0.942],
                    [0, 0],
                    [-0.002, -1.504],
                    [1.725, -1.002],
                    [0, 0],
                  ],
                  o: [
                    [-2.295, 0],
                    [0, 0],
                    [-1.746, -1.008],
                    [-0.003, -1.495],
                    [0, 0],
                    [1.624, -0.943],
                    [2.294, 0],
                    [0, 0],
                    [1.748, 1.009],
                    [0.003, 1.494],
                    [0, 0],
                    [-1.625, 0.944],
                  ],
                  v: [
                    [0.195, 35.195],
                    [-5.899, 33.732],
                    [-57.596, 3.884],
                    [-60.306, -0.009],
                    [-57.631, -3.885],
                    [-6.271, -33.733],
                    [-0.194, -35.195],
                    [5.895, -33.734],
                    [57.593, -3.887],
                    [60.307, 0.012],
                    [57.635, 3.883],
                    [6.275, 33.73],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5255, 0.5255, 0.5255] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [588.39, 326.329], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 3",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-3.378, -7.29],
                    [0, 0],
                    [-2.303, 0.319],
                    [-0.881, 1.921],
                    [0.303, 0.658],
                    [3.513, 2.01],
                  ],
                  o: [
                    [0, 0],
                    [0.619, 0.392],
                    [0.456, -0.064],
                    [0.689, -1.503],
                    [-2.584, -5.606],
                    [0, 0],
                  ],
                  v: [
                    [-17.304, -4.442],
                    [11.202, 13.225],
                    [15.919, 14.311],
                    [19.993, 11.946],
                    [19.593, 8.081],
                    [-12.731, -14.63],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.0706, 0.6392, 0.6941] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [677.359, 360.192], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 4",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-3.378, -7.29],
                    [0, 0],
                    [-2.303, 0.319],
                    [-0.881, 1.921],
                    [0.303, 0.658],
                    [3.513, 2.01],
                  ],
                  o: [
                    [0, 0],
                    [0.619, 0.392],
                    [0.456, -0.064],
                    [0.689, -1.503],
                    [-2.584, -5.606],
                    [0, 0],
                  ],
                  v: [
                    [-17.304, -4.442],
                    [11.202, 13.225],
                    [15.919, 14.311],
                    [19.993, 11.946],
                    [19.593, 8.081],
                    [-12.731, -14.63],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.0706, 0.6392, 0.6941] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [657.712, 371.419], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 5",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-3.378, -7.29],
                    [0, 0],
                    [-2.303, 0.319],
                    [-0.881, 1.921],
                    [0.303, 0.658],
                    [3.513, 2.01],
                  ],
                  o: [
                    [0, 0],
                    [0.619, 0.392],
                    [0.456, -0.064],
                    [0.689, -1.503],
                    [-2.584, -5.606],
                    [0, 0],
                  ],
                  v: [
                    [-17.304, -4.442],
                    [11.202, 13.225],
                    [15.919, 14.311],
                    [19.993, 11.946],
                    [19.593, 8.081],
                    [-12.731, -14.63],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.0706, 0.6392, 0.6941] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [638.064, 384.049], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 6",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-3.378, -7.29],
                    [0, 0],
                    [-2.303, 0.319],
                    [-0.881, 1.921],
                    [0.303, 0.658],
                    [3.513, 2.01],
                  ],
                  o: [
                    [0, 0],
                    [0.619, 0.392],
                    [0.456, -0.064],
                    [0.689, -1.503],
                    [-2.584, -5.606],
                    [0, 0],
                  ],
                  v: [
                    [-17.304, -4.442],
                    [11.202, 13.225],
                    [15.919, 14.311],
                    [19.993, 11.946],
                    [19.593, 8.081],
                    [-12.731, -14.63],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.0706, 0.6392, 0.6941] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [618.417, 395.277], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 7",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [8.026, 0.365],
                    [0, 0],
                    [0.779, -2.191],
                    [-1.299, -1.668],
                    [-0.723, -0.034],
                    [-3.405, 2.19],
                  ],
                  o: [
                    [0, 0],
                    [-0.633, 0.369],
                    [-0.154, 0.434],
                    [1.015, 1.304],
                    [6.167, 0.292],
                    [0, 0],
                  ],
                  v: [
                    [12.507, -13.629],
                    [-16.315, 3.518],
                    [-19.453, 7.203],
                    [-19.233, 11.91],
                    [-15.62, 13.337],
                    [19.438, -4.872],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.0706, 0.6392, 0.6941] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [494.238, 358.559], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 8",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [8.026, 0.365],
                    [0, 0],
                    [0.778, -2.191],
                    [-1.299, -1.668],
                    [-0.723, -0.034],
                    [-3.405, 2.19],
                  ],
                  o: [
                    [0, 0],
                    [-0.633, 0.369],
                    [-0.154, 0.434],
                    [1.015, 1.304],
                    [6.167, 0.292],
                    [0, 0],
                  ],
                  v: [
                    [12.507, -13.629],
                    [-16.315, 3.518],
                    [-19.453, 7.203],
                    [-19.233, 11.91],
                    [-15.62, 13.337],
                    [19.438, -4.872],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.0706, 0.6392, 0.6941] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [515.289, 369.786], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 9",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [8.026, 0.365],
                    [0, 0],
                    [0.779, -2.191],
                    [-1.299, -1.668],
                    [-0.723, -0.034],
                    [-3.405, 2.19],
                  ],
                  o: [
                    [0, 0],
                    [-0.633, 0.369],
                    [-0.154, 0.434],
                    [1.015, 1.304],
                    [6.167, 0.292],
                    [0, 0],
                  ],
                  v: [
                    [12.507, -13.629],
                    [-16.315, 3.518],
                    [-19.453, 7.203],
                    [-19.234, 11.91],
                    [-15.62, 13.337],
                    [19.438, -4.872],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.0706, 0.6392, 0.6941] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [533.533, 382.417], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 10",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [8.026, 0.365],
                    [0, 0],
                    [0.779, -2.191],
                    [-1.299, -1.668],
                    [-0.723, -0.034],
                    [-3.405, 2.19],
                  ],
                  o: [
                    [0, 0],
                    [-0.633, 0.369],
                    [-0.154, 0.434],
                    [1.015, 1.304],
                    [6.167, 0.292],
                    [0, 0],
                  ],
                  v: [
                    [12.507, -13.629],
                    [-16.315, 3.518],
                    [-19.453, 7.203],
                    [-19.234, 11.91],
                    [-15.62, 13.337],
                    [19.438, -4.872],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.0706, 0.6392, 0.6941] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [554.584, 393.644], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 11",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [2.915, -1.694],
                    [0, 0],
                    [2.931, 1.692],
                    [0, 0],
                    [-2.916, 1.694],
                    [0, 0],
                    [-2.93, -1.691],
                  ],
                  o: [
                    [2.93, 1.692],
                    [0, 0],
                    [-2.916, 1.694],
                    [0, 0],
                    [-2.93, -1.691],
                    [0, 0],
                    [2.915, -1.694],
                    [0, 0],
                  ],
                  v: [
                    [81.488, -3.079],
                    [81.519, 3.077],
                    [5.58, 47.208],
                    [-5.048, 47.21],
                    [-81.488, 3.077],
                    [-81.518, -3.078],
                    [-5.579, -47.208],
                    [5.048, -47.212],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.1686, 0.1569, 0.1608] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [587.621, 327.712], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 12",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.325, 0.189],
                    [0, 0],
                    [0.366, -0.146],
                    [0.098, -0.035],
                    [0.237, -0.067],
                    [0.257, -0.053],
                    [0.312, -0.039],
                    [0.074, -0.008],
                    [0.424, 0],
                    [0, 0],
                    [0.413, 0.043],
                    [0.063, 0.008],
                    [0.395, 0.089],
                    [0.071, 0.017],
                    [0.334, 0.121],
                    [0.062, 0.024],
                    [0.354, 0.204],
                    [0, 0],
                    [-0.004, 1.122],
                    [0, 0],
                    [-1.474, -0.851],
                    [0, 0],
                    [-0.379, -0.15],
                    [-0.021, -0.008],
                    [-0.064, -0.023],
                    [-0.223, -0.063],
                    [-0.129, -0.032],
                    [-0.071, -0.016],
                    [-0.063, -0.013],
                    [-0.252, -0.036],
                    [-0.093, -0.011],
                    [-0.064, -0.006],
                    [-0.09, -0.007],
                    [-0.249, -0.006],
                    [-0.079, 0],
                    [0, 0],
                    [-0.112, 0.004],
                    [-0.278, 0.028],
                    [-0.031, 0.003],
                    [-0.074, 0.009],
                    [-0.214, 0.038],
                    [-0.092, 0.019],
                    [-0.248, 0.069],
                    [-0.129, 0.042],
                    [-0.102, 0.036],
                    [-0.095, 0.038],
                  ],
                  o: [
                    [0, 0],
                    [-0.324, 0.189],
                    [-0.095, 0.038],
                    [-0.226, 0.082],
                    [-0.247, 0.069],
                    [-0.303, 0.062],
                    [-0.073, 0.009],
                    [-0.419, 0.045],
                    [0, 0],
                    [-0.418, 0],
                    [-0.063, -0.006],
                    [-0.411, -0.049],
                    [-0.071, -0.016],
                    [-0.357, -0.087],
                    [-0.064, -0.023],
                    [-0.401, -0.155],
                    [0, 0],
                    [-1.474, -0.851],
                    [0, 0],
                    [-0.003, 1.123],
                    [0, 0],
                    [0.335, 0.194],
                    [0.021, 0.008],
                    [0.063, 0.024],
                    [0.213, 0.077],
                    [0.126, 0.036],
                    [0.071, 0.017],
                    [0.062, 0.014],
                    [0.246, 0.051],
                    [0.092, 0.013],
                    [0.063, 0.008],
                    [0.089, 0.01],
                    [0.247, 0.021],
                    [0.079, 0.002],
                    [0, 0],
                    [0.112, 0],
                    [0.281, -0.01],
                    [0.031, -0.003],
                    [0.074, -0.008],
                    [0.218, -0.028],
                    [0.092, -0.017],
                    [0.257, -0.053],
                    [0.133, -0.037],
                    [0.104, -0.033],
                    [0.097, -0.035],
                    [0.367, -0.147],
                  ],
                  v: [
                    [44.603, 35.594],
                    [44.675, 10.074],
                    [43.629, 10.569],
                    [43.339, 10.679],
                    [42.637, 10.893],
                    [41.883, 11.081],
                    [40.963, 11.238],
                    [40.744, 11.176],
                    [39.477, 11.154],
                    [39.274, 11.154],
                    [38.026, 11.178],
                    [37.837, 11.203],
                    [36.627, 11.02],
                    [36.416, 10.98],
                    [35.38, 10.672],
                    [35.187, 10.61],
                    [34.048, 10.075],
                    [-42.392, -34.057],
                    [-44.599, -37.154],
                    [-44.672, -11.634],
                    [-42.464, -8.536],
                    [33.976, 35.596],
                    [35.049, 36.111],
                    [35.114, 36.133],
                    [35.308, 36.198],
                    [35.957, 36.417],
                    [36.344, 36.512],
                    [36.555, 36.564],
                    [36.74, 36.609],
                    [37.487, 36.739],
                    [37.765, 36.77],
                    [37.954, 36.791],
                    [38.221, 36.821],
                    [38.966, 37.008],
                    [39.202, 37.154],
                    [39.405, 37.154],
                    [39.741, 37.005],
                    [40.58, 36.875],
                    [40.672, 36.826],
                    [40.891, 36.777],
                    [41.543, 36.677],
                    [41.811, 36.605],
                    [42.564, 36.416],
                    [42.967, 36.314],
                    [43.268, 36.2],
                    [43.556, 36.09],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.1686, 0.1569, 0.1608] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [548.525, 364.846], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 13",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.006, -0.073],
                    [0.014, -0.08],
                    [0.017, -0.064],
                    [0.026, -0.075],
                    [0.027, -0.061],
                    [0.042, -0.078],
                    [0.034, -0.056],
                    [0.064, -0.086],
                    [0.042, -0.051],
                    [0.116, -0.112],
                    [0.043, -0.039],
                    [0.077, -0.062],
                    [0.075, -0.055],
                    [0.09, -0.059],
                    [0.101, -0.059],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.03, 0.018],
                    [-0.066, 0.044],
                    [-0.083, 0.061],
                    [-0.07, 0.056],
                    [-0.071, 0.064],
                    [-0.013, 0.012],
                    [-0.028, 0.028],
                    [-0.097, 0.117],
                    [-0.019, 0.026],
                    [-0.02, 0.026],
                    [-0.054, 0.088],
                    [-0.021, 0.037],
                    [-0.011, 0.02],
                    [-0.034, 0.078],
                    [-0.017, 0.045],
                    [-0.006, 0.016],
                    [-0.019, 0.076],
                    [-0.01, 0.05],
                    [-0.003, 0.014],
                    [-0.007, 0.08],
                    [-0.002, 0.051],
                    [0, 0.022],
                    [0, 0],
                  ],
                  o: [
                    [-0.007, 0.08],
                    [-0.012, 0.064],
                    [-0.019, 0.076],
                    [-0.022, 0.062],
                    [-0.034, 0.079],
                    [-0.03, 0.057],
                    [-0.055, 0.088],
                    [-0.039, 0.051],
                    [-0.096, 0.116],
                    [-0.041, 0.04],
                    [-0.071, 0.064],
                    [-0.07, 0.056],
                    [-0.084, 0.061],
                    [-0.095, 0.062],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.031, -0.018],
                    [0.07, -0.042],
                    [0.091, -0.058],
                    [0.075, -0.055],
                    [0.077, -0.062],
                    [0.014, -0.012],
                    [0.029, -0.027],
                    [0.115, -0.112],
                    [0.02, -0.025],
                    [0.021, -0.025],
                    [0.064, -0.086],
                    [0.022, -0.036],
                    [0.011, -0.02],
                    [0.042, -0.078],
                    [0.02, -0.045],
                    [0.006, -0.017],
                    [0.027, -0.076],
                    [0.013, -0.05],
                    [0.003, -0.014],
                    [0.015, -0.08],
                    [0.005, -0.051],
                    [0.001, -0.022],
                    [0, 0],
                    [0, 0.073],
                  ],
                  v: [
                    [39.076, -36.137],
                    [39.049, -35.895],
                    [39, -35.703],
                    [38.936, -35.477],
                    [38.858, -35.293],
                    [38.748, -35.059],
                    [38.648, -34.891],
                    [38.471, -34.628],
                    [38.349, -34.475],
                    [38.03, -34.132],
                    [37.909, -34.012],
                    [37.682, -33.826],
                    [37.47, -33.658],
                    [37.204, -33.48],
                    [36.918, -33.296],
                    [-39.022, 10.835],
                    [-39.094, 36.354],
                    [36.845, -7.775],
                    [36.936, -7.829],
                    [37.131, -7.96],
                    [37.397, -8.137],
                    [37.61, -8.305],
                    [37.836, -8.492],
                    [37.88, -8.529],
                    [37.958, -8.612],
                    [38.277, -8.956],
                    [38.345, -9.029],
                    [38.399, -9.108],
                    [38.575, -9.37],
                    [38.648, -9.478],
                    [38.676, -9.538],
                    [38.786, -9.773],
                    [38.849, -9.908],
                    [38.863, -9.956],
                    [38.928, -10.184],
                    [38.971, -10.333],
                    [38.976, -10.375],
                    [39.003, -10.615],
                    [39.021, -10.767],
                    [39.022, -10.835],
                    [39.094, -36.354],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.1686, 0.1569, 0.1608] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [632.222, 364.085], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 14",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [3.067, 7.426],
                    [0, 0],
                    [2.314, -0.222],
                    [0.961, -1.883],
                    [-0.275, -0.67],
                    [-3.425, -2.157],
                  ],
                  o: [
                    [0, 0],
                    [-0.603, -0.418],
                    [-0.459, 0.045],
                    [-0.751, 1.472],
                    [2.346, 5.71],
                    [0, 0],
                  ],
                  v: [
                    [17.129, 5.185],
                    [-10.607, -13.667],
                    [-15.274, -14.951],
                    [-19.445, -12.759],
                    [-19.208, -8.881],
                    [12.131, 15.172],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.0706, 0.6392, 0.6941] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [505.414, 315.476], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 15",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [3.067, 7.426],
                    [0, 0],
                    [2.314, -0.222],
                    [0.961, -1.883],
                    [-0.275, -0.67],
                    [-3.425, -2.157],
                  ],
                  o: [
                    [0, 0],
                    [-0.603, -0.417],
                    [-0.459, 0.044],
                    [-0.751, 1.472],
                    [2.346, 5.71],
                    [0, 0],
                  ],
                  v: [
                    [17.129, 5.185],
                    [-10.607, -13.668],
                    [-15.274, -14.951],
                    [-19.445, -12.759],
                    [-19.208, -8.881],
                    [12.131, 15.172],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.0706, 0.6392, 0.6941] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [525.518, 305.087], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 16",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [3.067, 7.426],
                    [0, 0],
                    [2.314, -0.222],
                    [0.961, -1.883],
                    [-0.275, -0.67],
                    [-3.425, -2.157],
                  ],
                  o: [
                    [0, 0],
                    [-0.603, -0.418],
                    [-0.459, 0.045],
                    [-0.751, 1.472],
                    [2.346, 5.71],
                    [0, 0],
                  ],
                  v: [
                    [17.129, 5.185],
                    [-10.607, -13.667],
                    [-15.274, -14.951],
                    [-19.445, -12.759],
                    [-19.208, -8.88],
                    [12.131, 15.173],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.0706, 0.6392, 0.6941] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [545.68, 293.295], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 17",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [3.067, 7.426],
                    [0, 0],
                    [2.314, -0.222],
                    [0.961, -1.883],
                    [-0.275, -0.67],
                    [-3.425, -2.157],
                  ],
                  o: [
                    [0, 0],
                    [-0.603, -0.417],
                    [-0.459, 0.044],
                    [-0.751, 1.472],
                    [2.346, 5.71],
                    [0, 0],
                  ],
                  v: [
                    [17.129, 5.185],
                    [-10.607, -13.668],
                    [-15.274, -14.951],
                    [-19.445, -12.759],
                    [-19.208, -8.881],
                    [12.131, 15.172],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.0706, 0.6392, 0.6941] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [565.783, 282.905], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 18",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-8.031, -0.223],
                    [0, 0],
                    [-0.74, 2.204],
                    [1.328, 1.645],
                    [0.724, 0.022],
                    [3.365, -2.25],
                  ],
                  o: [
                    [0, 0],
                    [0.627, -0.381],
                    [0.146, -0.437],
                    [-1.038, -1.286],
                    [-6.171, -0.183],
                    [0, 0],
                  ],
                  v: [
                    [-12.29, 13.82],
                    [16.224, -3.832],
                    [19.297, -7.572],
                    [18.994, -12.274],
                    [15.356, -13.637],
                    [-19.375, 5.189],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.0706, 0.6392, 0.6941] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [672.775, 317.857], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 19",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-8.031, -0.223],
                    [0, 0],
                    [-0.74, 2.204],
                    [1.328, 1.645],
                    [0.724, 0.022],
                    [3.365, -2.25],
                  ],
                  o: [
                    [0, 0],
                    [0.627, -0.381],
                    [0.146, -0.437],
                    [-1.038, -1.286],
                    [-6.171, -0.183],
                    [0, 0],
                  ],
                  v: [
                    [-12.29, 13.82],
                    [16.224, -3.832],
                    [19.297, -7.572],
                    [18.994, -12.274],
                    [15.356, -13.637],
                    [-19.375, 5.189],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.0706, 0.6392, 0.6941] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [651.529, 307.004], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 20",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-8.031, -0.223],
                    [0, 0],
                    [-0.74, 2.204],
                    [1.328, 1.645],
                    [0.724, 0.022],
                    [3.365, -2.25],
                  ],
                  o: [
                    [0, 0],
                    [0.627, -0.381],
                    [0.146, -0.437],
                    [-1.038, -1.286],
                    [-6.171, -0.183],
                    [0, 0],
                  ],
                  v: [
                    [-12.291, 13.82],
                    [16.223, -3.832],
                    [19.296, -7.572],
                    [18.993, -12.274],
                    [15.355, -13.637],
                    [-19.376, 5.189],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.0706, 0.6392, 0.6941] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [633.064, 294.697], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 21",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-8.031, -0.223],
                    [0, 0],
                    [-0.74, 2.204],
                    [1.328, 1.645],
                    [0.724, 0.022],
                    [3.365, -2.25],
                  ],
                  o: [
                    [0, 0],
                    [0.627, -0.381],
                    [0.146, -0.437],
                    [-1.038, -1.286],
                    [-6.171, -0.183],
                    [0, 0],
                  ],
                  v: [
                    [-12.291, 13.82],
                    [16.223, -3.832],
                    [19.296, -7.572],
                    [18.993, -12.274],
                    [15.355, -13.637],
                    [-19.376, 5.189],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.0706, 0.6392, 0.6941] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [611.818, 283.844], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 9,
    },
    {
      ty: 4,
      nm: "top_left_object_shadow Outlines",
      sr: 1,
      st: 0,
      op: 120,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [576, 360, 0] },
        s: { a: 0, k: [100, 100, 100] },
        sk: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 360, 0],
              t: 6,
              ti: [0, 0, 0],
              to: [0, -1.16666662693024, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 353, 0],
              t: 17,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 360, 0],
              t: 30,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 353, 0],
              t: 44,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 360, 0],
              t: 57,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 353, 0],
              t: 72,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.167, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 360, 0],
              t: 85,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.167, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 353, 0],
              t: 98,
              ti: [0, -1.16666662693024, 0],
              to: [0, 0, 0],
            },
            { s: [576, 360, 0], t: 111 },
          ],
        },
        r: { a: 0, k: 0 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 1",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.488, 0],
                    [0.424, -0.246],
                    [0, 0],
                    [-0.001, -1.003],
                    [-0.87, -0.503],
                    [0, 0],
                    [-0.847, 0.491],
                    [0, 0],
                    [0.001, 1.004],
                    [0.87, 0.502],
                    [0, 0],
                  ],
                  o: [
                    [-0.49, 0],
                    [0, 0],
                    [-0.868, 0.504],
                    [0.002, 1.004],
                    [0, 0],
                    [0.847, 0.489],
                    [0, 0],
                    [0.868, -0.504],
                    [-0.001, -1.004],
                    [0, 0],
                    [-0.423, -0.244],
                  ],
                  v: [
                    [6.765, -51.683],
                    [5.368, -51.307],
                    [-86.007, 1.795],
                    [-87.391, 4.203],
                    [-86, 6.609],
                    [-8.156, 51.552],
                    [-5.367, 51.548],
                    [86.007, -1.554],
                    [87.391, -3.962],
                    [86, -6.367],
                    [8.156, -51.309],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [1.019, 0],
                    [0.878, 0.506],
                    [0, 0],
                    [0.003, 2.086],
                    [-1.803, 1.049],
                    [0, 0],
                    [-1.761, -1.017],
                    [0, 0],
                    [-0.003, -2.087],
                    [1.804, -1.048],
                    [0, 0],
                  ],
                  o: [
                    [-1.013, 0],
                    [0, 0],
                    [-1.807, -1.043],
                    [-0.003, -2.087],
                    [0, 0],
                    [1.758, -1.022],
                    [0, 0],
                    [1.807, 1.044],
                    [0.003, 2.086],
                    [0, 0],
                    [-0.881, 0.512],
                  ],
                  v: [
                    [-6.765, 54.924],
                    [-9.656, 54.15],
                    [-87.5, 9.206],
                    [-90.391, 4.208],
                    [-87.515, -0.799],
                    [3.86, -53.9],
                    [9.656, -53.908],
                    [87.5, -8.965],
                    [90.391, -3.965],
                    [87.515, 1.04],
                    [-3.86, 54.142],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.0706, 0.6392, 0.7098] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [394.89, 211.705], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 2",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [2.845, -1.653],
                    [0, 0],
                    [1.329, 0.767],
                    [0, 0],
                    [-2.844, 1.653],
                    [0, 0],
                    [-1.328, -0.767],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.326, 0.771],
                    [0, 0],
                    [-2.85, -1.645],
                    [0, 0],
                    [1.326, -0.77],
                    [0, 0],
                    [2.849, 1.645],
                  ],
                  v: [
                    [86.76, -0.377],
                    [-4.615, 52.724],
                    [-8.907, 52.73],
                    [-86.751, 7.787],
                    [-86.762, 0.378],
                    [4.614, -52.724],
                    [8.906, -52.73],
                    [86.75, -7.786],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [394.89, 211.825], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 10,
    },
    {
      ty: 4,
      nm: "bottom_left_text Outlines",
      sr: 1,
      st: 0,
      op: 120,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: true,
      ao: 0,
      ks: {
        a: { a: 0, k: [576, 360, 0] },
        s: { a: 0, k: [100, 100, 100] },
        sk: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 360, 0],
              t: 9,
              ti: [0, 0, 0],
              to: [0, -1.16666662693024, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 353, 0],
              t: 20,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 360, 0],
              t: 33,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 353, 0],
              t: 47,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 360, 0],
              t: 60,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 353, 0],
              t: 75,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.167, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 360, 0],
              t: 88,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 353, 0],
              t: 101,
              ti: [0, -1.16666662693024, 0],
              to: [0, 0, 0],
            },
            { s: [576, 360, 0], t: 114 },
          ],
        },
        r: { a: 0, k: 0 },
        sa: { a: 0, k: 0 },
        o: {
          a: 1,
          k: [
            { o: { x: 0.285, y: 0 }, i: { x: 0.621, y: 0.986 }, s: [0], t: 17 },
            {
              o: { x: 0.213, y: 0.213 },
              i: { x: 0.68, y: 0.68 },
              s: [100],
              t: 21,
            },
            { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [100], t: 94 },
            { s: [0], t: 97 },
          ],
        },
      },
      ef: [],
      masksProperties: [
        {
          nm: "Mask 1",
          inv: false,
          mode: "a",
          x: {
            a: 1,
            k: [
              { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [-8], t: 17 },
              {
                o: { x: 0.333, y: 0.333 },
                i: { x: 0.833, y: 0.833 },
                s: [110],
                t: 23,
              },
              { o: { x: 0.167, y: 0 }, i: { x: 0.833, y: 1 }, s: [110], t: 91 },
              { s: [-8], t: 97 },
            ],
          },
          o: { a: 0, k: 100 },
          pt: {
            a: 0,
            k: {
              c: true,
              i: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
              ],
              o: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
              ],
              v: [
                [300.5, 340.219],
                [308, 355.219],
                [419, 415.469],
                [426.75, 417.219],
                [428, 405.469],
                [320.5, 345.969],
              ],
            },
          },
        },
      ],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 1",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [1.854, -5.478],
                    [-3.185, 4.045],
                    [-1.858, 5.478],
                    [3.185, -4.042],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [401.881, 472.7], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 2",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-4.475, -6.496],
                    [-5.233, -5.532],
                    [2.392, 4.002],
                    [-4.94, 4.669],
                    [-4.198, 6.496],
                    [5.232, 5.645],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [412.357, 475.778], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 3",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [4.387, -6.418],
                    [-5.177, -5.059],
                    [4.385, 6.418],
                    [5.102, 5.406],
                    [-2.333, -3.522],
                    [5.178, -4.589],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [392.334, 469.455], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 4",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [10.559, 2.64],
                    [-10.628, -9.213],
                    [-10.558, -2.641],
                    [10.629, 9.213],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [411.478, 460.372], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 5",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [39.505, 18.833],
                    [-39.574, -25.406],
                    [-39.504, -18.835],
                    [39.574, 25.407],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [354.732, 428.626], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 6",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [40.263, 19.256],
                    [-40.333, -25.833],
                    [-40.262, -19.255],
                    [40.333, 25.833],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [355.376, 418.456], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 7",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [7.003, 0.65],
                    [-7.072, -7.224],
                    [-7.002, -0.651],
                    [7.073, 7.224],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [416.095, 409.224], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 8",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [7.005, 0.65],
                    [-7.075, -7.227],
                    [-7.004, -0.649],
                    [7.075, 7.227],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [411.251, 439.187], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 9",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [7.003, 0.648],
                    [-7.073, -7.225],
                    [-7.003, -0.648],
                    [7.072, 7.226],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [393.62, 429.323], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 10",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [33.806, 15.643],
                    [-33.875, -22.221],
                    [-33.806, -15.644],
                    [33.875, 22.22],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [348.806, 404.253], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 11",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [49.016, 24.155],
                    [-49.086, -30.728],
                    [-49.016, -24.155],
                    [49.086, 30.728],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [363.891, 401.074], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 12",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [4.965, -0.49],
                    [-5.035, -6.083],
                    [-4.965, 0.489],
                    [5.035, 6.084],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [386.344, 403.108], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 13",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [31.451, 14.328],
                    [-31.52, -20.901],
                    [-31.449, -14.328],
                    [31.521, 20.901],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [346.212, 380.656], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 14",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [45.346, 22.101],
                    [-45.416, -28.675],
                    [-45.346, -22.103],
                    [45.416, 28.674],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [359.995, 377.839], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 11,
    },
    {
      ty: 4,
      nm: "bottom_left_object Outlines",
      sr: 1,
      st: 0,
      op: 120,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [576, 360, 0] },
        s: { a: 0, k: [100, 100, 100] },
        sk: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 360, 0],
              t: 9,
              ti: [0, 0, 0],
              to: [0, -1.16666662693024, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 353, 0],
              t: 20,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 360, 0],
              t: 33,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 353, 0],
              t: 47,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 360, 0],
              t: 60,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 353, 0],
              t: 75,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.167, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 360, 0],
              t: 88,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 353, 0],
              t: 101,
              ti: [0, -1.16666662693024, 0],
              to: [0, 0, 0],
            },
            { s: [576, 360, 0], t: 114 },
          ],
        },
        r: { a: 0, k: 0 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 1",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.009, -3.367],
                    [0, 0],
                    [2.935, 1.694],
                    [0, 0],
                    [-0.009, 3.367],
                    [0, 0],
                    [-2.93, -1.692],
                  ],
                  o: [
                    [2.934, 1.694],
                    [0, 0],
                    [-0.01, 3.367],
                    [0, 0],
                    [-2.93, -1.692],
                    [0, 0],
                    [0.01, -3.367],
                    [0, 0],
                  ],
                  v: [
                    [82.092, -17.175],
                    [87.408, -7.973],
                    [87.077, 108.711],
                    [81.725, 111.755],
                    [-82.095, 17.173],
                    [-87.407, 7.973],
                    [-87.076, -108.711],
                    [-81.73, -111.757],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [393.02, 423.156], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 2",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.009, -3.367],
                    [0, 0],
                    [2.934, 1.694],
                    [0, 0],
                    [-0.009, 3.367],
                    [0, 0],
                    [-2.934, -1.694],
                  ],
                  o: [
                    [2.934, 1.694],
                    [0, 0],
                    [-0.01, 3.368],
                    [0, 0],
                    [-2.934, -1.694],
                    [0, 0],
                    [0.01, -3.368],
                    [0, 0],
                  ],
                  v: [
                    [90.673, -18.559],
                    [95.988, -9.357],
                    [95.621, 119.982],
                    [90.272, 123.027],
                    [-90.672, 18.559],
                    [-95.988, 9.357],
                    [-95.621, -119.982],
                    [-90.271, -123.027],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [394.462, 423.222], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 3",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.002, 0.079],
                    [0, 0],
                    [0, -0.079],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.002, 0.08],
                    [0, 0],
                    [0, -0.078],
                  ],
                  v: [
                    [-4.194, 2.318],
                    [4.191, -2.555],
                    [4.194, -2.317],
                    [-4.191, 2.555],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.009, -3.367],
                    [0, 0],
                    [2.933, 1.695],
                  ],
                  o: [
                    [2.935, 1.694],
                    [0, 0],
                    [0.01, -3.366],
                    [0, 0],
                  ],
                  v: [
                    [-9.507, -6.647],
                    [-4.19, 2.555],
                    [4.194, -2.318],
                    [-1.121, -11.52],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [494.641, 411.31], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 4",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.005, 0.085],
                    [0, 0],
                    [-0.003, -0.085],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.005, 0.086],
                    [0, 0],
                    [-0.003, -0.085],
                  ],
                  v: [
                    [-4.198, 2.309],
                    [4.186, -2.564],
                    [4.198, -2.309],
                    [-4.186, 2.564],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.009, -3.367],
                    [0, 0],
                    [2.933, 1.695],
                  ],
                  o: [
                    [2.935, 1.694],
                    [0, 0],
                    [0.01, -3.366],
                    [0, 0],
                  ],
                  v: [
                    [-9.499, -6.401],
                    [-4.182, 2.801],
                    [4.202, -2.072],
                    [-1.113, -11.274],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [494.633, 411.064], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 5",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.008, 0.086],
                    [0, 0],
                    [-0.005, -0.085],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.008, 0.086],
                    [0, 0],
                    [-0.005, -0.085],
                  ],
                  v: [
                    [-4.203, 2.308],
                    [4.182, -2.565],
                    [4.202, -2.308],
                    [-4.183, 2.565],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.009, -3.367],
                    [0, 0],
                    [2.933, 1.695],
                  ],
                  o: [
                    [2.935, 1.694],
                    [0, 0],
                    [0.01, -3.366],
                    [0, 0],
                  ],
                  v: [
                    [-9.483, -6.146],
                    [-4.166, 3.056],
                    [4.218, -1.817],
                    [-1.097, -11.019],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [494.617, 410.809], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 6",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.011, 0.087],
                    [0, 0],
                    [-0.009, -0.086],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.011, 0.087],
                    [0, 0],
                    [-0.008, -0.086],
                  ],
                  v: [
                    [-4.207, 2.307],
                    [4.178, -2.566],
                    [4.207, -2.307],
                    [-4.178, 2.566],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.009, -3.367],
                    [0, 0],
                    [2.933, 1.695],
                  ],
                  o: [
                    [2.935, 1.694],
                    [0, 0],
                    [0.01, -3.366],
                    [0, 0],
                  ],
                  v: [
                    [-9.459, -5.888],
                    [-4.142, 3.314],
                    [4.242, -1.559],
                    [-1.073, -10.761],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [494.593, 410.551], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 7",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.014, 0.089],
                    [0, 0],
                    [-0.012, -0.088],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.014, 0.089],
                    [0, 0],
                    [-0.011, -0.089],
                  ],
                  v: [
                    [-4.211, 2.303],
                    [4.173, -2.569],
                    [4.211, -2.303],
                    [-4.173, 2.57],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.009, -3.367],
                    [0, 0],
                    [2.933, 1.695],
                  ],
                  o: [
                    [2.935, 1.694],
                    [0, 0],
                    [0.01, -3.366],
                    [0, 0],
                  ],
                  v: [
                    [-9.425, -5.626],
                    [-4.108, 3.576],
                    [4.276, -1.297],
                    [-1.039, -10.499],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [494.559, 410.289], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 8",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.017, 0.089],
                    [0, 0],
                    [-0.014, -0.089],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.017, 0.09],
                    [0, 0],
                    [-0.014, -0.089],
                  ],
                  v: [
                    [-4.216, 2.302],
                    [4.169, -2.57],
                    [4.215, -2.302],
                    [-4.17, 2.57],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.009, -3.367],
                    [0, 0],
                    [2.933, 1.695],
                  ],
                  o: [
                    [2.935, 1.694],
                    [0, 0],
                    [0.01, -3.366],
                    [0, 0],
                  ],
                  v: [
                    [-9.383, -5.359],
                    [-4.066, 3.843],
                    [4.318, -1.03],
                    [-0.997, -10.232],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [494.517, 410.022], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 9",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.02, 0.092],
                    [0, 0],
                    [-0.017, -0.092],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.02, 0.093],
                    [0, 0],
                    [-0.017, -0.092],
                  ],
                  v: [
                    [-4.22, 2.298],
                    [4.164, -2.575],
                    [4.22, -2.298],
                    [-4.164, 2.575],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.009, -3.367],
                    [0, 0],
                    [2.933, 1.695],
                  ],
                  o: [
                    [2.935, 1.694],
                    [0, 0],
                    [0.01, -3.366],
                    [0, 0],
                  ],
                  v: [
                    [-9.332, -5.087],
                    [-4.015, 4.115],
                    [4.369, -0.758],
                    [-0.946, -9.96],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [494.466, 409.75], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 10",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.023, 0.093],
                    [0, 0],
                    [-0.021, -0.093],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.023, 0.093],
                    [0, 0],
                    [-0.02, -0.092],
                  ],
                  v: [
                    [-4.225, 2.297],
                    [4.16, -2.576],
                    [4.225, -2.297],
                    [-4.16, 2.576],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.009, -3.367],
                    [0, 0],
                    [2.933, 1.695],
                  ],
                  o: [
                    [2.935, 1.694],
                    [0, 0],
                    [0.01, -3.366],
                    [0, 0],
                  ],
                  v: [
                    [-9.272, -4.809],
                    [-3.955, 4.393],
                    [4.429, -0.48],
                    [-0.886, -9.682],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [494.406, 409.472], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 11",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.026, 0.094],
                    [0, 0],
                    [-0.023, -0.094],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.026, 0.095],
                    [0, 0],
                    [-0.023, -0.094],
                  ],
                  v: [
                    [-4.229, 2.295],
                    [4.155, -2.578],
                    [4.229, -2.295],
                    [-4.156, 2.577],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.009, -3.367],
                    [0, 0],
                    [2.933, 1.695],
                  ],
                  o: [
                    [2.935, 1.694],
                    [0, 0],
                    [0.01, -3.366],
                    [0, 0],
                  ],
                  v: [
                    [-9.202, -4.529],
                    [-3.885, 4.673],
                    [4.499, -0.2],
                    [-0.816, -9.402],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [494.336, 409.192], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 12",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.029, 0.094],
                    [0, 0],
                    [-0.026, -0.094],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.028, 0.094],
                    [0, 0],
                    [-0.026, -0.094],
                  ],
                  v: [
                    [-4.233, 2.296],
                    [4.151, -2.577],
                    [4.233, -2.295],
                    [-4.152, 2.578],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.009, -3.367],
                    [0, 0],
                    [2.933, 1.695],
                  ],
                  o: [
                    [2.935, 1.694],
                    [0, 0],
                    [0.01, -3.366],
                    [0, 0],
                  ],
                  v: [
                    [-9.124, -4.247],
                    [-3.807, 4.955],
                    [4.577, 0.082],
                    [-0.738, -9.12],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [494.258, 408.91], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 13",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.031, 0.092],
                    [0, 0],
                    [-0.028, -0.092],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.03, 0.092],
                    [0, 0],
                    [-0.028, -0.092],
                  ],
                  v: [
                    [-4.237, 2.298],
                    [4.148, -2.574],
                    [4.236, -2.298],
                    [-4.149, 2.574],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.009, -3.367],
                    [0, 0],
                    [2.933, 1.695],
                  ],
                  o: [
                    [2.935, 1.694],
                    [0, 0],
                    [0.01, -3.366],
                    [0, 0],
                  ],
                  v: [
                    [-9.038, -3.968],
                    [-3.721, 5.234],
                    [4.663, 0.361],
                    [-0.652, -8.841],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [494.172, 408.631], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 14",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.033, 0.091],
                    [0, 0],
                    [-0.031, -0.091],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.033, 0.091],
                    [0, 0],
                    [-0.03, -0.09],
                  ],
                  v: [
                    [-4.24, 2.301],
                    [4.145, -2.572],
                    [4.24, -2.299],
                    [-4.145, 2.573],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.009, -3.367],
                    [0, 0],
                    [2.933, 1.695],
                  ],
                  o: [
                    [2.935, 1.694],
                    [0, 0],
                    [0.01, -3.366],
                    [0, 0],
                  ],
                  v: [
                    [-8.947, -3.694],
                    [-3.63, 5.508],
                    [4.754, 0.635],
                    [-0.561, -8.567],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [494.081, 408.357], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 15",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.034, 0.087],
                    [0, 0],
                    [-0.032, -0.087],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.034, 0.087],
                    [0, 0],
                    [-0.031, -0.087],
                  ],
                  v: [
                    [-4.241, 2.306],
                    [4.143, -2.567],
                    [4.241, -2.305],
                    [-4.143, 2.567],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.009, -3.367],
                    [0, 0],
                    [2.933, 1.695],
                  ],
                  o: [
                    [2.935, 1.694],
                    [0, 0],
                    [0.01, -3.366],
                    [0, 0],
                  ],
                  v: [
                    [-8.85, -3.427],
                    [-3.533, 5.775],
                    [4.851, 0.902],
                    [-0.464, -8.3],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [493.984, 408.09], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 16",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.036, 0.086],
                    [0, 0],
                    [-0.033, -0.087],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.036, 0.087],
                    [0, 0],
                    [-0.034, -0.087],
                  ],
                  v: [
                    [-4.245, 2.306],
                    [4.14, -2.566],
                    [4.244, -2.306],
                    [-4.14, 2.566],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.009, -3.367],
                    [0, 0],
                    [2.933, 1.695],
                  ],
                  o: [
                    [2.935, 1.694],
                    [0, 0],
                    [0.01, -3.366],
                    [0, 0],
                  ],
                  v: [
                    [-8.749, -3.166],
                    [-3.432, 6.036],
                    [4.952, 1.163],
                    [-0.363, -8.039],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [493.883, 407.829], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 17",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.038, 0.085],
                    [0, 0],
                    [-0.036, -0.086],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.037, 0.085],
                    [0, 0],
                    [-0.036, -0.085],
                  ],
                  v: [
                    [-4.247, 2.309],
                    [4.137, -2.564],
                    [4.247, -2.307],
                    [-4.138, 2.565],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.009, -3.367],
                    [0, 0],
                    [2.933, 1.695],
                  ],
                  o: [
                    [2.935, 1.694],
                    [0, 0],
                    [0.01, -3.366],
                    [0, 0],
                  ],
                  v: [
                    [-8.642, -2.908],
                    [-3.325, 6.294],
                    [5.059, 1.421],
                    [-0.256, -7.781],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [493.776, 407.571], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 18",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.04, 0.086],
                    [0, 0],
                    [-0.039, -0.087],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.04, 0.086],
                    [0, 0],
                    [-0.038, -0.087],
                  ],
                  v: [
                    [-4.251, 2.307],
                    [4.133, -2.566],
                    [4.251, -2.307],
                    [-4.134, 2.566],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.009, -3.367],
                    [0, 0],
                    [2.933, 1.695],
                  ],
                  o: [
                    [2.935, 1.694],
                    [0, 0],
                    [0.01, -3.366],
                    [0, 0],
                  ],
                  v: [
                    [-8.528, -2.651],
                    [-3.211, 6.551],
                    [5.173, 1.678],
                    [-0.142, -7.524],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [493.662, 407.314], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 19",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.047, 0.094],
                    [0, 0],
                    [-0.044, -0.095],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.046, 0.094],
                    [0, 0],
                    [-0.045, -0.094],
                  ],
                  v: [
                    [-4.26, 2.296],
                    [4.124, -2.577],
                    [4.26, -2.294],
                    [-4.124, 2.578],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.009, -3.367],
                    [0, 0],
                    [2.933, 1.695],
                  ],
                  o: [
                    [2.935, 1.694],
                    [0, 0],
                    [0.01, -3.366],
                    [0, 0],
                  ],
                  v: [
                    [-8.4, -2.38],
                    [-3.083, 6.822],
                    [5.301, 1.949],
                    [-0.014, -7.253],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [493.534, 407.043], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 20",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.057, 0.106],
                    [0, 0],
                    [-0.054, -0.107],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.056, 0.106],
                    [0, 0],
                    [-0.053, -0.107],
                  ],
                  v: [
                    [-4.275, 2.276],
                    [4.11, -2.596],
                    [4.275, -2.276],
                    [-4.11, 2.596],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.009, -3.367],
                    [0, 0],
                    [2.933, 1.695],
                  ],
                  o: [
                    [2.935, 1.694],
                    [0, 0],
                    [0.01, -3.366],
                    [0, 0],
                  ],
                  v: [
                    [-8.249, -2.079],
                    [-2.932, 7.123],
                    [5.452, 2.25],
                    [0.137, -6.952],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [493.383, 406.742], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 21",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.092, 0.156],
                    [0, 0],
                    [-0.085, -0.16],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.092, 0.156],
                    [0, 0],
                    [-0.086, -0.159],
                  ],
                  v: [
                    [-4.325, 2.2],
                    [4.06, -2.673],
                    [4.325, -2.199],
                    [-4.059, 2.673],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.009, -3.367],
                    [0, 0],
                    [2.933, 1.695],
                  ],
                  o: [
                    [2.935, 1.694],
                    [0, 0],
                    [0.01, -3.366],
                    [0, 0],
                  ],
                  v: [
                    [-8.034, -1.682],
                    [-2.717, 7.52],
                    [5.667, 2.647],
                    [0.352, -6.555],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [493.168, 406.345], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 22",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.139, 0.208],
                    [0, 0],
                    [-0.127, -0.217],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.139, 0.209],
                    [0, 0],
                    [-0.128, -0.216],
                  ],
                  v: [
                    [-4.392, 2.117],
                    [3.992, -2.755],
                    [4.392, -2.116],
                    [-3.992, 2.755],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.009, -3.367],
                    [0, 0],
                    [2.933, 1.695],
                  ],
                  o: [
                    [2.935, 1.694],
                    [0, 0],
                    [0.01, -3.366],
                    [0, 0],
                  ],
                  v: [
                    [-7.701, -1.126],
                    [-2.384, 8.076],
                    [6, 3.203],
                    [0.685, -5.999],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [492.835, 405.789], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 23",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.116, 0.157],
                    [0, 0],
                    [-0.109, -0.164],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.116, 0.158],
                    [0, 0],
                    [-0.109, -0.163],
                  ],
                  v: [
                    [-4.362, 2.196],
                    [4.023, -2.677],
                    [4.361, -2.195],
                    [-4.024, 2.677],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.009, -3.367],
                    [0, 0],
                    [2.933, 1.695],
                  ],
                  o: [
                    [2.935, 1.694],
                    [0, 0],
                    [0.01, -3.366],
                    [0, 0],
                  ],
                  v: [
                    [-7.332, -0.566],
                    [-2.015, 8.636],
                    [6.369, 3.763],
                    [1.054, -5.439],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [492.466, 405.229], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 24",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.08, 0.102],
                    [0, 0],
                    [-0.078, -0.105],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.08, 0.102],
                    [0, 0],
                    [-0.078, -0.105],
                  ],
                  v: [
                    [-4.311, 2.281],
                    [4.074, -2.592],
                    [4.311, -2.281],
                    [-4.074, 2.592],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.009, -3.367],
                    [0, 0],
                    [2.933, 1.695],
                  ],
                  o: [
                    [2.935, 1.694],
                    [0, 0],
                    [0.01, -3.366],
                    [0, 0],
                  ],
                  v: [
                    [-7.044, -0.17],
                    [-1.727, 9.032],
                    [6.657, 4.159],
                    [1.342, -5.043],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [492.178, 404.833], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 25",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.07, 0.084],
                    [0, 0],
                    [-0.068, -0.086],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.07, 0.084],
                    [0, 0],
                    [-0.068, -0.087],
                  ],
                  v: [
                    [-4.296, 2.309],
                    [4.089, -2.564],
                    [4.296, -2.308],
                    [-4.089, 2.565],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.009, -3.367],
                    [0, 0],
                    [2.933, 1.695],
                  ],
                  o: [
                    [2.935, 1.694],
                    [0, 0],
                    [0.01, -3.366],
                    [0, 0],
                  ],
                  v: [
                    [-6.822, 0.113],
                    [-1.505, 9.315],
                    [6.879, 4.442],
                    [1.564, -4.76],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [491.956, 404.55], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 26",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.07, 0.079],
                    [0, 0],
                    [-0.068, -0.082],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.069, 0.08],
                    [0, 0],
                    [-0.068, -0.082],
                  ],
                  v: [
                    [-4.296, 2.315],
                    [4.089, -2.557],
                    [4.295, -2.315],
                    [-4.089, 2.557],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.009, -3.367],
                    [0, 0],
                    [2.933, 1.695],
                  ],
                  o: [
                    [2.935, 1.694],
                    [0, 0],
                    [0.01, -3.366],
                    [0, 0],
                  ],
                  v: [
                    [-6.615, 0.361],
                    [-1.298, 9.563],
                    [7.086, 4.69],
                    [1.771, -4.512],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [491.749, 404.302], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 27",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.07, 0.076],
                    [0, 0],
                    [-0.069, -0.078],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.07, 0.076],
                    [0, 0],
                    [-0.069, -0.078],
                  ],
                  v: [
                    [-4.296, 2.321],
                    [4.089, -2.552],
                    [4.297, -2.321],
                    [-4.089, 2.551],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.009, -3.367],
                    [0, 0],
                    [2.933, 1.695],
                  ],
                  o: [
                    [2.935, 1.694],
                    [0, 0],
                    [0.01, -3.366],
                    [0, 0],
                  ],
                  v: [
                    [-6.408, 0.598],
                    [-1.091, 9.8],
                    [7.293, 4.927],
                    [1.978, -4.275],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [491.542, 404.065], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 28",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.074, 0.075],
                    [0, 0],
                    [-0.072, -0.077],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.073, 0.075],
                    [0, 0],
                    [-0.072, -0.078],
                  ],
                  v: [
                    [-4.302, 2.322],
                    [4.083, -2.551],
                    [4.301, -2.322],
                    [-4.084, 2.551],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.009, -3.367],
                    [0, 0],
                    [2.933, 1.695],
                  ],
                  o: [
                    [2.935, 1.694],
                    [0, 0],
                    [0.01, -3.366],
                    [0, 0],
                  ],
                  v: [
                    [-6.195, 0.827],
                    [-0.878, 10.029],
                    [7.506, 5.156],
                    [2.191, -4.046],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [491.329, 403.836], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 29",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.076, 0.073],
                    [0, 0],
                    [-0.074, -0.075],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.075, 0.073],
                    [0, 0],
                    [-0.074, -0.075],
                  ],
                  v: [
                    [-4.304, 2.326],
                    [4.081, -2.547],
                    [4.305, -2.325],
                    [-4.079, 2.548],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.009, -3.367],
                    [0, 0],
                    [2.933, 1.695],
                  ],
                  o: [
                    [2.935, 1.694],
                    [0, 0],
                    [0.01, -3.366],
                    [0, 0],
                  ],
                  v: [
                    [-5.973, 1.053],
                    [-0.656, 10.255],
                    [7.728, 5.382],
                    [2.413, -3.82],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [491.107, 403.61], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 30",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.079, 0.071],
                    [0, 0],
                    [-0.078, -0.074],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.078, 0.071],
                    [0, 0],
                    [-0.077, -0.075],
                  ],
                  v: [
                    [-4.31, 2.327],
                    [4.075, -2.546],
                    [4.309, -2.328],
                    [-4.076, 2.546],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.009, -3.367],
                    [0, 0],
                    [2.933, 1.695],
                  ],
                  o: [
                    [2.935, 1.694],
                    [0, 0],
                    [0.01, -3.366],
                    [0, 0],
                  ],
                  v: [
                    [-5.745, 1.272],
                    [-0.428, 10.474],
                    [7.956, 5.602],
                    [2.641, -3.6],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [490.879, 403.391], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 31",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.079, 0.067],
                    [0, 0],
                    [-0.078, -0.07],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.079, 0.067],
                    [0, 0],
                    [-0.078, -0.07],
                  ],
                  v: [
                    [-4.31, 2.333],
                    [4.074, -2.54],
                    [4.31, -2.334],
                    [-4.074, 2.539],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.009, -3.367],
                    [0, 0],
                    [2.933, 1.695],
                  ],
                  o: [
                    [2.935, 1.694],
                    [0, 0],
                    [0.01, -3.366],
                    [0, 0],
                  ],
                  v: [
                    [-5.509, 1.485],
                    [-0.192, 10.687],
                    [8.192, 5.814],
                    [2.877, -3.388],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [490.643, 403.178], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 32",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.079, 0.063],
                    [0, 0],
                    [-0.078, -0.066],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.079, 0.063],
                    [0, 0],
                    [-0.078, -0.066],
                  ],
                  v: [
                    [-4.31, 2.34],
                    [4.074, -2.533],
                    [4.31, -2.34],
                    [-4.074, 2.533],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.009, -3.367],
                    [0, 0],
                    [2.933, 1.695],
                  ],
                  o: [
                    [2.935, 1.694],
                    [0, 0],
                    [0.01, -3.366],
                    [0, 0],
                  ],
                  v: [
                    [-5.273, 1.684],
                    [0.044, 10.886],
                    [8.428, 6.013],
                    [3.113, -3.189],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [490.407, 402.979], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 33",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.078, 0.057],
                    [0, 0],
                    [-0.077, -0.061],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.078, 0.058],
                    [0, 0],
                    [-0.076, -0.06],
                  ],
                  v: [
                    [-4.309, 2.348],
                    [4.076, -2.525],
                    [4.308, -2.347],
                    [-4.077, 2.525],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.009, -3.367],
                    [0, 0],
                    [2.933, 1.695],
                  ],
                  o: [
                    [2.935, 1.694],
                    [0, 0],
                    [0.01, -3.366],
                    [0, 0],
                  ],
                  v: [
                    [-5.039, 1.869],
                    [0.278, 11.071],
                    [8.662, 6.198],
                    [3.347, -3.004],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [490.173, 402.794], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 34",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.077, 0.053],
                    [0, 0],
                    [-0.076, -0.056],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.077, 0.053],
                    [0, 0],
                    [-0.076, -0.057],
                  ],
                  v: [
                    [-4.307, 2.354],
                    [4.077, -2.518],
                    [4.307, -2.354],
                    [-4.078, 2.518],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.009, -3.367],
                    [0, 0],
                    [2.933, 1.695],
                  ],
                  o: [
                    [2.935, 1.694],
                    [0, 0],
                    [0.01, -3.366],
                    [0, 0],
                  ],
                  v: [
                    [-4.807, 2.039],
                    [0.51, 11.241],
                    [8.894, 6.368],
                    [3.579, -2.833],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [489.941, 402.624], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 35",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.075, 0.048],
                    [0, 0],
                    [-0.074, -0.051],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.075, 0.048],
                    [0, 0],
                    [-0.074, -0.051],
                  ],
                  v: [
                    [-4.304, 2.362],
                    [4.081, -2.51],
                    [4.305, -2.362],
                    [-4.08, 2.51],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.009, -3.367],
                    [0, 0],
                    [2.933, 1.695],
                  ],
                  o: [
                    [2.935, 1.694],
                    [0, 0],
                    [0.01, -3.366],
                    [0, 0],
                  ],
                  v: [
                    [-4.58, 2.196],
                    [0.737, 11.398],
                    [9.121, 6.525],
                    [3.806, -2.677],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [489.714, 402.467], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 36",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.075, 0.044],
                    [0, 0],
                    [-0.074, -0.047],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.075, 0.044],
                    [0, 0],
                    [-0.074, -0.047],
                  ],
                  v: [
                    [-4.304, 2.368],
                    [4.081, -2.505],
                    [4.304, -2.368],
                    [-4.081, 2.505],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.009, -3.367],
                    [0, 0],
                    [2.933, 1.695],
                  ],
                  o: [
                    [2.935, 1.694],
                    [0, 0],
                    [0.01, -3.366],
                    [0, 0],
                  ],
                  v: [
                    [-4.356, 2.338],
                    [0.961, 11.54],
                    [9.345, 6.667],
                    [4.03, -2.535],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [489.49, 402.325], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 37",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.017, 0.01],
                    [0, 0],
                    [-0.018, -0.011],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.017, 0.01],
                    [0, 0],
                    [-0.017, -0.01],
                  ],
                  v: [
                    [-4.218, 2.421],
                    [4.167, -2.452],
                    [4.219, -2.421],
                    [-4.167, 2.451],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.009, -3.367],
                    [0, 0],
                    [2.933, 1.695],
                  ],
                  o: [
                    [2.935, 1.694],
                    [0, 0],
                    [0.01, -3.366],
                    [0, 0],
                  ],
                  v: [
                    [-4.219, 2.422],
                    [1.098, 11.624],
                    [9.482, 6.751],
                    [4.167, -2.451],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [489.353, 402.241], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 38",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [2.934, 1.694],
                    [0, 0],
                    [0.01, -3.367],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [2.934, 1.695],
                    [0, 0],
                    [0.009, -3.367],
                  ],
                  v: [
                    [-6.855, -2.165],
                    [1.53, -7.038],
                    [6.845, 2.165],
                    [-1.539, 7.038],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [491.99, 406.827], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 39",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-94.664, -49.798],
                    [-86.279, -54.671],
                    [94.664, 49.797],
                    [86.28, 54.671],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [398.855, 349.992], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 40",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-4.009, -62.233],
                    [4.376, -67.106],
                    [4.009, 62.233],
                    [-4.376, 67.106],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [494.459, 476.098], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 41",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.248, 0.389],
                    [0, 0],
                    [0.368, -0.213],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.248, 0.39],
                    [0, 0],
                    [0.367, -0.214],
                  ],
                  v: [
                    [-3.728, 1.983],
                    [4.657, -2.89],
                    [3.727, -1.982],
                    [-4.657, 2.891],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.958, -0.557],
                    [0, 0],
                    [-0.006, 1.673],
                  ],
                  o: [
                    [-0.004, 1.672],
                    [0, 0],
                    [0.957, -0.557],
                    [0, 0],
                  ],
                  v: [
                    [-3.099, -0.519],
                    [-4.657, 2.89],
                    [3.728, -1.982],
                    [5.287, -5.392],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [493.182, 543.723], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 42",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.061, 0.129],
                    [0, 0],
                    [0.073, -0.115],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.061, 0.129],
                    [0, 0],
                    [0.073, -0.114],
                  ],
                  v: [
                    [-4.092, 2.254],
                    [4.293, -2.619],
                    [4.091, -2.253],
                    [-4.293, 2.619],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.958, -0.557],
                    [0, 0],
                    [-0.006, 1.673],
                  ],
                  o: [
                    [-0.004, 1.672],
                    [0, 0],
                    [0.957, -0.557],
                    [0, 0],
                  ],
                  v: [
                    [-3.664, 0.118],
                    [-5.222, 3.527],
                    [3.163, -1.345],
                    [4.722, -4.755],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [493.747, 543.086], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 43",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.039, 0.105],
                    [0, 0],
                    [0.046, -0.097],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.039, 0.105],
                    [0, 0],
                    [0.046, -0.097],
                  ],
                  v: [
                    [-4.129, 2.285],
                    [4.256, -2.588],
                    [4.128, -2.285],
                    [-4.256, 2.587],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.958, -0.557],
                    [0, 0],
                    [-0.006, 1.673],
                  ],
                  o: [
                    [-0.004, 1.672],
                    [0, 0],
                    [0.957, -0.557],
                    [0, 0],
                  ],
                  v: [
                    [-3.829, 0.452],
                    [-5.387, 3.861],
                    [2.998, -1.011],
                    [4.557, -4.421],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [493.912, 542.752], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 44",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.028, 0.096],
                    [0, 0],
                    [0.034, -0.091],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.028, 0.096],
                    [0, 0],
                    [0.034, -0.09],
                  ],
                  v: [
                    [-4.146, 2.297],
                    [4.239, -2.576],
                    [4.146, -2.296],
                    [-4.239, 2.576],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.958, -0.557],
                    [0, 0],
                    [-0.006, 1.673],
                  ],
                  o: [
                    [-0.004, 1.672],
                    [0, 0],
                    [0.957, -0.557],
                    [0, 0],
                  ],
                  v: [
                    [-3.938, 0.742],
                    [-5.496, 4.151],
                    [2.889, -0.721],
                    [4.448, -4.131],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [494.021, 542.462], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 45",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.021, 0.093],
                    [0, 0],
                    [0.026, -0.088],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.021, 0.093],
                    [0, 0],
                    [0.025, -0.088],
                  ],
                  v: [
                    [-4.158, 2.301],
                    [4.227, -2.572],
                    [4.156, -2.301],
                    [-4.228, 2.572],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.958, -0.557],
                    [0, 0],
                    [-0.006, 1.673],
                  ],
                  o: [
                    [-0.004, 1.672],
                    [0, 0],
                    [0.957, -0.557],
                    [0, 0],
                  ],
                  v: [
                    [-4.021, 1.017],
                    [-5.579, 4.426],
                    [2.806, -0.446],
                    [4.365, -3.856],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [494.104, 542.187], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 46",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.015, 0.088],
                    [0, 0],
                    [0.019, -0.085],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.016, 0.089],
                    [0, 0],
                    [0.019, -0.085],
                  ],
                  v: [
                    [-4.167, 2.307],
                    [4.218, -2.567],
                    [4.166, -2.307],
                    [-4.219, 2.567],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.958, -0.557],
                    [0, 0],
                    [-0.006, 1.673],
                  ],
                  o: [
                    [-0.004, 1.672],
                    [0, 0],
                    [0.957, -0.557],
                    [0, 0],
                  ],
                  v: [
                    [-4.082, 1.282],
                    [-5.64, 4.691],
                    [2.745, -0.181],
                    [4.304, -3.591],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [494.165, 541.922], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 47",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.011, 0.086],
                    [0, 0],
                    [0.014, -0.082],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.011, 0.086],
                    [0, 0],
                    [0.015, -0.082],
                  ],
                  v: [
                    [-4.173, 2.31],
                    [4.211, -2.563],
                    [4.173, -2.31],
                    [-4.212, 2.563],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.958, -0.557],
                    [0, 0],
                    [-0.006, 1.673],
                  ],
                  o: [
                    [-0.004, 1.672],
                    [0, 0],
                    [0.957, -0.557],
                    [0, 0],
                  ],
                  v: [
                    [-4.126, 1.538],
                    [-5.684, 4.947],
                    [2.701, 0.075],
                    [4.26, -3.335],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [494.209, 541.666], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 48",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.007, 0.086],
                    [0, 0],
                    [0.011, -0.084],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.007, 0.087],
                    [0, 0],
                    [0.01, -0.083],
                  ],
                  v: [
                    [-4.179, 2.31],
                    [4.206, -2.563],
                    [4.178, -2.308],
                    [-4.205, 2.564],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.958, -0.557],
                    [0, 0],
                    [-0.006, 1.673],
                  ],
                  o: [
                    [-0.004, 1.672],
                    [0, 0],
                    [0.957, -0.557],
                    [0, 0],
                  ],
                  v: [
                    [-4.159, 1.791],
                    [-5.717, 5.2],
                    [2.668, 0.328],
                    [4.227, -3.082],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [494.242, 541.413], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 49",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.004, 0.084],
                    [0, 0],
                    [0.007, -0.082],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.004, 0.084],
                    [0, 0],
                    [0.006, -0.081],
                  ],
                  v: [
                    [-4.185, 2.312],
                    [4.2, -2.56],
                    [4.184, -2.312],
                    [-4.2, 2.56],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.958, -0.557],
                    [0, 0],
                    [-0.006, 1.673],
                  ],
                  o: [
                    [-0.004, 1.672],
                    [0, 0],
                    [0.957, -0.557],
                    [0, 0],
                  ],
                  v: [
                    [-4.18, 2.043],
                    [-5.738, 5.452],
                    [2.647, 0.58],
                    [4.206, -2.83],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [494.263, 541.161], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 50",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0.086],
                    [0, 0],
                    [0.003, -0.084],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.001, 0.086],
                    [0, 0],
                    [0.004, -0.083],
                  ],
                  v: [
                    [-4.189, 2.309],
                    [4.195, -2.564],
                    [4.19, -2.309],
                    [-4.195, 2.563],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.958, -0.557],
                    [0, 0],
                    [-0.006, 1.673],
                  ],
                  o: [
                    [-0.004, 1.672],
                    [0, 0],
                    [0.957, -0.557],
                    [0, 0],
                  ],
                  v: [
                    [-4.19, 2.294],
                    [-5.748, 5.703],
                    [2.637, 0.831],
                    [4.196, -2.579],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [494.273, 540.91], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 51",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-4.035, 2.269],
                    [4.035, -2.444],
                    [4.035, -2.429],
                    [-3.965, 2.444],
                    [-3.965, 2.225],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.958, -0.557],
                    [0, 0],
                    [-0.006, 1.673],
                  ],
                  o: [
                    [-0.004, 1.672],
                    [0, 0],
                    [0.957, -0.557],
                    [0, 0],
                  ],
                  v: [
                    [-3.882, 2.429],
                    [-5.44, 5.838],
                    [2.945, 0.966],
                    [4.504, -2.444],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [493.965, 540.775], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 52",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.958, -0.557],
                    [0, 0],
                    [-0.005, 1.672],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.958, -0.557],
                    [0, 0],
                    [-0.005, 1.672],
                  ],
                  v: [
                    [3.413, -0.732],
                    [-4.972, 4.141],
                    [-3.413, 0.732],
                    [4.972, -4.141],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [493.497, 542.472], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 53",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.056, 0.03],
                    [0, 0],
                    [-0.056, -0.032],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.055, 0.03],
                    [0, 0],
                    [-0.056, -0.033],
                  ],
                  v: [
                    [-4.276, 2.39],
                    [4.109, -2.483],
                    [4.276, -2.389],
                    [-4.108, 2.484],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.409, -0.238],
                    [0, 0],
                    [-1.477, -0.853],
                    [0, 0],
                    [0.719, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.971, -0.565],
                    [0, 0],
                    [-0.854, -0.493],
                    [-0.524, 0],
                  ],
                  v: [
                    [0.485, -2.754],
                    [-7.9, 2.119],
                    [-4.108, 2.483],
                    [4.276, -2.39],
                    [1.894, -3.116],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [308.299, 297.712], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 54",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.072, 0.035],
                    [0, 0],
                    [-0.072, -0.039],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.071, 0.036],
                    [0, 0],
                    [-0.072, -0.039],
                  ],
                  v: [
                    [-4.3, 2.38],
                    [4.085, -2.493],
                    [4.301, -2.381],
                    [-4.085, 2.492],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.409, -0.238],
                    [0, 0],
                    [-1.477, -0.853],
                    [0, 0],
                    [0.719, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.971, -0.565],
                    [0, 0],
                    [-0.854, -0.493],
                    [-0.524, 0],
                  ],
                  v: [
                    [0.677, -2.651],
                    [-7.708, 2.222],
                    [-3.916, 2.586],
                    [4.468, -2.287],
                    [2.086, -3.013],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [308.107, 297.609], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 55",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.071, 0.032],
                    [0, 0],
                    [-0.072, -0.035],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.071, 0.032],
                    [0, 0],
                    [-0.072, -0.036],
                  ],
                  v: [
                    [-4.299, 2.386],
                    [4.085, -2.487],
                    [4.299, -2.386],
                    [-4.086, 2.487],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.409, -0.238],
                    [0, 0],
                    [-1.477, -0.853],
                    [0, 0],
                    [0.719, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.971, -0.565],
                    [0, 0],
                    [-0.854, -0.493],
                    [-0.524, 0],
                  ],
                  v: [
                    [0.892, -2.544],
                    [-7.493, 2.329],
                    [-3.701, 2.693],
                    [4.683, -2.18],
                    [2.301, -2.906],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [307.892, 297.502], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 56",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.07, 0.028],
                    [0, 0],
                    [-0.07, -0.032],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.07, 0.028],
                    [0, 0],
                    [-0.071, -0.032],
                  ],
                  v: [
                    [-4.298, 2.391],
                    [4.087, -2.482],
                    [4.298, -2.392],
                    [-4.086, 2.482],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.409, -0.238],
                    [0, 0],
                    [-1.477, -0.853],
                    [0, 0],
                    [0.719, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.971, -0.565],
                    [0, 0],
                    [-0.854, -0.493],
                    [-0.524, 0],
                  ],
                  v: [
                    [1.105, -2.449],
                    [-7.28, 2.424],
                    [-3.488, 2.788],
                    [4.896, -2.085],
                    [2.514, -2.811],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [307.679, 297.407], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 57",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.071, 0.025],
                    [0, 0],
                    [-0.072, -0.029],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.071, 0.025],
                    [0, 0],
                    [-0.072, -0.028],
                  ],
                  v: [
                    [-4.299, 2.396],
                    [4.085, -2.477],
                    [4.299, -2.396],
                    [-4.086, 2.477],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.409, -0.238],
                    [0, 0],
                    [-1.477, -0.853],
                    [0, 0],
                    [0.719, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.971, -0.565],
                    [0, 0],
                    [-0.854, -0.493],
                    [-0.524, 0],
                  ],
                  v: [
                    [1.317, -2.363],
                    [-7.068, 2.509],
                    [-3.276, 2.874],
                    [5.108, -2],
                    [2.726, -2.725],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [307.467, 297.322], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 58",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.07, 0.021],
                    [0, 0],
                    [-0.071, -0.025],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.07, 0.022],
                    [0, 0],
                    [-0.072, -0.026],
                  ],
                  v: [
                    [-4.298, 2.401],
                    [4.086, -2.471],
                    [4.298, -2.401],
                    [-4.086, 2.471],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.409, -0.238],
                    [0, 0],
                    [-1.477, -0.853],
                    [0, 0],
                    [0.719, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.971, -0.565],
                    [0, 0],
                    [-0.854, -0.493],
                    [-0.524, 0],
                  ],
                  v: [
                    [1.531, -2.289],
                    [-6.854, 2.584],
                    [-3.062, 2.948],
                    [5.322, -1.925],
                    [2.94, -2.651],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [307.253, 297.247], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 59",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.073, 0.018],
                    [0, 0],
                    [-0.074, -0.022],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.072, 0.018],
                    [0, 0],
                    [-0.074, -0.023],
                  ],
                  v: [
                    [-4.302, 2.406],
                    [4.083, -2.467],
                    [4.302, -2.406],
                    [-4.082, 2.467],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.409, -0.238],
                    [0, 0],
                    [-1.477, -0.853],
                    [0, 0],
                    [0.719, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.971, -0.565],
                    [0, 0],
                    [-0.854, -0.493],
                    [-0.524, 0],
                  ],
                  v: [
                    [1.747, -2.224],
                    [-6.638, 2.649],
                    [-2.846, 3.013],
                    [5.538, -1.86],
                    [3.156, -2.586],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [307.037, 297.182], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 60",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.072, 0.014],
                    [0, 0],
                    [-0.073, -0.018],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.072, 0.014],
                    [0, 0],
                    [-0.073, -0.019],
                  ],
                  v: [
                    [-4.302, 2.412],
                    [4.083, -2.461],
                    [4.301, -2.412],
                    [-4.084, 2.461],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.409, -0.238],
                    [0, 0],
                    [-1.477, -0.853],
                    [0, 0],
                    [0.719, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.971, -0.565],
                    [0, 0],
                    [-0.854, -0.493],
                    [-0.524, 0],
                  ],
                  v: [
                    [1.966, -2.169],
                    [-6.419, 2.704],
                    [-2.627, 3.068],
                    [5.757, -1.805],
                    [3.375, -2.531],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [306.818, 297.127], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 61",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.077, 0.01],
                    [0, 0],
                    [-0.079, -0.016],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.077, 0.011],
                    [0, 0],
                    [-0.078, -0.015],
                  ],
                  v: [
                    [-4.309, 2.417],
                    [4.076, -2.456],
                    [4.309, -2.417],
                    [-4.076, 2.456],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.409, -0.238],
                    [0, 0],
                    [-1.477, -0.853],
                    [0, 0],
                    [0.719, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.971, -0.565],
                    [0, 0],
                    [-0.854, -0.493],
                    [-0.524, 0],
                  ],
                  v: [
                    [2.192, -2.125],
                    [-6.193, 2.748],
                    [-2.401, 3.112],
                    [5.983, -1.761],
                    [3.601, -2.487],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [306.592, 297.083], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 62",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.078, 0.005],
                    [0, 0],
                    [-0.08, -0.011],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.078, 0.006],
                    [0, 0],
                    [-0.08, -0.011],
                  ],
                  v: [
                    [-4.311, 2.424],
                    [4.074, -2.449],
                    [4.311, -2.424],
                    [-4.074, 2.448],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.409, -0.238],
                    [0, 0],
                    [-1.477, -0.853],
                    [0, 0],
                    [0.719, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.971, -0.565],
                    [0, 0],
                    [-0.854, -0.493],
                    [-0.524, 0],
                  ],
                  v: [
                    [2.427, -2.094],
                    [-5.958, 2.779],
                    [-2.166, 3.143],
                    [6.218, -1.73],
                    [3.836, -2.456],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [306.357, 297.052], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 63",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.083, 0],
                    [0, 0],
                    [-0.085, -0.006],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.082, 0],
                    [0, 0],
                    [-0.085, -0.006],
                  ],
                  v: [
                    [-4.318, 2.432],
                    [4.067, -2.441],
                    [4.318, -2.432],
                    [-4.066, 2.44],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.409, -0.238],
                    [0, 0],
                    [-1.477, -0.853],
                    [0, 0],
                    [0.719, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.971, -0.565],
                    [0, 0],
                    [-0.854, -0.493],
                    [-0.524, 0],
                  ],
                  v: [
                    [2.671, -2.078],
                    [-5.714, 2.795],
                    [-1.922, 3.159],
                    [6.462, -1.714],
                    [4.08, -2.44],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [306.113, 297.036], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 64",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.091, -0.008],
                    [0, 0],
                    [-0.094, 0.001],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.091, -0.008],
                    [0, 0],
                    [-0.094, 0],
                  ],
                  v: [
                    [-4.331, 2.443],
                    [4.054, -2.43],
                    [4.331, -2.443],
                    [-4.054, 2.43],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.409, -0.238],
                    [0, 0],
                    [-1.477, -0.853],
                    [0, 0],
                    [0.719, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.971, -0.565],
                    [0, 0],
                    [-0.854, -0.493],
                    [-0.524, 0],
                  ],
                  v: [
                    [2.936, -2.081],
                    [-5.449, 2.792],
                    [-1.657, 3.156],
                    [6.727, -1.717],
                    [4.345, -2.443],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [305.848, 297.039], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 65",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.101, -0.02],
                    [0, 0],
                    [-0.105, 0.009],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.1, -0.02],
                    [0, 0],
                    [-0.106, 0.01],
                  ],
                  v: [
                    [-4.346, 2.458],
                    [4.038, -2.414],
                    [4.346, -2.458],
                    [-4.038, 2.414],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.409, -0.238],
                    [0, 0],
                    [-1.477, -0.853],
                    [0, 0],
                    [0.719, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.971, -0.565],
                    [0, 0],
                    [-0.854, -0.493],
                    [-0.524, 0],
                  ],
                  v: [
                    [3.229, -2.11],
                    [-5.156, 2.763],
                    [-1.364, 3.127],
                    [7.02, -1.746],
                    [4.638, -2.472],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [305.555, 297.068], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 66",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.121, -0.043],
                    [0, 0],
                    [-0.129, 0.026],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.121, -0.042],
                    [0, 0],
                    [-0.129, 0.026],
                  ],
                  v: [
                    [-4.38, 2.488],
                    [4.005, -2.385],
                    [4.38, -2.488],
                    [-4.005, 2.385],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.409, -0.238],
                    [0, 0],
                    [-1.477, -0.853],
                    [0, 0],
                    [0.719, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.971, -0.565],
                    [0, 0],
                    [-0.854, -0.493],
                    [-0.524, 0],
                  ],
                  v: [
                    [3.57, -2.183],
                    [-4.815, 2.69],
                    [-1.023, 3.054],
                    [7.361, -1.819],
                    [4.979, -2.545],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [305.214, 297.141], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 67",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.138, -0.08],
                    [0, 0],
                    [-0.151, 0.054],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.137, -0.08],
                    [0, 0],
                    [-0.152, 0.054],
                  ],
                  v: [
                    [-4.41, 2.537],
                    [3.975, -2.336],
                    [4.409, -2.537],
                    [-3.975, 2.336],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.409, -0.238],
                    [0, 0],
                    [-1.477, -0.853],
                    [0, 0],
                    [0.719, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.971, -0.565],
                    [0, 0],
                    [-0.854, -0.493],
                    [-0.524, 0],
                  ],
                  v: [
                    [3.975, -2.336],
                    [-4.41, 2.537],
                    [-0.618, 2.901],
                    [7.766, -1.972],
                    [5.384, -2.698],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [304.809, 297.294], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 68",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.971, -0.564],
                    [0, 0],
                    [-1.477, -0.853],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.971, -0.564],
                    [0, 0],
                    [-1.477, -0.852],
                  ],
                  v: [
                    [-6.088, 2.536],
                    [2.297, -2.336],
                    [6.088, -1.971],
                    [-2.296, 2.901],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [306.487, 297.294], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 12,
    },
    {
      ty: 4,
      nm: "middle_platform Outlines",
      sr: 1,
      st: 0,
      op: 120,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [576, 360, 0] },
        s: { a: 0, k: [100, 100, 100] },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [576, 360, 0] },
        r: { a: 0, k: 0 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 1",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [160.303, 15.366],
                    [27.052, 92.803],
                    [-160.304, -15.366],
                    [-27.053, -92.803],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [595.285, 352.393], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 2",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [93.7, 46.429],
                    [93.656, 61.741],
                    [-93.699, -46.429],
                    [-93.656, -61.741],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [528.638, 398.767], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 3",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [66.647, -46.375],
                    [66.604, -31.063],
                    [-66.647, 46.375],
                    [-66.604, 31.063],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [688.942, 414.134], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 13,
    },
    {
      ty: 4,
      nm: "top_right_objects_shadow Outlines",
      sr: 1,
      st: 0,
      op: 120,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [576, 360, 0] },
        s: { a: 0, k: [100, 100, 100] },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [576, 360, 0] },
        r: { a: 0, k: 0 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 1",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, -7.218],
                    [13.218, 0],
                    [0, 7.218],
                    [-13.218, 0],
                  ],
                  o: [
                    [0, 7.218],
                    [-13.218, 0],
                    [0, -7.218],
                    [13.218, 0],
                  ],
                  v: [
                    [23.933, 0],
                    [0, 13.069],
                    [-23.933, 0],
                    [0, -13.069],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [823.834, 215.88], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 2",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-11.266, -6.504],
                    [11.188, -6.502],
                    [11.265, 6.504],
                    [-11.192, 6.504],
                  ],
                  o: [
                    [11.266, 6.504],
                    [-11.193, 6.504],
                    [-11.266, -6.504],
                    [11.188, -6.502],
                  ],
                  v: [
                    [20.266, -11.777],
                    [20.398, 11.775],
                    [-20.266, 11.777],
                    [-20.395, -11.777],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [824.099, 217.648], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 3",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-1.46, -0.576],
                    [0, 0],
                    [1.297, 0.749],
                    [-0.012, 4.289],
                    [0, 0],
                    [-5.668, -3.273],
                  ],
                  o: [
                    [0, 0],
                    [-1.46, -0.576],
                    [-5.668, -3.273],
                    [0, 0],
                    [-0.012, 4.289],
                    [1.297, 0.749],
                  ],
                  v: [
                    [6.342, 0.032],
                    [6.303, 13.813],
                    [2.156, 11.825],
                    [-6.33, -0.032],
                    [-6.291, -13.813],
                    [2.195, -1.956],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.272],
                    [-11.192, 6.504],
                    [-0.013, 4.235],
                    [0, 0],
                    [5.558, -3.23],
                    [11.266, 6.505],
                    [-0.013, 4.288],
                  ],
                  o: [
                    [-0.011, 4.289],
                    [11.266, 6.504],
                    [5.559, -3.231],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.504],
                    [-5.668, -3.272],
                    [0, 0],
                  ],
                  v: [
                    [-6.331, -0.032],
                    [2.156, 11.824],
                    [42.82, 11.822],
                    [51.174, 0.128],
                    [51.213, -13.653],
                    [42.859, -1.959],
                    [2.195, -1.957],
                    [-6.291, -13.813],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [801.638, 231.382], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 4",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-1.199, -0.342],
                    [0, 0],
                    [1.125, 0.444],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.199, -0.342],
                    [0, 0],
                    [1.125, 0.444],
                  ],
                  v: [
                    [1.765, -6.301],
                    [1.726, 7.48],
                    [-1.765, 6.301],
                    [-1.726, -7.48],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.272],
                    [-11.192, 6.504],
                    [-0.013, 4.235],
                    [0, 0],
                    [5.558, -3.23],
                    [11.266, 6.505],
                    [-0.013, 4.288],
                  ],
                  o: [
                    [-0.011, 4.289],
                    [11.266, 6.504],
                    [5.559, -3.231],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.504],
                    [-5.668, -3.272],
                    [0, 0],
                  ],
                  v: [
                    [-14.399, -7.543],
                    [-5.912, 4.313],
                    [34.752, 4.311],
                    [43.106, -7.383],
                    [43.145, -21.164],
                    [34.791, -9.47],
                    [-5.873, -9.468],
                    [-14.359, -21.324],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [809.706, 238.893], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 5",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-1.017, -0.211],
                    [0, 0],
                    [0.98, 0.279],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.018, -0.211],
                    [0, 0],
                    [0.979, 0.279],
                  ],
                  v: [
                    [1.519, -6.523],
                    [1.48, 7.258],
                    [-1.519, 6.523],
                    [-1.479, -7.258],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.272],
                    [-11.192, 6.504],
                    [-0.013, 4.235],
                    [0, 0],
                    [5.558, -3.23],
                    [11.266, 6.505],
                    [-0.013, 4.288],
                  ],
                  o: [
                    [-0.011, 4.289],
                    [11.266, 6.504],
                    [5.559, -3.231],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.504],
                    [-5.668, -3.272],
                    [0, 0],
                  ],
                  v: [
                    [-17.643, -8.5],
                    [-9.156, 3.356],
                    [31.508, 3.354],
                    [39.862, -8.34],
                    [39.901, -22.121],
                    [31.547, -10.427],
                    [-9.117, -10.425],
                    [-17.603, -22.281],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [812.95, 239.85], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 6",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.961, -0.136],
                    [0, 0],
                    [0.937, 0.195],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.961, -0.136],
                    [0, 0],
                    [0.938, 0.195],
                  ],
                  v: [
                    [1.445, -6.643],
                    [1.406, 7.138],
                    [-1.445, 6.642],
                    [-1.406, -7.138],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.272],
                    [-11.192, 6.504],
                    [-0.013, 4.235],
                    [0, 0],
                    [5.558, -3.23],
                    [11.266, 6.505],
                    [-0.013, 4.288],
                  ],
                  o: [
                    [-0.011, 4.289],
                    [11.266, 6.504],
                    [5.559, -3.231],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.504],
                    [-5.668, -3.272],
                    [0, 0],
                  ],
                  v: [
                    [-20.568, -9.116],
                    [-12.081, 2.74],
                    [28.583, 2.738],
                    [36.937, -8.956],
                    [36.976, -22.737],
                    [28.622, -11.043],
                    [-12.042, -11.041],
                    [-20.528, -22.897],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [815.875, 240.466], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 7",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.938, -0.077],
                    [0, 0],
                    [0.924, 0.131],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.938, -0.077],
                    [0, 0],
                    [0.924, 0.131],
                  ],
                  v: [
                    [1.417, -6.735],
                    [1.378, 7.046],
                    [-1.417, 6.735],
                    [-1.378, -7.046],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.272],
                    [-11.192, 6.504],
                    [-0.013, 4.235],
                    [0, 0],
                    [5.558, -3.23],
                    [11.266, 6.505],
                    [-0.013, 4.288],
                  ],
                  o: [
                    [-0.011, 4.289],
                    [11.266, 6.504],
                    [5.559, -3.231],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.504],
                    [-5.668, -3.272],
                    [0, 0],
                  ],
                  v: [
                    [-23.391, -9.52],
                    [-14.904, 2.336],
                    [25.76, 2.334],
                    [34.114, -9.36],
                    [34.153, -23.141],
                    [25.799, -11.447],
                    [-14.865, -11.445],
                    [-23.351, -23.301],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [818.698, 240.87], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 8",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.947, -0.024],
                    [0, 0],
                    [0.94, 0.078],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.947, -0.024],
                    [0, 0],
                    [0.94, 0.077],
                  ],
                  v: [
                    [1.435, -6.815],
                    [1.396, 6.966],
                    [-1.436, 6.814],
                    [-1.397, -6.966],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.272],
                    [-11.192, 6.504],
                    [-0.013, 4.235],
                    [0, 0],
                    [5.558, -3.23],
                    [11.266, 6.505],
                    [-0.013, 4.288],
                  ],
                  o: [
                    [-0.011, 4.289],
                    [11.266, 6.504],
                    [5.559, -3.231],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.504],
                    [-5.668, -3.272],
                    [0, 0],
                  ],
                  v: [
                    [-26.204, -9.752],
                    [-17.717, 2.104],
                    [22.947, 2.102],
                    [31.301, -9.592],
                    [31.34, -23.373],
                    [22.986, -11.679],
                    [-17.678, -11.677],
                    [-26.164, -23.533],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [821.511, 241.102], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 9",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.981, 0.034],
                    [0, 0],
                    [0.981, 0.024],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.981, 0.033],
                    [0, 0],
                    [0.981, 0.024],
                  ],
                  v: [
                    [1.492, -6.91],
                    [1.453, 6.872],
                    [-1.492, 6.886],
                    [-1.453, -6.895],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.272],
                    [-11.192, 6.504],
                    [-0.013, 4.235],
                    [0, 0],
                    [5.558, -3.23],
                    [11.266, 6.505],
                    [-0.013, 4.288],
                  ],
                  o: [
                    [-0.011, 4.289],
                    [11.266, 6.504],
                    [5.559, -3.231],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.504],
                    [-5.668, -3.272],
                    [0, 0],
                  ],
                  v: [
                    [-29.093, -9.833],
                    [-20.606, 2.023],
                    [20.058, 2.021],
                    [28.412, -9.673],
                    [28.451, -23.454],
                    [20.097, -11.76],
                    [-20.567, -11.758],
                    [-29.053, -23.614],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [824.4, 241.183], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 10",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-1.056, 0.105],
                    [0, 0],
                    [1.068, -0.036],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.057, 0.105],
                    [0, 0],
                    [1.068, -0.037],
                  ],
                  v: [
                    [1.614, -6.997],
                    [1.575, 6.785],
                    [-1.614, 6.997],
                    [-1.575, -6.783],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.272],
                    [-11.192, 6.504],
                    [-0.013, 4.235],
                    [0, 0],
                    [5.558, -3.23],
                    [11.266, 6.505],
                    [-0.013, 4.288],
                  ],
                  o: [
                    [-0.011, 4.289],
                    [11.266, 6.504],
                    [5.559, -3.231],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.504],
                    [-5.668, -3.272],
                    [0, 0],
                  ],
                  v: [
                    [-32.16, -9.707],
                    [-23.673, 2.149],
                    [16.991, 2.147],
                    [25.345, -9.547],
                    [25.384, -23.328],
                    [17.03, -11.634],
                    [-23.634, -11.632],
                    [-32.12, -23.488],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [827.467, 241.057], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 11",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-1.206, 0.217],
                    [0, 0],
                    [1.238, -0.123],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.206, 0.217],
                    [0, 0],
                    [1.238, -0.123],
                  ],
                  v: [
                    [1.854, -7.146],
                    [1.815, 6.635],
                    [-1.854, 7.146],
                    [-1.815, -6.635],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.272],
                    [-11.192, 6.504],
                    [-0.013, 4.235],
                    [0, 0],
                    [5.558, -3.23],
                    [11.266, 6.505],
                    [-0.013, 4.288],
                  ],
                  o: [
                    [-0.011, 4.289],
                    [11.266, 6.504],
                    [5.559, -3.231],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.504],
                    [-5.668, -3.272],
                    [0, 0],
                  ],
                  v: [
                    [-35.589, -9.346],
                    [-27.102, 2.51],
                    [13.562, 2.508],
                    [21.916, -9.186],
                    [21.955, -22.967],
                    [13.601, -11.273],
                    [-27.063, -11.271],
                    [-35.549, -23.127],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [830.896, 240.696], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 12",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-1.745, 0.566],
                    [0, 0],
                    [1.874, -0.338],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.745, 0.566],
                    [0, 0],
                    [1.875, -0.338],
                  ],
                  v: [
                    [2.742, -7.569],
                    [2.703, 6.212],
                    [-2.742, 7.569],
                    [-2.703, -6.212],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.272],
                    [-11.192, 6.504],
                    [-0.013, 4.235],
                    [0, 0],
                    [5.558, -3.23],
                    [11.266, 6.505],
                    [-0.013, 4.288],
                  ],
                  o: [
                    [-0.011, 4.289],
                    [11.266, 6.504],
                    [5.559, -3.231],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.504],
                    [-5.668, -3.272],
                    [0, 0],
                  ],
                  v: [
                    [-40.145, -8.413],
                    [-31.658, 3.443],
                    [9.006, 3.441],
                    [17.36, -8.253],
                    [17.399, -22.034],
                    [9.045, -10.34],
                    [-31.619, -10.338],
                    [-40.105, -22.194],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [835.452, 239.763], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 13",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.116, 0.07],
                    [0, 0],
                    [0.118, -0.068],
                    [2.26, -0.733],
                    [0, 0],
                    [-1.918, 1.115],
                  ],
                  o: [
                    [0, 0],
                    [-0.116, 0.07],
                    [-1.918, 1.115],
                    [0, 0],
                    [2.26, -0.733],
                    [0.118, -0.068],
                  ],
                  v: [
                    [3.346, -8.38],
                    [3.307, 5.401],
                    [2.956, 5.608],
                    [-3.346, 8.38],
                    [-3.307, -5.401],
                    [2.995, -8.173],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.272],
                    [-11.192, 6.504],
                    [-0.013, 4.235],
                    [0, 0],
                    [5.558, -3.23],
                    [11.266, 6.505],
                    [-0.013, 4.288],
                  ],
                  o: [
                    [-0.011, 4.289],
                    [11.266, 6.504],
                    [5.559, -3.231],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.504],
                    [-5.668, -3.272],
                    [0, 0],
                  ],
                  v: [
                    [-46.195, -6.246],
                    [-37.708, 5.61],
                    [2.956, 5.608],
                    [11.31, -6.086],
                    [11.349, -19.867],
                    [2.995, -8.173],
                    [-37.669, -8.171],
                    [-46.155, -20.027],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [841.502, 237.596], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 14",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-1.031, 0.952],
                    [0, 0],
                    [1.396, -0.84],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.031, 0.952],
                    [0, 0],
                    [1.396, -0.84],
                  ],
                  v: [
                    [1.84, -8.238],
                    [1.801, 5.543],
                    [-1.839, 8.238],
                    [-1.8, -5.543],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.272],
                    [-11.192, 6.504],
                    [-0.013, 4.235],
                    [0, 0],
                    [5.558, -3.23],
                    [11.266, 6.505],
                    [-0.013, 4.288],
                  ],
                  o: [
                    [-0.011, 4.289],
                    [11.266, 6.504],
                    [5.559, -3.231],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.504],
                    [-5.668, -3.272],
                    [0, 0],
                  ],
                  v: [
                    [-51.341, -3.408],
                    [-42.854, 8.448],
                    [-2.19, 8.446],
                    [6.164, -3.248],
                    [6.203, -17.029],
                    [-2.151, -5.335],
                    [-42.815, -5.333],
                    [-51.301, -17.189],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [846.648, 234.758], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 15",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.517, 0.656],
                    [0, 0],
                    [0.672, -0.62],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.517, 0.656],
                    [0, 0],
                    [0.672, -0.62],
                  ],
                  v: [
                    [0.911, -7.849],
                    [0.872, 5.932],
                    [-0.911, 7.849],
                    [-0.872, -5.932],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.272],
                    [-11.192, 6.504],
                    [-0.013, 4.235],
                    [0, 0],
                    [5.558, -3.23],
                    [11.266, 6.505],
                    [-0.013, 4.288],
                  ],
                  o: [
                    [-0.011, 4.289],
                    [11.266, 6.504],
                    [5.559, -3.231],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.504],
                    [-5.668, -3.272],
                    [0, 0],
                  ],
                  v: [
                    [-54.053, -1.102],
                    [-45.566, 10.754],
                    [-4.902, 10.752],
                    [3.452, -0.942],
                    [3.491, -14.723],
                    [-4.863, -3.029],
                    [-45.527, -3.027],
                    [-54.013, -14.883],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [849.36, 232.452], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 16",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.329, 0.582],
                    [0, 0],
                    [0.443, -0.562],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.329, 0.582],
                    [0, 0],
                    [0.443, -0.562],
                  ],
                  v: [
                    [0.598, -7.75],
                    [0.559, 6.031],
                    [-0.598, 7.75],
                    [-0.559, -6.031],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.272],
                    [-11.192, 6.504],
                    [-0.013, 4.235],
                    [0, 0],
                    [5.558, -3.23],
                    [11.266, 6.505],
                    [-0.013, 4.288],
                  ],
                  o: [
                    [-0.011, 4.289],
                    [11.266, 6.504],
                    [5.559, -3.231],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.504],
                    [-5.668, -3.272],
                    [0, 0],
                  ],
                  v: [
                    [-55.523, 0.715],
                    [-47.036, 12.571],
                    [-6.372, 12.569],
                    [1.982, 0.875],
                    [2.021, -12.906],
                    [-6.333, -1.212],
                    [-46.997, -1.21],
                    [-55.483, -13.066],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [850.83, 230.635], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 17",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.207, 0.551],
                    [0, 0],
                    [0.304, -0.539],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.207, 0.551],
                    [0, 0],
                    [0.304, -0.539],
                  ],
                  v: [
                    [0.403, -7.708],
                    [0.364, 6.073],
                    [-0.403, 7.708],
                    [-0.364, -6.073],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.272],
                    [-11.192, 6.504],
                    [-0.013, 4.235],
                    [0, 0],
                    [5.558, -3.23],
                    [11.266, 6.505],
                    [-0.013, 4.288],
                  ],
                  o: [
                    [-0.011, 4.289],
                    [11.266, 6.504],
                    [5.559, -3.231],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.504],
                    [-5.668, -3.272],
                    [0, 0],
                  ],
                  v: [
                    [-56.484, 2.392],
                    [-47.997, 14.248],
                    [-7.333, 14.246],
                    [1.021, 2.552],
                    [1.06, -11.229],
                    [-7.294, 0.465],
                    [-47.958, 0.467],
                    [-56.444, -11.389],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [851.791, 228.958], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 18",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.109, 0.543],
                    [0, 0],
                    [0.201, -0.536],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.109, 0.543],
                    [0, 0],
                    [0.201, -0.536],
                  ],
                  v: [
                    [0.252, -7.7],
                    [0.213, 6.081],
                    [-0.252, 7.7],
                    [-0.213, -6.081],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.272],
                    [-11.192, 6.504],
                    [-0.013, 4.235],
                    [0, 0],
                    [5.558, -3.23],
                    [11.266, 6.505],
                    [-0.013, 4.288],
                  ],
                  o: [
                    [-0.011, 4.289],
                    [11.266, 6.504],
                    [5.559, -3.231],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.504],
                    [-5.668, -3.272],
                    [0, 0],
                  ],
                  v: [
                    [-57.099, 4.019],
                    [-48.612, 15.875],
                    [-7.948, 15.873],
                    [0.406, 4.179],
                    [0.445, -9.602],
                    [-7.909, 2.092],
                    [-48.573, 2.094],
                    [-57.059, -9.762],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [852.406, 227.331], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 19",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.016, 0.551],
                    [0, 0],
                    [0.11, -0.548],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.016, 0.551],
                    [0, 0],
                    [0.11, -0.548],
                  ],
                  v: [
                    [0.114, -7.715],
                    [0.075, 6.066],
                    [-0.114, 7.715],
                    [-0.075, -6.065],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.272],
                    [-11.192, 6.504],
                    [-0.013, 4.235],
                    [0, 0],
                    [5.558, -3.23],
                    [11.266, 6.505],
                    [-0.013, 4.288],
                  ],
                  o: [
                    [-0.011, 4.289],
                    [11.266, 6.504],
                    [5.559, -3.231],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.504],
                    [-5.668, -3.272],
                    [0, 0],
                  ],
                  v: [
                    [-57.426, 5.653],
                    [-48.939, 17.509],
                    [-8.275, 17.507],
                    [0.079, 5.813],
                    [0.118, -7.968],
                    [-8.236, 3.726],
                    [-48.9, 3.728],
                    [-57.386, -8.128],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [852.733, 225.697], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 20",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0.084],
                    [0, 0],
                    [0.002, -0.085],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0.084],
                    [0, 0],
                    [0.002, -0.085],
                  ],
                  v: [
                    [0.022, -7.017],
                    [-0.017, 6.764],
                    [-0.021, 7.017],
                    [0.018, -6.764],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.272],
                    [-11.192, 6.504],
                    [-0.013, 4.235],
                    [0, 0],
                    [5.558, -3.23],
                    [11.266, 6.505],
                    [-0.013, 4.288],
                  ],
                  o: [
                    [-0.011, 4.289],
                    [11.266, 6.504],
                    [5.559, -3.231],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.504],
                    [-5.668, -3.272],
                    [0, 0],
                  ],
                  v: [
                    [-57.522, 6.604],
                    [-49.035, 18.46],
                    [-8.371, 18.458],
                    [-0.017, 6.764],
                    [0.022, -7.017],
                    [-8.332, 4.677],
                    [-48.996, 4.679],
                    [-57.482, -7.177],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [852.829, 224.746], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 21",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.012, 4.235],
                    [0, 0],
                    [5.559, -3.23],
                    [11.265, 6.504],
                    [-0.012, 4.289],
                    [0, 0],
                    [-5.668, -3.273],
                    [-11.192, 6.504],
                  ],
                  o: [
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.504],
                    [-5.668, -3.273],
                    [0, 0],
                    [-0.012, 4.289],
                    [11.266, 6.504],
                    [5.559, -3.23],
                  ],
                  v: [
                    [28.778, -15.911],
                    [28.739, -2.13],
                    [20.385, 9.564],
                    [-20.279, 9.567],
                    [-28.766, -2.29],
                    [-28.727, -16.071],
                    [-20.24, -4.214],
                    [20.424, -4.217],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [824.073, 233.64], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 22",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-5.462, -3.082],
                    [-10.661, 6.338],
                    [-0.075, 3.977],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.029, 4],
                    [10.856, 6.124],
                    [5.19, -3.086],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [28.721, -24.211],
                    [-28.721, -24.464],
                    [-26.786, 7.622],
                    [-26.783, 7.622],
                    [-18.541, 18.34],
                    [20.42, 17.671],
                    [28.304, 6.488],
                    [28.312, 6.415],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [824.029, 256.377], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 23",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-10.855, -6.124],
                    [10.662, -6.338],
                    [10.855, 6.125],
                    [-10.662, 6.339],
                  ],
                  o: [
                    [10.855, 6.124],
                    [-10.661, 6.338],
                    [-10.855, -6.124],
                    [10.661, -6.338],
                  ],
                  v: [
                    [19.305, -11.477],
                    [19.656, 11.089],
                    [-19.304, 11.476],
                    [-19.655, -11.09],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [824.793, 262.676], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 24",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, -7.218],
                    [13.218, 0],
                    [0, 7.218],
                    [-13.218, 0],
                  ],
                  o: [
                    [0, 7.218],
                    [-13.218, 0],
                    [0, -7.218],
                    [13.218, 0],
                  ],
                  v: [
                    [23.933, 0],
                    [0, 13.069],
                    [-23.933, 0],
                    [0, -13.069],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [751.834, 184.88], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 25",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-11.266, -6.504],
                    [11.188, -6.502],
                    [11.265, 6.504],
                    [-11.192, 6.504],
                  ],
                  o: [
                    [11.266, 6.504],
                    [-11.193, 6.504],
                    [-11.266, -6.504],
                    [11.188, -6.502],
                  ],
                  v: [
                    [20.266, -11.777],
                    [20.398, 11.775],
                    [-20.266, 11.777],
                    [-20.395, -11.777],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [752.099, 186.648], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 26",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-1.46, -0.576],
                    [0, 0],
                    [1.297, 0.749],
                    [-0.012, 4.289],
                    [0, 0],
                    [-5.668, -3.273],
                  ],
                  o: [
                    [0, 0],
                    [-1.46, -0.576],
                    [-5.668, -3.273],
                    [0, 0],
                    [-0.012, 4.289],
                    [1.297, 0.749],
                  ],
                  v: [
                    [6.342, 0.032],
                    [6.303, 13.813],
                    [2.156, 11.825],
                    [-6.33, -0.032],
                    [-6.291, -13.813],
                    [2.195, -1.956],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.273],
                    [-11.192, 6.504],
                    [-0.013, 4.235],
                    [0, 0],
                    [5.558, -3.23],
                    [11.266, 6.504],
                    [-0.013, 4.29],
                  ],
                  o: [
                    [-0.011, 4.289],
                    [11.266, 6.504],
                    [5.559, -3.23],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.504],
                    [-5.668, -3.273],
                    [0, 0],
                  ],
                  v: [
                    [-6.331, -0.033],
                    [2.156, 11.825],
                    [42.82, 11.822],
                    [51.174, 0.128],
                    [51.213, -13.653],
                    [42.859, -1.959],
                    [2.195, -1.956],
                    [-6.291, -13.814],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [729.638, 200.382], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 27",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-1.199, -0.342],
                    [0, 0],
                    [1.125, 0.444],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.199, -0.342],
                    [0, 0],
                    [1.125, 0.444],
                  ],
                  v: [
                    [1.765, -6.301],
                    [1.726, 7.48],
                    [-1.765, 6.301],
                    [-1.726, -7.48],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.273],
                    [-11.192, 6.504],
                    [-0.013, 4.235],
                    [0, 0],
                    [5.558, -3.23],
                    [11.266, 6.504],
                    [-0.013, 4.29],
                  ],
                  o: [
                    [-0.011, 4.289],
                    [11.266, 6.504],
                    [5.559, -3.23],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.504],
                    [-5.668, -3.273],
                    [0, 0],
                  ],
                  v: [
                    [-14.399, -7.544],
                    [-5.912, 4.314],
                    [34.752, 4.311],
                    [43.106, -7.383],
                    [43.145, -21.164],
                    [34.791, -9.47],
                    [-5.873, -9.467],
                    [-14.359, -21.325],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [737.706, 207.893], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 28",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-1.017, -0.211],
                    [0, 0],
                    [0.98, 0.279],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.018, -0.211],
                    [0, 0],
                    [0.979, 0.279],
                  ],
                  v: [
                    [1.519, -6.523],
                    [1.48, 7.258],
                    [-1.519, 6.523],
                    [-1.479, -7.258],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.273],
                    [-11.192, 6.504],
                    [-0.013, 4.235],
                    [0, 0],
                    [5.558, -3.23],
                    [11.266, 6.504],
                    [-0.013, 4.29],
                  ],
                  o: [
                    [-0.011, 4.289],
                    [11.266, 6.504],
                    [5.559, -3.23],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.504],
                    [-5.668, -3.273],
                    [0, 0],
                  ],
                  v: [
                    [-17.643, -8.501],
                    [-9.156, 3.357],
                    [31.508, 3.354],
                    [39.862, -8.34],
                    [39.901, -22.121],
                    [31.547, -10.427],
                    [-9.117, -10.424],
                    [-17.603, -22.282],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [740.95, 208.85], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 29",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.961, -0.136],
                    [0, 0],
                    [0.937, 0.194],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.961, -0.136],
                    [0, 0],
                    [0.938, 0.195],
                  ],
                  v: [
                    [1.445, -6.643],
                    [1.406, 7.138],
                    [-1.445, 6.642],
                    [-1.406, -7.138],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.273],
                    [-11.192, 6.504],
                    [-0.013, 4.235],
                    [0, 0],
                    [5.558, -3.23],
                    [11.266, 6.504],
                    [-0.013, 4.29],
                  ],
                  o: [
                    [-0.011, 4.289],
                    [11.266, 6.504],
                    [5.559, -3.23],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.504],
                    [-5.668, -3.273],
                    [0, 0],
                  ],
                  v: [
                    [-20.568, -9.117],
                    [-12.081, 2.741],
                    [28.583, 2.738],
                    [36.937, -8.956],
                    [36.976, -22.737],
                    [28.622, -11.043],
                    [-12.042, -11.039],
                    [-20.528, -22.898],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [743.875, 209.466], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 30",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.938, -0.077],
                    [0, 0],
                    [0.924, 0.131],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.938, -0.077],
                    [0, 0],
                    [0.924, 0.131],
                  ],
                  v: [
                    [1.417, -6.735],
                    [1.378, 7.046],
                    [-1.417, 6.734],
                    [-1.378, -7.046],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.273],
                    [-11.192, 6.504],
                    [-0.013, 4.235],
                    [0, 0],
                    [5.558, -3.23],
                    [11.266, 6.504],
                    [-0.013, 4.29],
                  ],
                  o: [
                    [-0.011, 4.289],
                    [11.266, 6.504],
                    [5.559, -3.23],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.504],
                    [-5.668, -3.273],
                    [0, 0],
                  ],
                  v: [
                    [-23.391, -9.521],
                    [-14.904, 2.337],
                    [25.76, 2.334],
                    [34.114, -9.36],
                    [34.153, -23.141],
                    [25.799, -11.447],
                    [-14.865, -11.444],
                    [-23.351, -23.302],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [746.698, 209.87], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 31",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.947, -0.024],
                    [0, 0],
                    [0.94, 0.078],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.947, -0.024],
                    [0, 0],
                    [0.94, 0.077],
                  ],
                  v: [
                    [1.435, -6.815],
                    [1.396, 6.966],
                    [-1.436, 6.814],
                    [-1.397, -6.966],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.273],
                    [-11.192, 6.504],
                    [-0.013, 4.235],
                    [0, 0],
                    [5.558, -3.23],
                    [11.266, 6.504],
                    [-0.013, 4.29],
                  ],
                  o: [
                    [-0.011, 4.289],
                    [11.266, 6.504],
                    [5.559, -3.23],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.504],
                    [-5.668, -3.273],
                    [0, 0],
                  ],
                  v: [
                    [-26.204, -9.753],
                    [-17.717, 2.105],
                    [22.947, 2.102],
                    [31.301, -9.592],
                    [31.34, -23.373],
                    [22.986, -11.679],
                    [-17.678, -11.676],
                    [-26.164, -23.534],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [749.511, 210.102], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 32",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.981, 0.034],
                    [0, 0],
                    [0.981, 0.024],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.981, 0.033],
                    [0, 0],
                    [0.981, 0.024],
                  ],
                  v: [
                    [1.492, -6.91],
                    [1.453, 6.872],
                    [-1.492, 6.886],
                    [-1.453, -6.895],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.273],
                    [-11.192, 6.504],
                    [-0.013, 4.235],
                    [0, 0],
                    [5.558, -3.23],
                    [11.266, 6.504],
                    [-0.013, 4.29],
                  ],
                  o: [
                    [-0.011, 4.289],
                    [11.266, 6.504],
                    [5.559, -3.23],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.504],
                    [-5.668, -3.273],
                    [0, 0],
                  ],
                  v: [
                    [-29.093, -9.834],
                    [-20.606, 2.024],
                    [20.058, 2.021],
                    [28.412, -9.673],
                    [28.451, -23.454],
                    [20.097, -11.76],
                    [-20.567, -11.757],
                    [-29.053, -23.615],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [752.4, 210.183], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 33",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-1.056, 0.105],
                    [0, 0],
                    [1.068, -0.037],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.057, 0.105],
                    [0, 0],
                    [1.068, -0.037],
                  ],
                  v: [
                    [1.614, -6.997],
                    [1.575, 6.784],
                    [-1.614, 6.997],
                    [-1.575, -6.784],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.273],
                    [-11.192, 6.504],
                    [-0.013, 4.235],
                    [0, 0],
                    [5.558, -3.23],
                    [11.266, 6.504],
                    [-0.013, 4.29],
                  ],
                  o: [
                    [-0.011, 4.289],
                    [11.266, 6.504],
                    [5.559, -3.23],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.504],
                    [-5.668, -3.273],
                    [0, 0],
                  ],
                  v: [
                    [-32.16, -9.709],
                    [-23.673, 2.149],
                    [16.991, 2.146],
                    [25.345, -9.548],
                    [25.384, -23.329],
                    [17.03, -11.635],
                    [-23.634, -11.632],
                    [-32.12, -23.49],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [755.467, 210.058], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 34",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-1.206, 0.217],
                    [0, 0],
                    [1.238, -0.123],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.206, 0.217],
                    [0, 0],
                    [1.238, -0.123],
                  ],
                  v: [
                    [1.854, -7.146],
                    [1.815, 6.635],
                    [-1.854, 7.146],
                    [-1.815, -6.635],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.273],
                    [-11.192, 6.504],
                    [-0.013, 4.235],
                    [0, 0],
                    [5.558, -3.23],
                    [11.266, 6.504],
                    [-0.013, 4.29],
                  ],
                  o: [
                    [-0.011, 4.289],
                    [11.266, 6.504],
                    [5.559, -3.23],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.504],
                    [-5.668, -3.273],
                    [0, 0],
                  ],
                  v: [
                    [-35.589, -9.347],
                    [-27.102, 2.511],
                    [13.562, 2.508],
                    [21.916, -9.186],
                    [21.955, -22.967],
                    [13.601, -11.273],
                    [-27.063, -11.27],
                    [-35.549, -23.128],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [758.896, 209.696], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 35",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-1.745, 0.566],
                    [0, 0],
                    [1.874, -0.338],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.745, 0.566],
                    [0, 0],
                    [1.875, -0.338],
                  ],
                  v: [
                    [2.742, -7.569],
                    [2.703, 6.212],
                    [-2.742, 7.569],
                    [-2.703, -6.212],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.273],
                    [-11.192, 6.504],
                    [-0.013, 4.235],
                    [0, 0],
                    [5.558, -3.23],
                    [11.266, 6.504],
                    [-0.013, 4.29],
                  ],
                  o: [
                    [-0.011, 4.289],
                    [11.266, 6.504],
                    [5.559, -3.23],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.504],
                    [-5.668, -3.273],
                    [0, 0],
                  ],
                  v: [
                    [-40.145, -8.414],
                    [-31.658, 3.444],
                    [9.006, 3.441],
                    [17.36, -8.253],
                    [17.399, -22.034],
                    [9.045, -10.34],
                    [-31.619, -10.337],
                    [-40.105, -22.195],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [763.452, 208.763], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 36",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.116, 0.07],
                    [0, 0],
                    [0.118, -0.068],
                    [2.26, -0.733],
                    [0, 0],
                    [-1.918, 1.115],
                  ],
                  o: [
                    [0, 0],
                    [-0.116, 0.07],
                    [-1.918, 1.115],
                    [0, 0],
                    [2.26, -0.733],
                    [0.118, -0.068],
                  ],
                  v: [
                    [3.346, -8.38],
                    [3.307, 5.401],
                    [2.956, 5.608],
                    [-3.346, 8.38],
                    [-3.307, -5.401],
                    [2.995, -8.173],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.273],
                    [-11.192, 6.504],
                    [-0.013, 4.235],
                    [0, 0],
                    [5.558, -3.23],
                    [11.266, 6.504],
                    [-0.013, 4.29],
                  ],
                  o: [
                    [-0.011, 4.289],
                    [11.266, 6.504],
                    [5.559, -3.23],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.504],
                    [-5.668, -3.273],
                    [0, 0],
                  ],
                  v: [
                    [-46.195, -6.247],
                    [-37.708, 5.611],
                    [2.956, 5.608],
                    [11.31, -6.086],
                    [11.349, -19.867],
                    [2.995, -8.173],
                    [-37.669, -8.17],
                    [-46.155, -20.028],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [769.502, 206.596], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 37",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-1.031, 0.952],
                    [0, 0],
                    [1.396, -0.839],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.031, 0.952],
                    [0, 0],
                    [1.396, -0.839],
                  ],
                  v: [
                    [1.84, -8.238],
                    [1.801, 5.543],
                    [-1.839, 8.238],
                    [-1.8, -5.543],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.273],
                    [-11.192, 6.504],
                    [-0.013, 4.235],
                    [0, 0],
                    [5.558, -3.23],
                    [11.266, 6.504],
                    [-0.013, 4.29],
                  ],
                  o: [
                    [-0.011, 4.289],
                    [11.266, 6.504],
                    [5.559, -3.23],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.504],
                    [-5.668, -3.273],
                    [0, 0],
                  ],
                  v: [
                    [-51.341, -3.409],
                    [-42.854, 8.449],
                    [-2.19, 8.446],
                    [6.164, -3.248],
                    [6.203, -17.029],
                    [-2.151, -5.335],
                    [-42.815, -5.332],
                    [-51.301, -17.19],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [774.648, 203.758], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 38",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.517, 0.656],
                    [0, 0],
                    [0.672, -0.62],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.517, 0.656],
                    [0, 0],
                    [0.672, -0.62],
                  ],
                  v: [
                    [0.911, -7.849],
                    [0.872, 5.932],
                    [-0.911, 7.849],
                    [-0.872, -5.932],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.273],
                    [-11.192, 6.504],
                    [-0.013, 4.235],
                    [0, 0],
                    [5.558, -3.23],
                    [11.266, 6.504],
                    [-0.013, 4.29],
                  ],
                  o: [
                    [-0.011, 4.289],
                    [11.266, 6.504],
                    [5.559, -3.23],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.504],
                    [-5.668, -3.273],
                    [0, 0],
                  ],
                  v: [
                    [-54.053, -1.103],
                    [-45.566, 10.755],
                    [-4.902, 10.752],
                    [3.452, -0.942],
                    [3.491, -14.723],
                    [-4.863, -3.029],
                    [-45.527, -3.026],
                    [-54.013, -14.884],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [777.36, 201.452], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 39",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.329, 0.582],
                    [0, 0],
                    [0.443, -0.562],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.329, 0.582],
                    [0, 0],
                    [0.443, -0.562],
                  ],
                  v: [
                    [0.598, -7.75],
                    [0.559, 6.031],
                    [-0.598, 7.75],
                    [-0.559, -6.031],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.273],
                    [-11.192, 6.504],
                    [-0.013, 4.235],
                    [0, 0],
                    [5.558, -3.23],
                    [11.266, 6.504],
                    [-0.013, 4.29],
                  ],
                  o: [
                    [-0.011, 4.289],
                    [11.266, 6.504],
                    [5.559, -3.23],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.504],
                    [-5.668, -3.273],
                    [0, 0],
                  ],
                  v: [
                    [-55.523, 0.714],
                    [-47.036, 12.572],
                    [-6.372, 12.569],
                    [1.982, 0.875],
                    [2.021, -12.906],
                    [-6.333, -1.212],
                    [-46.997, -1.209],
                    [-55.483, -13.067],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [778.83, 199.635], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 40",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.207, 0.551],
                    [0, 0],
                    [0.304, -0.539],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.207, 0.551],
                    [0, 0],
                    [0.304, -0.539],
                  ],
                  v: [
                    [0.403, -7.708],
                    [0.364, 6.073],
                    [-0.403, 7.708],
                    [-0.364, -6.073],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.273],
                    [-11.192, 6.504],
                    [-0.013, 4.235],
                    [0, 0],
                    [5.558, -3.23],
                    [11.266, 6.504],
                    [-0.013, 4.29],
                  ],
                  o: [
                    [-0.011, 4.289],
                    [11.266, 6.504],
                    [5.559, -3.23],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.504],
                    [-5.668, -3.273],
                    [0, 0],
                  ],
                  v: [
                    [-56.484, 2.391],
                    [-47.997, 14.249],
                    [-7.333, 14.246],
                    [1.021, 2.552],
                    [1.06, -11.229],
                    [-7.294, 0.465],
                    [-47.958, 0.468],
                    [-56.444, -11.39],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [779.791, 197.958], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 41",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.109, 0.543],
                    [0, 0],
                    [0.201, -0.536],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.109, 0.543],
                    [0, 0],
                    [0.201, -0.536],
                  ],
                  v: [
                    [0.252, -7.7],
                    [0.213, 6.081],
                    [-0.252, 7.7],
                    [-0.213, -6.081],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.273],
                    [-11.192, 6.504],
                    [-0.013, 4.235],
                    [0, 0],
                    [5.558, -3.23],
                    [11.266, 6.504],
                    [-0.013, 4.29],
                  ],
                  o: [
                    [-0.011, 4.289],
                    [11.266, 6.504],
                    [5.559, -3.23],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.504],
                    [-5.668, -3.273],
                    [0, 0],
                  ],
                  v: [
                    [-57.099, 4.018],
                    [-48.612, 15.876],
                    [-7.948, 15.873],
                    [0.406, 4.179],
                    [0.445, -9.602],
                    [-7.909, 2.092],
                    [-48.573, 2.095],
                    [-57.059, -9.763],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [780.406, 196.331], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 42",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.016, 0.551],
                    [0, 0],
                    [0.11, -0.548],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.016, 0.551],
                    [0, 0],
                    [0.11, -0.548],
                  ],
                  v: [
                    [0.114, -7.716],
                    [0.075, 6.065],
                    [-0.114, 7.716],
                    [-0.075, -6.065],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.273],
                    [-11.192, 6.504],
                    [-0.013, 4.235],
                    [0, 0],
                    [5.558, -3.23],
                    [11.266, 6.504],
                    [-0.013, 4.29],
                  ],
                  o: [
                    [-0.011, 4.289],
                    [11.266, 6.504],
                    [5.559, -3.23],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.504],
                    [-5.668, -3.273],
                    [0, 0],
                  ],
                  v: [
                    [-57.426, 5.652],
                    [-48.939, 17.51],
                    [-8.275, 17.507],
                    [0.079, 5.813],
                    [0.118, -7.968],
                    [-8.236, 3.726],
                    [-48.9, 3.729],
                    [-57.386, -8.129],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [780.733, 194.697], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 43",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0.084],
                    [0, 0],
                    [0.002, -0.085],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0.084],
                    [0, 0],
                    [0.002, -0.085],
                  ],
                  v: [
                    [0.022, -7.017],
                    [-0.017, 6.764],
                    [-0.021, 7.017],
                    [0.018, -6.764],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.273],
                    [-11.192, 6.504],
                    [-0.013, 4.235],
                    [0, 0],
                    [5.558, -3.23],
                    [11.266, 6.504],
                    [-0.013, 4.29],
                  ],
                  o: [
                    [-0.011, 4.289],
                    [11.266, 6.504],
                    [5.559, -3.23],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.504],
                    [-5.668, -3.273],
                    [0, 0],
                  ],
                  v: [
                    [-57.522, 6.603],
                    [-49.035, 18.461],
                    [-8.371, 18.458],
                    [-0.017, 6.764],
                    [0.022, -7.017],
                    [-8.332, 4.677],
                    [-48.996, 4.68],
                    [-57.482, -7.178],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [780.829, 193.746], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 44",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.012, 4.235],
                    [0, 0],
                    [5.559, -3.23],
                    [11.265, 6.504],
                    [-0.012, 4.289],
                    [0, 0],
                    [-5.668, -3.273],
                    [-11.192, 6.504],
                  ],
                  o: [
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.504],
                    [-5.668, -3.273],
                    [0, 0],
                    [-0.012, 4.289],
                    [11.266, 6.504],
                    [5.559, -3.23],
                  ],
                  v: [
                    [28.778, -15.911],
                    [28.739, -2.13],
                    [20.385, 9.564],
                    [-20.279, 9.567],
                    [-28.766, -2.29],
                    [-28.727, -16.071],
                    [-20.24, -4.214],
                    [20.424, -4.217],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [752.073, 202.64], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 45",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, -7.218],
                    [13.218, 0],
                    [0, 7.218],
                    [-13.218, 0],
                  ],
                  o: [
                    [0, 7.218],
                    [-13.218, 0],
                    [0, -7.218],
                    [13.218, 0],
                  ],
                  v: [
                    [23.933, 0],
                    [0, 13.069],
                    [-23.933, 0],
                    [0, -13.069],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [677.834, 145.88], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 46",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-11.266, -6.504],
                    [11.188, -6.502],
                    [11.265, 6.504],
                    [-11.192, 6.504],
                  ],
                  o: [
                    [11.266, 6.504],
                    [-11.193, 6.504],
                    [-11.266, -6.504],
                    [11.188, -6.502],
                  ],
                  v: [
                    [20.266, -11.777],
                    [20.398, 11.775],
                    [-20.266, 11.777],
                    [-20.395, -11.777],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [678.099, 147.648], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 47",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-1.46, -0.576],
                    [0, 0],
                    [1.297, 0.749],
                    [-0.012, 4.289],
                    [0, 0],
                    [-5.668, -3.273],
                  ],
                  o: [
                    [0, 0],
                    [-1.46, -0.576],
                    [-5.668, -3.273],
                    [0, 0],
                    [-0.012, 4.289],
                    [1.297, 0.749],
                  ],
                  v: [
                    [6.342, 0.032],
                    [6.303, 13.813],
                    [2.156, 11.825],
                    [-6.33, -0.032],
                    [-6.291, -13.813],
                    [2.195, -1.956],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.273],
                    [-11.193, 6.504],
                    [-0.012, 4.235],
                    [0, 0],
                    [5.559, -3.231],
                    [11.266, 6.504],
                    [-0.012, 4.289],
                  ],
                  o: [
                    [-0.012, 4.29],
                    [11.265, 6.503],
                    [5.559, -3.231],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.505],
                    [-5.668, -3.272],
                    [0, 0],
                  ],
                  v: [
                    [-6.33, -0.033],
                    [2.156, 11.825],
                    [42.82, 11.822],
                    [51.174, 0.128],
                    [51.213, -13.653],
                    [42.859, -1.959],
                    [2.195, -1.957],
                    [-6.291, -13.813],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [655.638, 161.382], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 48",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-1.199, -0.342],
                    [0, 0],
                    [1.125, 0.444],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.199, -0.342],
                    [0, 0],
                    [1.125, 0.444],
                  ],
                  v: [
                    [1.765, -6.301],
                    [1.726, 7.48],
                    [-1.765, 6.301],
                    [-1.726, -7.48],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.273],
                    [-11.193, 6.504],
                    [-0.012, 4.235],
                    [0, 0],
                    [5.559, -3.231],
                    [11.266, 6.504],
                    [-0.012, 4.289],
                  ],
                  o: [
                    [-0.012, 4.29],
                    [11.265, 6.503],
                    [5.559, -3.231],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.505],
                    [-5.668, -3.272],
                    [0, 0],
                  ],
                  v: [
                    [-14.398, -7.544],
                    [-5.912, 4.314],
                    [34.752, 4.311],
                    [43.106, -7.383],
                    [43.145, -21.164],
                    [34.791, -9.47],
                    [-5.873, -9.468],
                    [-14.359, -21.324],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [663.706, 168.893], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 49",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-1.017, -0.211],
                    [0, 0],
                    [0.98, 0.279],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.018, -0.211],
                    [0, 0],
                    [0.979, 0.279],
                  ],
                  v: [
                    [1.519, -6.523],
                    [1.48, 7.258],
                    [-1.519, 6.523],
                    [-1.479, -7.258],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.273],
                    [-11.193, 6.504],
                    [-0.012, 4.235],
                    [0, 0],
                    [5.559, -3.231],
                    [11.266, 6.504],
                    [-0.012, 4.289],
                  ],
                  o: [
                    [-0.012, 4.29],
                    [11.265, 6.503],
                    [5.559, -3.231],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.505],
                    [-5.668, -3.272],
                    [0, 0],
                  ],
                  v: [
                    [-17.642, -8.501],
                    [-9.156, 3.357],
                    [31.508, 3.354],
                    [39.862, -8.34],
                    [39.901, -22.121],
                    [31.547, -10.427],
                    [-9.117, -10.425],
                    [-17.603, -22.281],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [666.95, 169.85], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 50",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.961, -0.136],
                    [0, 0],
                    [0.937, 0.194],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.961, -0.136],
                    [0, 0],
                    [0.938, 0.195],
                  ],
                  v: [
                    [1.445, -6.643],
                    [1.406, 7.138],
                    [-1.445, 6.643],
                    [-1.406, -7.138],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.273],
                    [-11.193, 6.504],
                    [-0.012, 4.235],
                    [0, 0],
                    [5.559, -3.231],
                    [11.266, 6.504],
                    [-0.012, 4.289],
                  ],
                  o: [
                    [-0.012, 4.29],
                    [11.265, 6.503],
                    [5.559, -3.231],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.505],
                    [-5.668, -3.272],
                    [0, 0],
                  ],
                  v: [
                    [-20.567, -9.117],
                    [-12.081, 2.741],
                    [28.583, 2.738],
                    [36.937, -8.956],
                    [36.976, -22.737],
                    [28.622, -11.043],
                    [-12.042, -11.041],
                    [-20.528, -22.897],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [669.875, 170.466], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 51",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.938, -0.077],
                    [0, 0],
                    [0.924, 0.131],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.938, -0.077],
                    [0, 0],
                    [0.924, 0.131],
                  ],
                  v: [
                    [1.417, -6.735],
                    [1.378, 7.046],
                    [-1.417, 6.735],
                    [-1.378, -7.046],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.273],
                    [-11.193, 6.504],
                    [-0.012, 4.235],
                    [0, 0],
                    [5.559, -3.231],
                    [11.266, 6.504],
                    [-0.012, 4.289],
                  ],
                  o: [
                    [-0.012, 4.29],
                    [11.265, 6.503],
                    [5.559, -3.231],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.505],
                    [-5.668, -3.272],
                    [0, 0],
                  ],
                  v: [
                    [-23.39, -9.521],
                    [-14.904, 2.337],
                    [25.76, 2.334],
                    [34.114, -9.36],
                    [34.153, -23.141],
                    [25.799, -11.447],
                    [-14.865, -11.445],
                    [-23.351, -23.301],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [672.698, 170.87], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 52",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.947, -0.024],
                    [0, 0],
                    [0.94, 0.077],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.947, -0.024],
                    [0, 0],
                    [0.94, 0.077],
                  ],
                  v: [
                    [1.435, -6.815],
                    [1.396, 6.966],
                    [-1.436, 6.815],
                    [-1.397, -6.966],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.273],
                    [-11.193, 6.504],
                    [-0.012, 4.235],
                    [0, 0],
                    [5.559, -3.231],
                    [11.266, 6.504],
                    [-0.012, 4.289],
                  ],
                  o: [
                    [-0.012, 4.29],
                    [11.265, 6.503],
                    [5.559, -3.231],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.505],
                    [-5.668, -3.272],
                    [0, 0],
                  ],
                  v: [
                    [-26.203, -9.753],
                    [-17.717, 2.105],
                    [22.947, 2.102],
                    [31.301, -9.592],
                    [31.34, -23.373],
                    [22.986, -11.679],
                    [-17.678, -11.677],
                    [-26.164, -23.533],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [675.511, 171.102], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 53",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.981, 0.034],
                    [0, 0],
                    [0.981, 0.024],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.981, 0.033],
                    [0, 0],
                    [0.981, 0.024],
                  ],
                  v: [
                    [1.492, -6.91],
                    [1.453, 6.871],
                    [-1.492, 6.886],
                    [-1.453, -6.895],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.273],
                    [-11.193, 6.504],
                    [-0.012, 4.235],
                    [0, 0],
                    [5.559, -3.231],
                    [11.266, 6.504],
                    [-0.012, 4.289],
                  ],
                  o: [
                    [-0.012, 4.29],
                    [11.265, 6.503],
                    [5.559, -3.231],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.505],
                    [-5.668, -3.272],
                    [0, 0],
                  ],
                  v: [
                    [-29.092, -9.834],
                    [-20.606, 2.024],
                    [20.058, 2.021],
                    [28.412, -9.673],
                    [28.451, -23.454],
                    [20.097, -11.76],
                    [-20.567, -11.758],
                    [-29.053, -23.614],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [678.4, 171.183], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 54",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-1.056, 0.105],
                    [0, 0],
                    [1.068, -0.037],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.057, 0.105],
                    [0, 0],
                    [1.068, -0.037],
                  ],
                  v: [
                    [1.614, -6.997],
                    [1.575, 6.784],
                    [-1.614, 6.997],
                    [-1.575, -6.784],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.273],
                    [-11.193, 6.504],
                    [-0.012, 4.235],
                    [0, 0],
                    [5.559, -3.231],
                    [11.266, 6.504],
                    [-0.012, 4.289],
                  ],
                  o: [
                    [-0.012, 4.29],
                    [11.265, 6.503],
                    [5.559, -3.231],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.505],
                    [-5.668, -3.272],
                    [0, 0],
                  ],
                  v: [
                    [-32.159, -9.709],
                    [-23.673, 2.149],
                    [16.991, 2.146],
                    [25.345, -9.548],
                    [25.384, -23.329],
                    [17.03, -11.635],
                    [-23.634, -11.633],
                    [-32.12, -23.489],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [681.467, 171.058], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 55",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-1.206, 0.217],
                    [0, 0],
                    [1.238, -0.123],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.206, 0.217],
                    [0, 0],
                    [1.238, -0.123],
                  ],
                  v: [
                    [1.854, -7.146],
                    [1.815, 6.635],
                    [-1.854, 7.146],
                    [-1.815, -6.635],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.273],
                    [-11.193, 6.504],
                    [-0.012, 4.235],
                    [0, 0],
                    [5.559, -3.231],
                    [11.266, 6.504],
                    [-0.012, 4.289],
                  ],
                  o: [
                    [-0.012, 4.29],
                    [11.265, 6.503],
                    [5.559, -3.231],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.505],
                    [-5.668, -3.272],
                    [0, 0],
                  ],
                  v: [
                    [-35.588, -9.347],
                    [-27.102, 2.511],
                    [13.562, 2.508],
                    [21.916, -9.186],
                    [21.955, -22.967],
                    [13.601, -11.273],
                    [-27.063, -11.271],
                    [-35.549, -23.127],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [684.896, 170.696], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 56",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-1.745, 0.566],
                    [0, 0],
                    [1.874, -0.338],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.745, 0.566],
                    [0, 0],
                    [1.875, -0.338],
                  ],
                  v: [
                    [2.742, -7.569],
                    [2.703, 6.212],
                    [-2.742, 7.569],
                    [-2.703, -6.212],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.273],
                    [-11.193, 6.504],
                    [-0.012, 4.235],
                    [0, 0],
                    [5.559, -3.231],
                    [11.266, 6.504],
                    [-0.012, 4.289],
                  ],
                  o: [
                    [-0.012, 4.29],
                    [11.265, 6.503],
                    [5.559, -3.231],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.505],
                    [-5.668, -3.272],
                    [0, 0],
                  ],
                  v: [
                    [-40.144, -8.414],
                    [-31.658, 3.444],
                    [9.006, 3.441],
                    [17.36, -8.253],
                    [17.399, -22.034],
                    [9.045, -10.34],
                    [-31.619, -10.338],
                    [-40.105, -22.194],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [689.452, 169.763], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 57",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.116, 0.07],
                    [0, 0],
                    [0.118, -0.068],
                    [2.26, -0.733],
                    [0, 0],
                    [-1.918, 1.115],
                  ],
                  o: [
                    [0, 0],
                    [-0.116, 0.07],
                    [-1.918, 1.115],
                    [0, 0],
                    [2.26, -0.733],
                    [0.118, -0.068],
                  ],
                  v: [
                    [3.346, -8.38],
                    [3.307, 5.401],
                    [2.956, 5.608],
                    [-3.346, 8.38],
                    [-3.307, -5.401],
                    [2.995, -8.173],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.273],
                    [-11.193, 6.504],
                    [-0.012, 4.235],
                    [0, 0],
                    [5.559, -3.231],
                    [11.266, 6.504],
                    [-0.012, 4.289],
                  ],
                  o: [
                    [-0.012, 4.29],
                    [11.265, 6.503],
                    [5.559, -3.231],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.505],
                    [-5.668, -3.272],
                    [0, 0],
                  ],
                  v: [
                    [-46.194, -6.247],
                    [-37.708, 5.611],
                    [2.956, 5.608],
                    [11.31, -6.086],
                    [11.349, -19.867],
                    [2.995, -8.173],
                    [-37.669, -8.171],
                    [-46.155, -20.027],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [695.502, 167.596], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 58",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-1.031, 0.952],
                    [0, 0],
                    [1.396, -0.839],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.031, 0.952],
                    [0, 0],
                    [1.396, -0.839],
                  ],
                  v: [
                    [1.84, -8.238],
                    [1.801, 5.543],
                    [-1.839, 8.238],
                    [-1.8, -5.543],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.273],
                    [-11.193, 6.504],
                    [-0.012, 4.235],
                    [0, 0],
                    [5.559, -3.231],
                    [11.266, 6.504],
                    [-0.012, 4.289],
                  ],
                  o: [
                    [-0.012, 4.29],
                    [11.265, 6.503],
                    [5.559, -3.231],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.505],
                    [-5.668, -3.272],
                    [0, 0],
                  ],
                  v: [
                    [-51.34, -3.409],
                    [-42.854, 8.449],
                    [-2.19, 8.446],
                    [6.164, -3.248],
                    [6.203, -17.029],
                    [-2.151, -5.335],
                    [-42.815, -5.333],
                    [-51.301, -17.189],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [700.648, 164.758], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 59",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.517, 0.656],
                    [0, 0],
                    [0.672, -0.62],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.517, 0.656],
                    [0, 0],
                    [0.672, -0.62],
                  ],
                  v: [
                    [0.911, -7.849],
                    [0.872, 5.932],
                    [-0.911, 7.849],
                    [-0.872, -5.932],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.273],
                    [-11.193, 6.504],
                    [-0.012, 4.235],
                    [0, 0],
                    [5.559, -3.231],
                    [11.266, 6.504],
                    [-0.012, 4.289],
                  ],
                  o: [
                    [-0.012, 4.29],
                    [11.265, 6.503],
                    [5.559, -3.231],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.505],
                    [-5.668, -3.272],
                    [0, 0],
                  ],
                  v: [
                    [-54.052, -1.103],
                    [-45.566, 10.755],
                    [-4.902, 10.752],
                    [3.452, -0.942],
                    [3.491, -14.723],
                    [-4.863, -3.029],
                    [-45.527, -3.027],
                    [-54.013, -14.883],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [703.36, 162.452], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 60",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.329, 0.582],
                    [0, 0],
                    [0.443, -0.562],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.329, 0.582],
                    [0, 0],
                    [0.443, -0.562],
                  ],
                  v: [
                    [0.598, -7.75],
                    [0.559, 6.031],
                    [-0.598, 7.75],
                    [-0.559, -6.031],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.273],
                    [-11.193, 6.504],
                    [-0.012, 4.235],
                    [0, 0],
                    [5.559, -3.231],
                    [11.266, 6.504],
                    [-0.012, 4.289],
                  ],
                  o: [
                    [-0.012, 4.29],
                    [11.265, 6.503],
                    [5.559, -3.231],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.505],
                    [-5.668, -3.272],
                    [0, 0],
                  ],
                  v: [
                    [-55.522, 0.714],
                    [-47.036, 12.572],
                    [-6.372, 12.569],
                    [1.982, 0.875],
                    [2.021, -12.906],
                    [-6.333, -1.212],
                    [-46.997, -1.21],
                    [-55.483, -13.066],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [704.83, 160.635], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 61",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.207, 0.551],
                    [0, 0],
                    [0.304, -0.539],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.207, 0.551],
                    [0, 0],
                    [0.304, -0.539],
                  ],
                  v: [
                    [0.403, -7.708],
                    [0.364, 6.073],
                    [-0.403, 7.708],
                    [-0.364, -6.073],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.273],
                    [-11.193, 6.504],
                    [-0.012, 4.235],
                    [0, 0],
                    [5.559, -3.231],
                    [11.266, 6.504],
                    [-0.012, 4.289],
                  ],
                  o: [
                    [-0.012, 4.29],
                    [11.265, 6.503],
                    [5.559, -3.231],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.505],
                    [-5.668, -3.272],
                    [0, 0],
                  ],
                  v: [
                    [-56.483, 2.391],
                    [-47.997, 14.249],
                    [-7.333, 14.246],
                    [1.021, 2.552],
                    [1.06, -11.229],
                    [-7.294, 0.465],
                    [-47.958, 0.467],
                    [-56.444, -11.389],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [705.791, 158.958], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 62",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.109, 0.543],
                    [0, 0],
                    [0.201, -0.536],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.109, 0.543],
                    [0, 0],
                    [0.201, -0.536],
                  ],
                  v: [
                    [0.252, -7.7],
                    [0.213, 6.081],
                    [-0.252, 7.7],
                    [-0.213, -6.081],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.273],
                    [-11.193, 6.504],
                    [-0.012, 4.235],
                    [0, 0],
                    [5.559, -3.231],
                    [11.266, 6.504],
                    [-0.012, 4.289],
                  ],
                  o: [
                    [-0.012, 4.29],
                    [11.265, 6.503],
                    [5.559, -3.231],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.505],
                    [-5.668, -3.272],
                    [0, 0],
                  ],
                  v: [
                    [-57.098, 4.018],
                    [-48.612, 15.876],
                    [-7.948, 15.873],
                    [0.406, 4.179],
                    [0.445, -9.602],
                    [-7.909, 2.092],
                    [-48.573, 2.094],
                    [-57.059, -9.762],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [706.406, 157.331], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 63",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.016, 0.551],
                    [0, 0],
                    [0.11, -0.548],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.016, 0.551],
                    [0, 0],
                    [0.11, -0.548],
                  ],
                  v: [
                    [0.114, -7.716],
                    [0.075, 6.065],
                    [-0.114, 7.716],
                    [-0.075, -6.065],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.273],
                    [-11.193, 6.504],
                    [-0.012, 4.235],
                    [0, 0],
                    [5.559, -3.231],
                    [11.266, 6.504],
                    [-0.012, 4.289],
                  ],
                  o: [
                    [-0.012, 4.29],
                    [11.265, 6.503],
                    [5.559, -3.231],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.505],
                    [-5.668, -3.272],
                    [0, 0],
                  ],
                  v: [
                    [-57.425, 5.652],
                    [-48.939, 17.51],
                    [-8.275, 17.507],
                    [0.079, 5.813],
                    [0.118, -7.968],
                    [-8.236, 3.726],
                    [-48.9, 3.728],
                    [-57.386, -8.128],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [706.733, 155.697], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 64",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0.084],
                    [0, 0],
                    [0.002, -0.085],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0.084],
                    [0, 0],
                    [0.002, -0.085],
                  ],
                  v: [
                    [0.022, -7.017],
                    [-0.017, 6.764],
                    [-0.021, 7.017],
                    [0.018, -6.764],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-5.668, -3.273],
                    [-11.193, 6.504],
                    [-0.012, 4.235],
                    [0, 0],
                    [5.559, -3.231],
                    [11.266, 6.504],
                    [-0.012, 4.289],
                  ],
                  o: [
                    [-0.012, 4.29],
                    [11.265, 6.503],
                    [5.559, -3.231],
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.505],
                    [-5.668, -3.272],
                    [0, 0],
                  ],
                  v: [
                    [-57.521, 6.603],
                    [-49.035, 18.461],
                    [-8.371, 18.458],
                    [-0.017, 6.764],
                    [0.022, -7.017],
                    [-8.332, 4.677],
                    [-48.996, 4.679],
                    [-57.482, -7.177],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [706.829, 154.746], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 65",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.012, 4.235],
                    [0, 0],
                    [5.559, -3.23],
                    [11.265, 6.504],
                    [-0.012, 4.289],
                    [0, 0],
                    [-5.668, -3.273],
                    [-11.192, 6.504],
                  ],
                  o: [
                    [0, 0],
                    [-0.012, 4.235],
                    [-11.192, 6.504],
                    [-5.668, -3.273],
                    [0, 0],
                    [-0.012, 4.289],
                    [11.266, 6.504],
                    [5.559, -3.23],
                  ],
                  v: [
                    [28.778, -15.911],
                    [28.739, -2.13],
                    [20.385, 9.564],
                    [-20.279, 9.567],
                    [-28.766, -2.29],
                    [-28.727, -16.071],
                    [-20.24, -4.214],
                    [20.424, -4.217],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [678.073, 163.64], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 66",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-5.462, -3.082],
                    [-10.661, 6.338],
                    [-0.075, 3.977],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.029, 4],
                    [10.856, 6.124],
                    [5.19, -3.086],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [28.721, -24.211],
                    [-28.721, -24.464],
                    [-26.786, 7.622],
                    [-26.783, 7.622],
                    [-18.541, 18.34],
                    [20.42, 17.671],
                    [28.304, 6.488],
                    [28.312, 6.416],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [678.029, 186.377], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 67",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-10.855, -6.124],
                    [10.662, -6.338],
                    [10.855, 6.125],
                    [-10.662, 6.339],
                  ],
                  o: [
                    [10.855, 6.124],
                    [-10.661, 6.338],
                    [-10.855, -6.124],
                    [10.661, -6.338],
                  ],
                  v: [
                    [19.305, -11.477],
                    [19.656, 11.089],
                    [-19.304, 11.476],
                    [-19.655, -11.09],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [678.793, 192.676], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 68",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-1.405, -0.539],
                    [0, 0],
                    [1.25, 0.705],
                    [0.029, 4.111],
                    [0, 0],
                    [-5.462, -3.082],
                  ],
                  o: [
                    [0, 0],
                    [-1.405, -0.539],
                    [-5.462, -3.082],
                    [0, 0],
                    [0.029, 4.11],
                    [1.251, 0.706],
                  ],
                  v: [
                    [6.07, -0.317],
                    [6.168, 13.464],
                    [2.175, 11.598],
                    [-6.07, 0.316],
                    [-6.168, -13.464],
                    [2.077, -2.183],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.296, -3.149],
                    [10.855, 6.125],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.462, -3.081],
                    [-10.662, 6.339],
                    [0.028, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.662, 6.338],
                    [-5.462, -3.081],
                    [0, 0],
                    [0.029, 4.11],
                    [10.855, 6.125],
                    [5.296, -3.148],
                    [0, 0],
                    [0.029, 4.058],
                  ],
                  v: [
                    [41.038, -2.57],
                    [2.077, -2.183],
                    [-6.168, -13.464],
                    [-6.07, 0.317],
                    [2.175, 11.598],
                    [41.136, 11.21],
                    [49.03, -0.074],
                    [48.931, -13.855],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [657.411, 206.335], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 69",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-1.152, -0.316],
                    [0, 0],
                    [1.083, 0.415],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.153, -0.316],
                    [0, 0],
                    [1.083, 0.415],
                  ],
                  v: [
                    [1.63, -6.343],
                    [1.728, 7.438],
                    [-1.629, 6.341],
                    [-1.727, -7.438],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.296, -3.149],
                    [10.855, 6.125],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.462, -3.081],
                    [-10.662, 6.339],
                    [0.028, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.662, 6.338],
                    [-5.462, -3.081],
                    [0, 0],
                    [0.029, 4.11],
                    [10.855, 6.125],
                    [5.296, -3.148],
                    [0, 0],
                    [0.029, 4.058],
                  ],
                  v: [
                    [33.241, -9.692],
                    [-5.72, -9.305],
                    [-13.965, -20.586],
                    [-13.867, -6.805],
                    [-5.622, 4.476],
                    [33.339, 4.088],
                    [41.233, -7.196],
                    [41.134, -20.977],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [665.208, 213.457], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 70",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.977, -0.192],
                    [0, 0],
                    [0.942, 0.258],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.977, -0.193],
                    [0, 0],
                    [0.942, 0.258],
                  ],
                  v: [
                    [1.392, -6.553],
                    [1.49, 7.228],
                    [-1.391, 6.552],
                    [-1.49, -7.228],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.296, -3.149],
                    [10.855, 6.125],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.462, -3.081],
                    [-10.662, 6.339],
                    [0.028, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.662, 6.338],
                    [-5.462, -3.081],
                    [0, 0],
                    [0.029, 4.11],
                    [10.855, 6.125],
                    [5.296, -3.148],
                    [0, 0],
                    [0.029, 4.058],
                  ],
                  v: [
                    [30.122, -10.578],
                    [-8.839, -10.191],
                    [-17.084, -21.472],
                    [-16.986, -7.691],
                    [-8.741, 3.59],
                    [30.22, 3.202],
                    [38.114, -8.082],
                    [38.015, -21.863],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [668.327, 214.343], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 71",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.922, -0.121],
                    [0, 0],
                    [0.9, 0.177],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.922, -0.122],
                    [0, 0],
                    [0.9, 0.177],
                  ],
                  v: [
                    [1.319, -6.667],
                    [1.417, 7.114],
                    [-1.319, 6.666],
                    [-1.417, -7.114],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.296, -3.149],
                    [10.855, 6.125],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.462, -3.081],
                    [-10.662, 6.339],
                    [0.028, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.662, 6.338],
                    [-5.462, -3.081],
                    [0, 0],
                    [0.029, 4.11],
                    [10.855, 6.125],
                    [5.296, -3.148],
                    [0, 0],
                    [0.029, 4.058],
                  ],
                  v: [
                    [27.314, -11.14],
                    [-11.647, -10.753],
                    [-19.892, -22.034],
                    [-19.794, -8.253],
                    [-11.549, 3.028],
                    [27.412, 2.64],
                    [35.306, -8.644],
                    [35.207, -22.425],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [671.135, 214.905], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 72",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.9, -0.065],
                    [0, 0],
                    [0.887, 0.117],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.9, -0.065],
                    [0, 0],
                    [0.887, 0.117],
                  ],
                  v: [
                    [1.292, -6.754],
                    [1.39, 7.027],
                    [-1.292, 6.754],
                    [-1.39, -7.027],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.296, -3.149],
                    [10.855, 6.125],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.462, -3.081],
                    [-10.662, 6.339],
                    [0.028, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.662, 6.338],
                    [-5.462, -3.081],
                    [0, 0],
                    [0.029, 4.11],
                    [10.855, 6.125],
                    [5.296, -3.148],
                    [0, 0],
                    [0.029, 4.058],
                  ],
                  v: [
                    [24.605, -11.5],
                    [-14.356, -11.113],
                    [-22.601, -22.394],
                    [-22.503, -8.613],
                    [-14.258, 2.668],
                    [24.703, 2.28],
                    [32.597, -9.004],
                    [32.498, -22.785],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [673.844, 215.265], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 73",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.907, -0.014],
                    [0, 0],
                    [0.901, 0.066],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.908, -0.014],
                    [0, 0],
                    [0.901, 0.065],
                  ],
                  v: [
                    [1.309, -6.832],
                    [1.406, 6.949],
                    [-1.308, 6.831],
                    [-1.406, -6.949],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.296, -3.149],
                    [10.855, 6.125],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.462, -3.081],
                    [-10.662, 6.339],
                    [0.028, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.662, 6.338],
                    [-5.462, -3.081],
                    [0, 0],
                    [0.029, 4.11],
                    [10.855, 6.125],
                    [5.296, -3.148],
                    [0, 0],
                    [0.029, 4.058],
                  ],
                  v: [
                    [21.906, -11.696],
                    [-17.055, -11.309],
                    [-25.3, -22.59],
                    [-25.202, -8.809],
                    [-16.957, 2.472],
                    [22.004, 2.084],
                    [29.898, -9.2],
                    [29.799, -22.981],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [676.543, 215.461], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 74",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.939, 0.041],
                    [0, 0],
                    [0.941, 0.014],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.939, 0.041],
                    [0, 0],
                    [0.941, 0.014],
                  ],
                  v: [
                    [1.362, -6.918],
                    [1.46, 6.863],
                    [-1.362, 6.904],
                    [-1.46, -6.877],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.296, -3.149],
                    [10.855, 6.125],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.462, -3.081],
                    [-10.662, 6.339],
                    [0.028, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.662, 6.338],
                    [-5.462, -3.081],
                    [0, 0],
                    [0.029, 4.11],
                    [10.855, 6.125],
                    [5.296, -3.148],
                    [0, 0],
                    [0.029, 4.058],
                  ],
                  v: [
                    [19.138, -11.741],
                    [-19.823, -11.354],
                    [-28.068, -22.635],
                    [-27.97, -8.854],
                    [-19.725, 2.427],
                    [19.236, 2.039],
                    [27.13, -9.245],
                    [27.031, -23.026],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [679.311, 215.506], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 75",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-1.011, 0.111],
                    [0, 0],
                    [1.023, -0.045],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.011, 0.111],
                    [0, 0],
                    [1.022, -0.045],
                  ],
                  v: [
                    [1.478, -7.008],
                    [1.576, 6.773],
                    [-1.479, 7.008],
                    [-1.576, -6.773],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.296, -3.149],
                    [10.855, 6.125],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.462, -3.081],
                    [-10.662, 6.339],
                    [0.028, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.662, 6.338],
                    [-5.462, -3.081],
                    [0, 0],
                    [0.029, 4.11],
                    [10.855, 6.125],
                    [5.296, -3.148],
                    [0, 0],
                    [0.029, 4.058],
                  ],
                  v: [
                    [16.2, -11.596],
                    [-22.761, -11.21],
                    [-31.006, -22.491],
                    [-30.908, -8.71],
                    [-22.663, 2.572],
                    [16.298, 2.184],
                    [24.192, -9.101],
                    [24.093, -22.882],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [682.249, 215.362], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 76",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-1.153, 0.22],
                    [0, 0],
                    [1.184, -0.13],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.153, 0.219],
                    [0, 0],
                    [1.184, -0.129],
                  ],
                  v: [
                    [1.706, -7.153],
                    [1.803, 6.628],
                    [-1.706, 7.153],
                    [-1.804, -6.628],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.296, -3.149],
                    [10.855, 6.125],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.462, -3.081],
                    [-10.662, 6.339],
                    [0.028, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.662, 6.338],
                    [-5.462, -3.081],
                    [0, 0],
                    [0.029, 4.11],
                    [10.855, 6.125],
                    [5.296, -3.148],
                    [0, 0],
                    [0.029, 4.058],
                  ],
                  v: [
                    [12.919, -11.217],
                    [-26.042, -10.83],
                    [-34.287, -22.111],
                    [-34.189, -8.33],
                    [-25.944, 2.951],
                    [13.017, 2.563],
                    [20.911, -8.721],
                    [20.812, -22.502],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [685.53, 214.982], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 77",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-1.666, 0.558],
                    [0, 0],
                    [1.792, -0.342],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.666, 0.558],
                    [0, 0],
                    [1.793, -0.341],
                  ],
                  v: [
                    [2.552, -7.566],
                    [2.65, 6.215],
                    [-2.553, 7.566],
                    [-2.651, -6.215],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.296, -3.149],
                    [10.855, 6.125],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.462, -3.081],
                    [-10.662, 6.339],
                    [0.028, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.662, 6.338],
                    [-5.462, -3.081],
                    [0, 0],
                    [0.029, 4.11],
                    [10.855, 6.125],
                    [5.296, -3.148],
                    [0, 0],
                    [0.029, 4.058],
                  ],
                  v: [
                    [8.563, -10.28],
                    [-30.398, -9.893],
                    [-38.643, -21.174],
                    [-38.545, -7.393],
                    [-30.3, 3.888],
                    [8.661, 3.5],
                    [16.555, -7.784],
                    [16.456, -21.565],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [689.886, 214.045], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 78",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.11, 0.068],
                    [0, 0],
                    [0.112, -0.067],
                    [2.157, -0.723],
                    [0, 0],
                    [-1.827, 1.086],
                  ],
                  o: [
                    [0, 0],
                    [-0.11, 0.067],
                    [-1.827, 1.086],
                    [0, 0],
                    [2.157, -0.723],
                    [0.112, -0.067],
                  ],
                  v: [
                    [3.123, -8.349],
                    [3.221, 5.432],
                    [2.887, 5.634],
                    [-3.123, 8.349],
                    [-3.221, -5.432],
                    [2.789, -8.146],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.296, -3.149],
                    [10.855, 6.125],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.462, -3.081],
                    [-10.662, 6.339],
                    [0.028, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.662, 6.338],
                    [-5.462, -3.081],
                    [0, 0],
                    [0.029, 4.11],
                    [10.855, 6.125],
                    [5.296, -3.148],
                    [0, 0],
                    [0.029, 4.058],
                  ],
                  v: [
                    [2.789, -8.146],
                    [-36.172, -7.76],
                    [-44.417, -19.041],
                    [-44.319, -5.26],
                    [-36.074, 6.022],
                    [2.887, 5.634],
                    [10.781, -5.651],
                    [10.682, -19.432],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [695.66, 211.911], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 79",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.979, 0.922],
                    [0, 0],
                    [1.33, -0.818],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.979, 0.921],
                    [0, 0],
                    [1.33, -0.818],
                  ],
                  v: [
                    [1.682, -8.199],
                    [1.78, 5.582],
                    [-1.683, 8.199],
                    [-1.781, -5.581],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.296, -3.149],
                    [10.855, 6.125],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.462, -3.081],
                    [-10.662, 6.339],
                    [0.028, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.662, 6.338],
                    [-5.462, -3.081],
                    [0, 0],
                    [0.029, 4.11],
                    [10.855, 6.125],
                    [5.296, -3.148],
                    [0, 0],
                    [0.029, 4.058],
                  ],
                  v: [
                    [-2.114, -5.379],
                    [-41.075, -4.992],
                    [-49.32, -16.273],
                    [-49.222, -2.492],
                    [-40.977, 8.789],
                    [-2.016, 8.401],
                    [5.878, -2.883],
                    [5.779, -16.664],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [700.563, 209.144], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 80",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.489, 0.634],
                    [0, 0],
                    [0.638, -0.601],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.489, 0.633],
                    [0, 0],
                    [0.638, -0.601],
                  ],
                  v: [
                    [0.796, -7.818],
                    [0.894, 5.964],
                    [-0.796, 7.818],
                    [-0.894, -5.964],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.296, -3.149],
                    [10.855, 6.125],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.462, -3.081],
                    [-10.662, 6.339],
                    [0.028, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.662, 6.338],
                    [-5.462, -3.081],
                    [0, 0],
                    [0.029, 4.11],
                    [10.855, 6.125],
                    [5.296, -3.148],
                    [0, 0],
                    [0.029, 4.058],
                  ],
                  v: [
                    [-4.691, -3.144],
                    [-43.652, -2.757],
                    [-51.897, -14.038],
                    [-51.799, -0.257],
                    [-43.554, 11.024],
                    [-4.593, 10.636],
                    [3.301, -0.648],
                    [3.202, -14.429],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [703.14, 206.909], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 81",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.309, 0.561],
                    [0, 0],
                    [0.418, -0.543],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.31, 0.561],
                    [0, 0],
                    [0.418, -0.543],
                  ],
                  v: [
                    [0.497, -7.719],
                    [0.595, 6.062],
                    [-0.497, 7.719],
                    [-0.595, -6.062],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.296, -3.149],
                    [10.855, 6.125],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.462, -3.081],
                    [-10.662, 6.339],
                    [0.028, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.662, 6.338],
                    [-5.462, -3.081],
                    [0, 0],
                    [0.029, 4.11],
                    [10.855, 6.125],
                    [5.296, -3.148],
                    [0, 0],
                    [0.029, 4.058],
                  ],
                  v: [
                    [-6.082, -1.388],
                    [-45.043, -1.001],
                    [-53.288, -12.282],
                    [-53.19, 1.499],
                    [-44.945, 12.78],
                    [-5.984, 12.392],
                    [1.91, 1.108],
                    [1.811, -12.673],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [704.531, 205.153], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 82",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.193, 0.53],
                    [0, 0],
                    [0.286, -0.518],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.193, 0.529],
                    [0, 0],
                    [0.286, -0.519],
                  ],
                  v: [
                    [0.31, -7.677],
                    [0.408, 6.104],
                    [-0.31, 7.677],
                    [-0.408, -6.103],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.296, -3.149],
                    [10.855, 6.125],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.462, -3.081],
                    [-10.662, 6.339],
                    [0.028, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.662, 6.338],
                    [-5.462, -3.081],
                    [0, 0],
                    [0.029, 4.11],
                    [10.855, 6.125],
                    [5.296, -3.148],
                    [0, 0],
                    [0.029, 4.058],
                  ],
                  v: [
                    [-6.988, 0.227],
                    [-45.949, 0.614],
                    [-54.194, -10.667],
                    [-54.096, 3.114],
                    [-45.851, 14.395],
                    [-6.89, 14.007],
                    [1.004, 2.723],
                    [0.905, -11.058],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [705.437, 203.538], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 83",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.099, 0.521],
                    [0, 0],
                    [0.188, -0.515],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.099, 0.521],
                    [0, 0],
                    [0.188, -0.515],
                  ],
                  v: [
                    [0.166, -7.668],
                    [0.264, 6.113],
                    [-0.166, 7.668],
                    [-0.264, -6.113],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.296, -3.149],
                    [10.855, 6.125],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.462, -3.081],
                    [-10.662, 6.339],
                    [0.028, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.662, 6.338],
                    [-5.462, -3.081],
                    [0, 0],
                    [0.029, 4.11],
                    [10.855, 6.125],
                    [5.296, -3.148],
                    [0, 0],
                    [0.029, 4.058],
                  ],
                  v: [
                    [-7.561, 1.792],
                    [-46.522, 2.179],
                    [-54.767, -9.102],
                    [-54.669, 4.679],
                    [-46.424, 15.96],
                    [-7.463, 15.572],
                    [0.431, 4.288],
                    [0.332, -9.493],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [706.01, 201.973], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 84",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.01, 0.528],
                    [0, 0],
                    [0.1, -0.526],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.01, 0.528],
                    [0, 0],
                    [0.1, -0.527],
                  ],
                  v: [
                    [0.034, -7.682],
                    [0.132, 6.1],
                    [-0.033, 7.682],
                    [-0.131, -6.098],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.296, -3.149],
                    [10.855, 6.125],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.462, -3.081],
                    [-10.662, 6.339],
                    [0.028, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.662, 6.338],
                    [-5.462, -3.081],
                    [0, 0],
                    [0.029, 4.11],
                    [10.855, 6.125],
                    [5.296, -3.148],
                    [0, 0],
                    [0.029, 4.058],
                  ],
                  v: [
                    [-7.859, 3.361],
                    [-46.82, 3.748],
                    [-55.065, -7.533],
                    [-54.967, 6.248],
                    [-46.722, 17.529],
                    [-7.761, 17.141],
                    [0.133, 5.857],
                    [0.034, -7.924],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [706.308, 200.404], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 85",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.001, 0.081],
                    [0, 0],
                    [0.002, -0.081],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0.08],
                    [0, 0],
                    [0.001, -0.081],
                  ],
                  v: [
                    [-0.048, -7.012],
                    [0.049, 6.77],
                    [0.047, 7.012],
                    [-0.049, -6.77],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.296, -3.149],
                    [10.855, 6.125],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.462, -3.081],
                    [-10.662, 6.339],
                    [0.028, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.662, 6.338],
                    [-5.462, -3.081],
                    [0, 0],
                    [0.029, 4.11],
                    [10.855, 6.125],
                    [5.296, -3.148],
                    [0, 0],
                    [0.029, 4.058],
                  ],
                  v: [
                    [-7.942, 4.273],
                    [-46.903, 4.66],
                    [-55.148, -6.621],
                    [-55.05, 7.16],
                    [-46.805, 18.441],
                    [-7.844, 18.053],
                    [0.05, 6.769],
                    [-0.049, -7.012],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [706.391, 199.492], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 86",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.029, 4.058],
                    [0, 0],
                    [5.296, -3.148],
                    [10.856, 6.124],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.463, -3.082],
                    [-10.661, 6.338],
                  ],
                  o: [
                    [0, 0],
                    [0.029, 4.057],
                    [-10.661, 6.338],
                    [-5.462, -3.082],
                    [0, 0],
                    [0.03, 4.111],
                    [10.855, 6.124],
                    [5.296, -3.149],
                  ],
                  v: [
                    [27.487, -15.789],
                    [27.584, -2.008],
                    [19.69, 9.276],
                    [-19.271, 9.664],
                    [-27.516, -1.617],
                    [-27.614, -15.398],
                    [-19.367, -4.116],
                    [19.592, -4.503],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [678.856, 208.269], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 87",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-5.462, -3.082],
                    [-10.661, 6.338],
                    [-0.075, 3.977],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.029, 4],
                    [10.856, 6.124],
                    [5.19, -3.086],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [28.721, -24.211],
                    [-28.721, -24.464],
                    [-26.786, 7.622],
                    [-26.783, 7.622],
                    [-18.541, 18.34],
                    [20.42, 17.671],
                    [28.304, 6.488],
                    [28.312, 6.415],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [752.029, 225.377], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 88",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-10.855, -6.124],
                    [10.662, -6.338],
                    [10.855, 6.125],
                    [-10.662, 6.339],
                  ],
                  o: [
                    [10.855, 6.124],
                    [-10.661, 6.338],
                    [-10.855, -6.124],
                    [10.661, -6.338],
                  ],
                  v: [
                    [19.305, -11.477],
                    [19.656, 11.089],
                    [-19.304, 11.476],
                    [-19.655, -11.09],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [752.793, 231.676], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 89",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-1.405, -0.538],
                    [0, 0],
                    [1.25, 0.705],
                    [0.029, 4.111],
                    [0, 0],
                    [-5.462, -3.082],
                  ],
                  o: [
                    [0, 0],
                    [-1.405, -0.539],
                    [-5.462, -3.082],
                    [0, 0],
                    [0.029, 4.11],
                    [1.251, 0.706],
                  ],
                  v: [
                    [6.07, -0.317],
                    [6.168, 13.464],
                    [2.175, 11.598],
                    [-6.07, 0.316],
                    [-6.168, -13.464],
                    [2.077, -2.183],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.297, -3.149],
                    [10.855, 6.125],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.463, -3.082],
                    [-10.662, 6.338],
                    [0.029, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.66, 6.338],
                    [-5.463, -3.082],
                    [0, 0],
                    [0.029, 4.111],
                    [10.855, 6.124],
                    [5.296, -3.149],
                    [0, 0],
                    [0.029, 4.057],
                  ],
                  v: [
                    [41.037, -2.57],
                    [2.077, -2.183],
                    [-6.168, -13.464],
                    [-6.07, 0.316],
                    [2.175, 11.598],
                    [41.136, 11.211],
                    [49.029, -0.074],
                    [48.931, -13.854],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [731.411, 245.335], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 90",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-1.152, -0.316],
                    [0, 0],
                    [1.083, 0.415],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.153, -0.316],
                    [0, 0],
                    [1.083, 0.415],
                  ],
                  v: [
                    [1.63, -6.343],
                    [1.728, 7.438],
                    [-1.629, 6.342],
                    [-1.727, -7.438],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.297, -3.149],
                    [10.855, 6.125],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.463, -3.082],
                    [-10.662, 6.338],
                    [0.029, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.66, 6.338],
                    [-5.463, -3.082],
                    [0, 0],
                    [0.029, 4.111],
                    [10.855, 6.124],
                    [5.296, -3.149],
                    [0, 0],
                    [0.029, 4.057],
                  ],
                  v: [
                    [33.24, -9.692],
                    [-5.72, -9.305],
                    [-13.965, -20.586],
                    [-13.867, -6.806],
                    [-5.622, 4.476],
                    [33.339, 4.089],
                    [41.232, -7.196],
                    [41.134, -20.976],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [739.208, 252.457], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 91",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.977, -0.192],
                    [0, 0],
                    [0.942, 0.258],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.977, -0.193],
                    [0, 0],
                    [0.942, 0.258],
                  ],
                  v: [
                    [1.392, -6.553],
                    [1.49, 7.229],
                    [-1.391, 6.553],
                    [-1.49, -7.229],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.297, -3.149],
                    [10.855, 6.125],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.463, -3.082],
                    [-10.662, 6.338],
                    [0.029, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.66, 6.338],
                    [-5.463, -3.082],
                    [0, 0],
                    [0.029, 4.111],
                    [10.855, 6.124],
                    [5.296, -3.149],
                    [0, 0],
                    [0.029, 4.057],
                  ],
                  v: [
                    [30.121, -10.578],
                    [-8.839, -10.191],
                    [-17.084, -21.472],
                    [-16.986, -7.692],
                    [-8.741, 3.59],
                    [30.22, 3.203],
                    [38.113, -8.082],
                    [38.015, -21.862],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [742.327, 253.343], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 92",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.922, -0.121],
                    [0, 0],
                    [0.9, 0.177],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.922, -0.122],
                    [0, 0],
                    [0.9, 0.177],
                  ],
                  v: [
                    [1.319, -6.667],
                    [1.417, 7.115],
                    [-1.319, 6.667],
                    [-1.417, -7.115],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.297, -3.149],
                    [10.855, 6.125],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.463, -3.082],
                    [-10.662, 6.338],
                    [0.029, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.66, 6.338],
                    [-5.463, -3.082],
                    [0, 0],
                    [0.029, 4.111],
                    [10.855, 6.124],
                    [5.296, -3.149],
                    [0, 0],
                    [0.029, 4.057],
                  ],
                  v: [
                    [27.313, -11.14],
                    [-11.647, -10.753],
                    [-19.892, -22.034],
                    [-19.794, -8.254],
                    [-11.549, 3.028],
                    [27.412, 2.641],
                    [35.305, -8.644],
                    [35.207, -22.424],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [745.135, 253.905], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 93",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.9, -0.065],
                    [0, 0],
                    [0.887, 0.117],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.9, -0.065],
                    [0, 0],
                    [0.887, 0.117],
                  ],
                  v: [
                    [1.292, -6.754],
                    [1.39, 7.027],
                    [-1.292, 6.754],
                    [-1.39, -7.027],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.297, -3.149],
                    [10.855, 6.125],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.463, -3.082],
                    [-10.662, 6.338],
                    [0.029, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.66, 6.338],
                    [-5.463, -3.082],
                    [0, 0],
                    [0.029, 4.111],
                    [10.855, 6.124],
                    [5.296, -3.149],
                    [0, 0],
                    [0.029, 4.057],
                  ],
                  v: [
                    [24.604, -11.5],
                    [-14.356, -11.113],
                    [-22.601, -22.394],
                    [-22.503, -8.614],
                    [-14.258, 2.668],
                    [24.703, 2.281],
                    [32.596, -9.004],
                    [32.498, -22.784],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [747.844, 254.265], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 94",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.907, -0.014],
                    [0, 0],
                    [0.901, 0.066],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.908, -0.014],
                    [0, 0],
                    [0.901, 0.065],
                  ],
                  v: [
                    [1.309, -6.832],
                    [1.406, 6.949],
                    [-1.308, 6.831],
                    [-1.406, -6.949],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.297, -3.149],
                    [10.855, 6.125],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.463, -3.082],
                    [-10.662, 6.338],
                    [0.029, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.66, 6.338],
                    [-5.463, -3.082],
                    [0, 0],
                    [0.029, 4.111],
                    [10.855, 6.124],
                    [5.296, -3.149],
                    [0, 0],
                    [0.029, 4.057],
                  ],
                  v: [
                    [21.905, -11.696],
                    [-17.055, -11.309],
                    [-25.3, -22.59],
                    [-25.202, -8.81],
                    [-16.957, 2.472],
                    [22.004, 2.085],
                    [29.897, -9.2],
                    [29.799, -22.98],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [750.543, 254.461], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 95",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.939, 0.041],
                    [0, 0],
                    [0.941, 0.014],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.939, 0.041],
                    [0, 0],
                    [0.941, 0.014],
                  ],
                  v: [
                    [1.362, -6.918],
                    [1.46, 6.863],
                    [-1.362, 6.904],
                    [-1.46, -6.877],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.297, -3.149],
                    [10.855, 6.125],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.463, -3.082],
                    [-10.662, 6.338],
                    [0.029, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.66, 6.338],
                    [-5.463, -3.082],
                    [0, 0],
                    [0.029, 4.111],
                    [10.855, 6.124],
                    [5.296, -3.149],
                    [0, 0],
                    [0.029, 4.057],
                  ],
                  v: [
                    [19.137, -11.741],
                    [-19.823, -11.354],
                    [-28.068, -22.635],
                    [-27.97, -8.855],
                    [-19.725, 2.427],
                    [19.236, 2.04],
                    [27.129, -9.245],
                    [27.031, -23.025],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [753.311, 254.506], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 96",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-1.011, 0.111],
                    [0, 0],
                    [1.023, -0.045],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.011, 0.111],
                    [0, 0],
                    [1.022, -0.045],
                  ],
                  v: [
                    [1.478, -7.008],
                    [1.576, 6.773],
                    [-1.479, 7.008],
                    [-1.576, -6.773],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.297, -3.149],
                    [10.855, 6.125],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.463, -3.082],
                    [-10.662, 6.338],
                    [0.029, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.66, 6.338],
                    [-5.463, -3.082],
                    [0, 0],
                    [0.029, 4.111],
                    [10.855, 6.124],
                    [5.296, -3.149],
                    [0, 0],
                    [0.029, 4.057],
                  ],
                  v: [
                    [16.199, -11.596],
                    [-22.761, -11.21],
                    [-31.006, -22.491],
                    [-30.908, -8.711],
                    [-22.663, 2.572],
                    [16.298, 2.184],
                    [24.191, -9.101],
                    [24.093, -22.881],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [756.249, 254.362], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 97",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-1.153, 0.22],
                    [0, 0],
                    [1.184, -0.13],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.153, 0.219],
                    [0, 0],
                    [1.184, -0.129],
                  ],
                  v: [
                    [1.706, -7.153],
                    [1.803, 6.628],
                    [-1.706, 7.153],
                    [-1.804, -6.628],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.297, -3.149],
                    [10.855, 6.125],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.463, -3.082],
                    [-10.662, 6.338],
                    [0.029, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.66, 6.338],
                    [-5.463, -3.082],
                    [0, 0],
                    [0.029, 4.111],
                    [10.855, 6.124],
                    [5.296, -3.149],
                    [0, 0],
                    [0.029, 4.057],
                  ],
                  v: [
                    [12.918, -11.217],
                    [-26.042, -10.83],
                    [-34.287, -22.111],
                    [-34.189, -8.331],
                    [-25.944, 2.951],
                    [13.017, 2.564],
                    [20.91, -8.721],
                    [20.812, -22.501],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [759.53, 253.982], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 98",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-1.666, 0.559],
                    [0, 0],
                    [1.792, -0.342],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.666, 0.558],
                    [0, 0],
                    [1.793, -0.341],
                  ],
                  v: [
                    [2.552, -7.566],
                    [2.65, 6.215],
                    [-2.553, 7.566],
                    [-2.651, -6.215],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.297, -3.149],
                    [10.855, 6.125],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.463, -3.082],
                    [-10.662, 6.338],
                    [0.029, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.66, 6.338],
                    [-5.463, -3.082],
                    [0, 0],
                    [0.029, 4.111],
                    [10.855, 6.124],
                    [5.296, -3.149],
                    [0, 0],
                    [0.029, 4.057],
                  ],
                  v: [
                    [8.562, -10.28],
                    [-30.398, -9.893],
                    [-38.643, -21.174],
                    [-38.545, -7.394],
                    [-30.3, 3.888],
                    [8.661, 3.501],
                    [16.554, -7.784],
                    [16.456, -21.564],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [763.886, 253.045], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 99",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.11, 0.068],
                    [0, 0],
                    [0.112, -0.067],
                    [2.157, -0.723],
                    [0, 0],
                    [-1.827, 1.086],
                  ],
                  o: [
                    [0, 0],
                    [-0.11, 0.067],
                    [-1.827, 1.086],
                    [0, 0],
                    [2.157, -0.723],
                    [0.112, -0.067],
                  ],
                  v: [
                    [3.123, -8.349],
                    [3.221, 5.432],
                    [2.887, 5.634],
                    [-3.123, 8.349],
                    [-3.221, -5.432],
                    [2.789, -8.146],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.297, -3.149],
                    [10.855, 6.125],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.463, -3.082],
                    [-10.662, 6.338],
                    [0.029, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.66, 6.338],
                    [-5.463, -3.082],
                    [0, 0],
                    [0.029, 4.111],
                    [10.855, 6.124],
                    [5.296, -3.149],
                    [0, 0],
                    [0.029, 4.057],
                  ],
                  v: [
                    [2.788, -8.146],
                    [-36.172, -7.759],
                    [-44.417, -19.04],
                    [-44.319, -5.26],
                    [-36.074, 6.022],
                    [2.887, 5.634],
                    [10.78, -5.651],
                    [10.682, -19.431],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [769.66, 250.911], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 100",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.979, 0.922],
                    [0, 0],
                    [1.33, -0.818],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.979, 0.921],
                    [0, 0],
                    [1.33, -0.818],
                  ],
                  v: [
                    [1.682, -8.199],
                    [1.78, 5.582],
                    [-1.683, 8.199],
                    [-1.781, -5.581],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.297, -3.149],
                    [10.855, 6.125],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.463, -3.082],
                    [-10.662, 6.338],
                    [0.029, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.66, 6.338],
                    [-5.463, -3.082],
                    [0, 0],
                    [0.029, 4.111],
                    [10.855, 6.124],
                    [5.296, -3.149],
                    [0, 0],
                    [0.029, 4.057],
                  ],
                  v: [
                    [-2.115, -5.379],
                    [-41.075, -4.992],
                    [-49.32, -16.273],
                    [-49.222, -2.493],
                    [-40.977, 8.789],
                    [-2.016, 8.402],
                    [5.877, -2.883],
                    [5.779, -16.663],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [774.563, 248.144], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 101",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.489, 0.634],
                    [0, 0],
                    [0.638, -0.601],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.489, 0.633],
                    [0, 0],
                    [0.638, -0.601],
                  ],
                  v: [
                    [0.796, -7.818],
                    [0.894, 5.964],
                    [-0.796, 7.818],
                    [-0.894, -5.964],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.297, -3.149],
                    [10.855, 6.125],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.463, -3.082],
                    [-10.662, 6.338],
                    [0.029, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.66, 6.338],
                    [-5.463, -3.082],
                    [0, 0],
                    [0.029, 4.111],
                    [10.855, 6.124],
                    [5.296, -3.149],
                    [0, 0],
                    [0.029, 4.057],
                  ],
                  v: [
                    [-4.692, -3.144],
                    [-43.652, -2.757],
                    [-51.897, -14.038],
                    [-51.799, -0.258],
                    [-43.554, 11.024],
                    [-4.593, 10.637],
                    [3.3, -0.648],
                    [3.202, -14.428],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [777.14, 245.909], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 102",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.309, 0.561],
                    [0, 0],
                    [0.418, -0.543],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.31, 0.561],
                    [0, 0],
                    [0.418, -0.543],
                  ],
                  v: [
                    [0.497, -7.719],
                    [0.595, 6.062],
                    [-0.497, 7.719],
                    [-0.595, -6.062],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.297, -3.149],
                    [10.855, 6.125],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.463, -3.082],
                    [-10.662, 6.338],
                    [0.029, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.66, 6.338],
                    [-5.463, -3.082],
                    [0, 0],
                    [0.029, 4.111],
                    [10.855, 6.124],
                    [5.296, -3.149],
                    [0, 0],
                    [0.029, 4.057],
                  ],
                  v: [
                    [-6.083, -1.388],
                    [-45.043, -1.001],
                    [-53.288, -12.282],
                    [-53.19, 1.498],
                    [-44.945, 12.78],
                    [-5.984, 12.393],
                    [1.909, 1.108],
                    [1.811, -12.672],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [778.531, 244.153], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 103",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.193, 0.53],
                    [0, 0],
                    [0.286, -0.518],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.193, 0.529],
                    [0, 0],
                    [0.286, -0.519],
                  ],
                  v: [
                    [0.31, -7.677],
                    [0.408, 6.104],
                    [-0.31, 7.677],
                    [-0.408, -6.103],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.297, -3.149],
                    [10.855, 6.125],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.463, -3.082],
                    [-10.662, 6.338],
                    [0.029, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.66, 6.338],
                    [-5.463, -3.082],
                    [0, 0],
                    [0.029, 4.111],
                    [10.855, 6.124],
                    [5.296, -3.149],
                    [0, 0],
                    [0.029, 4.057],
                  ],
                  v: [
                    [-6.989, 0.227],
                    [-45.949, 0.614],
                    [-54.194, -10.667],
                    [-54.096, 3.113],
                    [-45.851, 14.395],
                    [-6.89, 14.008],
                    [1.003, 2.723],
                    [0.905, -11.057],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [779.437, 242.538], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 104",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.099, 0.521],
                    [0, 0],
                    [0.188, -0.515],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.099, 0.521],
                    [0, 0],
                    [0.188, -0.515],
                  ],
                  v: [
                    [0.166, -7.668],
                    [0.264, 6.113],
                    [-0.166, 7.668],
                    [-0.264, -6.113],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.297, -3.149],
                    [10.855, 6.125],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.463, -3.082],
                    [-10.662, 6.338],
                    [0.029, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.66, 6.338],
                    [-5.463, -3.082],
                    [0, 0],
                    [0.029, 4.111],
                    [10.855, 6.124],
                    [5.296, -3.149],
                    [0, 0],
                    [0.029, 4.057],
                  ],
                  v: [
                    [-7.562, 1.792],
                    [-46.522, 2.179],
                    [-54.767, -9.102],
                    [-54.669, 4.678],
                    [-46.424, 15.96],
                    [-7.463, 15.573],
                    [0.43, 4.288],
                    [0.332, -9.492],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [780.01, 240.973], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 105",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.01, 0.528],
                    [0, 0],
                    [0.1, -0.526],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.01, 0.528],
                    [0, 0],
                    [0.1, -0.527],
                  ],
                  v: [
                    [0.034, -7.682],
                    [0.132, 6.1],
                    [-0.033, 7.682],
                    [-0.131, -6.098],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.297, -3.149],
                    [10.855, 6.125],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.463, -3.082],
                    [-10.662, 6.338],
                    [0.029, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.66, 6.338],
                    [-5.463, -3.082],
                    [0, 0],
                    [0.029, 4.111],
                    [10.855, 6.124],
                    [5.296, -3.149],
                    [0, 0],
                    [0.029, 4.057],
                  ],
                  v: [
                    [-7.86, 3.361],
                    [-46.82, 3.748],
                    [-55.065, -7.533],
                    [-54.967, 6.247],
                    [-46.722, 17.529],
                    [-7.761, 17.142],
                    [0.132, 5.857],
                    [0.034, -7.923],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [780.308, 239.404], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 106",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.001, 0.081],
                    [0, 0],
                    [0.002, -0.081],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0.08],
                    [0, 0],
                    [0.001, -0.081],
                  ],
                  v: [
                    [-0.048, -7.012],
                    [0.049, 6.769],
                    [0.047, 7.011],
                    [-0.049, -6.77],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.297, -3.149],
                    [10.855, 6.125],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.463, -3.082],
                    [-10.662, 6.338],
                    [0.029, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.66, 6.338],
                    [-5.463, -3.082],
                    [0, 0],
                    [0.029, 4.111],
                    [10.855, 6.124],
                    [5.296, -3.149],
                    [0, 0],
                    [0.029, 4.057],
                  ],
                  v: [
                    [-7.943, 4.273],
                    [-46.903, 4.66],
                    [-55.148, -6.621],
                    [-55.05, 7.159],
                    [-46.805, 18.441],
                    [-7.844, 18.054],
                    [0.049, 6.769],
                    [-0.049, -7.011],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [780.391, 238.492], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 107",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.029, 4.058],
                    [0, 0],
                    [5.296, -3.148],
                    [10.856, 6.124],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.463, -3.082],
                    [-10.661, 6.338],
                  ],
                  o: [
                    [0, 0],
                    [0.029, 4.057],
                    [-10.661, 6.338],
                    [-5.462, -3.082],
                    [0, 0],
                    [0.03, 4.111],
                    [10.855, 6.124],
                    [5.296, -3.149],
                  ],
                  v: [
                    [27.487, -15.789],
                    [27.584, -2.008],
                    [19.69, 9.276],
                    [-19.271, 9.664],
                    [-27.516, -1.617],
                    [-27.614, -15.398],
                    [-19.367, -4.116],
                    [19.592, -4.504],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [752.856, 247.269], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 108",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-1.405, -0.538],
                    [0, 0],
                    [1.25, 0.705],
                    [0.029, 4.111],
                    [0, 0],
                    [-5.462, -3.082],
                  ],
                  o: [
                    [0, 0],
                    [-1.405, -0.539],
                    [-5.462, -3.082],
                    [0, 0],
                    [0.029, 4.11],
                    [1.251, 0.706],
                  ],
                  v: [
                    [6.07, -0.317],
                    [6.168, 13.464],
                    [2.175, 11.598],
                    [-6.07, 0.316],
                    [-6.168, -13.464],
                    [2.077, -2.183],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.297, -3.148],
                    [10.855, 6.123],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.463, -3.082],
                    [-10.662, 6.338],
                    [0.029, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.66, 6.338],
                    [-5.463, -3.082],
                    [0, 0],
                    [0.029, 4.11],
                    [10.855, 6.124],
                    [5.296, -3.149],
                    [0, 0],
                    [0.029, 4.059],
                  ],
                  v: [
                    [41.037, -2.57],
                    [2.077, -2.182],
                    [-6.168, -13.464],
                    [-6.07, 0.317],
                    [2.175, 11.598],
                    [41.136, 11.211],
                    [49.029, -0.074],
                    [48.931, -13.855],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [803.411, 276.335], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 109",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-1.152, -0.316],
                    [0, 0],
                    [1.083, 0.415],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.153, -0.316],
                    [0, 0],
                    [1.083, 0.415],
                  ],
                  v: [
                    [1.63, -6.342],
                    [1.728, 7.439],
                    [-1.629, 6.342],
                    [-1.727, -7.438],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.297, -3.148],
                    [10.855, 6.123],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.463, -3.082],
                    [-10.662, 6.338],
                    [0.029, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.66, 6.338],
                    [-5.463, -3.082],
                    [0, 0],
                    [0.029, 4.11],
                    [10.855, 6.124],
                    [5.296, -3.149],
                    [0, 0],
                    [0.029, 4.059],
                  ],
                  v: [
                    [33.24, -9.692],
                    [-5.72, -9.304],
                    [-13.965, -20.586],
                    [-13.867, -6.805],
                    [-5.622, 4.476],
                    [33.339, 4.089],
                    [41.232, -7.196],
                    [41.134, -20.977],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [811.208, 283.457], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 110",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.977, -0.192],
                    [0, 0],
                    [0.942, 0.258],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.977, -0.193],
                    [0, 0],
                    [0.942, 0.258],
                  ],
                  v: [
                    [1.392, -6.553],
                    [1.49, 7.228],
                    [-1.391, 6.552],
                    [-1.49, -7.229],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.297, -3.148],
                    [10.855, 6.123],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.463, -3.082],
                    [-10.662, 6.338],
                    [0.029, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.66, 6.338],
                    [-5.463, -3.082],
                    [0, 0],
                    [0.029, 4.11],
                    [10.855, 6.124],
                    [5.296, -3.149],
                    [0, 0],
                    [0.029, 4.059],
                  ],
                  v: [
                    [30.121, -10.578],
                    [-8.839, -10.19],
                    [-17.084, -21.472],
                    [-16.986, -7.691],
                    [-8.741, 3.59],
                    [30.22, 3.203],
                    [38.113, -8.082],
                    [38.015, -21.863],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [814.327, 284.343], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 111",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.922, -0.121],
                    [0, 0],
                    [0.9, 0.177],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.922, -0.122],
                    [0, 0],
                    [0.9, 0.177],
                  ],
                  v: [
                    [1.319, -6.667],
                    [1.417, 7.115],
                    [-1.319, 6.667],
                    [-1.417, -7.115],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.297, -3.148],
                    [10.855, 6.123],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.463, -3.082],
                    [-10.662, 6.338],
                    [0.029, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.66, 6.338],
                    [-5.463, -3.082],
                    [0, 0],
                    [0.029, 4.11],
                    [10.855, 6.124],
                    [5.296, -3.149],
                    [0, 0],
                    [0.029, 4.059],
                  ],
                  v: [
                    [27.313, -11.14],
                    [-11.647, -10.752],
                    [-19.892, -22.034],
                    [-19.794, -8.253],
                    [-11.549, 3.028],
                    [27.412, 2.641],
                    [35.305, -8.644],
                    [35.207, -22.425],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [817.135, 284.905], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 112",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.9, -0.065],
                    [0, 0],
                    [0.887, 0.117],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.9, -0.065],
                    [0, 0],
                    [0.887, 0.117],
                  ],
                  v: [
                    [1.292, -6.754],
                    [1.39, 7.027],
                    [-1.292, 6.754],
                    [-1.39, -7.027],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.297, -3.148],
                    [10.855, 6.123],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.463, -3.082],
                    [-10.662, 6.338],
                    [0.029, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.66, 6.338],
                    [-5.463, -3.082],
                    [0, 0],
                    [0.029, 4.11],
                    [10.855, 6.124],
                    [5.296, -3.149],
                    [0, 0],
                    [0.029, 4.059],
                  ],
                  v: [
                    [24.604, -11.5],
                    [-14.356, -11.112],
                    [-22.601, -22.394],
                    [-22.503, -8.613],
                    [-14.258, 2.668],
                    [24.703, 2.281],
                    [32.596, -9.004],
                    [32.498, -22.785],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [819.844, 285.265], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 113",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.907, -0.014],
                    [0, 0],
                    [0.901, 0.066],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.908, -0.014],
                    [0, 0],
                    [0.901, 0.065],
                  ],
                  v: [
                    [1.309, -6.832],
                    [1.406, 6.949],
                    [-1.308, 6.831],
                    [-1.406, -6.949],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.297, -3.148],
                    [10.855, 6.123],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.463, -3.082],
                    [-10.662, 6.338],
                    [0.029, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.66, 6.338],
                    [-5.463, -3.082],
                    [0, 0],
                    [0.029, 4.11],
                    [10.855, 6.124],
                    [5.296, -3.149],
                    [0, 0],
                    [0.029, 4.059],
                  ],
                  v: [
                    [21.905, -11.696],
                    [-17.055, -11.308],
                    [-25.3, -22.59],
                    [-25.202, -8.809],
                    [-16.957, 2.472],
                    [22.004, 2.085],
                    [29.897, -9.2],
                    [29.799, -22.981],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [822.543, 285.461], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 114",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.939, 0.041],
                    [0, 0],
                    [0.941, 0.014],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.939, 0.041],
                    [0, 0],
                    [0.941, 0.014],
                  ],
                  v: [
                    [1.362, -6.918],
                    [1.46, 6.863],
                    [-1.362, 6.904],
                    [-1.46, -6.877],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.297, -3.148],
                    [10.855, 6.123],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.463, -3.082],
                    [-10.662, 6.338],
                    [0.029, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.66, 6.338],
                    [-5.463, -3.082],
                    [0, 0],
                    [0.029, 4.11],
                    [10.855, 6.124],
                    [5.296, -3.149],
                    [0, 0],
                    [0.029, 4.059],
                  ],
                  v: [
                    [19.137, -11.741],
                    [-19.823, -11.353],
                    [-28.068, -22.635],
                    [-27.97, -8.854],
                    [-19.725, 2.427],
                    [19.236, 2.04],
                    [27.129, -9.245],
                    [27.031, -23.026],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [825.311, 285.506], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 115",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-1.011, 0.111],
                    [0, 0],
                    [1.023, -0.045],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.011, 0.111],
                    [0, 0],
                    [1.022, -0.045],
                  ],
                  v: [
                    [1.478, -7.008],
                    [1.576, 6.773],
                    [-1.479, 7.008],
                    [-1.576, -6.773],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.297, -3.148],
                    [10.855, 6.123],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.463, -3.082],
                    [-10.662, 6.338],
                    [0.029, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.66, 6.338],
                    [-5.463, -3.082],
                    [0, 0],
                    [0.029, 4.11],
                    [10.855, 6.124],
                    [5.296, -3.149],
                    [0, 0],
                    [0.029, 4.059],
                  ],
                  v: [
                    [16.199, -11.596],
                    [-22.761, -11.208],
                    [-31.006, -22.491],
                    [-30.908, -8.71],
                    [-22.663, 2.572],
                    [16.298, 2.184],
                    [24.191, -9.101],
                    [24.093, -22.882],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [828.249, 285.362], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 116",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-1.153, 0.22],
                    [0, 0],
                    [1.184, -0.13],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.153, 0.219],
                    [0, 0],
                    [1.184, -0.129],
                  ],
                  v: [
                    [1.706, -7.153],
                    [1.803, 6.628],
                    [-1.706, 7.152],
                    [-1.804, -6.629],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.297, -3.148],
                    [10.855, 6.123],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.463, -3.082],
                    [-10.662, 6.338],
                    [0.029, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.66, 6.338],
                    [-5.463, -3.082],
                    [0, 0],
                    [0.029, 4.11],
                    [10.855, 6.124],
                    [5.296, -3.149],
                    [0, 0],
                    [0.029, 4.059],
                  ],
                  v: [
                    [12.918, -11.217],
                    [-26.042, -10.829],
                    [-34.287, -22.111],
                    [-34.189, -8.33],
                    [-25.944, 2.951],
                    [13.017, 2.564],
                    [20.91, -8.721],
                    [20.812, -22.502],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [831.53, 284.982], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 117",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-1.666, 0.559],
                    [0, 0],
                    [1.792, -0.342],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.666, 0.558],
                    [0, 0],
                    [1.793, -0.341],
                  ],
                  v: [
                    [2.552, -7.565],
                    [2.65, 6.216],
                    [-2.553, 7.566],
                    [-2.651, -6.215],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.297, -3.148],
                    [10.855, 6.123],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.463, -3.082],
                    [-10.662, 6.338],
                    [0.029, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.66, 6.338],
                    [-5.463, -3.082],
                    [0, 0],
                    [0.029, 4.11],
                    [10.855, 6.124],
                    [5.296, -3.149],
                    [0, 0],
                    [0.029, 4.059],
                  ],
                  v: [
                    [8.562, -10.28],
                    [-30.398, -9.892],
                    [-38.643, -21.174],
                    [-38.545, -7.393],
                    [-30.3, 3.888],
                    [8.661, 3.501],
                    [16.554, -7.784],
                    [16.456, -21.565],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [835.886, 284.045], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 118",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.11, 0.068],
                    [0, 0],
                    [0.112, -0.067],
                    [2.157, -0.723],
                    [0, 0],
                    [-1.827, 1.086],
                  ],
                  o: [
                    [0, 0],
                    [-0.11, 0.067],
                    [-1.827, 1.086],
                    [0, 0],
                    [2.157, -0.723],
                    [0.112, -0.067],
                  ],
                  v: [
                    [3.123, -8.349],
                    [3.221, 5.432],
                    [2.887, 5.634],
                    [-3.123, 8.349],
                    [-3.221, -5.432],
                    [2.789, -8.146],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.297, -3.148],
                    [10.855, 6.123],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.463, -3.082],
                    [-10.662, 6.338],
                    [0.029, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.66, 6.338],
                    [-5.463, -3.082],
                    [0, 0],
                    [0.029, 4.11],
                    [10.855, 6.124],
                    [5.296, -3.149],
                    [0, 0],
                    [0.029, 4.059],
                  ],
                  v: [
                    [2.788, -8.146],
                    [-36.172, -7.758],
                    [-44.417, -19.04],
                    [-44.319, -5.259],
                    [-36.074, 6.022],
                    [2.887, 5.634],
                    [10.78, -5.651],
                    [10.682, -19.431],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [841.66, 281.911], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 119",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.979, 0.922],
                    [0, 0],
                    [1.33, -0.818],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.979, 0.921],
                    [0, 0],
                    [1.33, -0.818],
                  ],
                  v: [
                    [1.682, -8.199],
                    [1.78, 5.582],
                    [-1.683, 8.199],
                    [-1.781, -5.581],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.297, -3.148],
                    [10.855, 6.123],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.463, -3.082],
                    [-10.662, 6.338],
                    [0.029, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.66, 6.338],
                    [-5.463, -3.082],
                    [0, 0],
                    [0.029, 4.11],
                    [10.855, 6.124],
                    [5.296, -3.149],
                    [0, 0],
                    [0.029, 4.059],
                  ],
                  v: [
                    [-2.115, -5.379],
                    [-41.075, -4.991],
                    [-49.32, -16.273],
                    [-49.222, -2.492],
                    [-40.977, 8.789],
                    [-2.016, 8.402],
                    [5.877, -2.883],
                    [5.779, -16.664],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [846.563, 279.144], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 120",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.489, 0.634],
                    [0, 0],
                    [0.638, -0.601],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.489, 0.633],
                    [0, 0],
                    [0.638, -0.601],
                  ],
                  v: [
                    [0.796, -7.818],
                    [0.894, 5.964],
                    [-0.796, 7.818],
                    [-0.894, -5.964],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.297, -3.148],
                    [10.855, 6.123],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.463, -3.082],
                    [-10.662, 6.338],
                    [0.029, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.66, 6.338],
                    [-5.463, -3.082],
                    [0, 0],
                    [0.029, 4.11],
                    [10.855, 6.124],
                    [5.296, -3.149],
                    [0, 0],
                    [0.029, 4.059],
                  ],
                  v: [
                    [-4.692, -3.144],
                    [-43.652, -2.756],
                    [-51.897, -14.038],
                    [-51.799, -0.257],
                    [-43.554, 11.024],
                    [-4.593, 10.637],
                    [3.3, -0.648],
                    [3.202, -14.429],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [849.14, 276.909], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 121",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.309, 0.561],
                    [0, 0],
                    [0.418, -0.543],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.31, 0.561],
                    [0, 0],
                    [0.418, -0.543],
                  ],
                  v: [
                    [0.497, -7.719],
                    [0.595, 6.062],
                    [-0.497, 7.719],
                    [-0.595, -6.062],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.297, -3.148],
                    [10.855, 6.123],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.463, -3.082],
                    [-10.662, 6.338],
                    [0.029, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.66, 6.338],
                    [-5.463, -3.082],
                    [0, 0],
                    [0.029, 4.11],
                    [10.855, 6.124],
                    [5.296, -3.149],
                    [0, 0],
                    [0.029, 4.059],
                  ],
                  v: [
                    [-6.083, -1.388],
                    [-45.043, -1],
                    [-53.288, -12.282],
                    [-53.19, 1.499],
                    [-44.945, 12.78],
                    [-5.984, 12.393],
                    [1.909, 1.108],
                    [1.811, -12.673],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [850.531, 275.153], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 122",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.193, 0.53],
                    [0, 0],
                    [0.286, -0.518],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.193, 0.529],
                    [0, 0],
                    [0.286, -0.519],
                  ],
                  v: [
                    [0.31, -7.677],
                    [0.408, 6.104],
                    [-0.31, 7.677],
                    [-0.408, -6.103],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.297, -3.148],
                    [10.855, 6.123],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.463, -3.082],
                    [-10.662, 6.338],
                    [0.029, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.66, 6.338],
                    [-5.463, -3.082],
                    [0, 0],
                    [0.029, 4.11],
                    [10.855, 6.124],
                    [5.296, -3.149],
                    [0, 0],
                    [0.029, 4.059],
                  ],
                  v: [
                    [-6.989, 0.227],
                    [-45.949, 0.615],
                    [-54.194, -10.667],
                    [-54.096, 3.114],
                    [-45.851, 14.395],
                    [-6.89, 14.008],
                    [1.003, 2.723],
                    [0.905, -11.058],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [851.437, 273.538], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 123",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.099, 0.521],
                    [0, 0],
                    [0.188, -0.515],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.099, 0.521],
                    [0, 0],
                    [0.188, -0.515],
                  ],
                  v: [
                    [0.166, -7.668],
                    [0.264, 6.113],
                    [-0.166, 7.668],
                    [-0.264, -6.113],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.297, -3.148],
                    [10.855, 6.123],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.463, -3.082],
                    [-10.662, 6.338],
                    [0.029, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.66, 6.338],
                    [-5.463, -3.082],
                    [0, 0],
                    [0.029, 4.11],
                    [10.855, 6.124],
                    [5.296, -3.149],
                    [0, 0],
                    [0.029, 4.059],
                  ],
                  v: [
                    [-7.562, 1.792],
                    [-46.522, 2.18],
                    [-54.767, -9.102],
                    [-54.669, 4.679],
                    [-46.424, 15.96],
                    [-7.463, 15.573],
                    [0.43, 4.288],
                    [0.332, -9.493],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [852.01, 271.973], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 124",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.01, 0.528],
                    [0, 0],
                    [0.1, -0.526],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.01, 0.528],
                    [0, 0],
                    [0.1, -0.527],
                  ],
                  v: [
                    [0.034, -7.681],
                    [0.132, 6.1],
                    [-0.033, 7.682],
                    [-0.131, -6.098],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.297, -3.148],
                    [10.855, 6.123],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.463, -3.082],
                    [-10.662, 6.338],
                    [0.029, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.66, 6.338],
                    [-5.463, -3.082],
                    [0, 0],
                    [0.029, 4.11],
                    [10.855, 6.124],
                    [5.296, -3.149],
                    [0, 0],
                    [0.029, 4.059],
                  ],
                  v: [
                    [-7.86, 3.361],
                    [-46.82, 3.749],
                    [-55.065, -7.533],
                    [-54.967, 6.248],
                    [-46.722, 17.529],
                    [-7.761, 17.142],
                    [0.132, 5.857],
                    [0.034, -7.924],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [852.308, 270.404], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 125",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.001, 0.081],
                    [0, 0],
                    [0.002, -0.081],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0.08],
                    [0, 0],
                    [0.001, -0.081],
                  ],
                  v: [
                    [-0.048, -7.012],
                    [0.049, 6.77],
                    [0.047, 7.012],
                    [-0.049, -6.77],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.297, -3.148],
                    [10.855, 6.123],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.463, -3.082],
                    [-10.662, 6.338],
                    [0.029, 4.058],
                    [0, 0],
                  ],
                  o: [
                    [-10.66, 6.338],
                    [-5.463, -3.082],
                    [0, 0],
                    [0.029, 4.11],
                    [10.855, 6.124],
                    [5.296, -3.149],
                    [0, 0],
                    [0.029, 4.059],
                  ],
                  v: [
                    [-7.943, 4.273],
                    [-46.903, 4.661],
                    [-55.148, -6.621],
                    [-55.05, 7.16],
                    [-46.805, 18.441],
                    [-7.844, 18.054],
                    [0.049, 6.769],
                    [-0.049, -7.012],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 4,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [852.391, 269.492], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 126",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.029, 4.058],
                    [0, 0],
                    [5.296, -3.148],
                    [10.856, 6.124],
                    [0.029, 4.11],
                    [0, 0],
                    [-5.463, -3.082],
                    [-10.661, 6.338],
                  ],
                  o: [
                    [0, 0],
                    [0.029, 4.057],
                    [-10.661, 6.338],
                    [-5.462, -3.082],
                    [0, 0],
                    [0.03, 4.111],
                    [10.855, 6.124],
                    [5.296, -3.149],
                  ],
                  v: [
                    [27.487, -15.789],
                    [27.584, -2.008],
                    [19.69, 9.276],
                    [-19.271, 9.664],
                    [-27.516, -1.617],
                    [-27.614, -15.398],
                    [-19.367, -4.116],
                    [19.592, -4.504],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [824.856, 278.269], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 14,
    },
    {
      ty: 4,
      nm: "top_left_platform Outlines",
      sr: 1,
      st: 0,
      op: 120,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [576, 360, 0] },
        s: { a: 0, k: [100, 100, 100] },
        sk: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 360, 0],
              t: 6,
              ti: [0, 0, 0],
              to: [0, -1.16666662693024, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 353, 0],
              t: 17,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 360, 0],
              t: 30,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 353, 0],
              t: 44,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 360, 0],
              t: 57,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 353, 0],
              t: 72,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.167, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 360, 0],
              t: 85,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.167, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 353, 0],
              t: 98,
              ti: [0, -1.16666662693024, 0],
              to: [0, 0, 0],
            },
            { s: [576, 360, 0], t: 111 },
          ],
        },
        r: { a: 0, k: 0 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 1",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [132.11, -5.802],
                    [-9.585, 76.542],
                    [-132.11, 5.802],
                    [9.585, -76.542],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [386.737, 223.93], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 2",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [61.272, 32.052],
                    [61.253, 38.688],
                    [-61.272, -32.052],
                    [-61.253, -38.688],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.8118, 0.8118, 0.8118] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [315.88, 268.42], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 3",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [70.857, -44.49],
                    [70.838, -37.854],
                    [-70.857, 44.49],
                    [-70.838, 37.855],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [447.99, 262.618], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 15,
    },
    {
      ty: 4,
      nm: "top_left_shadow Outlines",
      sr: 1,
      st: 0,
      op: 120,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [576, 360, 0] },
        s: { a: 0, k: [100, 100, 100] },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [576, 360, 0] },
        r: { a: 0, k: 0 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 1",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-130.617, 8.255],
                    [-9.596, 78.127],
                    [130.603, -3.349],
                    [130.617, -8.255],
                    [9.596, -78.126],
                    [-130.603, 3.349],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-9.592, 81.593],
                    [-133.622, 9.984],
                    [-133.598, 1.62],
                    [9.591, -81.593],
                    [133.622, -9.984],
                    [133.598, -1.62],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.0706, 0.6392, 0.7098] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [386.727, 249.162], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 2",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [9.594, -79.86],
                    [-132.101, 2.484],
                    [-132.12, 9.12],
                    [-9.594, 79.86],
                    [132.101, -2.484],
                    [132.12, -9.12],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.1098, 0.2, 0.3216] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [386.727, 249.162], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 16,
    },
    {
      ty: 4,
      nm: "middle_object_shadow Outlines",
      sr: 1,
      st: 0,
      op: 120,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [576, 360, 0] },
        s: { a: 0, k: [100, 100, 100] },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [576, 360, 0] },
        r: { a: 0, k: 0 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 1",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-158.82, -8.577],
                    [27.031, 98.725],
                    [158.783, 22.159],
                    [158.822, 8.576],
                    [-27.029, -98.725],
                    [-158.781, -22.16],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [27.036, 102.192],
                    [-161.825, -6.848],
                    [-161.777, -23.889],
                    [-27.034, -102.192],
                    [161.826, 6.846],
                    [161.778, 23.888],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.0706, 0.6392, 0.7098] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [594.947, 386.242], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 2",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-27.033, -100.459],
                    [-160.28, -23.024],
                    [-160.323, -7.712],
                    [27.033, 100.458],
                    [160.279, 23.023],
                    [160.323, 7.711],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.1098, 0.2, 0.3216] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [594.947, 386.242], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 17,
    },
    {
      ty: 4,
      nm: "top_right_objects_shadow Outlines",
      sr: 1,
      st: 0,
      op: 120,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [576, 360, 0] },
        s: { a: 0, k: [100, 100, 100] },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [576, 360, 0] },
        r: { a: 0, k: 0 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 1",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.099, -7.49],
                    [-4.931, -2.71],
                    [-7.508, -0.1],
                    [0, 0],
                    [-0.099, 7.49],
                    [14.911, 0.198],
                  ],
                  o: [
                    [-14.775, 0],
                    [-0.047, 3.544],
                    [5.229, 2.873],
                    [0, 0],
                    [14.775, 0],
                    [0.101, -7.558],
                    [0, 0],
                  ],
                  v: [
                    [-0.564, -13.834],
                    [-27.54, -0.251],
                    [-19.966, 9.448],
                    [-0.216, 14.058],
                    [0.501, 14.062],
                    [27.477, 0.479],
                    [0.154, -13.829],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [16.686, 0],
                    [0.254, 0.003],
                    [5.652, 3.106],
                    [-0.063, 4.706],
                    [-17.089, -0.233],
                    [0.126, -9.501],
                  ],
                  o: [
                    [-0.252, 0],
                    [-7.99, -0.106],
                    [-5.949, -3.27],
                    [0.125, -9.417],
                    [17.107, 0.227],
                    [-0.124, 9.276],
                  ],
                  v: [
                    [0.501, 17.062],
                    [-0.256, 17.058],
                    [-21.411, 12.077],
                    [-30.54, -0.291],
                    [0.194, -16.829],
                    [30.477, 0.52],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.2157, 0.902, 0.9608] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [822.382, 286.905], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 2",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.113, -8.529],
                    [16.021, 0.213],
                    [-0.114, 8.529],
                    [-16.021, -0.212],
                  ],
                  o: [
                    [-0.113, 8.529],
                    [-16.021, -0.212],
                    [0.113, -8.53],
                    [16.021, 0.213],
                  ],
                  v: [
                    [29.009, 0.385],
                    [-0.205, 15.443],
                    [-29.008, -0.385],
                    [0.205, -15.444],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [822.35, 287.02], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 3",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.1, -7.49],
                    [-14.911, -0.198],
                    [0, 0],
                    [-0.099, 7.491],
                    [14.912, 0.198],
                  ],
                  o: [
                    [-14.776, 0],
                    [-0.1, 7.559],
                    [0, 0],
                    [14.776, 0],
                    [0.101, -7.558],
                    [0, 0],
                  ],
                  v: [
                    [-0.531, -13.836],
                    [-27.509, -0.253],
                    [-0.185, 14.057],
                    [0.531, 14.061],
                    [27.509, 0.478],
                    [0.185, -13.831],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [16.687, 0],
                    [0.254, 0.004],
                    [-0.126, 9.501],
                    [-17.011, -0.231],
                    [0.126, -9.501],
                  ],
                  o: [
                    [-0.251, 0],
                    [-17.107, -0.227],
                    [0.126, -9.417],
                    [17.108, 0.227],
                    [-0.123, 9.276],
                  ],
                  v: [
                    [0.531, 17.061],
                    [-0.225, 17.057],
                    [-30.509, -0.292],
                    [0.225, -16.831],
                    [30.509, 0.518],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.2157, 0.902, 0.9608] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [752.222, 252.469], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 4",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.113, -8.529],
                    [16.021, 0.213],
                    [-0.114, 8.529],
                    [-16.021, -0.212],
                  ],
                  o: [
                    [-0.113, 8.529],
                    [-16.021, -0.212],
                    [0.113, -8.53],
                    [16.021, 0.213],
                  ],
                  v: [
                    [29.009, 0.385],
                    [-0.205, 15.443],
                    [-29.008, -0.385],
                    [0.205, -15.444],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [752.222, 252.582], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 5",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.099, -7.49],
                    [-4.931, -2.71],
                    [-7.508, -0.1],
                    [0, 0],
                    [-0.1, 7.49],
                    [14.911, 0.198],
                  ],
                  o: [
                    [-14.775, 0],
                    [-0.047, 3.544],
                    [5.229, 2.873],
                    [0, 0],
                    [14.776, 0],
                    [0.1, -7.558],
                    [0, 0],
                  ],
                  v: [
                    [-0.564, -13.84],
                    [-27.54, -0.257],
                    [-19.966, 9.442],
                    [-0.216, 14.053],
                    [0.501, 14.057],
                    [27.478, 0.473],
                    [0.154, -13.835],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [16.686, 0],
                    [0.254, 0.003],
                    [5.652, 3.106],
                    [-0.063, 4.706],
                    [-17.088, -0.222],
                    [0.125, -9.501],
                  ],
                  o: [
                    [-0.252, 0],
                    [-7.99, -0.106],
                    [-5.949, -3.27],
                    [0.125, -9.417],
                    [17.107, 0.227],
                    [-0.124, 9.276],
                  ],
                  v: [
                    [0.501, 17.057],
                    [-0.256, 17.053],
                    [-21.411, 12.071],
                    [-30.54, -0.296],
                    [0.194, -16.835],
                    [30.478, 0.514],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.2157, 0.902, 0.9608] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [678.622, 212.4], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 6",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.113, -8.529],
                    [16.021, 0.213],
                    [-0.114, 8.529],
                    [-16.021, -0.212],
                  ],
                  o: [
                    [-0.113, 8.529],
                    [-16.021, -0.213],
                    [0.113, -8.53],
                    [16.021, 0.213],
                  ],
                  v: [
                    [29.009, 0.385],
                    [-0.205, 15.443],
                    [-29.008, -0.385],
                    [0.205, -15.444],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [678.59, 212.509], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 18,
    },
    {
      ty: 4,
      nm: "bottom_left_platform Outlines",
      sr: 1,
      st: 0,
      op: 120,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [576, 360, 0] },
        s: { a: 0, k: [100, 100, 100] },
        sk: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 360, 0],
              t: 9,
              ti: [0, 0, 0],
              to: [0, -1.16666662693024, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 353, 0],
              t: 20,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 360, 0],
              t: 33,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 353, 0],
              t: 47,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 360, 0],
              t: 60,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 353, 0],
              t: 75,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.167, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 360, 0],
              t: 88,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 353, 0],
              t: 101,
              ti: [0, -1.16666662693024, 0],
              to: [0, 0, 0],
            },
            { s: [576, 360, 0], t: 114 },
          ],
        },
        r: { a: 0, k: 0 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 1",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [160.938, 13.681],
                    [24.143, 93.177],
                    [-160.938, -13.68],
                    [-24.143, -93.176],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [325.098, 556.462], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 2",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [92.55, 50.111],
                    [92.531, 56.746],
                    [-92.55, -50.11],
                    [-92.531, -56.746],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.8118, 0.8118, 0.8118] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [256.692, 599.527], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 3",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [68.406, -43.065],
                    [68.387, -36.43],
                    [-68.407, 43.065],
                    [-68.388, 36.43],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [417.629, 613.208], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 19,
    },
    {
      ty: 4,
      nm: "bottom_left_shadow Outlines",
      sr: 1,
      st: 0,
      op: 120,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [576, 360, 0] },
        s: { a: 0, k: [100, 100, 100] },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [576, 360, 0] },
        r: { a: 0, k: 0 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 1",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-143.048, 9.113],
                    [-10.5, 85.64],
                    [143.032, -3.583],
                    [143.047, -9.113],
                    [10.499, -85.64],
                    [-143.032, 3.583],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-10.495, 89.107],
                    [-146.053, 10.842],
                    [-146.027, 1.853],
                    [10.495, -89.107],
                    [146.052, -10.843],
                    [146.028, -1.853],
                    [145.283, -1.421],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.0706, 0.6392, 0.7098] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [345.402, 602.932], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 2",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [10.497, -87.373],
                    [-144.529, 2.719],
                    [-144.55, 9.977],
                    [-10.497, 87.373],
                    [144.53, -2.719],
                    [144.55, -9.977],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.1098, 0.2, 0.3216] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [345.402, 602.932], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 20,
    },
    {
      ty: 4,
      nm: "bottom_right_platform Outlines",
      sr: 1,
      st: 0,
      op: 120,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [576, 360, 0] },
        s: { a: 0, k: [100, 100, 100] },
        sk: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 360, 0],
              t: 3,
              ti: [0, 0, 0],
              to: [0, -1.16666662693024, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 353, 0],
              t: 14,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 360, 0],
              t: 27,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 353, 0],
              t: 41,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 360, 0],
              t: 54,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 353, 0],
              t: 69,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 360, 0],
              t: 82,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [576, 353, 0],
              t: 95,
              ti: [0, -1.16666662693024, 0],
              to: [0, 0, 0],
            },
            { s: [576, 360, 0], t: 108 },
          ],
        },
        r: { a: 0, k: 0 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 1",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [132.108, -5.801],
                    [-9.583, 76.541],
                    [-132.108, 5.801],
                    [9.583, -76.541],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [805.871, 494.584], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 2",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [61.272, 32.052],
                    [61.253, 38.688],
                    [-61.272, -32.053],
                    [-61.253, -38.688],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.8118, 0.8118, 0.8118] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [735.016, 539.072], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 3",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [70.855, -44.488],
                    [70.836, -37.853],
                    [-70.855, 44.489],
                    [-70.836, 37.854],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [867.124, 533.271], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 21,
    },
    {
      ty: 4,
      nm: "bottom_right_shadow Outlines",
      sr: 1,
      st: 0,
      op: 120,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [576, 360, 0] },
        s: { a: 0, k: [100, 100, 100] },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [576, 360, 0] },
        r: { a: 0, k: 0 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 1",
          np: 4,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-130.615, 8.254],
                    [-9.594, 78.126],
                    [130.602, -3.348],
                    [130.615, -8.253],
                    [9.595, -78.125],
                    [-130.601, 3.348],
                  ],
                },
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-9.589, 81.592],
                    [-133.62, 9.983],
                    [-133.596, 1.619],
                    [9.59, -81.592],
                    [133.62, -9.983],
                    [133.597, -1.618],
                  ],
                },
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.0706, 0.6392, 0.7098] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [805.757, 516.891], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 2",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [9.592, -79.858],
                    [-132.099, 2.484],
                    [-132.118, 9.119],
                    [-9.592, 79.859],
                    [132.099, -2.482],
                    [132.118, -9.118],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.1098, 0.2, 0.3216] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [805.757, 516.892], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 22,
    },
    {
      ty: 4,
      nm: "wires Outlines",
      sr: 1,
      st: 0,
      op: 120,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [576, 360, 0] },
        s: { a: 0, k: [100, 100, 100] },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [576, 360, 0] },
        r: { a: 0, k: 0 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 1",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-27.543, 13.936],
                    [-28.117, 13.117],
                    [10.521, -13.936],
                    [28.116, -2.608],
                    [27.576, -1.768],
                    [10.546, -12.732],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.0706, 0.6392, 0.7373] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [332.229, 484.435], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 2",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.412, 0],
                    [0, 0.952],
                    [1.512, 0],
                    [0, -0.953],
                    [-0.56, -0.316],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.544, 0],
                    [0, 0.952],
                    [1.512, 0],
                    [0, -0.953],
                    [-0.398, -0.299],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.53, 0],
                    [0, 0.953],
                    [1.511, 0],
                    [0, -0.952],
                    [-0.399, -0.3],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.635, 0],
                    [0, 0.952],
                    [1.512, 0],
                    [0, -0.953],
                    [-0.307, -0.278],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.35, 0.103],
                    [1.512, 0],
                    [0, -0.953],
                    [-1.511, 0],
                    [0, 0.51],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.426, 0.172],
                    [1.512, 0],
                    [0, -0.953],
                    [-1.512, 0],
                    [0, 0.421],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.418, 0.164],
                    [1.511, 0],
                    [0, -0.952],
                    [-1.513, 0],
                    [0, 0.422],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.464, 0.229],
                    [1.512, 0],
                    [0, -0.953],
                    [-1.512, 0],
                    [0, 0.365],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-41.965, 42.66],
                    [13.918, 7.169],
                    [13.91, 7.185],
                    [81.118, 47.281],
                    [81.298, 47.389],
                    [108.487, 27.034],
                    [109.636, 27.198],
                    [112.374, 25.474],
                    [109.636, 23.749],
                    [106.899, 25.474],
                    [107.817, 26.754],
                    [81.259, 46.635],
                    [14.494, 6.803],
                    [34.288, -5.766],
                    [35.761, -5.492],
                    [38.499, -7.216],
                    [35.761, -8.941],
                    [33.023, -7.216],
                    [33.67, -6.116],
                    [-41.974, 41.924],
                    [-111.194, 0.331],
                    [-86.89, -15.504],
                    [-50.477, -11.122],
                    [-50.379, -11.111],
                    [-16.403, -29.418],
                    [-14.965, -29.155],
                    [-12.228, -30.879],
                    [-14.965, -32.603],
                    [-17.703, -30.879],
                    [-17.055, -29.777],
                    [-50.501, -11.755],
                    [-86.073, -16.038],
                    [-42.678, -44.31],
                    [-41.002, -43.939],
                    [-38.265, -45.663],
                    [-41.002, -47.388],
                    [-43.74, -45.663],
                    [-43.251, -44.684],
                    [-112.374, 0.352],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.0706, 0.6392, 0.7373] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [691.853, 279.991], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 3",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-1.573, -0.139],
                    [-0.15, -0.001],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0.973],
                    [1.788, 0],
                    [0, -1.126],
                  ],
                  o: [
                    [0.125, 0.054],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [1.446, -0.204],
                    [0, -1.126],
                    [-1.788, 0],
                    [0, 1.03],
                  ],
                  v: [
                    [-12.455, -11.676],
                    [-12.039, -11.596],
                    [-12.12, -4.863],
                    [14.709, 15.725],
                    [15.243, 15.031],
                    [-11.372, -5.393],
                    [-11.296, -11.699],
                    [-8.767, -13.686],
                    [-12.005, -15.725],
                    [-15.243, -13.686],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.0706, 0.6392, 0.7373] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [542.892, 272.798], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 4",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.538, 0.464],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.447, 0.343],
                    [0.553, -0.721],
                    [-0.54, -0.414],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.305, -0.637],
                    [-0.54, -0.414],
                    [-0.554, 0.721],
                    [0.449, 0.344],
                  ],
                  v: [
                    [-8.661, -0.863],
                    [-2.782, 3.65],
                    [10.864, -0.184],
                    [10.874, -0.957],
                    [-2.464, 2.789],
                    [-8.141, -1.568],
                    [-8.33, -3.236],
                    [-10.309, -2.68],
                    [-10.334, -0.624],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.0706, 0.6392, 0.7373] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [451.784, 220.272], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 5",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.589, 0.004],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.447, 0.344],
                    [0.554, -0.721],
                    [-0.54, -0.414],
                    [-0.537, 0.464],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.643, -0.18],
                    [0.01, -0.803],
                    [-0.773, 0.217],
                    [-0.171, 0.66],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0.351],
                    [1.409, 0],
                    [0, -0.888],
                    [-1.409, 0],
                    [-0.366, 0.128],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.008, 0.668],
                    [0.772, -0.217],
                    [0.01, -0.803],
                    [-0.589, -0.004],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.643, -0.18],
                    [0.01, -0.803],
                    [-0.773, 0.217],
                    [-0.171, 0.659],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.447, 0.343],
                    [0.554, -0.721],
                    [-0.54, -0.414],
                    [-0.537, 0.464],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.008, -0.668],
                    [-0.773, 0.217],
                    [-0.009, 0.803],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.305, -0.638],
                    [-0.54, -0.414],
                    [-0.553, 0.721],
                    [0.449, 0.345],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.156, -0.568],
                    [-0.773, 0.217],
                    [-0.01, 0.803],
                    [0.643, -0.18],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.304, -0.264],
                    [0, -0.888],
                    [-1.409, 0],
                    [0, 0.887],
                    [0.447, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.593, -0.336],
                    [0.009, -0.803],
                    [-0.773, 0.217],
                    [-0.008, 0.668],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.156, -0.568],
                    [-0.773, 0.217],
                    [-0.01, 0.803],
                    [0.643, -0.181],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.305, -0.637],
                    [-0.54, -0.414],
                    [-0.553, 0.721],
                    [0.449, 0.344],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.593, 0.336],
                    [-0.01, 0.802],
                    [0.773, -0.217],
                    [0.008, -0.668],
                  ],
                  v: [
                    [104.409, 41.315],
                    [104.509, 33.04],
                    [95.494, 26.122],
                    [86.106, 28.759],
                    [77.488, 22.145],
                    [61.907, 26.521],
                    [55.482, 21.591],
                    [55.293, 19.922],
                    [53.313, 20.478],
                    [53.289, 22.534],
                    [54.962, 22.294],
                    [61.589, 27.38],
                    [77.17, 23.005],
                    [85.053, 29.055],
                    [60.013, 36.086],
                    [47.223, 26.271],
                    [36.334, 29.328],
                    [16.964, 14.462],
                    [8.798, 16.755],
                    [-2.278, 8.257],
                    [-4.805, 8.966],
                    [-6.135, 8.276],
                    [-7.552, 10.122],
                    [-6.17, 11.182],
                    [-4.814, 9.738],
                    [-2.595, 9.117],
                    [3.203, 13.565],
                    [-12.544, 17.988],
                    [-18.716, 13.253],
                    [-18.186, 13.104],
                    [-18.061, 2.813],
                    [-26.653, -3.781],
                    [-26.165, -4.719],
                    [-28.716, -6.327],
                    [-31.267, -4.719],
                    [-28.716, -3.112],
                    [-27.489, -3.319],
                    [-18.81, 3.341],
                    [-18.928, 13.09],
                    [-33.74, 1.724],
                    [-33.613, -8.706],
                    [-69.436, -36.198],
                    [-69.372, -41.525],
                    [-68.328, -43.207],
                    [-69.71, -44.268],
                    [-71.128, -42.422],
                    [-70.117, -41.315],
                    [-70.185, -35.668],
                    [-42.977, -14.788],
                    [-58.019, -10.565],
                    [-91.573, -36.315],
                    [-102.67, -33.199],
                    [-104, -33.889],
                    [-105.417, -32.043],
                    [-104.035, -30.982],
                    [-102.679, -32.426],
                    [-91.891, -35.456],
                    [-58.336, -9.706],
                    [-42.242, -14.224],
                    [-34.362, -8.177],
                    [-34.488, 2.253],
                    [-12.862, 18.849],
                    [3.939, 14.13],
                    [8.481, 17.616],
                    [16.646, 15.323],
                    [35.282, 29.624],
                    [19.628, 34.018],
                    [2.823, 21.122],
                    [2.634, 19.454],
                    [0.654, 20.01],
                    [0.63, 22.066],
                    [2.303, 21.828],
                    [19.31, 34.879],
                    [46.905, 27.13],
                    [59.696, 36.945],
                    [95.176, 26.983],
                    [103.76, 33.57],
                    [103.664, 41.525],
                    [102.62, 43.208],
                    [104.002, 44.268],
                    [105.419, 42.422],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.0706, 0.6392, 0.7373] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [473.603, 330.831], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 6",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.249, -0.174],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.217, 0.204],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [13.734, 1.482],
                    [37.539, 19.75],
                    [38.072, 19.055],
                    [14.052, 0.622],
                    [-4.157, 5.736],
                    [-37.367, -19.75],
                    [-38.072, -19.187],
                    [-4.475, 6.596],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.0706, 0.6392, 0.7373] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [523.796, 316.06], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 7",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.773, -0.217],
                    [0.171, -0.66],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.447, 0.344],
                    [0.554, -0.721],
                    [-0.54, -0.414],
                    [-0.537, 0.463],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.644, -0.181],
                    [0.01, -0.803],
                    [-0.772, 0.217],
                    [-0.171, 0.66],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.447, 0.343],
                    [0.553, -0.721],
                    [-0.54, -0.415],
                    [-0.537, 0.463],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.448, 0.343],
                    [0.553, -0.72],
                    [-0.54, -0.414],
                    [-0.537, 0.463],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.643, -0.181],
                    [0.009, -0.803],
                    [-0.773, 0.217],
                    [-0.171, 0.659],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.448, 0.344],
                    [0.553, -0.721],
                    [-0.54, -0.414],
                    [-0.538, 0.464],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.447, 0.343],
                    [0.553, -0.721],
                    [-0.54, -0.415],
                    [-0.537, 0.463],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.448, 0.344],
                    [0.553, -0.721],
                    [-0.54, -0.414],
                    [-0.537, 0.464],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0.716],
                    [1.788, 0],
                    [0, -1.126],
                    [-1.788, 0],
                    [-0.224, 0.031],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.008, 0.668],
                    [0.773, -0.217],
                    [0.01, -0.803],
                    [-0.589, -0.005],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.447, 0.343],
                    [0.554, -0.721],
                    [-0.54, -0.414],
                    [-0.537, 0.463],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.062, 0],
                    [0.467, -0.379],
                    [0, 0],
                    [0, 0],
                    [0.447, 0.343],
                    [0.554, -0.721],
                    [-0.54, -0.414],
                    [-0.538, 0.463],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.448, 0.344],
                    [0.554, -0.721],
                    [-0.539, -0.414],
                    [-0.538, 0.464],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, -0.02],
                    [-1.409, 0],
                    [-0.363, 0.125],
                    [0, 0],
                    [0, 0],
                    [0.106, -0.82],
                    [-0.938, 0.33],
                    [-0.128, 0.985],
                    [0.689, -0.044],
                    [0, 0],
                    [0, 0],
                    [0, 0.272],
                    [0.562, 0.295],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.643, 0.181],
                    [-0.009, 0.803],
                  ],
                  o: [
                    [-0.643, 0.181],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.305, -0.637],
                    [-0.54, -0.414],
                    [-0.553, 0.721],
                    [0.449, 0.344],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.156, -0.568],
                    [-0.772, 0.217],
                    [-0.009, 0.802],
                    [0.644, -0.18],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.305, -0.637],
                    [-0.54, -0.414],
                    [-0.554, 0.721],
                    [0.449, 0.344],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.306, -0.637],
                    [-0.539, -0.415],
                    [-0.554, 0.722],
                    [0.449, 0.345],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.156, -0.567],
                    [-0.773, 0.217],
                    [-0.01, 0.802],
                    [0.643, -0.181],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.305, -0.638],
                    [-0.539, -0.414],
                    [-0.554, 0.721],
                    [0.449, 0.344],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.305, -0.637],
                    [-0.54, -0.414],
                    [-0.554, 0.721],
                    [0.449, 0.344],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.305, -0.638],
                    [-0.539, -0.414],
                    [-0.554, 0.721],
                    [0.449, 0.344],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.886, -0.364],
                    [0, -1.126],
                    [-1.788, 0],
                    [0, 1.126],
                    [0.238, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.593, -0.337],
                    [0.009, -0.803],
                    [-0.773, 0.218],
                    [-0.008, 0.668],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.305, -0.637],
                    [-0.54, -0.414],
                    [-0.553, 0.721],
                    [0.449, 0.345],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.061, -0.002],
                    [-0.82, 0],
                    [0, 0],
                    [0, 0],
                    [0.305, -0.637],
                    [-0.54, -0.415],
                    [-0.553, 0.721],
                    [0.449, 0.345],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.305, -0.638],
                    [-0.539, -0.414],
                    [-0.553, 0.721],
                    [0.449, 0.345],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.001, 0.02],
                    [0, 0.887],
                    [0.444, 0],
                    [0, 0],
                    [0, 0],
                    [-0.744, 0.461],
                    [-0.129, 0.985],
                    [0.938, -0.33],
                    [0.107, -0.82],
                    [0, 0],
                    [0, 0],
                    [0.19, -0.225],
                    [0, -0.497],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.156, 0.567],
                    [0.773, -0.217],
                    [0.01, -0.803],
                  ],
                  v: [
                    [94.381, 57.923],
                    [93.025, 59.367],
                    [85.914, 61.364],
                    [72.907, 51.382],
                    [73.339, 15.477],
                    [27.894, -19.398],
                    [28.138, -39.574],
                    [8.41, -54.713],
                    [8.221, -56.381],
                    [6.241, -55.825],
                    [6.217, -53.769],
                    [7.89, -54.008],
                    [27.389, -39.044],
                    [27.159, -19.962],
                    [17.418, -27.438],
                    [17.557, -38.99],
                    [-12.72, -62.225],
                    [-33.887, -56.281],
                    [-35.218, -56.971],
                    [-36.635, -55.124],
                    [-35.253, -54.064],
                    [-33.897, -55.508],
                    [-13.038, -61.365],
                    [16.809, -38.461],
                    [16.683, -28.002],
                    [-6.955, -46.142],
                    [-16.82, -43.372],
                    [-27.103, -51.263],
                    [-38.52, -48.057],
                    [-48.41, -55.647],
                    [-48.599, -57.315],
                    [-50.578, -56.759],
                    [-50.603, -54.703],
                    [-48.93, -54.942],
                    [-38.838, -47.197],
                    [-27.421, -50.403],
                    [-17.873, -43.076],
                    [-38.868, -37.181],
                    [-63.737, -56.265],
                    [-63.926, -57.933],
                    [-65.905, -57.378],
                    [-65.93, -55.322],
                    [-64.257, -55.56],
                    [-39.186, -36.321],
                    [-7.273, -45.282],
                    [52.612, 0.674],
                    [34.748, 5.69],
                    [29.725, 1.836],
                    [29.726, 1.836],
                    [29.869, -10.011],
                    [-8.161, -39.196],
                    [-26.786, -33.966],
                    [-28.116, -34.655],
                    [-29.533, -32.808],
                    [-28.151, -31.748],
                    [-26.795, -33.192],
                    [-8.479, -38.337],
                    [29.121, -9.482],
                    [28.991, 1.272],
                    [-8.629, -27.597],
                    [-14.888, -25.839],
                    [-14.877, -25.854],
                    [-20.944, -30.51],
                    [-31.399, -27.574],
                    [-39.182, -33.546],
                    [-39.372, -35.215],
                    [-41.351, -34.659],
                    [-41.376, -32.603],
                    [-39.702, -32.842],
                    [-31.717, -26.714],
                    [-21.262, -29.65],
                    [-15.919, -25.55],
                    [-31.531, -21.166],
                    [-64.556, -46.509],
                    [-64.745, -48.177],
                    [-66.724, -47.621],
                    [-66.749, -45.565],
                    [-65.076, -45.804],
                    [-31.849, -20.306],
                    [-8.946, -26.737],
                    [34.43, 6.55],
                    [53.347, 1.238],
                    [72.591, 16.006],
                    [72.517, 22.127],
                    [55.752, 9.261],
                    [30.55, 16.339],
                    [22.202, 9.933],
                    [22.013, 8.264],
                    [20.034, 8.82],
                    [20.009, 10.876],
                    [21.682, 10.637],
                    [30.232, 17.198],
                    [55.434, 10.121],
                    [72.504, 23.221],
                    [72.36, 35.19],
                    [50.265, 18.234],
                    [29.616, 24.033],
                    [10.722, 9.533],
                    [10.829, 0.715],
                    [8.897, -0.767],
                    [8.975, -7.217],
                    [-3.469, -16.767],
                    [-1.995, -18.474],
                    [-5.232, -20.514],
                    [-8.47, -18.474],
                    [-5.232, -16.434],
                    [-4.539, -16.484],
                    [8.227, -6.688],
                    [8.162, -1.331],
                    [-11.816, -16.662],
                    [-40.35, -8.65],
                    [-43.827, -11.318],
                    [-43.696, -22.191],
                    [-76.414, -47.299],
                    [-76.306, -56.28],
                    [-75.261, -57.964],
                    [-76.644, -59.025],
                    [-78.061, -57.178],
                    [-77.05, -56.071],
                    [-77.162, -46.77],
                    [-44.444, -21.662],
                    [-44.562, -11.882],
                    [-59.658, -23.467],
                    [-59.847, -25.135],
                    [-61.827, -24.579],
                    [-61.851, -22.524],
                    [-60.178, -22.762],
                    [-40.668, -7.79],
                    [-12.134, -15.803],
                    [10.081, 1.245],
                    [9.986, 8.969],
                    [-9.127, -5.699],
                    [-22.05, -2.07],
                    [-22.232, -2.081],
                    [-24.241, -1.454],
                    [-38.976, 2.683],
                    [-93.04, -38.806],
                    [-93.229, -40.474],
                    [-95.209, -39.919],
                    [-95.233, -37.863],
                    [-93.56, -38.101],
                    [-59.307, -11.816],
                    [-66.701, -9.739],
                    [-87.862, -25.978],
                    [-88.052, -27.647],
                    [-90.031, -27.091],
                    [-90.056, -25.035],
                    [-88.382, -25.274],
                    [-66.776, -8.879],
                    [-58.119, -11.242],
                    [-58.119, -11.276],
                    [-39.051, 3.543],
                    [-24.653, -0.534],
                    [-24.723, -0.474],
                    [-22.202, 1.133],
                    [-20.999, 0.93],
                    [-12.032, 8.202],
                    [-13.269, 17.674],
                    [-14.74, 19.826],
                    [-13.276, 21.013],
                    [-11.346, 18.631],
                    [-12.369, 17.357],
                    [-11.085, 7.491],
                    [-19.989, 0.276],
                    [-19.681, -0.474],
                    [-20.608, -1.704],
                    [-9.445, -4.839],
                    [29.298, 24.893],
                    [49.947, 19.094],
                    [72.347, 36.284],
                    [72.158, 51.911],
                    [85.596, 62.224],
                    [93.016, 60.141],
                    [94.346, 60.83],
                    [95.763, 58.984],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.0706, 0.6392, 0.7373] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [494.119, 293.585], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 8",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.537, -0.463],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.448, 0.344],
                    [0.553, -0.722],
                    [-0.54, -0.414],
                    [-0.537, 0.463],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.448, 0.343],
                    [0.553, -0.721],
                    [-0.54, -0.414],
                    [-0.537, 0.464],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0.826],
                    [1.409, 0],
                    [0, -0.887],
                    [-1.192, -0.135],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.643, -0.18],
                    [0.009, -0.803],
                    [-0.773, 0.217],
                    [-0.171, 0.66],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.448, 0.343],
                    [0.553, -0.722],
                    [-0.54, -0.414],
                    [-0.538, 0.463],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.448, 0.344],
                    [0.553, -0.721],
                    [-0.54, -0.414],
                    [-0.538, 0.464],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.447, 0.344],
                    [0.554, -0.721],
                    [-0.54, -0.414],
                    [-0.537, 0.463],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.447, 0.343],
                    [0.554, -0.722],
                    [-0.539, -0.414],
                    [-0.538, 0.463],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.008, -0.668],
                    [-0.772, 0.218],
                    [-0.01, 0.803],
                    [0.589, 0.005],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.447, -0.343],
                    [-0.554, 0.721],
                    [0.539, 0.415],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.305, -0.638],
                    [-0.539, -0.414],
                    [-0.554, 0.721],
                    [0.449, 0.345],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.305, -0.638],
                    [-0.54, -0.415],
                    [-0.554, 0.721],
                    [0.449, 0.345],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [1.271, -0.091],
                    [0, -0.887],
                    [-1.409, 0],
                    [0, 0.79],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.156, -0.567],
                    [-0.773, 0.218],
                    [-0.01, 0.803],
                    [0.643, -0.181],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.304, -0.637],
                    [-0.539, -0.415],
                    [-0.554, 0.721],
                    [0.449, 0.345],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.304, -0.637],
                    [-0.539, -0.414],
                    [-0.554, 0.721],
                    [0.449, 0.345],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.305, -0.637],
                    [-0.54, -0.414],
                    [-0.553, 0.721],
                    [0.449, 0.344],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.305, -0.638],
                    [-0.54, -0.414],
                    [-0.553, 0.721],
                    [0.449, 0.345],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.593, 0.336],
                    [-0.01, 0.803],
                    [0.773, -0.216],
                    [0.008, -0.668],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.305, 0.637],
                    [0.54, 0.414],
                    [0.553, -0.721],
                    [-0.45, -0.344],
                  ],
                  v: [
                    [84.558, 34.625],
                    [64.376, 19.138],
                    [59.402, 20.535],
                    [57.392, 18.993],
                    [57.484, 11.416],
                    [42.408, -0.153],
                    [26.783, 4.235],
                    [7.015, -10.935],
                    [6.826, -12.604],
                    [4.847, -12.047],
                    [4.822, -9.992],
                    [6.495, -10.23],
                    [26.465, 5.095],
                    [42.092, 0.707],
                    [56.736, 11.946],
                    [56.658, 18.429],
                    [51.821, 14.717],
                    [45.163, 16.587],
                    [37.012, 10.333],
                    [36.824, 8.665],
                    [34.845, 9.22],
                    [34.819, 11.276],
                    [36.493, 11.037],
                    [44.109, 16.883],
                    [35.574, 19.303],
                    [24.004, 10.358],
                    [24.004, 10.335],
                    [23.897, 10.341],
                    [-3.395, -10.57],
                    [-15.056, -7.302],
                    [-32.832, -20.935],
                    [-32.815, -22.743],
                    [-30.556, -24.332],
                    [-33.108, -25.939],
                    [-35.66, -24.332],
                    [-33.563, -22.753],
                    [-33.591, -20.406],
                    [-16.13, -7.006],
                    [-22.25, -5.288],
                    [-23.58, -5.978],
                    [-24.997, -4.131],
                    [-23.615, -3.07],
                    [-22.259, -4.515],
                    [-3.757, -9.71],
                    [11.266, 1.817],
                    [3.119, 4.105],
                    [-2.934, -0.541],
                    [-3.124, -2.209],
                    [-5.103, -1.653],
                    [-5.128, 0.402],
                    [-3.454, 0.164],
                    [2.801, 4.965],
                    [12.001, 2.382],
                    [22.759, 10.637],
                    [1.846, 16.51],
                    [-17.339, 1.787],
                    [-30.76, 5.555],
                    [-37.404, 0.457],
                    [-37.252, -12.119],
                    [-46.385, -19.127],
                    [-46.304, -25.804],
                    [-58.288, -35.001],
                    [-58.478, -36.669],
                    [-60.457, -36.113],
                    [-60.482, -34.057],
                    [-58.808, -34.296],
                    [-47.053, -25.275],
                    [-47.12, -19.692],
                    [-65.302, -33.645],
                    [-65.491, -35.313],
                    [-67.471, -34.757],
                    [-67.495, -32.701],
                    [-65.822, -32.94],
                    [-38.001, -11.59],
                    [-38.139, -0.108],
                    [-84.037, -35.33],
                    [-84.226, -36.998],
                    [-86.206, -36.442],
                    [-86.231, -34.387],
                    [-84.557, -34.625],
                    [-31.078, 6.415],
                    [-17.657, 2.646],
                    [1.528, 17.369],
                    [13.355, 14.048],
                    [19.35, 18.649],
                    [19.298, 23.048],
                    [18.254, 24.732],
                    [19.634, 25.792],
                    [21.053, 23.946],
                    [20.041, 22.839],
                    [20.1, 18.119],
                    [14.408, 13.752],
                    [23.494, 11.201],
                    [35.171, 20.163],
                    [51.504, 15.577],
                    [59.085, 21.395],
                    [64.059, 19.998],
                    [84.038, 35.33],
                    [84.226, 36.998],
                    [86.207, 36.442],
                    [86.231, 34.386],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.0706, 0.6392, 0.7373] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [497.997, 327.443], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 9",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-114.165, 29.681],
                    [-113.585, 30.494],
                    [71.105, -101.393],
                    [112.323, -76.217],
                    [-2.159, 1.946],
                    [-2.328, 2.061],
                    [-22.496, 102.393],
                    [-21.516, 102.591],
                    [-1.427, 2.658],
                    [114.165, -76.264],
                    [71.063, -102.591],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.0706, 0.6392, 0.7373] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [438.963, 477.144], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 10",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.478, -0.3],
                    [-0.483, 0.77],
                    [0.577, 0.362],
                    [0.491, -0.512],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.055, 0.665],
                    [0.749, -0.289],
                    [-0.065, -0.8],
                    [-0.587, 0.051],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.478, -0.3],
                    [-0.483, 0.77],
                    [0.577, 0.362],
                    [0.491, -0.512],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.623, 0.24],
                    [0.066, 0.8],
                    [0.749, -0.289],
                    [0.108, -0.673],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.478, -0.3],
                    [-0.483, 0.771],
                    [0.577, 0.362],
                    [0.491, -0.512],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.055, -0.666],
                    [-0.749, 0.289],
                    [0.066, 0.8],
                    [0.586, -0.051],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.243, 0.663],
                    [0.576, 0.361],
                    [0.483, -0.77],
                    [-0.479, -0.3],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.559, -0.39],
                    [-0.066, -0.801],
                    [-0.75, 0.289],
                    [0.055, 0.666],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.243, 0.663],
                    [0.576, 0.362],
                    [0.483, -0.77],
                    [-0.479, -0.301],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.209, 0.551],
                    [0.749, -0.289],
                    [-0.065, -0.8],
                    [-0.623, 0.24],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.243, 0.664],
                    [0.576, 0.362],
                    [0.483, -0.77],
                    [-0.479, -0.3],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.559, 0.39],
                    [0.066, 0.8],
                    [0.749, -0.289],
                    [-0.055, -0.666],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [52.049, 3.291],
                    [51.193, -7.105],
                    [28.099, -21.591],
                    [11.818, -15.311],
                    [6.967, -18.354],
                    [-0.945, -15.302],
                    [-20.844, -27.784],
                    [-5.674, -33.634],
                    [12.269, -22.378],
                    [12.615, -20.734],
                    [14.534, -21.475],
                    [14.365, -23.523],
                    [12.722, -23.128],
                    [-5.438, -34.52],
                    [-32.182, -24.208],
                    [-45.839, -32.775],
                    [-80.224, -19.516],
                    [-89.391, -25.265],
                    [-90.044, -33.194],
                    [-89.164, -34.967],
                    [-90.638, -35.894],
                    [-91.877, -33.922],
                    [-90.766, -32.916],
                    [-90.086, -24.668],
                    [-80.461, -18.63],
                    [-71.362, -22.138],
                    [-62.159, -16.365],
                    [-47.06, -22.188],
                    [-40.2, -17.885],
                    [-39.854, -16.242],
                    [-37.935, -16.98],
                    [-38.104, -19.031],
                    [-39.747, -18.635],
                    [-46.825, -23.074],
                    [-61.924, -17.251],
                    [-70.341, -22.532],
                    [-46.076, -31.889],
                    [-32.417, -23.322],
                    [-21.865, -27.391],
                    [-1.182, -14.416],
                    [6.732, -17.467],
                    [18.56, -10.049],
                    [21.008, -10.993],
                    [22.398, -10.431],
                    [23.635, -12.403],
                    [22.159, -13.329],
                    [20.945, -11.764],
                    [18.794, -10.935],
                    [12.602, -14.819],
                    [27.865, -20.704],
                    [33.799, -16.982],
                    [34.631, -6.854],
                    [44.631, -0.581],
                    [44.977, 1.063],
                    [46.896, 0.322],
                    [46.727, -1.727],
                    [45.083, -1.331],
                    [35.328, -7.451],
                    [34.583, -16.49],
                    [50.497, -6.507],
                    [51.354, 3.889],
                    [89.605, 27.884],
                    [90.044, 33.193],
                    [89.162, 34.968],
                    [90.638, 35.893],
                    [91.875, 33.921],
                    [90.766, 32.915],
                    [90.3, 27.287],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.0706, 0.6392, 0.7373] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [720.835, 414.324], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 11",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.478, -0.3],
                    [-0.483, 0.77],
                    [0.577, 0.362],
                    [0.491, -0.512],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.055, 0.666],
                    [0.749, -0.288],
                    [-0.066, -0.801],
                    [-0.586, 0.051],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.477, 0.299],
                    [0.483, -0.77],
                    [-0.576, -0.361],
                    [-0.492, 0.512],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.478, -0.3],
                    [-0.483, 0.77],
                    [0.577, 0.361],
                    [0.491, -0.512],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.055, -0.666],
                    [-0.749, 0.289],
                    [0.066, 0.8],
                    [0.586, -0.051],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.623, 0.241],
                    [0.066, 0.801],
                    [0.749, -0.289],
                    [0.109, -0.673],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.478, -0.299],
                    [-0.483, 0.77],
                    [0.577, 0.361],
                    [0.491, -0.512],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.477, -0.3],
                    [-0.483, 0.77],
                    [0.576, 0.362],
                    [0.492, -0.512],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0.576, 0.362],
                    [0.483, -0.77],
                    [-0.479, -0.301],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.559, -0.391],
                    [-0.066, -0.8],
                    [-0.749, 0.289],
                    [0.055, 0.665],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.243, -0.664],
                    [-0.577, -0.362],
                    [-0.483, 0.77],
                    [0.479, 0.301],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.243, 0.663],
                    [0.576, 0.361],
                    [0.483, -0.77],
                    [-0.479, -0.301],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.559, 0.391],
                    [0.066, 0.8],
                    [0.749, -0.288],
                    [-0.055, -0.665],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.209, 0.55],
                    [0.749, -0.288],
                    [-0.066, -0.8],
                    [-0.623, 0.24],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.243, 0.663],
                    [0.576, 0.362],
                    [0.483, -0.77],
                    [-0.479, -0.301],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.244, 0.663],
                    [0.577, 0.362],
                    [0.483, -0.77],
                    [-0.48, -0.3],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.244, 0.663],
                  ],
                  v: [
                    [87.337, 27.855],
                    [89.255, 27.115],
                    [89.086, 25.066],
                    [87.443, 25.461],
                    [30.337, -10.361],
                    [17.33, -5.345],
                    [-3.156, -18.197],
                    [-14.618, -13.776],
                    [-21.021, -17.792],
                    [-21.381, -22.177],
                    [-20.5, -23.952],
                    [-21.977, -24.878],
                    [-23.214, -22.905],
                    [-22.103, -21.899],
                    [-21.715, -17.195],
                    [-15.639, -13.383],
                    [-24.443, -9.988],
                    [-36.913, -17.81],
                    [-52.741, -11.706],
                    [-60.836, -16.785],
                    [-65.656, -14.926],
                    [-86.99, -28.308],
                    [-87.335, -29.951],
                    [-89.254, -29.212],
                    [-89.085, -27.163],
                    [-87.441, -27.558],
                    [-65.891, -14.039],
                    [-61.071, -15.899],
                    [-58.926, -14.553],
                    [-58.303, -7.001],
                    [-42.206, 3.097],
                    [-27.063, -2.743],
                    [-5.954, 10.499],
                    [-5.608, 12.142],
                    [-3.69, 11.402],
                    [-3.859, 9.353],
                    [-5.503, 9.748],
                    [-26.827, -3.629],
                    [-41.97, 2.211],
                    [-57.608, -7.599],
                    [-58.141, -14.06],
                    [-52.977, -10.821],
                    [-37.148, -16.924],
                    [-24.701, -9.116],
                    [-24.699, -9.094],
                    [-24.68, -9.102],
                    [4.419, 9.151],
                    [15.699, 4.802],
                    [34.669, 16.701],
                    [34.885, 19.339],
                    [34.005, 21.114],
                    [35.481, 22.039],
                    [36.718, 20.067],
                    [35.608, 19.061],
                    [35.364, 16.104],
                    [16.719, 4.408],
                    [22.65, 2.121],
                    [24.039, 2.682],
                    [25.276, 0.71],
                    [23.799, -0.215],
                    [22.586, 1.35],
                    [4.655, 8.265],
                    [-23.659, -9.495],
                    [-3.392, -17.311],
                    [17.095, -4.46],
                    [30.102, -9.475],
                    [37.195, -5.025],
                    [38.229, 7.509],
                    [47.981, 13.627],
                    [48.529, 20.281],
                    [61.326, 28.309],
                    [61.672, 29.951],
                    [63.59, 29.212],
                    [63.421, 27.163],
                    [61.778, 27.558],
                    [49.225, 19.684],
                    [48.766, 14.119],
                    [68.181, 26.298],
                    [68.526, 27.941],
                    [70.445, 27.201],
                    [70.276, 25.152],
                    [68.632, 25.548],
                    [38.924, 6.912],
                    [37.981, -4.532],
                    [86.992, 26.212],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.0706, 0.6392, 0.7373] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [713.938, 419.428], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 12",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.576, 0.362],
                    [0.491, -0.512],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.478, -0.3],
                    [-0.483, 0.77],
                    [0.576, 0.361],
                    [0.491, -0.512],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.055, 0.666],
                    [0.749, -0.289],
                    [-0.066, -0.801],
                    [-0.587, 0.051],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.477, -0.299],
                    [-0.483, 0.77],
                    [0.576, 0.361],
                    [0.492, -0.512],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.624, -0.241],
                    [-0.066, -0.8],
                    [-0.749, 0.289],
                    [-0.108, 0.673],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.055, -0.666],
                    [-0.748, 0.288],
                    [0.066, 0.8],
                    [0.587, -0.051],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.478, -0.3],
                    [-0.483, 0.77],
                    [0.577, 0.362],
                    [0.491, -0.512],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.054, -0.665],
                    [-0.749, 0.289],
                    [0.066, 0.8],
                    [0.587, -0.052],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.478, -0.3],
                    [-0.483, 0.77],
                    [0.576, 0.362],
                    [0.491, -0.513],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.623, 0.24],
                    [0.066, 0.8],
                    [0.749, -0.289],
                    [0.109, -0.673],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.477, -0.299],
                    [-0.483, 0.769],
                    [0.576, 0.361],
                    [0.492, -0.512],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.478, -0.299],
                    [-0.483, 0.77],
                    [0.576, 0.362],
                    [0.491, -0.512],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.478, -0.3],
                    [-0.483, 0.77],
                    [0.577, 0.361],
                    [0.491, -0.512],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.478, -0.3],
                    [-0.484, 0.77],
                    [0.576, 0.361],
                    [0.492, -0.512],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.477, -0.3],
                    [-0.483, 0.77],
                  ],
                  o: [
                    [-0.479, -0.301],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.244, 0.663],
                    [0.576, 0.361],
                    [0.483, -0.77],
                    [-0.48, -0.301],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.559, -0.391],
                    [-0.066, -0.8],
                    [-0.749, 0.289],
                    [0.055, 0.665],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.244, 0.664],
                    [0.577, 0.362],
                    [0.483, -0.769],
                    [-0.48, -0.301],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.209, -0.55],
                    [-0.749, 0.289],
                    [0.066, 0.8],
                    [0.623, -0.24],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.559, 0.391],
                    [0.066, 0.8],
                    [0.749, -0.289],
                    [-0.055, -0.666],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.243, 0.663],
                    [0.576, 0.361],
                    [0.483, -0.77],
                    [-0.479, -0.301],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.559, 0.39],
                    [0.066, 0.8],
                    [0.749, -0.289],
                    [-0.055, -0.666],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.244, 0.663],
                    [0.576, 0.362],
                    [0.483, -0.77],
                    [-0.48, -0.301],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.209, 0.55],
                    [0.749, -0.289],
                    [-0.066, -0.801],
                    [-0.623, 0.24],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.244, 0.663],
                    [0.577, 0.362],
                    [0.483, -0.77],
                    [-0.48, -0.301],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.243, 0.663],
                    [0.576, 0.362],
                    [0.483, -0.769],
                    [-0.48, -0.301],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.243, 0.663],
                    [0.576, 0.361],
                    [0.483, -0.77],
                    [-0.479, -0.301],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.244, 0.663],
                    [0.577, 0.361],
                    [0.483, -0.77],
                    [-0.48, -0.301],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.243, 0.663],
                    [0.577, 0.361],
                    [0.483, -0.77],
                  ],
                  v: [
                    [99.341, 7.753],
                    [97.698, 8.147],
                    [61.121, -14.797],
                    [68.286, -17.56],
                    [90.883, -3.385],
                    [91.228, -1.742],
                    [93.146, -2.482],
                    [92.978, -4.531],
                    [91.334, -4.135],
                    [68.522, -18.445],
                    [60.368, -15.301],
                    [60.371, -15.267],
                    [39.75, -28.203],
                    [24.286, -22.238],
                    [13.035, -29.297],
                    [12.401, -36.986],
                    [13.282, -38.76],
                    [11.807, -39.685],
                    [10.569, -37.714],
                    [11.68, -36.707],
                    [12.341, -28.698],
                    [23.266, -21.845],
                    [10.823, -17.047],
                    [-30.547, -42.999],
                    [-50.557, -35.281],
                    [-74.477, -50.285],
                    [-75.156, -58.523],
                    [-50.176, -42.853],
                    [-32.529, -49.659],
                    [3.428, -27.103],
                    [3.773, -25.461],
                    [5.693, -26.201],
                    [5.523, -28.249],
                    [3.88, -27.853],
                    [-32.294, -50.546],
                    [-49.94, -43.74],
                    [-75.245, -59.613],
                    [-75.76, -65.862],
                    [-90.109, -74.862],
                    [-97.299, -72.091],
                    [-98.69, -72.651],
                    [-99.927, -70.68],
                    [-98.451, -69.755],
                    [-97.237, -71.319],
                    [-90.346, -73.977],
                    [-76.456, -65.263],
                    [-73.505, -29.478],
                    [-63.591, -23.258],
                    [-63.641, -23.238],
                    [-61.135, 7.162],
                    [8.646, 50.936],
                    [28.464, 43.294],
                    [64.469, 65.88],
                    [64.964, 71.873],
                    [64.082, 73.648],
                    [65.557, 74.575],
                    [66.795, 72.602],
                    [65.685, 71.596],
                    [65.164, 65.282],
                    [40.225, 49.638],
                    [52.206, 45.018],
                    [58.267, 48.82],
                    [58.613, 50.464],
                    [60.531, 49.724],
                    [60.362, 47.674],
                    [58.719, 48.07],
                    [52.441, 44.132],
                    [39.44, 49.146],
                    [28.699, 42.409],
                    [8.881, 50.05],
                    [-60.44, 6.565],
                    [-61.714, -8.897],
                    [-33.805, 8.61],
                    [-33.278, 15.003],
                    [-34.159, 16.776],
                    [-32.682, 17.703],
                    [-31.445, 15.73],
                    [-32.555, 14.724],
                    [-33.109, 8.013],
                    [-61.758, -9.958],
                    [-61.797, -9.896],
                    [-62.861, -22.799],
                    [-24.979, 0.964],
                    [-23.322, 21.073],
                    [-2.255, 34.286],
                    [-1.911, 35.93],
                    [0.008, 35.19],
                    [-0.161, 33.141],
                    [-1.805, 33.536],
                    [-22.627, 20.474],
                    [-24.193, 1.456],
                    [-13.792, 7.982],
                    [-12.843, 19.495],
                    [19.487, 39.776],
                    [40.001, 31.865],
                    [41.391, 32.427],
                    [42.628, 30.456],
                    [41.151, 29.529],
                    [39.938, 31.094],
                    [19.724, 38.889],
                    [-12.148, 18.897],
                    [-13.007, 8.474],
                    [12.234, 24.306],
                    [43.161, 12.38],
                    [69.716, 29.039],
                    [70.061, 30.681],
                    [71.98, 29.943],
                    [71.811, 27.893],
                    [70.167, 28.289],
                    [43.396, 11.495],
                    [12.469, 23.421],
                    [-54.838, -18.801],
                    [-49.466, -20.872],
                    [-48.786, -20.445],
                    [-36.986, -24.996],
                    [8.654, 3.633],
                    [14.719, 1.293],
                    [14.709, 1.309],
                    [21.188, 5.372],
                    [31.32, 1.466],
                    [39.631, 6.678],
                    [39.977, 8.321],
                    [41.896, 7.581],
                    [41.727, 5.533],
                    [40.083, 5.928],
                    [31.557, 0.579],
                    [21.423, 4.487],
                    [15.719, 0.909],
                    [30.849, -4.927],
                    [66.112, 17.194],
                    [66.458, 18.837],
                    [68.376, 18.097],
                    [68.207, 16.048],
                    [66.565, 16.443],
                    [31.084, -5.813],
                    [8.888, 2.746],
                    [-35.964, -25.389],
                    [-24.362, -29.865],
                    [-15.448, -24.272],
                    [-15.104, -22.629],
                    [-13.185, -23.37],
                    [-13.354, -25.418],
                    [-14.997, -25.023],
                    [-24.128, -30.75],
                    [-36.75, -25.883],
                    [-37.43, -26.309],
                    [-49.231, -21.758],
                    [-66.844, -32.806],
                    [-67.31, -32.065],
                    [-50.252, -21.365],
                    [-55.622, -19.293],
                    [-72.81, -30.076],
                    [-74.387, -49.195],
                    [-50.794, -34.396],
                    [-30.781, -42.112],
                    [10.589, -16.161],
                    [39.516, -27.316],
                    [97.245, 8.898],
                    [97.591, 10.542],
                    [99.509, 9.802],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.0706, 0.6392, 0.7373] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [720.021, 472.697], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 23,
    },
    {
      ty: 4,
      nm: "background Outlines",
      sr: 1,
      st: 0,
      op: 120,
      ip: 120,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [576, 360, 0] },
        s: { a: 0, k: [100, 100, 100] },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [576, 360, 0] },
        r: { a: 0, k: 0 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 1",
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-576, 360],
                    [576, 360],
                    [576, -360],
                    [-576, -360],
                  ],
                },
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.149, 0.702, 0.8196] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [576, 360], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 24,
    },
  ],
  v: "4.5.5",
  fr: 30,
  op: 120,
  ip: 0,
  assets: [],
};
